import { FC, useState } from 'react';
import React from 'react';
import { ProfessionalExperience } from 'src/types';
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  CardMedia,
  Divider,
  useMediaQuery,
  Fab,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import {
  Business,
  CalendarTodayOutlined,
  PersonPinOutlined,
} from '@mui/icons-material';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import moment, { Moment } from "moment";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Theme } from 'src/theme';
import { getCorrectLabel, getCorrectIndustryLabel } from 'src/utils/getCorrectLabel';
import { jobTypes } from 'src/utils/getJobTypes';
import { skillLevelOptions } from 'src/utils/getSkillLevelOptions';
import personIcon from 'src/assets/images/icons/attribution_black_24dp.svg';
import getCoinType from 'src/utils/getCoinType';
import useIndustriesGetter from "src/hooks/useIndustriesGetter";
import Modal from 'src/components/ModalCurriculum/ModalCurriculum';

const useStyles = makeStyles((theme: Theme) => ({
  texts: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    alignItems: 'center',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '12px',
    letterSpacing: '0.25px',
    color: '#000000'
  },
  titles: {
    verticalAlign: 'middle',
    alignItems: 'center',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.25px',
  },
  subtitles: {
    verticalAlign: 'middle',
    display: 'block',
    alignItems: 'center',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '0.25px',
  },
  description: {
    wordBreak: 'break-word',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    whiteSpace: 'pre-line'
  },
  iconBtns: {
    marginTop: 'inherit',
    height: 15,
    width: 15,
    marginRight: 20,
    color: 'rgba(0, 0, 0, 0.54)'
  },
  iconSVG: {
    color: 'rgba(0, 0, 0, 0.54)',
    width: 15,
    height: 15,
    fontSize: 15
  },
  iconSVGPerformance: {
    color: 'rgba(0, 0, 0, 0.54)',
    width: 22,
    height: 22,
    fontSize: 22,
  },
  icon: {
    color: theme.palette.icon.main,
    width: '15px',
  },
  fab: {
    padding: '10px 15px',
    marginTop: '10px',
    border: 'none',
    '&:first-child': {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.primary.main,
    },
    '&:last-child': {
      backgroundColor: '#F44336',
      color: '#FFF',
    },
  },
  button: {
    padding: '10px 15px',
    marginTop: '10px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    '&:first-child': {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.primary.main,
      border: 'none'
    },
    '&:last-child': {
      backgroundColor: '#F44336',
      color: '#FFF',
      border: 'none'
    },
  },
}));

interface ProfessionalExperienceCardProps {
  professionalExperience: ProfessionalExperience;
  withActions?: boolean;
  index: number;
  length: number;
  onEdit?: React.MouseEventHandler<HTMLButtonElement>;
  onDelete?: React.MouseEventHandler<HTMLButtonElement>;
  elevation?: number;
}

const ProfessionalExperienceCard: FC<ProfessionalExperienceCardProps> = ({
  professionalExperience,
  withActions,
  index,
  length,
  onEdit,
  onDelete,
  elevation,
}) => {
  const classes = useStyles();
  const [deleteData, setDeleteData] = useState(false);
  const industries = useIndustriesGetter()

  const closeModalMessage = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDeleteData(false);
    onDelete(event);
    return;
  }

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true,
  });

  const handleWithFormatDate = (timestamp: Date | Moment) => {
    if (timestamp) {
      let date = moment.utc(timestamp);

      return date.isValid() ? date.format('DD/MM/YYYY') : '';
    }
    return '';
  };

  return (
    <Card elevation={elevation}>
      <CardContent>
        <Grid spacing={isSmallScreen ? 5 : 2}>
          <Grid xs={12} spacing={2}>
            <Grid spacing={2}>
              <Grid xs={12} style={{ display: 'flex', marginBottom: 18, gap: 10 }}>
                <PersonPinOutlined className={classes.iconSVGPerformance} />
                <Typography variant="h5" className={classes.titles}>
                  {professionalExperience.performance}
                </Typography>
              </Grid>
            </Grid>
            <Grid spacing={2} alignItems="center" style={{ marginTop: 20 }}>
              <Grid xs={12} style={{ display: 'flex', gap: 10 }}>
                <Box style={{ display: 'flex', gap: 10 }}>
                  <Business className={classes.iconSVG} />
                  <Typography variant="h5" className={classes.subtitles}>
                    {professionalExperience.company}
                  </Typography>
                </Box>
                {!isSmallScreen && <Typography variant="subtitle1" className={classes.texts} style={professionalExperience.industry?.id === 1 ? {display: 'none'} : {}}>
                  {getCorrectIndustryLabel(
                    industries,
                    professionalExperience.industry?.id
                  )}
                </Typography>}
              </Grid>
              {isSmallScreen && <Typography style={(professionalExperience.industry?.id === 1) ? {} :{ marginLeft: 26, marginTop: 8 }} variant="subtitle1" className={classes.texts}>
                {getCorrectIndustryLabel(
                  industries,
                  professionalExperience?.industry?.id
                )}
              </Typography>}
              <Grid xs={12} style={{ marginTop: 15 }}>
                <Grid spacing={4} alignItems="center" style={{ display: 'flex', flexWrap: 'wrap', gap: 10 }}>
                  <Grid xs={5}>
                    {professionalExperience.salary > 0 && (
                      <Typography variant="subtitle1" className={classes.texts}>
                        <LocalAtmIcon className={classes.iconSVG} />
                        &ensp;
                        {getCoinType(`${professionalExperience.currency}`, professionalExperience.salary)}
                      </Typography>
                    )}
                    {professionalExperience.salary < 1 && (
                      <Typography variant="subtitle1" className={classes.texts}>
                        <LocalAtmIcon className={classes.iconSVG} />
                        &ensp;Não informado
                      </Typography>
                    )}
                  </Grid>
                  {professionalExperience.skillLevel && professionalExperience.skillLevel !== "OTHER" && <Grid xs={5}>
                    <Typography variant="subtitle1" className={classes.texts}>
                      <CardMedia
                        className={classes.iconSVG}
                        component="img"
                        src={personIcon}
                      ></CardMedia>
                      &ensp;
                      {getCorrectLabel(
                        skillLevelOptions,
                        professionalExperience.skillLevel
                      )}
                    </Typography>
                  </Grid>}
                  {professionalExperience.jobType && professionalExperience.jobType !== "OTHER" && <Grid item xs={5}>
                    <Typography variant="subtitle1" className={classes.texts}>
                      <CardTravelIcon className={classes.iconSVG} />
                      &ensp;
                      {getCorrectLabel(
                        jobTypes,
                        professionalExperience.jobType
                      )}
                    </Typography>
                  </Grid>}
                  <Grid item style={{ display: 'flex', gap: '10px 15px', flexWrap: 'wrap' }} xs={12}>
                    <Typography variant="subtitle1" className={classes.texts}>
                      <CalendarTodayOutlined className={classes.iconSVG} />
                      &ensp;
                      {handleWithFormatDate(professionalExperience.startDate)}
                    </Typography>
                    {professionalExperience.currentWork === 'NOT_CURRENT' && (
                      <Typography variant="subtitle1" className={classes.texts}>
                        <CalendarTodayOutlined className={classes.iconSVG} />
                        &ensp;
                        {handleWithFormatDate(professionalExperience.endDate)}
                      </Typography>
                    )}
                    {professionalExperience.currentWork === 'CURRENT' && (
                      <Typography variant="subtitle1" className={classes.texts}>
                        <CalendarTodayOutlined className={classes.iconSVG} />
                        &ensp;Trabalho atualmente neste cargo
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {professionalExperience.description &&
                <>
                  <Grid xs={12} style={{ marginTop: 20 }}>
                    <Typography variant="h5" color="textPrimary" style={{ fontSize: 12 }}>Descrição</Typography>
                  </Grid>
                  <Grid xs={12}>
                    <Typography color="textPrimary" className={classes.description}>
                      {professionalExperience.description}
                    </Typography>
                  </Grid>
                </>
              }
            </Grid>
          </Grid>
          {professionalExperience.isTemporary && (
            <Box style={{ marginTop: 12 }}>
              <Typography 
                style={{ fontFamily: 'Nunito', fontSize: 12, color: 'red' }}
              >
                Complete as informações obrigatórias ou a experiência não será salva
              </Typography>
            </Box>
          )}
        </Grid>
        {!isSmallScreen && withActions && (
          <Box
            display="flex"
            alignItems="flex-end"
            p={1}
            m={1}
            height="100%"
            justifyContent="flex-end"
          >
            <IconButton
              aria-label="edit"
              color="inherit"
              onClick={onEdit}
              className={classes.iconBtns}
              size="large">
              <EditIcon className={classes.icon} />
            </IconButton>
            <IconButton
              aria-label="delete"
              color="inherit"
              onClick={() => setDeleteData(true)}
              className={classes.iconBtns}
              size="large">
              <DeleteIcon className={classes.icon} />
            </IconButton>
          </Box>
        )}
        {/* {isSmallScreen && withActions && 
          <Box display="flex" alignItems="center" justifyContent="space-around">
            <Button aria-label="edit" onClick={onEdit} className={classes.button}>
              Editar
            </Button> 

            <Button aria-label="delete" onClick={() => setDeleteData(true)} className={classes.button}>
              Deletar
            </Button> 
          </Box>} */}

        {isSmallScreen && withActions &&
          <Box display="flex" alignItems="center" justifyContent="space-around" style={{ margin: '0px 30px 20px' }}>
            <Fab size="small" aria-label="edit" onClick={onEdit} className={classes.fab}>
              <EditIcon />
            </Fab>

            <Fab size="small" aria-label="delete" onClick={() => setDeleteData(true)} className={classes.fab}>
              <DeleteIcon />
            </Fab>
          </Box>}
        {index !== length - 1 && <Box mt={2} mr={0} ml={1}>
          <Divider variant="middle" style={isSmallScreen ? { backgroundColor: "0000001F" } : { backgroundColor: "0000001F" }} />
        </Box>}
      </CardContent>
      <Modal open={deleteData}
        title="Você deseja excluir a informação do currículo?"
        subtitle="Se você confirmar, os dados salvos serão apagados permanentemente."
        btnLeftLabel="Cancelar"
        btnRightLabel="Sim, desejo excluir"
        onLeftButtonClick={() => setDeleteData(false)}
        onRightButtonClick={closeModalMessage} />

    </Card>
  );
};


ProfessionalExperienceCard.defaultProps = {
  withActions: false,
  onEdit: () => { },
  onDelete: () => { },
  elevation: 0
};

export default ProfessionalExperienceCard;
