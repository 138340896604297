import type { FC, ReactNode } from 'react';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated, userType, isLogout, userProfile } = useAuth();
  const history = useHistory();
  const path = history.location.pathname;
  const params = history.location.search;
  const AUTH_URL = process.env.REACT_APP_AUTH_URL;
  const encodedPath = window.btoa(path + params);

  const userIsInsights =
    userProfile?.associatedCompanies[0]?.company?.typeService?.includes(
      'insights'
    );

  const userIsAll =
    userProfile?.associatedCompanies[0]?.company?.typeService?.includes('all');

  if (!isAuthenticated && !isLogout) {
    window.location.href = `${AUTH_URL}?action=job_open&reference=${encodedPath}`;
  } else if (!userType.isCompany()) {
    if (userType.isCandidate()) {
      return <Redirect to={{ pathname: '/candidate/jobs' }} />;
    }

    if (
      (userType.isCandidate() && userIsInsights) ||
      (userType.isCompany() && userIsInsights)
    ) {
      return <Redirect to={{ pathname: '/insights' }} />;
    }

    return <Redirect to="/404" />;
  }

  if (userType.isCompany() && userIsInsights) {
    return <Redirect to={{ pathname: '/insights' }} />;
  } else if (userType.isCompany() && !userIsInsights) {
    return <>{children}</>;
  } else {
    return <Redirect to="/404" />;
  }
};

export default AuthGuard;
