import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box, Card,
  CardContent, Fab, Grid,
  IconButton,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import type { FC } from "react";
import React, { useState } from 'react';
import Modal from 'src/components/ModalCurriculum/ModalCurriculum';
import { Theme } from "src/theme";
import { Language } from "src/types";

const levelOptionsToLabel = {
  "NATIVE": 'Nativo',
  "FLUENT": 'Fluente',
  "INTERMEDIARY": 'Intermediário',
  "BASIC": 'Básico',
  "OTHER": "Outro"
}

const useStyles = makeStyles((theme: Theme) => ({

  heigthCard: {
    height: '32px',
    borderRadius: 10,
    border: '1px solid',
  },
  marginContent: {
    marginLeft: '1.9vw'
  },
  iconSVG: {
    color: theme.palette.icon.main,
    width: '15px',
  },
  textLanguage: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '12px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.25px',
    maxWidth: 100,
    [theme.breakpoints.down('md')] : {
      flexDirection: 'column',
      justifyContent: 'center',
      fontSize: '16px',
      lineHeight: '16px',
    },
  },
  textFluency: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '12px',
    display: 'flexDirection',
    alignItems: 'center',
    letterSpacing: '0.25px',
    [theme.breakpoints.down('md')] : {
      flexDirection: 'column',
      justifyContent: 'center',
      fontSize: '14px',
      marginTop: '2px',
      width: '90px',
      textAlign: 'center'
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    background: theme.palette.chip.main,
    borderRadius: '60px', 
    height: '32px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      padding: 30,
      alignSelf: 'center',
      textAlign: 'center'
    }
  },
  fab: {
    padding: '10px 15px', 
    marginTop: '10px',
    border: 'none',
    '&:first-child': {
      color:  theme.palette.background.default,
      backgroundColor: theme.palette.primary.main,
    },
    '&:last-child': {
      backgroundColor: '#F44336',
      color: '#FFF',
      marginLeft: '10px'
    },
  },
  button: {
    padding: '10px 15px', 
    margin: '10px 5px 0px',
    color:  theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.primary.main}` ,
    borderRadius: 10,
    '&:first-child': {
      color:  theme.palette.background.default,
    backgroundColor: theme.palette.primary.main,
    border: 'none'
    }
  },
}));

interface LanguageCardProps {
  language: Language,
  withActions?: boolean,
  onEdit?: React.MouseEventHandler<HTMLButtonElement>,
  onDelete?: React.MouseEventHandler<HTMLButtonElement>,
  elevation?: number
}

const LanguageCard: FC<LanguageCardProps> = ({ language, withActions, onEdit, onDelete, elevation }) => {
  const classes = useStyles();
  const [deleteData, setDeleteData] = useState(false);

  const closeModalMessage = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDeleteData(false);
    onDelete(event);
    return;
  }

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true,
  });

  return (
    <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
      <Card elevation={elevation} >
        <CardContent style={isSmallScreen ? {display: 'flex', padding: "16px 0px"} : null}>
          <Box className={classes.content}>
            <Typography variant="subtitle1"
              className={classes.textLanguage}>
                {/* &ensp; */}
              {language.language}
            </Typography>
            <Typography variant="body1" className={classes.textFluency}>
              {` ${levelOptionsToLabel[language.level]}`}
            </Typography>
        
            {!isSmallScreen && withActions &&
              <Box
                marginLeft="10px"
              >
                <IconButton aria-label="edit" color="inherit" size="small"
                  onClick={onEdit}>
                  <EditIcon className={classes.iconSVG} />
                </IconButton>
                <IconButton aria-label="delete" color="inherit" size="small"
                  onClick={() => setDeleteData(true)} style={{ marginLeft: 10 }}>
                  <DeleteIcon className={classes.iconSVG} />
                </IconButton>
              </Box>
              
            }
            
          </Box>
          {isSmallScreen && withActions && 
          <Box display="flex" alignItems="center" justifyContent="space-around" style={{margin: '0px 20px 20px'}}>
          <Fab size="small" aria-label="edit" onClick={onEdit} className={classes.fab}>
            <EditIcon />
            </Fab> 

            <Fab size="small" aria-label="delete" onClick={() => setDeleteData(true)} className={classes.fab}>
            <DeleteIcon />
            </Fab> 
          </Box>}
        </CardContent>
        <Modal open={deleteData}
          title="Você deseja excluir a informação do currículo?"
          subtitle="Se você confirmar, os dados salvos serão apagados permanentemente."
          btnLeftLabel="Cancelar"
          btnRightLabel="Sim, desejo excluir"
          onLeftButtonClick={() => setDeleteData(false)}
          onRightButtonClick={closeModalMessage} />
      </Card>
    </Grid>
  );
}

LanguageCard.defaultProps = {
  withActions: false,
  onEdit: () => { },
  onDelete: () => { },
  elevation: 0
}

export default LanguageCard
