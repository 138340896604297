import {
  AddCircle,
  Business,
  Edit,
  LockOpenOutlined,
  NoteAddOutlined,
  NoteAltOutlined,
  OpenInNew,
  Person,
  ReceiptLong,
  Repeat
} from '@mui/icons-material';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import useAuth from 'src/hooks/useAuth';
import { companyService } from 'src/services';
import { Theme } from 'src/theme';
import SwitchCompanyModal from '../TopBar/SwitchCompanyModal';

const useStyles = makeStyles((theme: Theme) => ({
  buttonSelected: {
    borderTopRightRadius: '30px',
    borderBottomRightRadius: '30px',
    '&.Mui-selected': {
      color: theme.palette.primary.main
    }
  },
  text: {
    '& .MuiListItemText-primary': {
      fontFamily: 'Nunito',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: '0.15px'
    }
  },
  titleSection: {
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '0.10px',
    color: theme.palette.text.secondary,
    paddingLeft: '16px',
    paddingBottom: '8px'
  }
}));

const LateralMenu = () => {
  const history = useHistory();
  const classes = useStyles();
  const { userType, userProfile } = useAuth();
  const companyId = userProfile?.associatedCompanies[0]?.company.id;
  const isCompany = userType?.isCompany();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,

    position: number,

    redirect: string
  ) => {
    setSelectedIndex(position);
    history.push(redirect);

    if (position === 6) {
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (
      history.location.pathname.includes('/company/profile/user-management') &&
      !['OWNER', 'ADMIN', 'SUPPORT'].includes(
        userProfile.associatedCompanies[0]?.role
      )
    ) {
      history.push('/company/profile');
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (history.location.pathname.includes('/company/profile')) {
      setSelectedIndex(0);
    }
    if (
      history.location.pathname.includes('/member/profile') ||
      history.location.pathname.includes('/candidate/profile')
    ) {
      setSelectedIndex(1);
    }
    if (
      history.location.pathname.includes('/company/profile/settings') ||
      history.location.pathname.includes('/candidate/profile/settings')
    ) {
      setSelectedIndex(2);
    }
    if (
      history.location.pathname.includes('/company/profile/user-management') ||
      history.location.pathname.includes(
        '/company/profile/user-management/invite-member'
      )
    ) {
      setSelectedIndex(3);
    }
    if (
      history.location.pathname.includes('/company/profile/invoice-management')
    ) {
      setSelectedIndex(4);
    }
    if (history.location.pathname.includes('/company/support')) {
      setSelectedIndex(5);
    }
    if (history.location.pathname.includes('/company/support/create-roles')) {
      setSelectedIndex(7);
    }
    if (history.location.pathname.includes('/company/support/alter-roles')) {
      setSelectedIndex(8);
    }
    if (history.location.pathname.includes('/company/support/edit-companies')) {
      setSelectedIndex(9);
    }
  }, [history.location.pathname]);
  const listItemCompany = ['OWNER', 'ADMIN', 'SUPPORT'].includes(
    userProfile.associatedCompanies[0]?.role
  )
    ? [
        {
          position: 0,
          icon: <Business />,
          text: 'Dados da empresa',
          redirect: '/company/profile'
        },
        {
          position: 4,
          icon: <ReceiptLong />,
          text: 'Faturamento',
          redirect: '/company/profile/invoice-management'
        },
        {
          position: 3,
          icon: <SupervisedUserCircleIcon />,
          text: 'Gerenciar usuários',
          redirect: '/company/profile/user-management'
        }
      ]
    : [
        {
          position: 0,
          icon: <Business />,
          text: 'Dados da empresa',
          redirect: '/company/profile'
        },
        {
          position: 4,
          icon: <ReceiptLong />,
          text: 'Faturamento',
          redirect: '/company/profile/invoice-management'
        }
      ];
  const listItemMembers = [
    {
      position: 1,
      icon: <Person />,
      text: 'Dados pessoais',
      redirect: isCompany ? '/company/member/profile' : '/candidate/profile'
    },
    {
      position: 2,
      icon: <LockOpenOutlined />,
      text: 'Acesso à conta',
      redirect: isCompany
        ? '/company/profile/settings'
        : '/candidate/profile/settings'
    }
  ];

  const listSupportOptions = [
    {
      position: 5,
      icon: <AddCircle />,
      text: 'Criar conta',
      redirect: '/company/support'
    },
    {
      position: 6,
      icon: <Repeat />,
      text: 'Trocar de empresa'
    },
    {
      position: 7,
      icon: <NoteAddOutlined />,
      text: 'Criar cargos',
      redirect: '/company/support/create-roles'
    },
    {
      position: 8,
      icon: <NoteAltOutlined />,
      text: 'Inserir cargos',
      redirect: '/company/support/alter-roles'
    },
    {
      position: 9,
      icon: <Edit />,
      text: 'Editar empresas',
      redirect: '/company/support/edit-companies'
    }
  ];

  const [showModal, setShowModal] = useState(false);

  return (
    <Box sx={{ width: '100%', maxWidth: 260, marginTop: '30px' }}>
      {userProfile.associatedCompanies[0]?.role === 'SUPPORT' && (
        <SwitchCompanyModal
          open={showModal}
          onClose={() => setShowModal(false)}
        />
      )}
      <>
        {isCompany && (
          <List component="nav" aria-label="main mailbox folders">
            <Typography className={classes.titleSection}>
              Perfil da empresa
            </Typography>
            {!['OWNER', 'ADMIN', 'SUPPORT'].includes(
              userProfile.associatedCompanies[0]?.role
            )
              ? listItemCompany.map(
                  ({ position, icon, text, redirect }) =>
                    position !== 3 && (
                      <ListItemButton
                        key={position}
                        className={classes.buttonSelected}
                        selected={selectedIndex === position}
                        onClick={(event: any) =>
                          handleListItemClick(event, position, redirect)
                        }
                      >
                        <ListItemIcon
                          sx={
                            selectedIndex === position
                              ? { color: '#590A9D' }
                              : { color: '#0000008A' }
                          }
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={text} className={classes.text} />
                      </ListItemButton>
                    )
                )
              : listItemCompany.map(({ position, icon, text, redirect }) => (
                  <ListItemButton
                    key={position}
                    className={classes.buttonSelected}
                    selected={selectedIndex === position}
                    onClick={(event: any) =>
                      handleListItemClick(event, position, redirect)
                    }
                  >
                    <ListItemIcon
                      sx={
                        selectedIndex === position
                          ? { color: '#590A9D' }
                          : { color: '#0000008A' }
                      }
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemText primary={text} className={classes.text} />
                  </ListItemButton>
                ))}
          </List>
        )}
      </>
      <List
        component="nav"
        aria-label="main mailbox folders"
        sx={isCompany ? { marginTop: '24px' } : {}}
      >
        {isCompany && (
          <Typography className={classes.titleSection}>Seu perfil</Typography>
        )}
        {listItemMembers.map(({ position, icon, text, redirect }) => (
          <ListItemButton
            component={RouterLink}
            to={{ pathname: redirect }}
            key={position}
            className={classes.buttonSelected}
            selected={selectedIndex === position}
            onClick={(event) => handleListItemClick(event, position, redirect)}
          >
            <ListItemIcon
              sx={
                selectedIndex === position
                  ? { color: '#590A9D' }
                  : { color: '#0000008A' }
              }
            >
              {icon}
            </ListItemIcon>
            <ListItemText primary={text} className={classes.text} />
          </ListItemButton>
        ))}
      </List>
      {['SUPPORT'].includes(userProfile.associatedCompanies[0]?.role) && (
        <List
          component="nav"
          aria-label="main mailbox folders"
          sx={isCompany ? { marginTop: '24px' } : {}}
        >
          {isCompany && (
            <Typography className={classes.titleSection}>Suporte</Typography>
          )}
          {listSupportOptions.map(({ position, icon, text, redirect }) => (
            <ListItemButton
              component={RouterLink}
              to={{ pathname: redirect }}
              key={position}
              className={classes.buttonSelected}
              selected={selectedIndex === position}
              onClick={(event) =>
                handleListItemClick(event, position, redirect)
              }
            >
              <ListItemIcon
                sx={
                  selectedIndex === position
                    ? { color: '#590A9D' }
                    : { color: '#0000008A' }
                }
              >
                {icon}
              </ListItemIcon>
              <ListItemText primary={text} className={classes.text} />
            </ListItemButton>
          ))}
        </List>
      )}
    </Box>
  );
};

export default LateralMenu;
