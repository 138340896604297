import AddIcon from '@mui/icons-material/Add';
import { Box, Chip, Fab, TextField, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { FC, KeyboardEvent, useEffect, useState } from 'react';
import { Theme } from 'src/theme';

interface TagInputProps {
  title?: string;
  tags?: string[];
  onChange(tags: string[]): void;
  editable?: boolean;
  errorMessage?: string;
  showTitle?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    width: 36,
    height: 36,
    border: '1px solid #590A9D',
    boxSizing: 'border-box',
    borderRadius: '4px',
    marginLeft: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#590A9D',
    [theme.breakpoints.down('md')]: {
      border: 'none',
      color: '#FFF',
      backgroundColor: '#590A9D',
      borderRadius: '50%'
    }
  },
  inputColor: {
    '& input': {
      color: theme.palette.table.grayPrimary
    },
    '& .MuiInputBase-root': {
      color: theme.palette.table.grayPrimary
    }
  },
  chipStyle: {
    fontFamily: 'Nunito',
    backgroundColor: theme.palette.chip.main,
    marginLeft: 10,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%'
    },
    [theme.breakpoints.down(500)]: {
      maxWidth: 340,
    },
    [theme.breakpoints.down(414)]: {
      maxWidth: 150
    }
  }
}));

const TagInput: FC<TagInputProps> = ({
  title,
  tags,
  onChange,
  editable,
  errorMessage,
  showTitle
}) => {
  const [tagValue, setTagValue] = useState('');
  const [_tags, _setTags] = useState<string[]>(tags);
  const [changed, setChanged] = useState(false);
  const [inputError, setInputError] = useState('');
  const letterRegexx = /[a-zA-Z]/;
  const classes = useStyles();

  useEffect(() => {
    if (!changed) {
      _setTags(tags);
    }
  }, [tags, _tags, changed]);

  const handleInsert = () => {
    if (tagValue !== '') {
      _tags.push(tagValue);
      _setTags(_tags);
      setChanged(true);
      setTagValue('');
    }
  };

  function handleDelete(value) {
    let temp = _tags.filter(function(_, index) {
      return index !== value;
    });
    onChange(temp);
    _setTags(temp);
    setChanged(true);
  }

  function handleKeyPress(e: KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleButtonClick();
    }
  }

  function handleButtonClick() {
    if (
      tagValue.trim() &&
      verifyItem(tagValue) === false &&
      _tags.length < 10 &&
      letterRegexx.test(tagValue.trim())
    ) {
      handleInsert();
      setInputError('');
    } else if (_tags.length >= 10) {
      setInputError('Limite de habilidades atingido.');
    } else if (
      tagValue.trim() &&
      verifyItem(tagValue) === true &&
      _tags.length < 10
    ) {
      setInputError('Habilidade já existe.');
    } else if (!letterRegexx.test(tagValue.trim())) {
      setInputError('Nome de habilidade inválido.');
    } else {
      setInputError(errorMessage);
    }
  }

  const verifyItem = input => {
    let result = false;
    _tags.map(tag => {
      if (tag.toLowerCase() === input.toLowerCase()) {
        result = true;
      }
      return tag;
    });
    return result;
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      className={classes.inputColor}
      width="100%"
      style={{ marginBottom: '30px' }}
      onBlur={() => onChange(_tags)}
    >
      {editable ? (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            onChange={e => {
              setTagValue(e.target.value);
              if (e.target.value.trim() === '') {
                setInputError('');
              }
            }}
            value={tagValue}
            label={title}
            color="primary"
            variant="standard"
            onKeyPress={handleKeyPress}
            error={Boolean(inputError)}
            helperText={inputError}
            style={{ marginBottom: '10px', width: '70%', alignSelf: 'center' }}
            fullWidth
            inputProps={{
              maxLength: 50
            }}
          />

          {isSmallScreen ? 
          <Fab className={classes.addButton} onClick={handleButtonClick}>
            <AddIcon />
          </Fab>
           : 
           <Box className={classes.addButton} onClick={handleButtonClick}>
          <AddIcon style={{ width: 20, height: 20 }} />
          </Box>}
        </Box>
      ) : (
        <></>
      )}

      {showTitle && <Typography component="p" style={{ fontFamily: 'Nunito', fontSize: 12 }}>{title}</Typography>}

      <Box style={{ lineHeight: '40px' }}>
        {_tags?.map((tag, id) => (
          <Chip
            key={id}
            label={tag}
            onDelete={
              editable
                ? () => {
                    handleDelete(id);
                  }
                : null
            }
            className={classes.chipStyle}
          />
        ))}
      </Box>
    </Box>
  );
};

export default TagInput;
