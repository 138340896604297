import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as candidaciesReducer } from 'src/slices/candidacies';
import { reducer as candidateCurriculumReducer } from 'src/slices/candidateCurriculum';
import { reducer as candidatesBankReducer } from 'src/slices/candidatesBank';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as insightsTableReducer } from 'src/slices/insights';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as opportunitiesReducer } from 'src/slices/opportunities';
import { reducer as opportunityCandidatesReducer } from 'src/slices/opportunityCandidates';
import { reducer as opportunityTestsReducer } from 'src/slices/opportunityTests';
import { reducer as performanceReducer } from 'src/slices/performance';

const rootReducer = combineReducers({
  opportunities: opportunitiesReducer,
  opportunityTests: opportunityTestsReducer,
  opportunityCandidates: opportunityCandidatesReducer,
  candidacies: candidaciesReducer,
  candidatesBank: candidatesBankReducer,
  insightsTable: insightsTableReducer,
  performance: performanceReducer,
  candidateCurriculum: candidateCurriculumReducer,
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer
});

export default rootReducer;
