import { makeStyles } from '@mui/styles';
import type { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: '#ffffff',
    height: '100%',
    outline: 'none',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFFFFF',
    padding: 18
  },
  arrow: {
    color: '#0000008A',
    cursor: 'pointer',
    position: 'absolute',
    left: 18
  },
  logo: {
    width: 59.65,
    height: 22.57
  },
  avatar: {
    width: 32,
    height: 32
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 564,
    height: '100%',
    margin: '16px 20px 20px 20px',
    [theme.breakpoints.down(596)]: {
      margin: '16px 0 0 0',
      padding: 16
    },
  },
  title: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: 28,
    lineHeight: '123.5%',
    textAlign: 'center',
    letterSpacing: 0.25,
    marginBottom: 20,
    [theme.breakpoints.down(596)]: {
      fontSize: 34,
      textAlign: 'left'
    }
  }
}));