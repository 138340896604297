import { createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import type { Opportunity } from 'src/types/Opportunity';
import { candidateService, companyService } from 'src/services';

interface OpportunitiesState {
  opportunities: Opportunity[];
  total: number;
  countPages: number;
  countByStatus: {
    countOpen: number;
    countDraft: number;
    countClosed: number;
    countPaused: number;
  }
};

const initialState: OpportunitiesState = {
  opportunities: null,
  total: 0,
  countPages: 0,
  countByStatus: {
    countOpen: 0,
    countDraft: 0,
    countClosed: 0,
    countPaused: 0
  }
};

const slice = createSlice({
  name: 'opportunities',
  initialState,
  reducers: {
    getOpportunities(state: OpportunitiesState, action) {
      state.opportunities = action.payload.items || action.payload;
      state.total = action.payload.meta ? action.payload.meta.totalItems : state.opportunities.length;
      state.countPages = action.payload.meta.totalPages
      state.countByStatus = {
        countOpen: action.payload.meta.totalJobs?.totalOpen ?? 0,
        countDraft: action.payload.meta.totalJobs?.totalDraft ?? 0,
        countClosed: action.payload.meta.totalJobs?.totalClosed ?? 0,
        countPaused: action.payload.meta.totalJobs?.totalPaused ?? 0
      }
    },
    handleApply(state: OpportunitiesState, action) {
      state.opportunities = state.opportunities.map((opportunity, index) => {
        if (opportunity.id === action.payload) {
          opportunity.amountJobApplications += 1;
          opportunity.application.isApplied = true;
        }
        return opportunity;
      })
    },
    handleFinishOpportunityStatus(state: OpportunitiesState, action) {
      state.opportunities = state.opportunities.map((opportunity, index) => {
        if (opportunity.id === action.payload) {
          opportunity.status = 'CLOSED'
        }
        return opportunity;
      })
    }
  }
});

export const reducer = slice.reducer;

export const getOpportunities = (params = ""): AppThunk => async (dispatch) => {
  const response = await candidateService.getAllOpportunities({ params });
  dispatch(slice.actions.getOpportunities(response.data));
};

export const getOpportunitiesByCompanyId = (id, params?): AppThunk => async (dispatch) => {
  const response = await companyService.getAllOpportunitiesByCompnayId({ id, params });
  dispatch(slice.actions.getOpportunities(response.data));
};


export const handleApply = (id: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.handleApply(id));
};

export const handleFinishOpportunityStatus = (id: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.handleFinishOpportunityStatus(id))
}

export default slice;
