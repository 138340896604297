import React, { useState, useEffect } from 'react';

import type { FC } from 'react';

import {
  useStripe,
  useElements,
  PaymentElement
} from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import { Alert, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';

import { useStyles } from './style';
import { companyService } from 'src/services';

interface StripeFormProps {
  onReady?: (loading: boolean) => void;
  companyId?: string;
  handleClose?: () => void;
}

export const StripeForm: FC<StripeFormProps> = ({
  onReady,
  companyId,
  handleClose
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [errorMessage, setErrorMessage] = useState<{
    type: any;
    message: string;
  }>({
    type: 'success',
    message: ''
  });

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [sendRequest, setSendRequest] = useState(false);
  onReady(loading);

  const openModalRedirect = () => {
    unlock();
    if (history.location.pathname.includes('company/registered-candidates')) {
      const searchParams = new URLSearchParams(history.location.search);
      searchParams.set('subscribeModal', 'true');
      window.location.replace(
        `${history.location.pathname}?${searchParams.toString()}`
      );
    } else {
      window.location.reload();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    try {
      setSendRequest(true);
      const response = await stripe.confirmSetup({
        elements,
        redirect: 'if_required'
      });

      if (response.error) {
        setErrorMessage({ type: 'error', message: response.error.message });
      } else {
        await companyService.intentSetupNotification(companyId);
        setErrorMessage({
          type: 'success',
          message: 'Sucesso! O seu meio de pagamento foi salvo.'
        });
        enqueueSnackbar('Sucesso! O seu meio de pagamento foi salvo.', {
          variant: 'success'
        });
        unlock();
        handleClose();
        openModalRedirect();
      }
    } catch (error) {
      unlock();
      console.error(error);
    } finally {
      unlock();
      setSendRequest(false);
    }
  };

  const [isBlock, setIsBlock] = useState(false);

  const onBeforeUnload = (event: any) => {
    event.preventDefault();
    event.returnValue = '';
  };

  useEffect(() => {
    if (isBlock) {
      window.addEventListener('beforeunload', onBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [isBlock]);

  const block = () => {
    if (!isBlock) setIsBlock(true);
  };

  const unlock = () => {
    if (isBlock) setIsBlock(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      {errorMessage?.message && (
        <Alert style={{ marginBottom: '12px' }} severity={errorMessage?.type}>
          {errorMessage?.message}
        </Alert>
      )}
      <PaymentElement onReady={() => setLoading(false)} onChange={block} />
      {!loading && (
        <LoadingButton
          loading={sendRequest}
          type="submit"
          fullWidth
          variant="contained"
          disabled={!stripe || !elements || sendRequest}
          className={classes.button}
        >
          Salvar
        </LoadingButton>
      )}
    </form>
  );
};
