import type { FC } from 'react';
import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  AddCircle,
  Business,
  Close,
  Edit,
  LockOpenOutlined,
  LogoutOutlined,
  NoteAddOutlined,
  NoteAltOutlined,
  OpenInNew,
  Person,
  Receipt,
  ReceiptLong,
  Repeat,
  WarningAmber,
  WhatsApp
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import brokenImage from 'src/assets/images/broken-image.svg';
import defaultCompanyAvatar from 'src/assets/images/company-avatar-small.svg';
import noImage from 'src/assets/images/no-image.svg';
import useAuth from 'src/hooks/useAuth';
import { Theme } from 'src/theme';
import { convertImageSize } from 'src/utils/handleImagePath';
import { companyService } from 'src/services';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    height: '36px',
    width: '36px',
    marginRight: '16px'
  },
  popover: {
    width: '100%',
    maxWidth: 280,
    backgroundColor: theme.palette.background.default,
    color: '#000000DE' //TODO: change tehem text color
  },
  textUser: {
    color: theme.palette.text.black,
    marginRight: theme.spacing(1)
  },
  changeHover: {
    fontSize: 14,
    fontWeight: 500
  },
  iconColor: {
    color: theme.palette.text.disabled,
    cursor: 'pointer'
  },
  mobileDrawer: {
    width: '80%',
    maxWidth: '300px'
  },
  text: {
    '& .MuiListItemText-primary': {
      fontFamily: 'Nunito',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: '#000000DE'
    }
  },
  icon: {
    color: theme.palette.icon.default,
    marginRight: '8px'
  },
  titleBar: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: '20px',
    letterSpacing: '0.15px',
    lineHeight: '32px'
  },
  boxBusinessInfo: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 12px'
  },
  titleSection: {
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '0.10px',
    color: theme.palette.text.secondary,
    paddingLeft: '16px',
    paddingBottom: '8px'
  },
  memberName: {
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.15px'
  },
  companyName: {
    fontFamily: 'Nunito',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '17px',
    letterSpacing: '0.15px',
    color: theme.palette.text.secondary
  }
}));

interface AccountMobileProps {
  handleOpen: boolean;
  handleClose: () => void;
  setShowModal: () => void;
}

const AccountMobile: FC<AccountMobileProps> = ({
  handleOpen,
  handleClose,
  setShowModal
}) => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef<any>(null);
  const { userProfile, logout, userType } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [hasShownWarning, setHasShownWarning] = useState(false);
  const isCompany = userType?.isCompany();
  const isCandidate = userType?.isCandidate();
  const phoneNumber = process.env.REACT_APP_WHATSAPP_REDIRECT;

  const name = userType.isCandidate()
    ? userProfile?.user?.name
      ? userProfile?.user?.name
      : userProfile?.user?.email?.split('@')[0]
    : (
        userProfile?.associatedCompanies[0]?.company?.name
          ? userProfile?.associatedCompanies[0]?.company?.name
          : userProfile?.associatedCompanies[0]?.company?.email?.split('@')[0]
      )
    ? userProfile?.associatedCompanies[0]?.company?.businessName
      ? userProfile?.associatedCompanies[0]?.company?.businessName
      : userProfile?.associatedCompanies[0]?.company?.email?.split('@')[0]
    : '';

  const fantasyName = userProfile?.associatedCompanies[0]?.company?.fantasyName;
  const memberName =
    userType.isCompany() && userProfile?.user?.name !== ''
      ? userProfile?.user?.name
      : userProfile?.user?.email?.split('@')[0];

  const redirectLink = () => {
    let link;
    if (process.env.REACT_APP_PUBLIC_URL.includes('stg'))
      link = 'https://www.stg.gria.com.br';
    else if (
      process.env.REACT_APP_PUBLIC_URL.includes('dev') ||
      process.env.REACT_APP_PUBLIC_URL.includes('localhost')
    )
      link = 'https://www.dev.gria.com.br';
    else link = 'https://www.gria.com.br';
    return window.location.replace(link);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      redirectLink();
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Não foi possível sair', {
        variant: 'error'
      });
    }
  };

  let tempAvatarUrl = userType.isCandidate()
    ? userProfile.user.avatarFileName
    : userProfile.associatedCompanies[0].company.avatarFileName ??
      defaultCompanyAvatar;

  const [avatarUrl, setAvatarUrl] = useState(tempAvatarUrl);

  useEffect(() => {
    setAvatarUrl(tempAvatarUrl);
  }, [tempAvatarUrl]);

  const handleErrorAvatar = () => {
    setAvatarUrl(brokenImage);
  };

  const companyId = userProfile?.associatedCompanies[0]?.company.id;

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    redirect: any
  ) => {
    if (index === 3) {
      setShowModal();
    } else if (index === 4) {
      handleLogout();
    } else if (index === 7) {
      window.open(redirect, '_blank');
    } else if (index === 8) {
      window.open(redirect, '_blank');
    } else {
      history.push(redirect);
    }

    handleClose();
  };

  const accessRouteIsInsights = history.location.pathname.includes('/insights');
  const userIsSupport = ['SUPPORT'].includes(
    userProfile.associatedCompanies[0]?.role
  );

  const listItemCompany =
    ['OWNER', 'ADMIN', 'SUPPORT'].includes(
      userProfile.associatedCompanies[0]?.role
    ) && !accessRouteIsInsights
      ? [
          {
            index: 0,
            icon: <Business />,
            text: 'Dados da empresa',
            redirect: '/company/profile'
          },
          {
            index: 0,
            icon: <ReceiptLong />,
            text: 'Faturamento',
            redirect: '/company/profile/invoice-management'
          },
          {
            index: 0,
            icon: <SupervisedUserCircleIcon />,
            text: 'Gerenciar usuários',
            redirect: '/company/profile/user-management'
          }
        ]
      : accessRouteIsInsights
      ? [
          {
            index: 6,
            icon: <Receipt />,
            text: 'Histórico de testes',
            redirect: '/insights/tests-history'
          }
        ]
      : [
          {
            index: 0,
            icon: <Business />,
            text: 'Dados da empresa',
            redirect: '/company/profile'
          },
          {
            index: 0,
            icon: <ReceiptLong />,
            text: 'Faturamento',
            redirect: '/company/profile/invoice-management'
          }
        ];

  const listItens = accessRouteIsInsights
    ? [
        {
          index: 1,
          icon: <Person />,
          text: 'Dados pessoais',
          redirect: '/insights/member/profile'
        },
        { index: 4, icon: <LogoutOutlined />, text: 'Sair', redirect: '' }
      ]
    : [
        {
          index: 1,
          icon: <Person />,
          text: 'Dados pessoais',
          redirect: isCompany
            ? '/company/member/profile'
            : '/candidate/profile/view'
        },
        {
          index: 2,
          icon: <LockOpenOutlined />,
          text: 'Acesso à conta',
          redirect: isCompany
            ? '/company/profile/settings'
            : '/candidate/profile/settings'
        },
        { index: 4, icon: <LogoutOutlined />, text: 'Sair', redirect: '' }
      ];

  const listSupportOptions = [
    userIsSupport
      ? {
          index: 5,
          icon: <AddCircle />,
          text: 'Criar conta',
          redirect: '/company/support'
        }
      : {},
    userIsSupport
      ? {
          index: 3,
          icon: <Repeat />,
          text: 'Trocar de empresa',
          redirect: ''
        }
      : {},
    !accessRouteIsInsights
      ? {
          index: 7,
          icon: <WarningAmber />,
          text: 'Reportar problema',
          redirect:
            'https://gria.surveysparrow.com/s/Reporte-de-problema/tt-2vTzvuFvZRwcNBDfW1XkEy'
        }
      : {},
    accessRouteIsInsights
      ? {
          index: 8,
          icon: <WhatsApp />,
          text: 'Ajuda via WhatsApp',
          redirect: `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=Ol%C3%A1%21+Estou+entrando+em+contato+pois+estou+precisando+de+ajuda+com+a+plataforma%21&type=phone_number&app_absent=0`
        }
      : {},
    userIsSupport
      ? {
          index: 9,
          icon: <NoteAddOutlined />,
          text: 'Criar cargos',
          redirect: '/company/support/create-roles'
        }
      : {},
    userIsSupport
      ? {
          index: 10,
          icon: <NoteAltOutlined />,
          text: 'Inserir cargos',
          redirect: '/company/support/alter-roles'
        }
      : {},
    userIsSupport
      ? {
          index: 11,
          icon: <Edit />,
          text: 'Editar empresas',
          redirect: '/company/support/edit-companies'
        }
      : {}
  ].filter((object) => Object.keys(object).length > 0);

  const avatar = userProfile.user.hasAvatar
    ? convertImageSize(userProfile?.user.avatarFileName, 'avatar', 'small')
    : noImage;

  return (
    <>
      <Paper sx={{ display: { lg: 'none', xs: 'block' } }}>
        <Drawer
          anchor="right"
          classes={{ paper: classes.mobileDrawer }}
          onClose={handleClose}
          open={handleOpen}
          variant="temporary"
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '16px'
            }}
          >
            <Typography variant="h6" className={classes.titleBar}>
              Perfil
            </Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <Divider variant="fullWidth" />
          {isCompany && (
            <List component="nav" aria-label="profile-drawer">
              {!accessRouteIsInsights && (
                <Box className={classes.boxBusinessInfo}>
                  <Avatar
                    alt="user-avatar"
                    className={classes.avatar}
                    src={avatar}
                    imgProps={{
                      onError: (event: any) => {
                        event.target.src = handleErrorAvatar();
                      }
                    }}
                  >
                    {avatarUrl && (
                      <img
                        src={brokenImage}
                        className={classes.avatar}
                        style={{ marginRight: 0 }}
                      />
                    )}
                  </Avatar>
                  <Box>
                    <Typography className={classes.memberName}>
                      {memberName}
                    </Typography>
                    <Typography className={classes.companyName}>
                      {fantasyName !== null ? fantasyName : name}
                    </Typography>
                  </Box>
                </Box>
              )}
              <Typography className={classes.titleSection} mt={2}>
                Perfil da empresa
              </Typography>
              {listItemCompany.map(({ index, icon, text, redirect }) => (
                <ListItemButton
                  key={index}
                  onClick={(event) =>
                    handleListItemClick(event, index, redirect)
                  }
                >
                  <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
                  <ListItemText primary={text} className={classes.text} />
                </ListItemButton>
              ))}
            </List>
          )}

          {isCompany && (
            <List component="nav" aria-label="profile-drawer">
              <Typography className={classes.titleSection}>Suporte</Typography>

              {listSupportOptions.map(({ index, icon, text, redirect }) => (
                <ListItemButton
                  key={index}
                  onClick={(event) =>
                    handleListItemClick(event, index, redirect)
                  }
                >
                  <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
                  <ListItemText primary={text} className={classes.text} />
                </ListItemButton>
              ))}
            </List>
          )}

          <List component="nav" aria-label="profile-drawer">
            {isCandidate && (
              <Box className={classes.boxBusinessInfo}>
                <Avatar
                  alt="user-avatar"
                  className={classes.avatar}
                  src={avatar}
                  imgProps={{
                    onError: (event: any) => {
                      event.target.src = handleErrorAvatar();
                    }
                  }}
                >
                  {avatarUrl && (
                    <img
                      src={brokenImage}
                      className={classes.avatar}
                      style={{ marginRight: 0 }}
                    />
                  )}
                </Avatar>
                <Box>
                  <Typography className={classes.memberName}>{name}</Typography>
                </Box>
              </Box>
            )}
            {isCompany && (
              <Typography className={classes.titleSection}>
                Seu perfil
              </Typography>
            )}
            {listItens.map(({ index, icon, text, redirect }) => (
              <ListItemButton
                key={index}
                onClick={(event) => handleListItemClick(event, index, redirect)}
              >
                <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
                <ListItemText primary={text} className={classes.text} />
              </ListItemButton>
            ))}
          </List>
        </Drawer>
      </Paper>
    </>
  );
};

export default AccountMobile;
