import moment from 'moment';

const sortByPropAndDate = (array: any[], propertyName: string, valuesOrder: string[]) => {
  const result = [...array];
  return result.sort((a, b) => {
    const aOrder = valuesOrder.findIndex(value => a[propertyName] === value);
    const bOrder = valuesOrder.findIndex(value => b[propertyName] === value);

    if (a[propertyName] === b[propertyName]) {
      let dateDiff: number;
      if (a?.startDate && a[propertyName] !== "NOT_CURRENT" && a[propertyName] !== "FINISHED") {
        dateDiff = moment(a.startDate).diff(moment(b.startDate));
      } else {
        dateDiff = moment(a?.endDate).diff(moment(b?.endDate));
      }

      if (dateDiff > 0) {
        return -1
      }
      if (dateDiff < 0) {
        return 1
      }

      return 0
    }

    if (aOrder < bOrder) {
      return -1
    } 
    if (aOrder > bOrder) {
      return 1
    }

    return 0
  })
}

export { sortByPropAndDate }