import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import type { OpportunityTest } from 'src/types/opportunityTest';
import api from 'src/api';

interface OpportunityTestState {
  opportunityTests: OpportunityTest[];
};

const initialState: OpportunityTestState = {
  opportunityTests: []
};

const slice = createSlice({
  name: 'opportunityTests',
  initialState,
  reducers: {
    getOpportunityTests(state: OpportunityTestState, action: PayloadAction<OpportunityTest[]>) {
      const opportunityTests = action.payload;
      state.opportunityTests = opportunityTests;
    }
  }
});

export const reducer = slice.reducer;

export const getOpportunityTests = (opportunity: string): AppThunk => async (dispatch) => {
  const response = await api.get<OpportunityTest[]>("test/all", {
    params: Object.assign({ opportunity: opportunity })
  })

  dispatch(slice.actions.getOpportunityTests(response.data));
}

export default slice;
