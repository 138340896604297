import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import {
  Box,
  Button,
  CardMedia,
  Skeleton,
  TextField,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router';
import api from 'src/api';
import personIcon from 'src/assets/images/icons/person_icon.svg';
import noImage from 'src/assets/images/no-image.svg';
import CompanyState from 'src/components/CompanyState';
import useAuth from 'src/hooks/useAuth';
import useIndustriesGetter from 'src/hooks/useIndustriesGetter';
import type { Theme } from 'src/theme';
import type { Opportunity } from 'src/types/Opportunity';
import { getAddress } from 'src/utils/getAddress';
import getCoinType from 'src/utils/getCoinType';
import {
  getCorrectIndustryLabel,
  getCorrectLabel
} from 'src/utils/getCorrectLabel';
import { renderOpportunityDescription } from 'src/utils/renderOpportunityDescription';
import { amountWorkers } from 'src/views/opportunities/opportunitieCreateView/objectLabels';
import TagInput from 'src/views/opportunities/opportunitieCreateView/TagInput';
import ModalFinishResume from 'src/views/opportunities/opportunitieModals/ModalFinishResume';
import AutoCompleteResumeModal from 'src/views/opportunities/opportunitieModals/AutoCompleteResumeModal';
import ModalSyncStatus from 'src/views/opportunities/opportunitieModals/ModalSyncStatus';
import DialogOpportunityApply from 'src/views/opportunities/opportunitieModals/opportunityApply';
import {
  benefitsToLabels,
  jobTypeToLabel,
  levelToLabel,
  opportunityStateToLabel,
  opportunityStateType,
  workloadDescriptionToLabel
} from '../utils/helpers';
import InfoCard from './OpportunitieInfoCard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '84vh',
    paddingBottom: 20
  },
  wrapper: {
    border: '0.5px solid #C4C4C4',
    borderRadius: 6,
    backgroundColor: '#ffffff',
    height: '100%'
  },
  header: {
    display: 'flex'
  },
  highlightText: {
    fontSize: 14,
    fontWeight: 500
  },
  defaultText: {
    fontSize: 14,
    fontWeight: 300
  },
  smallText: {
    fontSize: 12,
    fontWeight: 300,
    lineHeight: '16px'
  },
  informationsText: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.67,
    letterSpacing: 0.25,
    color: '#000'
  },
  computedCreatedAt: {
    fontSize: 12,
    fontWeight: 300,
    lineHeight: '16px',
    marginLeft: 'auto'
  },
  container: {
    padding: '0 1.25rem'
  },
  card: {
    backgroundColor: '#fff',
    height: 158,
    width: '100%',
    borderRadius: 6,
    border: '0.5px solid #C4C4C4',
    padding: '0.875rem 1.25rem',
    overflow: 'hidden'
  },
  icon: {
    marginRight: 5,
    color: 'rgba(0, 0, 0, 0.54)'
  },
  createdAt: {
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  infoContainer: {
    display: 'flex',
    margin: '30px 0 15px',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      flexDirection: 'column'
    }
  },
  button: {
    height: 56,
    width: 240,
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flex: 'none',
      height: 54
    }
  },
  iconSVG: {
    marginRight: 5,
    color: 'rgba(0, 0, 0, 0.54)',
    minWidth: 15,
    width: 15
  },
  img: {
    objectFit: 'cover',
    // height: "100%",
    borderRadius: '50%',
    '& .MuiAvatar-img': {
      height: 'auto',
      background: '#FFFFFF'
    }
  },
  wrapperExternalLink: {
    display: 'flex',
    alignItems: 'flex-end',
    position: 'absolute',
    top: 95,
    right: 20,
    '& button': {
      marginLeft: 10
    }
  },
  externalLink: {
    '& input': {
      padding: 10
    }
  },
  perfectScroll: {
    flexDirection: 'column',
    display: 'flex'
  },
  buttonEnd: {
    display: 'flex',
    padding: theme.spacing(0, 2.5, 2.5, 2.5),
    justifyContent: 'center',
    marginTop: 'auto'
  },
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
}));

interface OpportunitiesDetailProps {
  opportunity: Opportunity;
  className?: string;
  btnLabel?: string;
  onClick?: Function;
  loading?: boolean;
  wrapperClass?: string;
  defaultModal?: boolean;
  openApplyModal?: boolean;
}

const OpportunitieDetail: FC<OpportunitiesDetailProps> = ({
  className,
  wrapperClass,
  opportunity = {},
  btnLabel = 'Candidatar-se',
  onClick = () => {},
  loading = false,
  defaultModal = false,
  openApplyModal = false
}) => {
  const classes = useStyles();
  const containerRef = useRef(null);
  const { userType, isAuthenticated } = useAuth();
  const { isCompany } = userType;
  const history = useHistory();

  useEffect(() => {
    containerRef &&
      containerRef.current &&
      containerRef.current._container.scrollTo(0, 0);
  }, [opportunity]);

  const {
    id,
    city,
    country,
    initialDate,
    currency,
    description,
    finalDate,
    jobType,
    maxSalary,
    minSalary,
    hideSalary,
    skillLevel,
    state,
    status,
    title,
    workLoad,
    company,
    benefits,
    desirableSkills,
    requiredSkills,
    deficiency,
    application
  } = opportunity;
  const computedCreatedAt: string = moment(initialDate).fromNow();
  const [openDialogApply, setOpenDialogApply] = useState(defaultModal);
  const [openModalFinishResume, setOpenModalFinishResume] = useState(false);
  const [openModalSyncResume, setOpenModalSyncResume] = useState(false);
  const [openModalSyncStatus, setOpenModalSyncStatus] = useState('');
  const industries = useIndustriesGetter();
  const address = getAddress(country, state, city);
  const { protocol, host, pathname } = window.location;
  const externalLink =
    pathname.includes('opportunities/general') &&
    protocol +
      '//' +
      host +
      '/' +
      title.replaceAll(/[^\w\s]/gi, '').replaceAll(' ', '%20') +
      '/' +
      id;
  const options = [
    [
      {
        Contrato: jobTypeToLabel[jobType],
        Vagas: opportunity.amountVacancy || '--',
        Candidatos: opportunity?.amountJobApplications || 0
      }
    ],
    [
      {
        Funcionários:
          company &&
          (company.amountIntervalWorkers
            ? getCorrectLabel(amountWorkers, company.amountIntervalWorkers)
            : '--'),
        Segmento: getCorrectIndustryLabel(industries, company.industry.id)
      }
    ]
  ];
  const [markup, setMarkup] = useState('');

  useEffect(() => {
    if (openApplyModal) handleOpportunityApply();
  }, [openApplyModal]);

  useEffect(() => {
    if (description) {
      setMarkup(renderOpportunityDescription(description));
    }
  }, [description]);

  const handleOpportunityApply = async () => {
    if (isCompany() || !isAuthenticated) {
      return onClick(opportunity);
    }

    const response = await api.get('/profile');

    if (opportunity && application.isApplied) {
      const pathname = `/candidate/applications/${application.idApplication}`;
      history.push({ pathname });
    } else {
      if (!response.data.finishedRegister) {
        return setOpenModalFinishResume(true);
      } else {
        return setOpenDialogApply(true);
      }
    }
  };

  const copyClipboard = () => {
    navigator.clipboard.writeText(externalLink);
  };

  const getBenefits = () => {
    const newArray = [];

    benefits.forEach((benefit) => {
      newArray.push(benefitsToLabels[benefit]);
    });
    return newArray;
  };

  return (
    <Box component="section" className={clsx(classes.root, className)}>
      <Box className={clsx(classes.wrapper, wrapperClass)} overflow="hidden">
        <PerfectScrollbar
          className={classes.perfectScroll}
          options={{ suppressScrollX: true }}
          ref={containerRef}
        >
          <Box padding="20px">
            <Box className={classes.header}>
              <Box component="figure" height={60} width={60} marginRight="15px">
                <CardMedia
                  component="img"
                  className={classes.img}
                  src={
                    company
                      ? `${process.env.REACT_APP_MEDIA_URL}/companies/${company.id}/avatar-small.png`
                      : noImage
                  }
                ></CardMedia>
              </Box>
              <Box flex={1}>
                <Box marginBottom="4px">
                  {!loading ? (
                    <Typography
                      className={classes.defaultText}
                      component="h2"
                      style={{ display: 'inline-block' }}
                    >
                      {company &&
                        (company.fantasyName !== null
                          ? company.fantasyName
                          : company.businessName)}
                    </Typography>
                  ) : (
                    <Skeleton width="50%" animation="wave" />
                  )}
                </Box>
                {!loading ? (
                  <Typography className={classes.highlightText} component="h2">
                    {title}
                  </Typography>
                ) : (
                  <Skeleton width="50%" animation="wave" />
                )}
                {loading ? (
                  new Array(3).fill(0).map((_, index) => {
                    return (
                      <Box marginBottom="5px" key={index}>
                        <Skeleton width="100px" />
                      </Box>
                    );
                  })
                ) : (
                  <Box marginLeft="-80px">
                    {skillLevel && (
                      <Box display="flex" alignItems="center" marginTop="25px">
                        <CardMedia
                          className={classes.iconSVG}
                          component="img"
                          src={personIcon}
                        ></CardMedia>
                        <Typography className={classes.defaultText}>
                          {levelToLabel[skillLevel]}
                        </Typography>
                      </Box>
                    )}
                    {workLoad && (
                      <Box display="flex" alignItems="center" marginTop="2px">
                        <AccessAlarmOutlinedIcon
                          fontSize="inherit"
                          className={classes.icon}
                        />
                        <Typography className={classes.defaultText}>
                          {workloadDescriptionToLabel[workLoad]}
                        </Typography>
                      </Box>
                    )}
                    {address && (
                      <Box display="flex" alignItems="center" marginTop="2px">
                        <RoomOutlinedIcon
                          fontSize="inherit"
                          className={classes.icon}
                        />
                        <Typography className={classes.defaultText}>
                          {address}
                        </Typography>
                      </Box>
                    )}
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="space-between"
                      marginTop="2px"
                    >
                      {status && (
                        <Box display="flex" alignItems="center">
                          {status === 'OPEN' ? (
                            <FiberManualRecordOutlinedIcon
                              fontSize="inherit"
                              className={classes.icon}
                            />
                          ) : (
                            <FiberManualRecordIcon
                              fontSize="inherit"
                              className={classes.icon}
                            />
                          )}
                          <CompanyState
                            stateType={opportunityStateType[status]}
                            stateLabel={opportunityStateToLabel[status]}
                            fontSize={14}
                            hideIcon={true}
                          />

                          {initialDate && (
                            <Box
                              display="flex"
                              alignItems="center"
                              marginLeft="20px"
                            >
                              <EventAvailableIcon
                                fontSize="inherit"
                                className={classes.icon}
                              />
                              <Typography className={classes.defaultText}>
                                {moment(initialDate).format('L')}
                              </Typography>
                            </Box>
                          )}

                          {deficiency && (
                            <Box
                              display="flex"
                              alignItems="center"
                              marginLeft="20px"
                            >
                              <AccessibleForwardIcon
                                fontSize="inherit"
                                className={classes.icon}
                              />
                              <Typography className={classes.defaultText}>
                                Exclusiva para PCD
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      )}
                      <Box component="small" className={classes.createdAt}>
                        {initialDate && (
                          <Typography className={classes.computedCreatedAt}>
                            {computedCreatedAt}
                          </Typography>
                        )}
                      </Box>
                      {externalLink && status === 'OPEN' && (
                        <Box className={classes.wrapperExternalLink}>
                          <Box className={classes.externalLink}>
                            <Typography>
                              Link de compartilhamento da vaga
                            </Typography>
                            <TextField
                              disabled
                              value={externalLink}
                              variant="outlined"
                              fullWidth
                            ></TextField>
                          </Box>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={copyClipboard}
                          >
                            Copiar
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <Box className={classes.infoContainer}>
              {options.map((option, index) => (
                <InfoCard options={option} key={index} />
              ))}
            </Box>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleOpportunityApply}
              style={{ width: 125, margin: '0px 0px 30px' }}
            >
              {btnLabel}
            </Button>
            <DialogOpportunityApply
              open={openDialogApply}
              onClose={() => setOpenDialogApply(false)}
              opportunity={opportunity}
            />
            {isAuthenticated && (
              <>
                <ModalFinishResume
                  showModal={openModalFinishResume}
                  onClose={() => setOpenModalFinishResume(false)}
                  finishRegister={() => setOpenModalSyncResume(true)}
                />
                <AutoCompleteResumeModal
                  showModal={openModalSyncResume}
                  setShowModal={(value) => setOpenModalSyncResume(value)}
                  companyName={company.fantasyName}
                  opportunityId={id}
                  opportunityTitle={title}
                />
                <ModalSyncStatus
                  showModal={
                    openModalSyncStatus === 'error' ||
                    openModalSyncStatus === 'success'
                  }
                  onClose={() => setOpenModalSyncStatus('')}
                  error={openModalSyncStatus === 'error'}
                  tryAgain={() => setOpenModalSyncResume(true)}
                  opportunityId={id}
                />
              </>
            )}
            {!loading ? (
              <Box>
                <Typography dangerouslySetInnerHTML={{ __html: markup }} />
              </Box>
            ) : (
              new Array(5).fill(0).map((_, index) => {
                return (
                  <Box marginBottom="15px" key={index}>
                    <Skeleton width="100px" />
                    <Skeleton animation={false} />
                    <Skeleton animation="wave" />
                  </Box>
                );
              })
            )}

            {requiredSkills && requiredSkills.length > 0 && (
              <Box mt={5}>
                <Typography className={classes.informationsText}>
                  Habilidades técnicas
                </Typography>
                <TagInput
                  title="Habilidades Ténicas"
                  tags={requiredSkills}
                  onChange={() => {}}
                  editable={false}
                />
              </Box>
            )}

            {desirableSkills && desirableSkills.length > 0 && (
              <Box mt={5}>
                <Typography className={classes.informationsText}>
                  Habilidades comportamentais
                </Typography>
                <TagInput
                  title="Habiliaddes Comportamentais"
                  tags={desirableSkills}
                  onChange={() => {}}
                  editable={false}
                />
              </Box>
            )}

            <Box mt={5}>
              {Boolean(benefits?.length) && (
                <Box>
                  <Typography className={classes.informationsText}>
                    Benefícios
                  </Typography>
                  <TagInput
                    title="Benefícios"
                    tags={getBenefits()}
                    onChange={() => {}}
                    editable={false}
                  />
                </Box>
              )}
            </Box>

            <Box
              display="flex"
              alignItems="flex-top"
              justifyContent="flex-start"
              flexWrap="wrap"
              mt={5}
              style={{ gap: 20 }}
            >
              {skillLevel && (
                <Box>
                  <Typography className={classes.informationsText}>
                    Perfil da função
                  </Typography>
                  <Typography
                    className={classes.informationsText}
                    style={{ marginTop: 10, fontWeight: 300 }}
                  >
                    {levelToLabel[skillLevel]}
                  </Typography>
                </Box>
              )}
              {workLoad && (
                <Box>
                  <Typography className={classes.informationsText}>
                    Período
                  </Typography>
                  <Typography
                    className={classes.informationsText}
                    style={{ marginTop: 10, fontWeight: 300 }}
                  >
                    {workloadDescriptionToLabel[workLoad]}
                  </Typography>
                </Box>
              )}
              {
                <Box>
                  <Typography className={classes.informationsText}>
                    Faixa salarial
                  </Typography>
                  {hideSalary && !isCompany() && (
                    <Typography
                      className={classes.informationsText}
                      style={{ marginTop: 10, fontWeight: 300 }}
                    >
                      Faixa salarial indisponível
                    </Typography>
                  )}
                  {(!hideSalary || isCompany()) && (
                    <>
                      <Typography
                        className={classes.informationsText}
                        style={{ marginTop: 10, fontWeight: 300 }}
                      >
                        {`de ${getCoinType(currency, minSalary)}`}
                      </Typography>
                      <Typography
                        className={classes.informationsText}
                        style={{ marginTop: 5, fontWeight: 300 }}
                      >
                        {`até ${getCoinType(currency, maxSalary)}`}
                      </Typography>
                    </>
                  )}
                </Box>
              }

              {company.industry && (
                <Box>
                  <Typography className={classes.informationsText}>
                    Segmento
                  </Typography>
                  <Typography
                    className={classes.informationsText}
                    style={{
                      marginTop: 10,
                      fontWeight: 300,
                      maxWidth: 193,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {getCorrectIndustryLabel(industries, company.industry.id)}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box className={classes.buttonEnd}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={handleOpportunityApply}
              style={{ width: 140 }}
            >
              {btnLabel}
            </Button>
          </Box>
        </PerfectScrollbar>
      </Box>
    </Box>
  );
};

OpportunitieDetail.propTypes = {
  className: PropTypes.string
};

export default OpportunitieDetail;
