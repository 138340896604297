import { Industry } from "src/types/industry";

export const getCorrectIndustryLabel = (mapper: Industry[], valueToFind: number): string => {
  const result = mapper.filter(obj => {
    return obj.id === valueToFind
  })[0]?.name
  
  return result
}

export const getCorrectLabel = (mapper: Array<{value: string , label:string}>, valueToFind: string): string => {
  const result = mapper.filter(obj => {
    return obj.value === valueToFind
  })[0]?.label
  
  return result
}