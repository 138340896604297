import { Component } from "react";

interface UserTypeProps {
  roles: string[];
}

interface UserTypeState {
  roles: string[];
}

const Roles = Object.freeze({
  USER: "USER",
  ADMIN: "ADMIN"
});

class UserType extends Component<UserTypeProps, UserTypeState> {
  constructor(props?: UserTypeProps) {
    super(props);

    if(this.props) {
      this.state = {
        roles: this.props.roles
      };
    }
  }

  isCandidate = () => Roles.USER === this.state.roles[0];

  isCompany = () => Roles.ADMIN === this.state.roles[0];
}

export default UserType;