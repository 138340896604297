import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    bottom: '16px',
    right: '32px',
    backgroundColor: '#25D366',
    '&:hover': {
      backgroundColor: '#1DA851'
    },
    [theme.breakpoints.down(900)]: {
      right: '16px'
    }
  },
  icon: {
    color: theme.palette.background.default,
    fontSize: 'xx-large'
  },
  tooltip: {
    maxWidth: '250px',
    fontFamily: 'Nunito',
    fontWeight: 700,
    padding: '8px',
    borderRadius: '4px',
    background: theme.palette.background.default,
    boxShadow: '1px 2px 4px 2px rgba(0, 0, 0, 0.15)',
    '& .MuiTooltip-arrow': {
      color: theme.palette.background.default
    },
    top: '10px'
  },
  tooltipText: {
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'center',
    lineHeight: '24px',
    color: theme.palette.text.primary
  }
}));
