import { makeStyles } from '@mui/styles';
import type { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: 32,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  iconButton: {
    height: 56,
    width: 56,
    '&.MuiIconButton-root': {
      background: theme.palette.primary.main,
      color: '#FFFFFF',
      '&.Mui-disabled': {
        background: '#E0E0E0',
        color: '#00000042'
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.main
      }
    }
  },
}));
