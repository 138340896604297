/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import type { FC, ReactNode } from 'react';
import React, { useEffect, useState } from 'react';

import {
  AddBusiness,
  Close,
  Dashboard,
  Home,
  HowToVoteOutlined,
  OpenInNew,
  People,
  Person,
  WorkOutlined
} from '@mui/icons-material';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import defaultCompanyAvatar from 'src/assets/images/company-avatar.svg';
import useAuth from 'src/hooks/useAuth';
import { useSelector } from 'src/store';
import { Theme } from 'src/theme';
import NavItem from './NavItem';
import { InsightsListOptions } from './InsightsListOptions';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title?: string;
  finishedRegister?: boolean;
}

interface Section {
  items: Item[];
  subheader: string;
  finishedRegister?: boolean;
}

function renderNavItems({
  items,
  pathname,
  depth = 0,
  finishedRegister,
  close
}: {
  items: Item[];
  pathname: string;
  depth?: number;
  finishedRegister?: boolean;
  close: () => void;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({
            acc,
            item,
            pathname,
            depth,
            finishedRegister,
            close
          }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
  finishedRegister,
  close
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
  finishedRegister?: boolean;
  close: () => void;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        finishedRegister={finishedRegister}
        close={close}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          close
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        finishedRegister={finishedRegister}
        close={close}
      />
    );
  }

  return acc;
}

const terms = 'termos de uso';

const useStyles = makeStyles((theme: Theme) => ({
  mobileDrawer: {
    width: '80%',
    maxWidth: '300px'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
    objectFit: 'cover',
    '& .MuiAvatar-img': {
      height: 'auto',
      background: '#FFFFFF'
    }
  },
  name: {
    lineHeight: 1,
    color: theme.palette.table.blackSecondary,
    '&:hover': {
      textDecoration: 'none'
    }
  },
  specialty: {
    lineHeight: 1,
    letterSpacing: 0.1,
    color: theme.palette.table.grayPrimary
  },
  info: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  },
  copyright: {
    fontSize: 12,
    color: theme.palette.table.grayPrimary,
    cursor: 'pointer'
  },
  terms: {
    fontSize: 12,
    cursor: 'pointer',
    color: theme.palette.primary.main
  },
  navigation: {
    '& a:hover': {
      background: '#F0F1F9'
    },
    '& [data-open=true]': {
      background: '#F0F1F9',
      borderRadius: 4
    }
  },
  icon: {
    '& > img': {
      width: 78,
      height: 53,
      maxHeight: '100%'
    }
  },
  titleBar: {
    fontFamily: 'Nunito',
    color: theme.palette.table.grayPrimary,
    fontWeight: 400,
    fontSize: '18px',
    letterSpacing: '0.1px',
    lineHeight: '14px',
    marginBottom: '16px',
    padding: '0 8px',
    '& > span': {
      fontWeight: 700
    }
  },
  redirectItem: {
    fontFamily: 'Nunito',
    color: theme.palette.table.grayPrimary,
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '0.5px',
    lineHeight: '22px',
    padding: '0 8px',
    '& > span': {
      fontWeight: 700
    }
  },
  button: {
    marginTop: '16px',
    heigth: '40px',
    fontFamily: 'Nunito',
    fontWeight: 700,
    fontSize: '14px'
  },
  tooltipText: {
    fontFamily: 'Nunito',
    fontSize: '12px',
    fontWeight: 400,
    textAlign: 'center',
    lineHeight: '14px',
    padding: '4px 8px'
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { userProfile, userType } = useAuth();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true
  });
  const { countByStatus } = useSelector((state) => state.opportunities);
  const userIsAll =
    userProfile?.associatedCompanies[0]?.company?.typeService?.includes('all');

  var tempAvatarUrl = userType.isCandidate()
    ? `${process.env.REACT_APP_MEDIA_URL}/users/${userProfile.user.id}/avatar-small.png`
    : `${process.env.REACT_APP_MEDIA_URL}/companies/${userProfile.associatedCompanies[0].company.id}/avatar-small.png` ??
      defaultCompanyAvatar;

  const [avatarUrl, setAvatarUrl] = useState(tempAvatarUrl);
  useEffect(() => {
    setAvatarUrl(tempAvatarUrl);
  }, [tempAvatarUrl]);

  const name = userType.isCandidate()
    ? userProfile?.user?.name
      ? userProfile?.user?.name
      : userProfile?.user?.email?.split('@')[0]
    : userProfile?.associatedCompanies[0]?.company?.businessName
    ? userProfile?.associatedCompanies[0]?.company?.businessName
    : userProfile?.associatedCompanies[0]?.company?.email?.split('@')[0];

  const finishedRegister =
    !userType.isCandidate() &&
    userProfile.associatedCompanies[0].company.finishedRegister;

  const plans = userProfile?.associatedCompanies[0]?.company?.subscriptionType;

  const isStringifiedArray = (plan) => {
    try {
      const parsedValue = JSON.parse(plan);
      if (Array.isArray(parsedValue)) {
        return JSON.parse(plan);
      }
    } catch (error) {
      return plan;
    }
  };

  const hasTeamsPlan = isStringifiedArray(plans)?.includes('performance_teams');
  const hasPeoplePlan =
    isStringifiedArray(plans)?.includes('performance_people');
  const hasHiringPlan = isStringifiedArray(plans)?.includes('hiring');

  useEffect(() => {
    if (openMobile && onMobileClose && isMediumScreen) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMediumScreen]);

  const accessRouteIsInsights = history.location.pathname.includes('/insights');

  const handleRedirectRouter = () => {
    accessRouteIsInsights
      ? history.push('/company/jobs')
      : history.push('/insights');

    onMobileClose();
  };

  const productsList = [
    {
      option: accessRouteIsInsights ? (
        <Typography className={classes.redirectItem}>
          Ir para o <span>JOBS</span>
        </Typography>
      ) : (
        <Typography className={classes.redirectItem}>
          Ir para o <span>FLOW</span>
        </Typography>
      ),
      icon: <OpenInNew sx={{ color: '#0000008A' }} />,
      onClick: () => {
        handleRedirectRouter();
      }
    }
  ];

  const sections: {
    company: Section[];
    candidate: Section[];
    insights?: Section[];
  } = {
    company: [
      {
        subheader: '',
        items: [
          {
            title: 'Dashboard',
            href: '/company/dashboard',
            icon: Dashboard
          },
          {
            title: 'Vagas',
            href: '/company/jobs',
            icon: WorkOutlined
          },
          {
            title: 'Banco de talentos',
            href: '/company/registered-candidates',
            icon: People
          }
        ]
      }
    ],
    insights: [
      {
        subheader: '',
        items: []
      }
    ],
    candidate: [
      {
        subheader: '',
        items: [
          {
            title: 'Vagas',
            href: '/candidate/jobs',
            icon: WorkOutlined
          },
          {
            title: 'Candidaturas',
            href: '/candidate/applications/',
            icon: HowToVoteOutlined
          }
        ]
      }
    ]
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column" p={2} pt={0}>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {accessRouteIsInsights ? (
          <Typography className={classes.titleBar}>
            Explorar o <span>FLOW</span>
          </Typography>
        ) : (
          <Typography className={classes.titleBar}>
            Explorar o <span>JOBS</span>
          </Typography>
        )}
        <Box paddingX={0} paddingY={1}>
          {(userType.isCompany() && accessRouteIsInsights
            ? []
            : userType.isCompany() && !accessRouteIsInsights
            ? sections['company']
            : sections['candidate']
          ).map((section) => (
            <List
              className={classes.navigation}
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
                finishedRegister: finishedRegister,
                close: onMobileClose
              })}
            </List>
          ))}
          {userType?.isCompany() && accessRouteIsInsights && (
            <InsightsListOptions onClose={onMobileClose} />
          )}
        </Box>
        {userType.isCompany() && userIsAll && (
          <>
            <Divider variant="fullWidth" sx={{ margin: '9px 0 27px 0 ' }} />
            <Typography className={classes.titleBar}>
              Trocar de plataforma
            </Typography>
            {productsList.map(({ option, icon, onClick }, index) => {
              return (
                <List
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                  onClick={onClick}
                >
                  <ListItemText>{option}</ListItemText>
                  <ListItemIcon color="#0000008A">{icon}</ListItemIcon>
                </List>
              );
            })}
          </>
        )}
      </PerfectScrollbar>
    </Box>
  );
  return (
    <Paper sx={{ display: { lg: 'none', xs: 'block' } }}>
      <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            padding: '8px'
          }}
        >
          <IconButton onClick={onMobileClose}>
            <Close sx={{ color: '#0000008A' }} />
          </IconButton>
        </Box>
        {content}
      </Drawer>
    </Paper>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
