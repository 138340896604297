import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CandidateGuard from 'src/components/CandidateGuard';
import CompanyGuard from 'src/components/CompanyGuard';
import InsightsGuard from 'src/components/InsightsGuard';
import GuestGuard from 'src/components/GuestGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import DashboardLayout from 'src/layouts/DashboardLayout';
import IndexView from 'src/views/IndexView';
import { Main as MainLayout } from './layouts';
import SupportGuard from './components/SupportGuard';
import HiringGuard from './components/HiringGuard';
import PerformanceGuard from './components/PerformanceGuard';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/insights/404',
    component: lazy(() => import('src/views/errors/NotFoundViewInsights'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login-linkedin',
    component: lazy(() => import('src/views/auth/Linkedin'))
  },
  {
    path: '/candidate',
    guard: CandidateGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/candidate/jobs',
        component: lazy(
          () => import('src/views/opportunities/opportunitieSearchView')
        )
      },
      {
        exact: true,
        path: '/candidate/jobs/search',
        component: lazy(
          () => import('src/views/opportunities/opportunitieSearchView')
        )
      },
      {
        exact: false,
        path: '/candidate/profile/',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: false,
        path: '/candidate/profile/finish/',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/candidate/applications/',
        component: lazy(() => import('src/views/candidacies'))
      },
      {
        exact: true,
        path: '/candidate/applications/:applicationId',
        component: lazy(() => import('src/views/applications/applicationView'))
      },
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/candidate/profile/" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/company',
    guard: CompanyGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/company/jobs',
        component: lazy(
          () => import('src/views/opportunities/opportunitieSearchView')
        )
      },
      {
        exact: true,
        path: '/company/dashboard',
        component: lazy(() => import('src/views/dashboard'))
      },
      {
        exact: true,
        path: '/company/jobs/create',
        component: lazy(
          () => import('src/views/opportunities/opportunitieGeneralView')
        )
      },
      {
        exact: true,
        path: '/company/jobs/edit/:jobId',
        component: lazy(
          () => import('src/views/opportunities/opportunitieGeneralView')
        )
      },
      {
        exact: true,
        path: '/company/jobs/view/:jobId',
        component: lazy(
          () => import('src/views/opportunities/opportunitieGeneralView')
        )
      },
      {
        exact: false,
        path: '/company/jobs/:idOpportunity/applications/:applicationID',
        component: lazy(
          () => import('src/views/opportunities/opportunitieSearchView')
        )
      },
      {
        exact: true,
        path: '/company/registered-candidates',
        component: lazy(() => import('src/views/candidatesBank'))
      },
      {
        exact: true,
        path: '/company/registered-candidates/:candidateId',
        component: lazy(() => import('src/views/candidatesBank'))
      },
      {
        exact: true,
        path: '/company/profile',
        component: lazy(() => import('src/views/account/CompanyView'))
      },
      {
        exact: true,
        path: '/company/dashboard',
        component: lazy(() => import('src/views/account/CompanyView'))
      },
      {
        exact: true,
        path: '/company/profile/user-management',
        component: lazy(() => import('src/views/account/CompanyView'))
      },
      {
        exact: true,
        path: '/company/profile/user-management/invite-member',
        component: lazy(() => import('src/views/account/CompanyView'))
      },
      {
        exact: true,
        path: '/company/member/profile',
        component: lazy(() => import('src/views/account/CompanyView'))
      },
      {
        exact: true,
        path: '/company/profile/invoice-management',
        component: lazy(() => import('src/views/account/CompanyView'))
      },
      {
        exact: true,
        path: '/company/profile/edit',
        component: lazy(() => import('src/views/account/CompanyView'))
      },
      {
        exact: true,
        path: '/company/profile/settings',
        component: lazy(() => import('src/views/account/CompanyView'))
      },
      {
        exact: true,
        path: '/company/support',
        component: lazy(() => import('src/views/account/CompanyView')),
        guard: SupportGuard
      },
      {
        exact: true,
        path: '/company/support/edit-companies',
        component: lazy(() => import('src/views/account/CompanyView')),
        guard: SupportGuard
      },
      {
        exact: true,
        path: '/company/support/create-roles',
        component: lazy(() => import('src/views/account/CompanyView')),
        guard: SupportGuard
      },
      {
        exact: true,
        path: '/company/support/alter-roles',
        component: lazy(() => import('src/views/account/CompanyView')),
        guard: SupportGuard
      },
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/company/profile/edit" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/insights',
    layout: DashboardLayout,
    guard: InsightsGuard,
    routes: [
      {
        exact: true,
        path: '/insights',
        component: lazy(() => import('src/views/insights/pages/Home/Card')),
        guard: InsightsGuard
      },
      {
        exact: true,
        path: '/insights/hiring',
        component: lazy(() => import('src/views/insights/index')),
        guard: HiringGuard
      },
      {
        exact: true,
        path: '/insights/teams',
        component: lazy(() => import('src/views/insights/pages/Teams/index')),
        guard: PerformanceGuard
      },
      {
        exact: true,
        path: '/insights/groups',
        component: lazy(
          () => import('src/views/insights/pages/GroupsManagement/index')
        ),
        guard: PerformanceGuard
      },
      {
        exact: true,
        path: '/insights/request-quote-roles',
        component: lazy(
          () =>
            import(
              'src/views/insights/pages/RequestQuoteRoles/RequestQuoteRoles'
            )
        ),
        guard: InsightsGuard
      },
      {
        exact: true,
        path: '/insights/teams/settings',
        component: lazy(
          () => import('src/views/insights/pages/TeamSettings/index')
        ),
        guard: PerformanceGuard
      },
      // { //TODO: rota para movimentar pessoas entre times
      //   exact: true,
      //   path: '/insights/teams/team-management',
      //   component: lazy(
      //     () => import('src/views/insights/pages/TeamManagement/index')
      //   ),
      //   guard: InsightsGuard
      // },
      {
        exact: true,
        path: '/insights/teams/group-analysis',
        component: lazy(
          () => import('src/views/insights/pages/DevelopingTeams/index')
        ),
        guard: PerformanceGuard
      },
      {
        exact: true,
        path: '/insights/hiring/add/external-link',
        component: lazy(() => import('src/views/insights/index')),
        guard: HiringGuard
      },
      {
        exact: true,
        path: '/insights/survey-share-links',
        component: lazy(
          () => import('src/views/insights/pages/ShareLinks/index')
        ),
        guard: PerformanceGuard
      },
      {
        exact: true,
        path: '/insights/deadline-flow-responses',
        component: lazy(
          () => import('src/views/insights/pages/ShareLinks/index')
        ),
        guard: PerformanceGuard
      },
      // {//TODO: Remoção temporária do upload de CSV
      //   exact: true,
      //   path: '/insights/add',
      //   component: lazy(() => import('src/views/insights/index')),
      //   guard: InsightsGuard
      // },
      // {
      //   exact: true,
      //   path: '/insights/add/upload-file',
      //   component: lazy(() => import('src/views/insights/index')),
      //   guard: InsightsGuard
      // },
      {
        exact: true,
        path: '/insights/tests-history',
        component: lazy(
          () => import('src/views/insights/pages/TestsHistory/index')
        ),
        guard: InsightsGuard
      },
      {
        exact: true,
        path: '/insights/peoples/people-development',
        component: lazy(
          () => import('src/views/insights/PeopleDevelopment/index')
        ),
        guard: PerformanceGuard
      },
      {
        exact: true,
        path: '/insights/profile/user-management',
        component: lazy(() => import('src/views/account/CompanyView')),
        guard: InsightsGuard
      },
      {
        exact: true,
        path: '/insights/profile/user-management/invite-member',
        component: lazy(() => import('src/views/account/CompanyView')),
        guard: InsightsGuard
      },
      {
        exact: true,
        path: '/insights/member/profile',
        component: lazy(() => import('src/views/account/CompanyView'))
      },
      {
        exact: true,
        path: '/insights/peoples/analysis-people',
        component: lazy(
          () => import('src/views/insights/pages/AnalysisPeople/index')
        ),
        guard: PerformanceGuard
      },
      {
        exact: true,
        path: '/insights/peoples/analysis-people/development',
        component: lazy(
          () => import('src/views/insights/pages/AnalysisPeople/index')
        ),
        guard: PerformanceGuard
      },
      {
        exact: true,
        path: '/insights/peoples/analysis-people/possibilities',
        component: lazy(
          () => import('src/views/insights/pages/AnalysisPeople/index')
        ),
        guard: PerformanceGuard
      },
      {
        exact: true,
        path: '/insights/hiring/details-result',
        component: lazy(
          () => import('src/views/insights/pages/DetailsResultsHiring/index')
        ),
        guard: HiringGuard
      },
      {
        component: () => <Redirect to="/insights/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: IndexView,
        guard: GuestGuard
      },
      {
        exact: true,
        path: '/termos-uso-politica-privacidade',
        component: lazy(() => import('src/views/politics/PoliticsTerms'))
      },
      {
        exact: true,
        path: '/termos-condicoes-anuncios-vagas',
        component: lazy(() => import('src/views/termsOfService/TermsOfService'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
