import api from "src/api";

export class AuthenticationService {
  private static instance: AuthenticationService;

  constructor() {
    if (!AuthenticationService.instance) {
      AuthenticationService.instance = this;
    }

    return AuthenticationService.instance;
  }

  login = (email: string, password: string, isCompanyLogin: boolean, captcha: string) => {
    return api.post('/authentication/signing', { email, password, isCompanyLogin, captcha });
  }

  loginLinkedin = (params: any) => {
    const { state, code } = params
    return api.get(`/authentication/linkedin?state=${state}&code=${code}`);
  }

  loginGoogle = (accessToken: string) => {
    const url = process.env.REACT_APP_SERVER_URL;
    return api.post(`${url}/authentication/google/${accessToken}`)
  }

  loginFacebook = (data: any) => {
    const { accessToken } = data
    const url = process.env.REACT_APP_SERVER_URL;
    return api.post(`${url}/authentication/facebook-login/${accessToken}`)
  }
  
  forgotPassord = (email: string, captcha: string) => {
    return api.post('/authentication/reset-password', { email, captcha });
  }
}

const authenticationService = new AuthenticationService();
export default authenticationService;