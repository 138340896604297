import React, { useState, useEffect } from 'react';

import type { FC } from 'react';

import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Box,
  IconButton,
  Divider,
  Collapse
} from '@mui/material';

import {
  AddBusiness,
  Person,
  House,
  Link,
  Schema,
  ArrowDropDown,
  ArrowDropUp,
  SearchOff,
  ManageAccounts,
  AccountTree,
  Group,
  PostAdd
} from '@mui/icons-material';
import { useHistory, useLocation } from 'react-router';

import { useStyles } from './styles';
import useAuth from 'src/hooks/useAuth';

interface MenuItem {
  id?: number;
  name?: string;
  icon?: JSX.Element | string;
  redirect?: string;
  subheader?: string;
  subitems?: MenuItem[];
}

interface InsightsListOptionsProsps {
  onClose?: () => void;
}

export const InsightsListOptions: FC<InsightsListOptionsProsps> = ({
  onClose
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { userProfile } = useAuth();
  const [openItems, setOpenItems] = useState<number[]>([]);
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [selectedSubitemId, setSelectedSubitemId] = useState<number | null>(
    null
  );
  const role: string = userProfile?.associatedCompanies[0]?.role;
  const plans = userProfile?.associatedCompanies[0]?.company?.subscriptionType;

  const isStringifiedArray = (plan) => {
    try {
      const parsedValue = JSON.parse(plan);
      if (Array.isArray(parsedValue)) {
        return JSON.parse(plan);
      }
    } catch (error) {
      return plan;
    }
  };

  const hasTeamsPlan = isStringifiedArray(plans)?.includes('performance_teams');
  const hasPeoplePlan =
    isStringifiedArray(plans)?.includes('performance_people');
  const hasHiringPlan = isStringifiedArray(plans)?.includes('hiring');
  const menuItems: MenuItem[] = [
    {
      id: 0,
      name: 'Página inicial',
      icon: <House className={classes.icon} />,
      redirect: '/insights',
      section: 'sectionInitial'
    },
    {
      id: 1,
      name: 'Área de administradores',
      redirect: '',
      subitems: [
        {
          id: 11,
          name: 'Configurar usuários',
          icon: <SearchOff className={classes.icon} />,
          redirect: '/insights/profile/user-management'
        },
        hasTeamsPlan || hasPeoplePlan
          ? {
              id: 12,
              name: 'Configurar times',
              icon: <ManageAccounts className={classes.icon} />,
              redirect: '/insights/teams/settings'
            }
          : {},
        hasTeamsPlan || hasPeoplePlan
          ? {
              id: 13,
              name: 'Grupos de permissão',
              icon: <AccountTree className={classes.icon} />,
              redirect: '/insights/groups'
            }
          : {}
        // {
        //   id: 14,
        //   name: 'Cargos personalizados',
        //   icon: <PostAdd className={classes.icon} />,
        //   redirect: '/insights/request-quote-roles'
        // }
      ].filter((object) => Object.keys(object).length > 0)
    },
    hasHiringPlan
      ? {
          id: 2,
          name: 'Candidatos',
          redirect: '',
          section: 'sectionSelective',
          subitems: [
            {
              id: 21,
              name: 'Gestão de candidatos',
              icon: <AddBusiness className={classes.icon} />,
              redirect: '/insights/hiring'
            }
          ]
        }
      : {},
    hasTeamsPlan || hasPeoplePlan
      ? {
          id: 3,
          name: 'Colaboradores',
          section: 'sectionSelective',
          subitems: [
            {
              id: 31,
              name: 'Link pro FLOW',
              icon: <Link className={classes.icon} />,
              redirect: '/insights/survey-share-links'
            },
            {
              id: 32,
              name: 'Resultados individuais',
              icon: <Person className={classes.icon} />,
              redirect: '/insights/peoples/people-development',
              section: 'sectionIndividual'
            },
            {
              id: 33,
              name: 'Resultados coletivos',
              icon: <Group className={classes.icon} />,
              redirect: '/insights/teams/group-analysis'
            }
          ]
        }
      : {}
  ].filter((object) => Object.keys(object).length > 0);

  const recruiterMenuItems: MenuItem[] = [
    {
      id: 0,
      name: 'Página inicial',
      icon: <House className={classes.icon} />,
      redirect: '/insights',
      subheader: '',
      section: 'sectionInitial'
    },
    hasHiringPlan
      ? {
          id: 21,
          name: 'Gestão de candidatos',
          icon: <AddBusiness className={classes.icon} />,
          redirect: '/insights/hiring',
          subheader: 'Candidatos',
          section: 'sectionSelective'
        }
      : {}
    // {
    //   id: 14,
    //   name: 'Cargos personalizados',
    //   icon: <PostAdd className={classes.icon} />,
    //   redirect: '/insights/request-quote-roles',
    //   subheader: ''
    // }
  ].filter((object) => Object.keys(object).length > 0);

  const leadershipMenuItems: MenuItem[] = [
    {
      id: 0,
      name: 'Página inicial',
      icon: <House className={classes.icon} />,
      redirect: '/insights',
      subheader: '',
      section: 'sectionInitial'
    },
    hasTeamsPlan || hasPeoplePlan
      ? {
          id: 31,
          name: 'Link pro FLOW',
          icon: <Link className={classes.icon} />,
          redirect: '/insights/survey-share-links',
          subheader: 'Colaboradores'
        }
      : {},
    hasTeamsPlan || hasPeoplePlan
      ? {
          id: 32,
          name: 'Resultados individuais',
          icon: <Person className={classes.icon} />,
          redirect: '/insights/peoples/people-development',
          subheader: '',
          section: 'sectionIndividual'
        }
      : {},
    hasTeamsPlan || hasPeoplePlan
      ? {
          id: 33,
          name: 'Resultados coletivos',
          icon: <Group className={classes.icon} />,
          redirect: '/insights/teams/group-analysis'
        }
      : {}
  ].filter((object) => Object.keys(object).length > 0);

  const handleItemClick = (itemId: number) => {
    setSelectedItemId(itemId);
    if (openItems.includes(itemId)) {
      setOpenItems(openItems.filter((id) => id !== itemId));
    } else {
      setOpenItems([...openItems, itemId]);
    }

    if (
      (role === 'RECRUITER' || role === 'LEADERSHIP') &&
      (itemId !== Number(14) ||
        itemId !== Number(21) ||
        itemId !== Number(31) ||
        itemId !== Number(32) ||
        itemId !== Number(33))
    ) {
      setSelectedSubitemId(null);
    }
  };

  const handleRedirectItems = (redirect: string) => {
    history.push(redirect);
    (selectedItemId !== Number(1) ||
      selectedItemId !== Number(2) ||
      selectedItemId !== Number(3)) &&
      onClose();
  };

  useEffect(() => {
    if (location.pathname === '/insights') {
      setSelectedItemId(0);
      setSelectedSubitemId(null);
    } else if (location.pathname === '/insights/profile/user-management') {
      setSelectedSubitemId(11);
    } else if (location.pathname === '/insights/teams/settings') {
      setSelectedSubitemId(12);
    } else if (location.pathname === '/insights/groups') {
      setSelectedSubitemId(13);
    } else if (location.pathname === '/insights/request-quote-roles') {
      role === 'RECRUITER' || role === 'LEADERSHIP'
        ? setSelectedItemId(14)
        : setSelectedSubitemId(14);
    } else if (
      location.pathname === '/insights/hiring' ||
      location.pathname === '/insights/hiring/add/external-link' ||
      location.pathname === '/insights/hiring/details-result'
    ) {
      role === 'RECRUITER' || role === 'LEADERSHIP'
        ? setSelectedItemId(21)
        : setSelectedSubitemId(21);
    } else if (
      location.pathname === '/insights/survey-share-links' ||
      location.pathname === '/insights/deadline-flow-responses'
    ) {
      role === 'RECRUITER' || role === 'LEADERSHIP'
        ? setSelectedItemId(31)
        : setSelectedSubitemId(31);
    } else if (
      location.pathname === '/insights/peoples/people-development' ||
      location.pathname === '/insights/peoples/analysis-people' ||
      location.pathname === '/insights/peoples/analysis-people/development' ||
      location.pathname === '/insights/peoples/analysis-people/possibilities'
    ) {
      role === 'RECRUITER' || role === 'LEADERSHIP'
        ? setSelectedItemId(32)
        : setSelectedSubitemId(32);
    } else if (
      location.pathname === '/insights/teams/group-analysis' ||
      location.pathname === '/insights/teams'
    ) {
      role === 'RECRUITER' || role === 'LEADERSHIP'
        ? setSelectedItemId(33)
        : setSelectedSubitemId(33);
    } else if (location.pathname === '/insights/teams/team-management') {
      setSelectedSubitemId(42);
    } else {
      setSelectedSubitemId(null);
      setSelectedItemId(null);
    }
  }, [history.location.pathname, window.location.pathname]);

  const handleRedirectRouter = () => {
    return window.location.replace('/company/jobs');
  };

  let selectList =
    role === 'RECRUITER'
      ? recruiterMenuItems
      : role === 'LEADERSHIP'
      ? leadershipMenuItems
      : menuItems;

  const hideItems =
    role === String('LEADERSHIP') || role === String('RECRUITER');

  return (
    <List component="nav" className={classes.list}>
      {selectList?.map((item: MenuItem) => {
        return (
          <Box key={item.id}>
            <ListSubheader>{item.subheader}</ListSubheader>
            <ListItemButton
              selected={
                hideItems
                  ? selectedItemId === item.id
                  : selectedItemId === item.id &&
                    item.id === 0 &&
                    selectedSubitemId === null
              }
              onClick={() => {
                item.redirect && handleRedirectItems(item.redirect);
                handleItemClick(item.id);
              }}
            >
              {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
              <ListItemText
                primary={item.name}
                sx={
                  hideItems
                    ? {}
                    : item.id !== 0 && {
                        '& .MuiTypography-root': {
                          fontWeight: 700
                        }
                      }
                }
              />
              {item.subitems &&
                (openItems.includes(item.id) ? (
                  <IconButton
                    size="small"
                    className={classes.icon}
                    onClick={() => handleItemClick(item.id)}
                  >
                    <ArrowDropDown />
                  </IconButton>
                ) : (
                  <IconButton
                    className={classes.icon}
                    size="small"
                    onClick={() => handleItemClick(item.id)}
                  >
                    <ArrowDropUp />
                  </IconButton>
                ))}
            </ListItemButton>
            {item.id === 0 && (
              <Divider
                variant="fullWidth"
                style={{ margin: '16px -8px 8px -8px' }}
              />
            )}
            {item.subitems && (
              <>
                <Collapse
                  in={!openItems.includes(item.id)}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {item.subitems.map((subitem) => (
                      <ListItemButton
                        key={subitem.id}
                        selected={selectedSubitemId === subitem.id}
                        onClick={() => {
                          setSelectedSubitemId(subitem.id);
                          handleRedirectItems(subitem.redirect);
                          onClose();
                        }}
                      >
                        <ListItemIcon>{subitem.icon}</ListItemIcon>
                        <ListItemText primary={subitem.name} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
                {(hasHiringPlan
                  ? item.id === 1
                  : item.id === 1 || item.id === 2) && (
                  <Divider
                    variant="fullWidth"
                    style={{ margin: '16px -8px 8px -8px' }}
                  />
                )}
              </>
            )}
          </Box>
        );
      })}
    </List>
  );
};
