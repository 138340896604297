import {
  ArrowForward,
  Close, Email
} from '@mui/icons-material';
import { Box, Divider, Fab, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { companyService } from 'src/services';
import { Theme } from 'src/theme';
import { formattedExamName } from 'src/utils/formatters';
import StatusIcon from './Atom/StatusIcon';

interface ProfileProps {
  candidateExams: any;
  companyExams: any;
  isCompanyView?: Boolean;
  idApplication?: string;
  updateExamRequest?: (newEvents: any) => void;
  isShowDrawer?: Boolean;
  isClosed?: boolean;
  situation?: any;
  updateApplication?: (
    applicationList: { situation: any; id: any; events: any }[]
  ) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    maxWidth: '684px',
    background: '#FFFFFF',
    borderRadius: '4px',
    padding: '0 16px'
  },
  cropText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down(400)]: {
      maxWidth: 190
    }
  },
  infoBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 0',
    gap: 12
  },
  title: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '157%',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  subtitle: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '166%',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.6)',
    gap: 3
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '157%',
    letterSpacing: '0.1px',
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: 24,
    marginBottom: 8
  }
}));

const ExamsCard: FC<ProfileProps> = ({
  companyExams,
  candidateExams,
  isCompanyView,
  idApplication,
  updateExamRequest,
  isShowDrawer,
  isClosed,
  situation,
  updateApplication
}) => {
  const theme = useTheme()
  const { applicationId } = useParams<{ applicationId: string }>();
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [examRequestLoading, setExamRequestLoading] = useState(false);
  const [examRequestStatus, setExamRequestStatus] = useState(null);
  const [buttonExamRequestId, setButtonExamRequestId] = useState('');
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(500), {
    defaultMatches: true,
  });

  useEffect(() => {
    if (examRequestStatus !== null) {
      setExamRequestLoading(false);
      setExamRequestStatus(null);
      setButtonExamRequestId('');
    }
  }, [examRequestStatus]);

  const goToExam = (id: string) => {
    const EXAM_URL = process.env.REACT_APP_EXAM_SERVER_URL;
    window.location.href = `${EXAM_URL}/candidate/exam/${id}?applicationId=${applicationId}`;
  };

  if (!companyExams || companyExams.length <= 0) {
    return null;
  }

  const customStatus = (situation: string, attempts: number) => {
    let status = isCompanyView
      ? 'Aguardando candidato'
      : 'Aguardando certificação';

    if (situation === 'APPROVED') {
      status = 'Certificado';
    } else if (situation === 'BLOCKED' || attempts > 1) {
      status = 'Não certificado';
    }

    return (
      <>
        {status}
        {status.includes('Aguardando certificação') && (
          <> &#x2022; {attempts}/2 tentativas</>
        )}
      </>
    );
  };

  const endedDate = (endedAt: string) => ` em ${moment(endedAt).format('L')}`;

  const restartDate = (restartAt: string, startedAt: string) => {
    const newDate = restartAt
      ? moment(restartAt).format('L')
      : moment(startedAt).add(30, 'days').format('L');
    return <> &#x2022; disponível novamente em {newDate}</>;
  };

  const customDate = (candidateExam: any) => {
    if (candidateExam && !isCompanyView) {
      const { endedAt, restartAt, startedAt, situation, attempts } =
        candidateExam;

      if (situation === 'APPROVED') {
        return endedDate(endedAt);
      } else if (attempts > 1) {
        return restartDate(restartAt, startedAt);
      }
    }
  };


  const examRequest = async (examId: string, examName: string) => {
    setExamRequestLoading(true);
    try {
      const { data, status } = await companyService.examRequestByCompany({
        id: idApplication,
        examId,
        examName
      });
      updateExamRequest([data]);
      setExamRequestStatus(status);
      enqueueSnackbar(
        'Email de solicitação de certificação de habilidade enviado com sucesso! ',
        {
          variant: 'success',
          autoHideDuration: 2000,
          action: (id) => (
            <Close
              onClick={() => closeSnackbar(id)}
              fontSize="small"
              style={{ cursor: 'pointer' }}
            />
          )
        }
      );
    } catch (error) {
      console.error('error', error);
      enqueueSnackbar(
        'Erro ao enviar email de solicitação de certificação de habilidade! ',
        {
          variant: 'error',
          autoHideDuration: 2000,
          action: (id) => (
            <Close
              onClick={() => closeSnackbar(id)}
              fontSize="small"
              style={{ cursor: 'pointer' }}
            />
          )
        }
      );
    }
  };

  return (
    <Box style={{ width: '100%', maxWidth: 684 }}>
      {!isCompanyView && <Typography className={classes.header}>Teste de certificações</Typography>}
      <Box width="100%" className={classes.root}>
        {companyExams.map((companyExam: any, index: number) => {
          const exam = companyExam.exam;
          const candidateExam = candidateExams?.find(
            (newExam: any) => newExam.examId === exam.id
          );
          const isOpen =
            !['APPROVED', 'BLOCKED'].includes(candidateExam?.situation) &&
            (candidateExam?.attempts ?? 0) < 2;

          return (
            <Box key={index}>
              <Box className={classes.infoBox}>
                <StatusIcon
                  status={candidateExam?.situation}
                  attempts={candidateExam?.attempts ?? 0}
                />
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: 12
                    }}
                  >
                    <Box className={classes.infoContainer}>
                      <Typography
                        style={isSmallScreen ? { maxWidth: '175px' } : null}
                        variant="caption"
                        className={`${classes.title} ${classes.cropText}`}
                      >
                        {formattedExamName(exam.title, exam.level)}
                      </Typography>

                      <Typography
                        variant="caption"
                        className={`${classes.subtitle} ${classes.cropText}`}
                      >
                        {customStatus(
                          candidateExam?.situation ?? '',
                          candidateExam?.attempts ?? 0
                        )}
                        {customDate(candidateExam)}
                      </Typography>
                    </Box>
                    {!isCompanyView && isOpen && (
                      <Fab
                        size="small"
                        onClick={() => goToExam(exam.id)}
                        color="primary"
                      >
                        <ArrowForward style={{ color: '#ffffff' }} />
                      </Fab>
                    )}
                    {isCompanyView && isOpen && (
                      <Tooltip
                        placement="top"
                        title="Enviar solicitação de certificação de habilidade"
                      >
                        <Fab
                          id={`exams-request-${exam.id}`}
                          size="small"
                          onClick={() => {
                            setButtonExamRequestId(exam.id);
                            examRequest(
                              exam.id,
                              formattedExamName(exam.title, exam.level)
                            );
                          }}
                          aria-label="exams-request"
                          color="primary"
                          disabled={
                            isClosed ||
                            (examRequestLoading &&
                              buttonExamRequestId === exam.id)
                          }
                        >
                          <Email style={{ color: '#ffffff' }} />
                        </Fab>
                      </Tooltip>
                    )}
                  </Box>
                </Box>
              </Box>
              {index < companyExams.length - 1 && (
                <Divider variant="fullWidth" style={{ marginRight: '-16px' }} />
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ExamsCard;
