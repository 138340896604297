import React from 'react';
import type { FC } from 'react';
import { styled } from '@mui/material/styles';
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useStyles } from './styles';

interface ActionsMenuProps {
  listItems?: any;
  anchor: null | HTMLElement;
  handleClose: () => void;
  isActionPageMenu?: boolean;
}

export const ActionsMenu: FC<ActionsMenuProps> = ({
  anchor,
  handleClose,
  listItems,
  isActionPageMenu
}) => {
  const open = Boolean(anchor);
  const classes = useStyles();
  const theme = useTheme();
  const isCustomScreen = useMediaQuery(theme.breakpoints.down(470), {
    defaultMatches: true
  });

  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: '187px',
      padding: '8px 4px',
      fontSize: '12px',
      fontFamily: 'Nunito',
      fontWeight: 700,
      background: '#616161'
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#616161'
    }
  });

  return (
    <Menu
      id="actions-menu-page"
      aria-labelledby="actions-menu-page"
      anchorEl={anchor}
      open={open}
      elevation={0}
      sx={{
        '& .MuiPaper-root': {
          border: '1px groove rgba(89, 10, 157, 0.08)',
          boxShadow: '0px 4px 20px 0px rgba(157, 10, 107, 0.15)',
          top:
            isActionPageMenu && isCustomScreen
              ? '170px !important'
              : isActionPageMenu && !isCustomScreen
              ? '130px !important'
              : '108px'
        }
      }}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      {listItems?.map(
        ({ option, icon, onClick, disabled, tooltipMessage }, index) => {
          return (
            <Box key={index}>
              {option !== '' && (
                <CustomTooltip
                  title={tooltipMessage ? tooltipMessage : ''}
                  placement="left"
                  arrow
                >
                  <span>
                    <MenuItem
                      onClick={onClick}
                      key={index}
                      className={classes.hoverMenuItem}
                      disabled={disabled ?? false}
                    >
                      <ListItemText className={classes.listItemText}>
                        {option}
                      </ListItemText>
                      <ListItemIcon className={classes.listItemIcon}>
                        {icon}
                      </ListItemIcon>
                    </MenuItem>
                  </span>
                </CustomTooltip>
              )}
            </Box>
          );
        }
      )}
    </Menu>
  );
};
