import { useState, useEffect, useRef } from 'react';

function useWebSocket() {
  const [isConnected, setIsConnected] = useState(false);
  const [receiveData, setReceiveData] = useState(null);
  const [params, setParams] = useState('');
  const socket = useRef(null);
  const url = `${process.env.REACT_APP_WEBSOCKET_API_URL}?${params}`;
  
  useEffect(() => {
    if (params) {
      socket.current = new WebSocket(url);
    
      socket.current.onopen = () => {
        setIsConnected(true);
      }
  
      socket.current.onmessage = (res: any) => {
        setReceiveData(JSON.parse(res.data));
      }

      return () => {
        socket.current.close();
      };
    }
  }, [params]);

  const startConnection = (newParams: string) => {
    setParams(newParams);
  }

  return { receiveData, isConnected, startConnection };
}

export default useWebSocket;