import { useEffect, useState } from 'react';
import { companyService } from 'src/services';

export const usePrefixesList = () => {
  
  const [prefixes, setPrefixes] = useState(window.localStorage.getItem('prefixes') ? JSON.parse(window.localStorage.getItem('prefixes')) : []) 
  
  const getPrefixes = async () => {
    try {
      const { data } = await companyService.getAllPositionsPrefixes()
      window.localStorage.setItem('prefixes', JSON.stringify(data))
      setPrefixes(data)
    }
    catch (err) {
      console.error(err)
    }
  } 
  useEffect(() => {
    !window.localStorage.getItem('prefixes') && getPrefixes()
  }, [window.localStorage.getItem('prefixes')]);

  return prefixes;

}

export const usePositionsList = () => {

  const [positions, setPositions] = useState(window.localStorage.getItem('positions') ? JSON.parse(window.localStorage.getItem('positions')) : []) 
  
  const getPositions = async () => {
    try {
      const { data } = await companyService.getCompanyPositions()
      window.localStorage.setItem('positions', JSON.stringify(data))
      setPositions(data)
    }
    catch (err) {
      console.error(err)
    }
  } 
  useEffect(() => {
    !window.localStorage.getItem('positions') && getPositions()
  }, [window.localStorage.getItem('positions')]);

  return positions;
 
}

export const getPositionByTerm = async (term:string) => {
  try {
    if(term === undefined || term === "undefined") throw "ocorreu um erro ao buscar por termo: termo vazio"
    const { data } = await companyService.getCompanyPositionsByRoleOrCode(undefined, term)
    return { ...data, matchMaking: data?.hasMmr }
  } catch(err) {
    console.error("Ocorreu um erro ao buscar pela posição: "+err.message)
    return { role: term }
  }
}