import makeStyles from '@mui/styles/makeStyles';
import { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontFamily: 'Nunito',
    borderRadius: '4px',
    alignItems: 'center',
    display: 'flex',
    background: theme.palette.premium.main,
    border: `1px solid ${theme.palette.premium.border}`,
    color: theme.palette.primary.dark,
    marginBottom: '24px',
    height:'62px',
    '& .MuiAlert-message': {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      height:'100%',
      '& .MuiAlert-icon': {
        alignSelf: 'baseline'
      },
      '& .MuiAlert-message': {
        padding: '8px 0 0'
      }
    },
    [theme.breakpoints.down('sm')]: {
      padding:'12px'
    }
  },
  message: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.15px',
    display:'flex',
    alignItems: 'center'
  },
  boxContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      '& .MuiAlert-icon': {
        alignSelf: 'baseline'
      },
      '& .MuiIconButton-root': {
        alignSelf: 'baseline',
        paddingTop: 0,
        paddingRight: 0
      }
    }
  },
  boxMessage: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '8px'
    }
  },
  button: {
    fontFamily: 'Nunito',
    fontWeight: 700,
    fontSize: '13px',
    lineHeight: '22px',
    letterSpacing: '0.46px',
    height: '40px',
    width: 'max-content',
    color: theme.palette.primary.dark,
    borderColor: '#3F51B580',
    [theme.breakpoints.down('sm')]:{
      width: '100%'
    },
    [theme.breakpoints.down('md')]:{
      marginTop:'12px'
    }
  }

}));