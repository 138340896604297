import {
  AccountBalanceOutlined,
  CalendarTodayOutlined,
  VerifiedUserOutlined
} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
  Box, Card,
  CardContent,
  Divider, Fab, Grid,
  IconButton,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import moment, { Moment } from "moment";
import type { FC } from "react";
import React, { useState } from 'react';
import Modal from 'src/components/ModalCurriculum/ModalCurriculum';
import { Theme } from 'src/theme';
import { Certification } from "src/types";

const useStyles = makeStyles((theme: Theme) => ({
  wrapIcon: {
    verticalAlign: 'middle',
    wordBreak: 'break-word',
    fontFamily: 'Nunito',
    fontSize: 12
  },
  wrapIconLink: {
    verticalAlign: 'middle',
    wordBreak: 'break-all',
    fontFamily: 'Nunito',
    fontSize: 12
  },
  iconBtns: {
    marginTop: 'inherit',
    color: theme.palette.icon.main
  },
  iconSVG: {
    color: theme.palette.icon.main,
    width: '15px',
  },
  fab: {
    padding: '10px 15px',
    marginTop: '10px',
    border: 'none',
    '&:first-child': {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.primary.main,
    },
    '&:last-child': {
      backgroundColor: '#F44336',
      color: '#FFF',
    },
  },
  button: {
    padding: '10px 15px',
    marginTop: '10px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    '&:first-child': {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.primary.main,
      border: 'none'
    }
  },
}));

interface CertificationCardProps {
  certification: Certification,
  withActions?: boolean,
  index: number,
  length: number,
  onEdit?: React.MouseEventHandler<HTMLButtonElement>,
  onDelete?: React.MouseEventHandler<HTMLButtonElement>,
  elevation?: number
}

const CertificationCard: FC<CertificationCardProps> = ({
  certification,
  index,
  length,
  withActions,
  onEdit,
  onDelete,
  elevation
}) => {
  const classes = useStyles();
  const [deleteData, setDeleteData] = useState(false);
  const closeModalMessage = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDeleteData(false);
    onDelete(event);
    return;
  }
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true,
  });

  const handleWithFormatDate = (timestamp: Date | Moment) => {
    if (timestamp) {
      let date = moment.utc(timestamp);

      return date.isValid() ? date.format('DD/MM/YYYY') : '';
    }
    return '';
  };

  return (
    <Card elevation={elevation} style={{ boxShadow: 'unset' }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{ display: 'flex', gap: 10 }}>
                <VerifiedUserOutlined style={{ color: 'rgba(0, 0, 0, 0.54)', height: 20, width: 20 }} /> 
                <Typography
                  variant="h5"
                  className={classes.wrapIcon}
                  style={{ verticalAlign: '', fontSize: 14, fontWeight: 700 }}
                >
                  {certification.title}
                </Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center" style={{ marginTop: 15 }}>
              <Grid item style={{ display: 'flex', gap: 10 }}>
                <AccountBalanceOutlined style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 18, height: 12, width: 12, marginTop: 6 }} />
                <Typography
                  variant="subtitle1"
                  className={classes.wrapIcon}
                  style={{ fontSize: 14 }}
                >
                  {certification.institution}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} direction="row" alignItems="center">
              <Grid item>
                {certification.emissionDate && (
                  <Typography
                    variant="subtitle1"
                    className={classes.wrapIcon}
                  >
                    <CalendarTodayOutlined
                      style={{
                        color: 'rgba(0, 0, 0, 0.54)',
                        fontSize: 18,
                        height: 12,
                        width: 12
                      }}
                    />
                    &ensp;{handleWithFormatDate(certification.emissionDate)}
                  </Typography>
                )}
              </Grid>
              {certification.expirationDate &&
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    className={classes.wrapIcon}
                  >
                    <CalendarTodayOutlined style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 18, height: 12, width: 12 }} /> &ensp;{handleWithFormatDate(certification.expirationDate)}
                  </Typography>
                </Grid>
              }
              {certification.credentialCode &&
                <Grid item style={{ display: 'flex', gap: 5 }}>
                  <LockOutlinedIcon style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 18, height: 12, width: 12, marginTop: 4 }} />
                  <Typography
                    variant="subtitle1"
                    className={classes.wrapIcon}
                  >
                    {certification.credentialCode}
                  </Typography>
                </Grid>
              }
              {certification.credentialUrl &&
                <Grid item style={{ display: 'flex', gap: 5 }}>
                  <LinkOutlinedIcon style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 18, height: 12, width: 12, marginTop: 4 }} />
                  <Typography
                    variant="subtitle1"
                    className={classes.wrapIconLink}
                  >
                    {certification.credentialUrl}
                  </Typography>
                </Grid>
              }
            </Grid>
            {certification.isTemporary && (
              <Box style={{ marginTop: 12 }}>
                <Typography 
                  style={{ fontFamily: 'Nunito', fontSize: 12, color: 'red' }}
                >
                  Complete as informações obrigatórias ou a certificação não será salva
                </Typography>
              </Box>
            )}
          </Grid>
          {!isSmallScreen && withActions && <Grid item lg={2} md={2} sm={2} xs={2}>
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="flex-end"
              p={1}
              m={1}
              height="100%"
            >
              <IconButton
                aria-label="edit"
                color="inherit"
                onClick={onEdit}
                className={classes.iconBtns}
                size="large">
                <EditIcon className={classes.iconSVG} />
              </IconButton>
              <IconButton
                aria-label="delete"
                color="inherit"
                onClick={() => setDeleteData(true)}
                className={classes.iconBtns}
                size="large">
                <DeleteIcon className={classes.iconSVG} />
              </IconButton>
            </Box>
          </Grid>}
        </Grid>
        {isSmallScreen && withActions &&
          <Box display="flex" alignItems="center" justifyContent="space-around" style={{ margin: '0px 30px 20px' }}>
            <Fab size="small" aria-label="edit" onClick={onEdit} className={classes.fab}>
              <EditIcon />
            </Fab>

            <Fab size="small" aria-label="delete" onClick={() => setDeleteData(true)} className={classes.fab}>
              <DeleteIcon />
            </Fab>
          </Box>}
        {index !== length - 1 && <Box mt={2} mr={1} ml={1}>
          <Divider variant="middle" style={isSmallScreen ? { backgroundColor: "0000001F" } : { backgroundColor: "0000001F" }} />
        </Box>}
      </CardContent>
      <Modal open={deleteData}
        title="Você deseja excluir a informação do currículo?"
        subtitle="Se você confirmar, os dados salvos serão apagados permanentemente."
        btnLeftLabel="Cancelar"
        btnRightLabel="Sim, desejo excluir"
        onLeftButtonClick={() => setDeleteData(false)}
        onRightButtonClick={closeModalMessage} />
    </Card>
  );
}

CertificationCard.defaultProps = {
  withActions: false,
  onEdit: () => { },
  onDelete: () => { },
  elevation: 0
}

export default CertificationCard
