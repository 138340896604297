import React from 'react';
import type { FC } from 'react';
import { Icon } from '@mui/material';

import premiumIcon from 'src/assets/images/icons/gria-premium-icon.svg';

interface PremiumIconProps {
  style?: any;
}

export const PremiumIcon: FC<PremiumIconProps> = ({ style }) => {
  return (
    <Icon sx={style} style={{ alignItems: 'center', display: 'flex' }}>
      <img src={premiumIcon} loading="lazy" />
    </Icon>
  );
};
