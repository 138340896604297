import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3.5),
    [theme.breakpoints.down('md')]: {
      padding: '17px 0px 20px 0px'
    }
  },
  container: {
    maxWidth: '1145px',
    '& .MuiPaper-root': {
      background: 'transparent'
    }
  },
  appbar: {
    background: theme.palette.background.default
  },
  logoBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    alignItems: 'end'
  },
  image: {
    '& > img': {
      width: 78,
      height: 53,
      maxHeight: '100%'
    }
  },
  logo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    cursor: 'pointer',
    width: '100%',
    maxWidth: '200px'
  },
  content: {
    //border: '1px solid #0000001F',
    borderRadius: '4px'
  },
  pagination: {
    '& .MuiToolbar-root': {
      background: theme.palette.background.default
    },
    '& .MuiTablePagination-selectLabel': {
      color: theme.palette.text.secondary
    },
    '& .MuiTablePagination-select': {
      color: theme.palette.text.primary
    },
    '& .MuiSelect-icon': {
      color: theme.palette.icon.default
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiTablePagination-toolbar': {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        '& p:nth-child(2)': {
          display: 'none'
        }
      },
      '& .MuiTablePagination-spacer': {
        display: 'none'
      },
      '& .MuiTablePagination-actions': {
        marginLeft: 0
      }
    }
  },
  tableHeaderCell: {
    fontFamily: 'Nunito',
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.17px'
  },
  table: {
    borderSpacing: '0 4px',
    '& .MuiTableRow-root': {
      borderBottom: 'none',
      background: theme.palette.background.default
    }
  },
  tableRow: {
    '&:hover': {
      background: '#F2ECF7',
      cursor: 'pointer'
    },
    '& .MuiTableCell-root': {
      borderBottom: 'none'
    }
  },
  tableCell: {
    letterSpacing: 0.1,
    color: theme.palette.text.primary
  },
  avatar: {
    width: 40,
    height: 40,
    '& .MuiAvatar-img': {
      height: 'auto',
      background: theme.palette.background.default
    }
  },
  nameBox: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.table.blackSecondary
  },
  name: {
    fontFamily: 'Nunito',
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.15px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 300,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100vw - 200px)',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    }
  },
  currentPosition: {
    fontFamily: 'Nunito',
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.15px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 300,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100vw - 200px)',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    }
  },
  title: {
    fontFamily: 'Nunito',
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 400,
    letterSpacing: 0.25,
    color: 'rgba(0, 0, 0, 0.87)'
  },
  boxTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    marginBottom: '20px',
    flexWrap: 'wrap',
    gap: '16px'
  },
  titleMobile: {
    fontFamily: 'Nunito',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 700,
    letterSpacing: '0.17px',
    color: theme.palette.text.primary
  },
  lastUpdateMobile: {
    fontFamily: 'Nunito',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    letterSpacing: '0.15px',
    color: theme.palette.text.secondary
  },
  nameSection: {
    width: 300,
    [theme.breakpoints.down(1200)]: {
      width: 170
    },
    [theme.breakpoints.down(1140)]: {
      width: 130
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  hideCell: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  hideCellSmall: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideCellCustom: {
    [theme.breakpoints.down(1250)]: {
      display: 'none'
    }
  },
  lastApplicationUpdate: {
    fontFamily: 'Nunito',
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.4px'
  },
  messageBox: {
    minHeight: '25vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    background: theme.palette.background.default
  },
  message: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1,
    letterSpacing: 0.25
  },
  icon: {
    color: theme.palette.icon.default
  },
  modalRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden auto'
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '900px',
    height: 'auto',
    maxHeight: '900px',
    minHeight: '400px',
    backgroundColor: theme.palette.background.default,
    padding: '12px',
    borderRadius: '4px',
    position: 'absolute',
    '&:focus': {
      outline: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      height: '100%'
    }
  },
  halfInput: {
    flex: 1,
    width: '300px',
    '& .MuiInputBase-root': {
      height: '44px'
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      marginRight: 0
    },
    [theme.breakpoints.down(620)]: {
      width: '100%'
    },
    '& fieldset': {
      borderRadius: 6,
      border: '0.5px solid #ECEFF1'
    }
  },
  halfInputMobile: {
    flex: 1,
    width: '300px',
    '& .MuiInputBase-root': {
      height: '44px',
      background: '#ECEFF1',
      border: '0.5px solid #ECEFF1'
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      marginRight: 0
    },
    [theme.breakpoints.down(620)]: {
      width: '100%'
    },
    '& fieldset': {
      //borderRadius: 8,
      //border: '0.5px solid #C4C4C4',
      border: '0.5px solid #ECEFF1',

      borderRadius: '8px'
    },
    '& input::placeholder': {
      color: theme.palette.table.grayPrimary
    }
  },
  iconProps: {
    color: theme.palette.icon.default
  },
  seekCandidateInput: {
    padding: '14px',
    '&.MuiInputBase-root': {
      height: '44px'
    }
  },
  rootUpload: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '32px'
  },
  input: {
    display: 'none'
  },
  button: {
    marginTop: '32px',
    minWidth: '160px',
    height: '40px'
  },
  fileName: {
    marginTop: '32px'
  },
  dropZone: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '600px',
    width: '100%',
    maxHeight: '200px',
    height: '100%',
    padding: '60px',
    border: '2px dashed',
    borderColor: theme.palette.icon.default,
    borderRadius: '4px',
    background: theme.palette.background.default
  },
  uploadIcon: {
    fontSize: '48px',
    marginBottom: '30px',
    color: theme.palette.primary.main
  },
  resultImage: {
    width: '100%',
    height: '100%',
    maxWidth: '900px',
    maxHeight: '600px',
    objectFit: 'contain'
  },
  loadingBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '80vh'
  },
  uploadTitle: {
    color: theme.palette.text.primary,
    fontFamily: 'Nunito',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '160%',
    letterSpacing: '0.15px',
    textAlign: 'center',
    marginBottom: '16px'
  },
  uploadContent: {
    borderRadius: '0px 16px 16px 16px',
    padding: '32px 16px',
    height: '527px',
    background: theme.palette.background.default,
    [theme.breakpoints.down('md')]: {
      background: 'transparent',
      borderRadius: 'none',
      marginTop: '20px',
      height: '100%'
    }
  },
  instructionsList: {
    color: theme.palette.text.secondary,
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '143%',
    letterSpacing: '0.15px',
    '& > li': {
      color: theme.palette.text.secondary,
      fontFamily: 'Nunito',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '143%',
      letterSpacing: '0.15px'
    }
  },
  uploadButtonBox: {
    display: 'flex',
    gap: '24px',
    flexFlow: 'wrap',
    '& .MuiButton-outlined': {
      border: '1px solid rgba(63, 81, 181, 0.50)',
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`
      }
    }
  },
  uploadButton: {
    height: '40px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  instructions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '32px'
  },
  instructionsListBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '32px',
    padding: '0 16px'
  },
  linearProgress: {
    width: '204px',
    height: '8px',
    borderRadius: '4px'
  },
  downloadFileName: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    fontFamily: 'Nunito',
    color: '#4C74AF',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.8'
    }
  },
  fileComponentPaper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '980px',
    padding: '8px',
    flexWrap: 'wrap'
  },
  fileComponentRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '32px',
    textAlign: 'center'
  },
  boxButtonActionsPage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
  },
  optionsButtonMobile: {
    marginTop: '-12px',
    marginRight: '-8px',
    display: 'flex',
    justifyContent: 'end'
  },
  boxStatusMobile: {
    display: 'flex',
    gap: '16px',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  boxItemsMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: '16px'
  },
  paginationMobile: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    margin: '25px 0'
  },
  loadingSplashBox: {
    background: '#FFFFFF',
    height: '527px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    borderRadius: '0px 16px 16px 16px',
    padding: '16px'
  },
  csvName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '300px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '200px'
    },
    [theme.breakpoints.down(360)]: {
      maxWidth: '100px'
    }
  },
  buttonTable: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
}));
