import {
  jobTypes,
  skillLevelOptions,
  workLoadTypes
} from 'src/views/opportunities/opportunitieCreateView/objectLabels';

export enum CompanyStateType {
  DRAFT,
  OPEN,
  PAUSE,
  CLOSED,
  CANCELED,
  UNPAID
}

export const levelToLabel = {
  OPERACIONAL: 'Operacional',
  SPECIALIST: 'Especialista',
  INTERNSHIP: 'Estágio',
  TRAINEE: 'Trainee',
  JUNIOR: 'Júnior',
  INTERMEDIATE: 'Pleno',
  SENIOR: 'Sênior',
  COORDINATOR: 'Coordenação',
  MANAGER: 'Gerencial',
  DIRECTOR: 'Diretoria',
  TECHNICIAN: 'Técnico',
  ARCHITECT: 'Arquiteto',
  MASTER: 'Mestre',
  AUXILIARY: 'Auxiliar',
  ASSISTANT: 'Assistente'
};

export const workloadDescriptionToLabel = {
  FULL_TIME: 'Integral',
  PART_TIME: 'Meio período',
  FLEX: 'Flexível'
};

export const opportunityStateToLabel = {
  DRAFT: 'Rascunho',
  OPEN: 'Aberta',
  PAUSE: 'Pausada',
  CLOSED: 'Finalizada',
  CANCELED: 'Cancelada',
  UNPAID: 'Pendente'
};

export const opportunityStateType = {
  DRAFT: CompanyStateType.DRAFT,
  OPEN: CompanyStateType.OPEN,
  PAUSE: CompanyStateType.PAUSE,
  CLOSED: CompanyStateType.CLOSED,
  CANCELED: CompanyStateType.CANCELED,
  UNPAID: CompanyStateType.UNPAID
};

export const jobTypeToLabel = {
  TRAINEE: 'Trainee',
  INTERNSHIP: 'Estágio',
  CLT: 'CLT',
  PJ: 'PJ',
  CONTRACT: 'Temporário',
  AUTONOMOUS: 'Autônomo',
  TO_BE_DEFINED: 'A definir'
};

export const currencyToLabel = {
  BRL: 'Real',
  EUR: 'Euro',
  USD: 'Dólar',
  MXN: 'Peso mexicano',
  COP: 'Peso colombiano'
};

export const degreeOptionsToLabels = {
  PHD: 'Pós-Doutorado',
  DOCTOR: 'Doutorado',
  MASTER: 'Mestrado',
  MBA: 'MBA',
  P_GRADUATE: 'Pós-Graduação',
  SPECIALIZATION: 'Especialização',
  BACHELOR: 'Graduação',
  B_TECH: 'Tecnólogo',
  HIGH_SCHOOL: 'Ensino Médio',
  SCHOOL: 'Ensino Fundamental',
  TECHNICIAN: 'Técnico'
};

export const progressOptionsLabels = {
  FINISHED: 'Concluída',
  IN_PROGRESS: 'Em andamento',
  UNFINISHED: 'Incompleta'
};

export const benefits = [
  'Apoio saúde mental',
  'Auxílio combustível',
  'Auxílio Home Office',
  'Benefício flex',
  'Convênio odontológico',
  'Gympass',
  'Incentivo educacional',
  'Plano de saúde',
  'Vale alimentação',
  'Vale refeição',
  'Vale refeição ou Vale alimentação',
  'Vale transporte',
  'Vale transporte ou Auxílio combustível'
];

export const benefitsToLabels = {
  MENTAL_HEALTH_SUPPORT: 'Apoio saúde mental',
  HEALTH_CARE: 'Plano de saúde',
  DENTAL_CARE: 'Convênio odontológico',
  HOME_OFFICE_ASSISTANCE: 'Auxílio Home Office',
  FLEX_BENEFITS: 'Benefício flex',
  GYMPASS: 'Gympass',
  EDUCATIONAL_INCENTIVE: 'Incentivo educacional',
  MEAL_VOUCHER_OR_FOOD_VOUCHER: 'Vale refeição ou Vale alimentação',
  MEAL_VOUCHER: 'Vale refeição',
  FOOD_VOUCHER: 'Vale alimentação',
  TRANSPOR_VOUCHER_OR_FUEL_VOUCHER: 'Vale transporte ou Auxílio combustível',
  TRANSPOR_VOUCHER: 'Vale transporte',
  FUEL_VOUCHER: 'Auxílio combustível'
};

export const PlaceOfPerformanceTypes = {
  AGRIBUSINESS: 'Agronegócio',
  TRAINING_CONSULTING: 'Consultoria e Treinamentos',
  EDUCATION_TEACHING: 'Educação e Ensino',
  ENGINEERING_GENERAL_INDUSTRY: 'Engenharia e Indústria Geral',
  EVENTS: 'Eventos',
  FINANCIAL_LEGAL_RELATED_SERVICES:
    'Financeiro, Jurídico e Serviços Relacionados',
  GOVERNMENT_PUBLIC_AGENCIES: 'Financeiro, Governo e Órgãos Públicos',
  HARDWARE_ELECTRONICS: 'Hardware e Eletrônicos',
  REAL_ESTATE: 'Imobiliária',
  MEDIA_COMMUNICATION: 'Mídia e Comunicação',
  ONGS: 'Ongs',
  AESTHETIC_HEALTH: 'Saúde e Estética',
  SERVICES_HR_COACHING: 'Serviços de RH e Coaching',
  SOFTWARE_CLOUD: 'Software e Cloud',
  TELECOMMUNICATIONS: 'Telecomunicações',
  LEISURE_TOURISM: 'Lazer e Turismo',
  RETAIL: 'Varejo',
  COMMUNICATION_VEHICLE: 'Veículo de Comunicação',
  E_COMMERCE: 'E-commerce',
  OTHER: 'Outros'
};

export const benefitsToValues = {
  'Apoio saúde mental': 'MENTAL_HEALTH_SUPPORT',
  'Plano de saúde': 'HEALTH_CARE',
  'Convênio odontológico': 'DENTAL_CARE',
  'Auxílio Home Office': 'HOME_OFFICE_ASSISTANCE',
  'Benefício flex': 'FLEX_BENEFITS',
  Gympass: 'GYMPASS',
  'Incentivo educacional': 'EDUCATIONAL_INCENTIVE',
  'Vale refeição ou Vale alimentação': 'MEAL_VOUCHER_OR_FOOD_VOUCHER',
  'Vale refeição': 'MEAL_VOUCHER',
  'Vale alimentação': 'FOOD_VOUCHER',
  'Vale transporte ou Auxílio combustível': 'TRANSPOR_VOUCHER_OR_FUEL_VOUCHER',
  'Vale transporte': 'TRANSPOR_VOUCHER',
  'Auxílio combustível': 'FUEL_VOUCHER'
};

export const currencyArray = [
  { label: 'Real', value: 'BRL' },
  { label: 'Euro', value: 'EUR' },
  { label: 'Dólar', value: 'USD' },
  { label: 'Peso mexicano', value: 'MXN' },
  { label: 'Peso colombiano', value: 'COP' }
].sort(function (a, b) {
  return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
});

export const workRegimeLabels = {
  REMOTE: 'Remoto',
  HYBRID: 'Híbrido',
  PRESENTIAL: 'Presencial',
  OFFSHORE: 'Offshore'
};

export const professionalLevels = [
  {
    value: 'all',
    label: 'Todos'
  },
  {
    value: 'ARCHITECT',
    label: 'Arquiteto'
  },
  {
    value: 'ASSISTANT',
    label: 'Assistente'
  },
  {
    value: 'AUXILIARY',
    label: 'Auxiliar'
  },
  {
    value: 'COORDINATOR',
    label: 'Coordenador'
  },
  {
    value: 'DIRECTOR',
    label: 'Diretor'
  },
  {
    value: 'SPECIALIST',
    label: 'Especialista'
  },
  {
    value: 'INTERNSHIP',
    label: 'Estágio'
  },
  {
    value: 'MANAGER',
    label: 'Gerente'
  },
  {
    value: 'JUNIOR',
    label: 'Júnior'
  },
  {
    value: 'MASTER',
    label: 'Master'
  },
  {
    value: 'OPERACIONAL',
    label: 'Operacional'
  },
  {
    value: 'INTERMEDIATE',
    label: 'Pleno'
  },
  {
    value: 'SENIOR',
    label: 'Sênior'
  },
  {
    value: 'TECHNICIAN',
    label: 'Técnico'
  },
  {
    value: 'TRAINEE',
    label: 'Trainee'
  }
];

export const filters = {
  level: {
    label: 'Perfil da função',
    name: 'skillLevel',
    selection: 'multiple',
    options: [...skillLevelOptions]
  },
  typeOfContract: {
    label: 'Tipo de contrato',
    name: 'jobType',
    selection: 'multiple',
    options: [...jobTypes]
  },
  workload_description: {
    label: 'Período',
    name: 'period',
    selection: 'multiple',
    options: [...workLoadTypes]
  }
};

export const availabilityOptions = [
  {
    label: 'Imediato',
    value: 'NOW'
  },
  {
    label: '1-2 semanas',
    value: 'ONE_OR_TWO_WEEKS'
  },
  {
    label: '1 mês',
    value: 'ONE_MONTH'
  },
  {
    label: 'Mais de 1 mês',
    value: 'MORE_THAN_ONE_MONTH'
  }
];
