import React from 'react';
import PromoCard from 'src/views/IndexView/components/Home/PromoCard';
import Home from 'src/views/IndexView/components/Home';

import Page from 'src/components/Page';

const IndexView = () => {

  return (
    <Page style={{ overflowX: 'hidden', display:'flex'}} title="Página Inicial | Gria">
      <PromoCard/>
      <Home/>
    </Page>
  );

};

export default IndexView;
