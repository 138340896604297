
import facebookDrawerLateral from '../assets/images/facebookDrawerLateral.svg';
import gitHubDrawerLateral from '../assets/images/gitHubDrawerLateral.svg';
import instagramDrawerLateral from '../assets/images/instagramDrawerLateral.svg';
import linkedInDrawerLateral from '../assets/images/linkedinDrawerLateral.svg';
import othersDrawerLateral from '../assets/images/othersDrawerLateral.svg';
import tiktokDrawerLateral from '../assets/images/tiktokDrawerLateral.svg';
import youtubeDrawerLateral from '../assets/images/youtubeDrawerLateral.svg';


const getSocialImageDrawer = (socialMedia: string) => {
  switch (socialMedia) {
    case 'Linkedin':
      return linkedInDrawerLateral;
    case 'Facebook':
      return facebookDrawerLateral;
    case 'Instagram':
      return instagramDrawerLateral;
    case 'Tiktok':
      return tiktokDrawerLateral;
    case 'YouTube':
      return youtubeDrawerLateral;
    case 'GitHub':
      return gitHubDrawerLateral;
    default:
      return othersDrawerLateral;
  }
}

export default getSocialImageDrawer;
