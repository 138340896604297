import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { getModal, removeRouteSearchParams } from 'src/utils/handleRoutes';

type UseModalRouteType = (modalName: string, queriesParam?: string[]) => any[]

const useModalRoute: UseModalRouteType = (modalName, queriesParam) => {
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const queries = queriesParam ? queriesParam : []
  const modal = getModal(history.location.search, queries)

  useEffect(() => {
    if (modal[0] === modalName) {
      setOpenModal(true) 
    } else {
      setOpenModal(false)
    }
  }, [history.location])

  const removeFromRoute = (paramsToRemove: string[]) => {
    const searchParams = removeRouteSearchParams(history.location?.search, paramsToRemove)
    const correctSearch = searchParams.includes('??') ? searchParams.replace('?', '') : searchParams
    history.push({ search: correctSearch, state: history.location?.state })
  }
  
  const onClose = () => {
    //TODO
    //setOpenModal(false)
    removeFromRoute(['modal', 'candidateIds', 'candidateID', 'modal'])
  }

  const queriesValues = modal.slice(1)

  return [openModal, onClose, queriesValues]
};

export default useModalRoute