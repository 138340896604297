import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { companyService } from "src/services";
import type { AppThunk } from "src/store";
import { CandidateApplicationsItemType } from "src/types/candidacy";

interface RequestParamsProps {
  page: number;
  limit: number;
  userName?: string;
  average?: string;
  status?: string;
  process?: string;
}

interface OpportunityCandidateState {
  opportunityCandidates: CandidateApplicationsItemType[];
  opportunityCandidatesTotal: number;
  countJobStatus: {
    totalProcess: number;
    totalQuitterRegistered: number;
    totalRegistered: number;
    totalApproved: number;
    totalQuitterProcess: number;
    totalUnapproved: number;
    totalImmutableProcess: number;
  }
};

const initialState: OpportunityCandidateState = {
  opportunityCandidates: [],
  opportunityCandidatesTotal: 0,
  countJobStatus: {
    totalProcess: 0,
    totalQuitterRegistered: 0,
    totalRegistered: 0,
    totalApproved: 0,
    totalQuitterProcess: 0,
    totalUnapproved: 0,
    totalImmutableProcess: 0
  }
};

const slice = createSlice({
  name: "opportunityCandidates",
  initialState,
  reducers: {
    getOpportunityCandidates(
      state: OpportunityCandidateState,
      action: PayloadAction<{ items: any[], meta: any }>
    ) {
      const { items, meta } = action.payload;

      state.opportunityCandidates = items.map((opportunity, index) => {
      return opportunity
      }) || [];
      
      state.opportunityCandidatesTotal = meta.totalItems || 0;
      
      state.countJobStatus = {
        totalProcess: action.payload.meta.totalProcess ?? 0,
        totalQuitterRegistered: action.payload.meta.totalQuitterRegistered ?? 0,
        totalRegistered: action.payload.meta.totalRegistered ?? 0,
        totalApproved: action.payload.meta.totalApproved ?? 0,
        totalQuitterProcess: action.payload.meta.totalQuitterProcess ?? 0,
        totalUnapproved: action.payload.meta.totalUnapproved ?? 0,
        totalImmutableProcess: action.payload.meta.totalImmutableProcess ?? 0,
      }
    },
    setOpportunityCandidates(
      state: OpportunityCandidateState,
      action: PayloadAction<CandidateApplicationsItemType[]>
    ) {
      state.opportunityCandidates = action.payload
    }
  }
});

export const reducer = slice.reducer;

export const setOpportunityCandidates = (
  newOpportunityCandidates: CandidateApplicationsItemType[]
): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setOpportunityCandidates(newOpportunityCandidates))
}

export const getOpportunityCandidates = (
  jobID: string,
  params: RequestParamsProps,
  callback?: ()=> void, 
): AppThunk => async (dispatch) => {
  const response = await companyService.getAllAplicationsByJobId(jobID, params);

  dispatch(slice.actions.getOpportunityCandidates(response.data));
  
  if(callback){
    callback()
  }
}

export default slice;