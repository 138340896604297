import { ChatBubbleOutline } from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import brokenImage from 'src/assets/images/broken-image.svg';
import noImage from 'src/assets/images/no-image.svg';
import useAuth from 'src/hooks/useAuth';
import useModalRoute from 'src/hooks/useModalRoute';
import { useDispatch } from 'src/store';
import { Theme } from 'src/theme';
import { removeRouteSearchParams } from 'src/utils/handleRoutes';
import DrawerLateral from 'src/views/opportunities/opportunityCandidates/components/DrawerLateral';
import ModalAproveOrReprove from 'src/views/opportunities/opportunityCandidates/ModalAproveOrReprove';
import { companyService } from 'src/services';

import { AccountProps } from './Account';

const useStyles = makeStyles((theme: Theme) => ({
  readFlag: {
    minWidth: 12,
    minHeight: 12,
    marginRight: 16,
    borderRadius: '50%'
  },
  menuAvatar: {
    height: 40,
    width: 40,
    marginRight: 12
  },
  menuCandidacy: {
    fontSize: 14,
    fontWeight: 700
  },
  menuOpportunity: {
    fontSize: 14,
    fontWeight: 400
  },
  menuData: {
    fontSize: 12,
    fontWeight: 400
  },
  menuSubmenu: {
    margin: 0,
    padding: 0
  },
  messageOptions: {
    display: 'flex',
    marginLeft: 'auto'
  },
  changeHover: {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Nunito',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    '&:hover': {
      backgroundColor: '#F2ECF7'
    }
  },
  titleSection: {
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '0.10px',
    color: theme.palette.text.secondary,
    padding: '8px 16px'
  },
  messageDescription: {
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    color: 'inherit',
    width: '100%',
    '& p': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('sm')]: {
        width: 185
      }
    }
  },
  handleRead: {
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '0.10px',
    color: theme.palette.text.secondary
  }
}));

const Messages: FC<AccountProps> = ({
  allMessages,
  messagesCount,
  unreadMessages,
  moreMessages,
  isCompany,
  handleNotificationRead
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [isFistRequest, setIsFistRequest] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const [messageCandidate, onMessageCandidateClose, messageCandidateQueries] =
    useModalRoute('message', ['candidateIds']);
  const [reproveCandidate, onReproveCandidateClose, reproveCandidateQueries] =
    useModalRoute('reprove', ['candidateIds']);
  const [hireCandidate, onHireCandidateClose, hireCandidateQueries] =
    useModalRoute('hire', ['candidateIds']);
  const [
    undoUnapproveCandidate,
    onUndoUnapproveCandidateClose,
    undoUnapproveCandidateQueries
  ] = useModalRoute('undo', ['candidateId']);
  const [quittedCandidate, onQuittedCandidateClose, quittedCandidateQueries] =
    useModalRoute('quitter', ['candidateId']);

  const [infoApplication, setInfoApplication] = useState<any>({});

  const handleModalApproveorReproveClose = () => {
    if (hireCandidate) {
      onHireCandidateClose();
    } else if (reproveCandidate) {
      onReproveCandidateClose();
    } else if (quittedCandidate) {
      onQuittedCandidateClose();
    } else if (messageCandidate) {
      onMessageCandidateClose();
    } else {
      onUndoUnapproveCandidateClose();
    }
    removeFromRoute(['candidateIds']);
  };

  const {
    userProfile,
    candidateListParams,
    setCandidateListParams,
    setCurrentJobId
  } = useAuth();

  const dispatch = useDispatch();

  const state = useSelector((state: any) => state);

  const updateSituation = (newSituation, newEvent, applicationIds) => {
    const newApplication = { ...infoApplication };
    newApplication.events = newEvent[0];
    newApplication.situation = newSituation[0];
    setInfoApplication(newApplication);
  };

  const [anchorElMessage, setAnchorElMessage] =
    React.useState<null | HTMLElement>(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMessage(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElMessage(null);
  };

  const noImageCompany = `${process.env.REACT_APP_MEDIA_URL}/static/emails/assets/business.png`;

  const eventDate = (date: string) => {
    let startDate = moment(date);
    let current = moment();
    let dateDiff = moment.duration(current.diff(startDate)).asDays();
    return dateDiff >= 7
      ? moment(date).format('D [de] MMMM [às] H:M')
      : moment(date).calendar();
  };

  const handleAtualizationSearch = (applicationID: string) => {
    setIsFistRequest(true);
    urlParams.set('drawer', 'true');
    urlParams.set('applicationId', applicationID);
    urlParams.set('notification', 'true');
    history.push({
      pathname: history.location.pathname,
      search: urlParams.toString()
    });
  };

  const messageTabAvatar = (
    userHasAvatar: boolean,
    userToken: string,
    companyHasAvatar: boolean,
    companyToken: string
  ) => {
    if (isCompany)
      return userHasAvatar
        ? `${process.env.REACT_APP_MEDIA_URL}/users/${userToken}/avatar.png`
        : noImage;
    else
      return companyHasAvatar
        ? `${process.env.REACT_APP_MEDIA_URL}/companies/${companyToken}/avatar.png`
        : noImageCompany;
  };

  const [opportunity, setOpportunity] = useState({});

  const readNotification = async (token: string, read: boolean) => {
    setIsLoading(true);
    await handleNotificationRead(token, read);
    setIsLoading(false);
  };
  const messageClick = async (notification, job, application) => {
    if (!notification.view)
      await readNotification(notification.id, notification.view);
    isCompany
      ? handleAtualizationSearch(application.id)
      : history.push(`/candidate/applications/${application.id}`);

    handleCloseUserMenu();
  };

  const removeFromRoute = (paramsToRemove: string[]) => {
    const searchParams = removeRouteSearchParams(
      history.location?.search,
      paramsToRemove
    );
    const correctSearch = searchParams.includes('??')
      ? searchParams.replace('?', '')
      : searchParams;
    history.push({ search: correctSearch, state: history.location?.state });
  };

  return (
    <Box style={{ marginRight: 20 }}>
      <Tooltip title="Abrir mensagens">
        <IconButton
          onClick={handleOpenUserMenu}
          sx={{ p: 0 }}
          style={
            Boolean(anchorElMessage)
              ? {
                  width: 36,
                  height: 36,
                  borderRadius: '50%',
                  backgroundColor: '#0000000A'
                }
              : {
                  width: 36,
                  height: 36,
                  borderRadius: '50%'
                }
          }
        >
          <Badge badgeContent={unreadMessages} color="error">
            <ChatBubbleOutline />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        style={{ maxHeight: 600 }}
        sx={{ mt: '45px' }}
        id="messages-menu"
        anchorEl={anchorElMessage}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElMessage)}
        onClose={handleCloseUserMenu}
      >
        <Typography className={classes.titleSection}>Mensagens</Typography>
        {allMessages.length ? (
          allMessages.map(
            ({ user, company, application, job, notification }, index) => (
              <>
                <MenuItem
                  id={`messages-menu-item-${index}`}
                  className={classes.changeHover}
                  disabled={isLoading}
                  item-index={index}
                >
                  <Tooltip
                    title={`Marcar como ${notification.view ? 'não' : ''} lido`}
                  >
                    <span
                      onClick={() =>
                        readNotification(notification.id, notification.view)
                      }
                      style={
                        !notification.view
                          ? {
                              backgroundColor: '#2196F3'
                            }
                          : { backgroundColor: '#00000010' }
                      }
                      className={classes.readFlag}
                    />
                  </Tooltip>
                  <Avatar
                    alt="User"
                    className={classes.menuAvatar}
                    onClick={() => messageClick(notification, job, application)}
                    src={messageTabAvatar(
                      user.hasAvatar,
                      user.id,
                      company.hasAvatar,
                      company.id
                    )}
                  >
                    {((isCompany && company.hasAvatar) ||
                      (!isCompany && user.hasAvatar)) && (
                      <img
                        src={brokenImage}
                        className={classes.menuAvatar}
                        style={{ marginRight: 0 }}
                      />
                    )}
                  </Avatar>
                  <Box
                    className={classes.messageDescription}
                    onClick={() => messageClick(notification, job, application)}
                  >
                    <Typography className={classes.menuCandidacy}>
                      {isCompany ? user.name : company.fantasyName}
                    </Typography>
                    <Typography className={classes.menuOpportunity}>
                      {job.name}
                    </Typography>
                    <Typography className={classes.menuData}>
                      {eventDate(notification.createdAt)}
                    </Typography>
                  </Box>
                </MenuItem>
              </>
            )
          )
        ) : (
          <Typography className={classes.titleSection}>
            Você ainda não recebeu nenhuma mensagem
          </Typography>
        )}
        {allMessages.length &&
        allMessages.length < messagesCount &&
        !isLoading ? (
          <Typography
            className={classes.titleSection}
            style={{ textAlign: 'center', cursor: 'pointer' }}
            onClick={async () => {
              setIsLoading(true);
              await moreMessages();
              setIsLoading(false);
            }}
          >
            Ver mais ({allMessages.length}/{messagesCount})
          </Typography>
        ) : (
          <></>
        )}
      </Menu>
      {history.location.search.includes('notification=true') &&
        history.location.search.includes('drawer=true') && (
          <DrawerLateral
            isFistRequest={isFistRequest}
            setIsFistRequest={setIsFistRequest}
            infoApplication={infoApplication}
            setInfoApplication={setInfoApplication}
          />
        )}
      {history.location.search.includes('notification=true') && (
        <ModalAproveOrReprove
          open={
            hireCandidate ||
            messageCandidate ||
            reproveCandidate ||
            undoUnapproveCandidate ||
            quittedCandidate
          }
          onClose={handleModalApproveorReproveClose}
          application={infoApplication}
          isOpenDrawerToMessage={true}
          updateSituation={updateSituation}
        />
      )}
    </Box>
  );
};

export default Messages;
