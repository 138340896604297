import { makeStyles } from '@mui/styles';
import type { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 22,
    background: 'rgba(89, 10, 157, 0.06)',
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: 4,
    padding: 36
  },
  icon: {
    height: 35,
    width: 30,
    color: theme.palette.primary.main
  },
  textBold: {
    fontFamily: 'Nunito',
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: 0.15,
    textAlign: 'center',
    color: theme.palette.primary.main
  },
  text: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    letterSpacing: 0.15,
    textAlign: 'center',
    color: '#00000099'
  },
  button: {
    fontFamily: 'Nunito',
    fontWeight: 700,
    padding: '8px 12px'
  }
}));
