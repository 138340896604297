import type { FC } from 'react';
import React, { useEffect, useState } from 'react';

import { Close } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  createFilterOptions,
  Fade,
  IconButton,
  Modal,
  TextField,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { usePrefixesList } from 'src/hooks/usePositionsList';

import { LoadingButton } from '@mui/lab';
import { companyService } from 'src/services';
import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    color: '#000',
    [theme.breakpoints.down('sm')]: {}
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalWrapper: {
    height: 'auto',
    maxWidth: 564,
    width: '100%',
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
    '&:focus': {
      outline: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 12,
      marginRight: 12
    }
  },
  modalContent: {
    textAlign: 'center',
    margin: '16px 24px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    fontFamily: 'Nunito',
    fontSize: '20px',
    color: theme.palette.text.primary,
    fontWeight: 400,
    lineHeight: '32px',
    letterSpacing: 0.15,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'start'
    }
  },
  subtitle: {
    fontFamily: 'Nunito',
    fontSize: '14px',
    paddingLeft: '24px',
    textAlign: 'justify',
    color: theme.palette.text.secondary,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: 0.15
  },
  button: {
    fontFamily: 'Nunito',
    fontWeight: 700,
    height: '40px',
    maxWidth: '452px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      textAlign: 'center'
    }
  },
  childrenBox: {
    padding: '0 24px',
    marginTop: '16px'
  },
  boxContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 0'
  },
  input: {
    width: '54px',
    '& .MuiInputBase-root': {
      height: '40px'
    },
    '& .MuiOutlinedInput-input': {
      textAlign: 'center',
      fontFamily: 'Nunito'
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none'
    },
    '& input[type=number]': {
      MozAppearance: 'textfield'
    }
  },
  boxButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    textAlign: 'right',
    marginTop: '16px',
    marginBottom: '24px',
    padding: '0 24px',
    gap: '16px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: 0
    }
  }
}));

interface PositionModalProps {
  open: boolean;
  close?: () => void;
  title?: string;
  subtitle?: string;
  btnLeftLabel?: string;
  btnRightLabel?: string;
  onLeftButtonClick?: (value: any) => void;
  onRightButtonClick?: (value: any) => void;
  isEditing?: boolean;
  isPositionFound?: boolean;
  setItemValue?: any;
  setIsFistRender?: (any) => void;
  isFistRender?: boolean;
}

interface PostionItemType {
  inputValue?: string;
  role: string;
  code?: string;
}

interface PrefixItemType {
  inputValue?: string;
  prefix: string;
}

export const PositionModal: FC<PositionModalProps> = ({
  open,
  close,
  title,
  subtitle,
  btnLeftLabel,
  btnRightLabel,
  onLeftButtonClick,
  onRightButtonClick,
  isEditing,
  isPositionFound,
  setItemValue,
  setIsFistRender,
  isFistRender
}) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);

  const [prefixesList, setPrefixesList] = useState([]);
  let getPrefixesList = usePrefixesList();
  useEffect(() => {
    setIsLoading(true);
    setPrefixesList(
      getPrefixesList.map((item, index) => {
        return { index, prefix: item };
      })
    );
    setIsLoading(false);
  }, [window.localStorage.getItem('prefixes')]);

  const [prefixOptions, setPrefixOptions] = useState([]);

  const filter = createFilterOptions<PostionItemType>({
    trim: true,
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any'
  });
  const prefixFilter = createFilterOptions<PrefixItemType>();

  let prefixRef, positionRef;

  const [value, setValue] = useState(setItemValue || null);
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState('');
  const [isEditInputValue, setIsEditInputValue] = useState(false);
  const [prefixValue, setPrefixValue] = useState(null);
  const [inputPrefix, setInputPrefix] = useState('');
  const [prefixError, setPrefixError] = useState('');

  const [listOpen, setListOpen] = useState(false);

  const firstWordUppercase = (position: string) => {
    position.trim();
    if (
      typeof position !== 'string' ||
      position === null ||
      position === '' ||
      position === undefined ||
      position === 'null'
    )
      return '';

    let isPrefix = position.split(' ').length <= 1;
    let exist,
      listedItem = undefined;
    if (isPrefix) {
      exist = prefixesList.some((item) => {
        listedItem =
          item.prefix.toLocaleLowerCase() === position.toLocaleLowerCase()
            ? item.prefix
            : undefined;
        return item.prefix.toLocaleLowerCase() === position.toLocaleLowerCase();
      });
    } else {
      exist = prefixOptions.some((item) => {
        listedItem =
          item.role.toLocaleLowerCase() === position.toLocaleLowerCase()
            ? item.role
            : undefined;
        return item.role.toLocaleLowerCase() === position.toLocaleLowerCase();
      });
    }

    return exist
      ? listedItem
      : position
          .split(' ')
          .map((element) => {
            return element.length > 2
              ? element.charAt(0).toUpperCase() + element.slice(1).toLowerCase()
              : element;
          })
          .join(' ')
          .split('-')
          .map((element) => {
            return element.length > 2
              ? element.charAt(0).toUpperCase() + element.slice(1)
              : element;
          })
          .join('-');
  };

  const [selectedPrefix, setSelectedPrefix] = useState('');

  const getPositionsByPrefix = async (prefix: string) => {
    if (selectedPrefix?.trim() !== prefix.trim() && prefix !== '') {
      setSelectedPrefix(prefix);

      let prefixListCopy = prefixesList.map((item) => item.prefix);
      let presetPrefix = prefixListCopy.includes(prefix.trim());

      const { data } = presetPrefix
        ? await companyService.getPositionsByPrefix(prefix)
        : await companyService.getPositionsByUnsetPrefix(prefix);

      data.slice().map((position) => {
        if (position.role === prefix.trim()) {
          setInputValue(position.role + ' ');
          setValue({ ...position, role: position.role + ' ' });
        }
      });
      setPrefixOptions(data);
    }
    positionRef?.focus();
    setListOpen(true);
  };

  const changeInputValue = (event, newValue) => {
    setIsEditInputValue(true);
    if (!!inputError) {
      if (
        newValue
          ?.match(/[a-zA-Z]+/g)
          ?.join('')
          ?.match(/^[a-zA-Z]{3,}/) ||
        newValue === ''
      ) {
        setInputError('');
      }
    }

    if (
      newValue.trimLeft().indexOf(' ') == -1 &&
      !prefixOptions.find((item) => item.role === newValue.trimLeft())
    ) {
      setInputPrefix(newValue);
      setPrefixValue(null);
      setValue(null);
      setInputValue('');

      prefixRef?.focus();
      setListOpen(true);
    } else {
      setInputValue(firstWordUppercase(newValue));
    }
  };

  const changePrefixValue = (event, newValue: string) => {
    if (!!prefixError) {
      if (
        newValue
          ?.match(/[a-zA-Z]+/g)
          ?.join('')
          ?.match(/^[a-zA-Z]{3,}/) ||
        newValue === ''
      ) {
        setInputError('');
      }
    }
    setInputPrefix(firstWordUppercase(newValue));

    if (newValue.trimLeft().indexOf(' ') != -1) {
      getPositionsByPrefix(newValue.trim());
      setPrefixValue({ prefix: newValue.trimLeft() });
      setValue(newValue.trimLeft());

      positionRef?.focus();
      setListOpen(true);
    } else if (value !== '') {
      setValue(null);
    }
  };

  const confirmPositionDisabled = () => {
    if (value === null && value !== '') return true;
    if (!!inputError) return true;
    if (
      typeof value === 'string' &&
      prefixOptions?.length == 1 &&
      prefixOptions[0]?.role !== value.trim()
    )
      return true;
    if (prefixOptions?.length != 1 && typeof value === 'string') return true;

    return false;
  };

  const clearIndicator = Array.from(
    document.getElementsByClassName('MuiAutocomplete-clearIndicator')
  );
  clearIndicator.forEach((element) => {
    element.setAttribute('title', 'Limpar');
  });

  const dropdownIndicator = Array.from(
    document.getElementsByClassName('MuiAutocomplete-popupIndicator')
  );
  dropdownIndicator.forEach((element) => {
    element.setAttribute('title', 'Abrir');
  });
  useEffect(() => {
    dropdownIndicator.forEach((element) => {
      element.setAttribute('title', listOpen ? 'Fechar' : 'Abrir');
    });
  }, [listOpen]);

  useEffect(() => {
    if (!prefixValue && setItemValue?.role) {
      defaultInputValues();
    }
  }, []);

  const defaultInputValues = async () => {
    setIsLoading(true);
    let prefix = setItemValue?.role?.replace(/ .*/, '');
    setSelectedPrefix(prefix);
    const { data }: any = await companyService.getPositionsByPrefix(prefix);
    data?.slice().map((position) => {
      if (position.role === prefix.trim()) {
        setInputValue(position.role + ' ');
        setValue({ ...position, role: position.role + ' ' });
      }
    });
    setPrefixOptions(data);
    setInputPrefix(setItemValue?.role);
    setInputValue(setItemValue?.role);
    setValue({ role: setItemValue?.role });
    setListOpen(false);
    setIsLoading(false);
  };

  return (
    <Box className={classes.root}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
      >
        <Fade in={open}>
          <Box className={classes.modalWrapper}>
            <Box className={classes.modalContent}>
              {title && (
                <Typography
                  component="h5"
                  variant="h5"
                  className={classes.title}
                >
                  {title}
                </Typography>
              )}
              <IconButton onClick={close} style={{ paddingRight: 0 }}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
            <Box>
              {subtitle && (
                <Typography
                  component="h5"
                  variant="h5"
                  className={classes.subtitle}
                >
                  {subtitle}
                </Typography>
              )}
            </Box>
            <Box className={classes.childrenBox}>
              {inputPrefix.trimLeft().indexOf(' ') == -1 ? (
                <Autocomplete
                  freeSolo
                  value={prefixValue}
                  inputValue={inputPrefix}
                  disabled={isLoading}
                  loading={isLoading}
                  noOptionsText={'Sem opções'}
                  open={listOpen}
                  onOpen={() => {
                    setListOpen(true);
                  }}
                  onClose={() => {
                    setListOpen(false);
                  }}
                  onFocus={() => setListOpen(false)}
                  loadingText="Carregando..."
                  sx={{ '& .MuiInputLabel-root': { paddingTop: '4px' } }}
                  onChange={(event, newValue) => {
                    if (
                      newValue?.prefix
                        ?.match(/[a-zA-Z]+/g)
                        ?.join('')
                        ?.match(/^[a-zA-Z]{3,}/) ||
                      !newValue?.prefix
                    ) {
                      if (!!setPrefixError) {
                        setPrefixError('');
                      }
                    } else {
                      setPrefixError(
                        'Nome de cargo deve conter pelo menos 3 letras.'
                      );
                    }

                    if (typeof newValue === 'string') {
                      setPrefixValue({
                        prefix: newValue.replace(/['"]+/g, '') + ' '
                      });
                    } else if (newValue && newValue.prefix) {
                      setPrefixValue({
                        prefix: newValue.prefix.replace(/['"]+/g, '') + ' '
                      });
                    } else {
                      setPrefixValue({
                        prefix: newValue.replace(/['"]+/g, '') + ' '
                      });
                    }
                    isFistRender && setIsFistRender(false)
                  }}
                  onInputChange={changePrefixValue}
                  filterOptions={(options, params) => {
                    const filtered = prefixFilter(options, params);

                    const { inputValue } = params;

                    const isExisting = options.some(
                      (option) => inputValue === option.prefix
                    );
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        prefix: `"${inputValue}"`
                      });
                    }
                    let filteredSort = filtered?.sort((a, b) => {
                      let firstPosition = a.prefix?.toLowerCase()?.trim();
                      let secondPosition = b.prefix?.toLowerCase()?.trim();
                      return firstPosition < secondPosition
                        ? 1
                        : firstPosition > secondPosition
                        ? -1
                        : 0;
                    });
                    return filteredSort?.reverse().slice(0, 5);
                  }}
                  selectOnFocus
                  clearOnBlur
                  clearOnEscape
                  autoSelect
                  handleHomeEndKeys
                  id="input-prefix-id"
                  options={prefixesList}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }

                    if (option.inputValue) {
                      return option.inputValue;
                    }

                    return option.prefix;
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.prefix}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={(input) => {
                        prefixRef = input;
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                      label="Cargo"
                      sx={{ '& .MuiOutlinedInput-input': { height: '1.92em' } }}
                      variant="outlined"
                      error={!!prefixError}
                      helperText={prefixError}
                    />
                  )}
                />
              ) : (
                <Autocomplete
                  value={value}
                  freeSolo
                  inputValue={inputValue}
                  disabled={isLoading}
                  loading={isLoading}
                  noOptionsText={'Sem opções'}
                  open={listOpen}
                  onOpen={() => {
                    setListOpen(true);
                  }}
                  onClose={() => {
                    setListOpen(false);
                  }}
                  onFocus={() => setListOpen(true)}
                  loadingText="Carregando..."
                  sx={{ '& .MuiInputLabel-root': { paddingTop: '4px' } }}
                    onChange={(event, newValue) => {
                    if (
                      newValue?.role
                        ?.match(/[a-zA-Z]+/g)
                        ?.join('')
                        ?.match(/^[a-zA-Z]{3,}/) ||
                      !newValue?.role
                    ) {
                      if (!!setInputError) {
                        setInputError('');
                      }
                    } else {
                      setInputError(
                        'Nome de cargo deve conter pelo menos 3 letras.'
                      );
                    }
                    if (typeof newValue === 'string') {
                      setValue({
                        role: newValue,
                        code: value?.code
                      });
                    } else if (newValue && newValue.inputValue) {
                      setValue({
                        role: newValue.inputValue
                      });
                    } else {
                      setValue(newValue);
                    }
                      isFistRender && setIsFistRender(false)
                  }}
                  onInputChange={changeInputValue}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;

                    const isExisting = options.some(
                      (option) =>
                        inputValue.toLocaleLowerCase() ===
                        option.role.toLocaleLowerCase()
                    );
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        role: `"${inputValue}"`
                      });
                    }
                    let filteredSort = filtered?.sort((a, b) => {
                      let firstPosition = a.role?.toLowerCase()?.trim();
                      let secondPosition = b.role?.toLowerCase()?.trim();
                      return firstPosition < secondPosition
                        ? 1
                        : firstPosition > secondPosition
                        ? -1
                        : 0;
                    });
                    return filteredSort?.reverse().slice(0, 5);
                  }}
                  selectOnFocus
                  clearOnBlur
                  clearOnEscape
                  autoSelect
                  handleHomeEndKeys
                  id="input-position-id"
                  options={prefixOptions}
                  getOptionDisabled={(option) =>
                    option.role === selectedPrefix &&
                    option.role === inputValue.trim()
                  }
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }

                    if (option.inputValue) {
                      return option.inputValue;
                    }

                    return option.role;
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.role}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={(input) => {
                        positionRef = input;
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                      label="Cargo"
                      sx={{ '& .MuiOutlinedInput-input': { height: '1.92em' } }}
                      variant="outlined"
                      error={!!inputError}
                      helperText={inputError}
                    />
                  )}
                />
              )}
            </Box>
            <Box padding="16px 24px">
              {isEditing && !isLoading && isEditInputValue && !isFistRender && (
                <Alert severity={value?.code ? 'warning' : 'info'}>
                  {value?.code && <AlertTitle>Atenção</AlertTitle>}
                  {value?.code ? (
                    <p>
                      Ao clicar em <strong>sobrescrever os dados</strong>, você
                      irá perder todos os campos preenchidos anteriormente na
                      vaga.
                    </p>
                  ) : (
                    <p>
                      Ao clicar em <strong>alterar o cargo</strong>, você irá
                      trocar apenas o nome do cargo. Os outros campos da vaga
                      não serão alterados.
                    </p>
                  )}
                </Alert>
              )}
            </Box>
            <Box className={classes.boxButtons}>
              {isEditing && !isLoading && value?.code && !isFistRender && (
                <Button
                  id="cancel-set-position-button-id"
                  variant="outlined"
                  color="error"
                  className={classes.button}
                  onClick={() => onLeftButtonClick(value)}
                >
                  {btnLeftLabel}
                </Button>
              )}
              {btnRightLabel && !isLoading && isEditInputValue && !isFistRender && (
                <LoadingButton
                  loading={isLoading}
                  id="confirm-set-position-button-id"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    setIsLoading(true);
                    onRightButtonClick(value);
                  }}
                  disabled={confirmPositionDisabled()}
                >
                  {isEditing && !value?.code
                    ? 'Alterar o cargo'
                    : btnRightLabel}
                </LoadingButton>
              )}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};
