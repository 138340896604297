import React, { useState, useEffect } from 'react';
import type { FC, ReactNode } from 'react';

import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box } from '@mui/material';

import type { Theme } from 'src/theme';
import NavBar from './NavBar';
import TopBar from './TopBar';
import LateralMenu from 'src/layouts/DashboardLayout/NavBar/LateralMenu';
import ModalRegistration from 'src/views/opportunities/opportunitieModals/ModalRegistration';
import useAuth from 'src/hooks/useAuth';
import { SwitchProducts } from 'src/components/molecules/Modals/SwitchProducts/SwitchProducts';
import { MenuDrawer } from './Drawer/MenuDrawer';
import WhasApp from 'src/chat/WhatsApp/WhasApp';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.layout,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    paddingTop: 64
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  pageContainer: {
    flex: 1,
    display: 'flex',
    overflow: 'hidden'
  },
  page: {
    flex: 1,
    overflow: 'auto'
  }
}));

const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const { userProfile, userType } = useAuth();
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  const accessRouteIsInsights = history.location.pathname.includes('/insights');
  const showLateralMenu = [
    '/company/member/profile',
    '/company/profile',
    '/company/profile/edit',
    '/company/profile/settings',
    '/candidate/profile/settings',
    '/candidate/profile/',
    '/candidate/profile/view',
    '/company/profile/user-management',
    '/company/profile/user-management/invite-member',
    '/company/profile/invoice-management',
    '/company/support',
    '/company/support/create-roles',
    '/company/support/alter-roles',
    '/company/support/edit-companies'
  ].includes(history?.location?.pathname);

  const showModalRegistration = ![
    '/company/profile/edit',
    '/candidate/profile/finish/'
  ].includes(history.location.pathname);

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true
  });

  const typeService = userProfile?.associatedCompanies[0]?.company?.typeService;
  const companyIsAll = typeService === 'all';
  const [openSwitchProductsModal, setOpenSwitchProductsModal] =
    useState<boolean>(
      window.localStorage.getItem('switchProductsModal')
        ? JSON.parse(window.localStorage.getItem('switchProductsModal'))
        : true
    );

  const handleCloseSwitchProducts = () => {
    setOpenSwitchProductsModal(false);
    window.localStorage.setItem('switchProductsModal', JSON.stringify(false));
  };

  const [openDrawer, setOpenDrawer] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const userIsCompany = userType?.isCompany();
  const [hideChat, setHideChat] = useState<boolean>(true);

  useEffect(() => {
    if (!accessRouteIsInsights) {
      setHideChat(false);
    }
  }, [history.location.pathname]);

  return (
    <Box className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        handleOpenDrawer={handleDrawerOpen}
      />
      {isMediumScreen && (
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
      )}
      <Box className={classes.content}>
        {showModalRegistration && !accessRouteIsInsights && (
          <ModalRegistration />
        )}
        <Box className={classes.pageContainer}>
          {showLateralMenu && !isMediumScreen && <LateralMenu />}
          {accessRouteIsInsights && openDrawer && !isMediumScreen && (
            <MenuDrawer open={openDrawer} handleClose={handleDrawerClose} />
          )}
          <Box className={classes.page}>{children}</Box>
        </Box>
      </Box>
      {openSwitchProductsModal && companyIsAll && (
        <SwitchProducts
          open={openSwitchProductsModal && companyIsAll}
          handleClose={handleCloseSwitchProducts}
        />
      )}

      {userIsCompany && hideChat && <WhasApp />}
    </Box>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
