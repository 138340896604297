import React, { useState, useEffect } from 'react';
import useAuth from 'src/hooks/useAuth';
import {
  Modal,
  Typography,
  Box,
  Autocomplete,
  Button,
  TextField
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useStyles } from './styles';
import { companyService } from 'src/services';

interface SwitchCompanyModalProps {
  open: boolean;
  onClose: () => void;
}

function SwitchCompanyModal({ open, onClose }: SwitchCompanyModalProps) {
  const styles = useStyles();
  const { userProfile, switchCompany } = useAuth();

  const [value, setValue] = useState(null);
  const [listOpen, setListOpen] = useState<boolean>(false);
  const [options, setOptions] = useState([]);

  const getCompanies = () => {
    companyService.getAll().then((res) => {
      const currentCompany = userProfile?.associatedCompanies[0]?.company;
      const newOptions = res.data.filter(
        (company: any) => company.id !== currentCompany?.id
      );
      setOptions(newOptions);
    });
  };

  useEffect(() => {
    setValue(null);
    if (open) {
      getCompanies();
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={styles.container}>
        <Box className={styles.header}>
          <Typography variant="h6" className={styles.title}>
            Trocar de empresa
          </Typography>
          <Close className={styles.icon} onClick={onClose} />
        </Box>
        <Autocomplete
          value={value}
          onChange={(event: any, newValue: any) => {
            setValue(newValue);
          }}
          getOptionLabel={(option: any) => option.fantasyName}
          options={options}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.fantasyName}
            </li>
          )}
          noOptionsText={'Nenhuma empresa encontrada'}
          sx={{ '& .MuiInputLabel-root': { paddingTop: '2px' } }}
          open={listOpen}
          selectOnFocus
          clearOnBlur
          clearOnEscape
          handleHomeEndKeys
          onOpen={() => {
            setListOpen(true);
          }}
          onClose={() => {
            setListOpen(false);
          }}
          onFocus={() => setListOpen(true)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Empresa"
              sx={{ '& .MuiOutlinedInput-input': { height: '1.92em' } }}
              variant="outlined"
            />
          )}
        />
        <Box className={styles.buttonContainer}>
          <Button
            onClick={() => switchCompany(value.id)}
            className={styles.button}
            variant="contained"
            disabled={!value}
          >
            Trocar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default SwitchCompanyModal;
