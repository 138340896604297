import React, { useState, useCallback, useEffect } from 'react';
import { Typography, Alert, Button } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Dropzone from './Dropzone';
import { candidateService } from 'src/services';
import useWebSocket from 'src/hooks/useWebSocket';
import useAuth from 'src/hooks/useAuth';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { useStyles } from './styles';

interface UploadResumeDataProps {
  loadData: (data: any) => void;
}

function UploadResumeData({ loadData }: UploadResumeDataProps) {
  const styles = useStyles();
  const { receiveData: curriculum, startConnection } = useWebSocket();
  const { userProfile } = useAuth();
  const defaultEMail = userProfile.user.email;

  const [hasPreviousCurriculum, setHasPreviousCurriculum] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const onDrop = useCallback((files) => {
    setError('');
    setIsLoading(true);
    const file = files[0];

    const formData = new FormData();
    formData.append('file', file);
    formData.append('_method', 'POST');

    candidateService
      .uploadCurriculum(formData)
      .then(() => {
        startConnection(
          `source=frontend&type=profile&userId=${userProfile?.user.id}`
        );
      })
      .catch(() => {
        setError(
          'Erro ao subir o arquivo do currículo. Confira o formato do arquivo e tente novamente.'
        );
        setIsLoading(false);
      });
  }, []);

  const handleData = (data: any) => {
    const newCurriculum = _.cloneDeep(data.curriculum);

    newCurriculum.experiences.map(exp => {
      if (exp.startDate) {
        exp.currentWork = exp.endDate ? 'NOT_CURRENT' : 'CURRENT';
      }

      return exp;
    });

    for (const i in newCurriculum) {
      if (Array.isArray(newCurriculum[i])) {
        newCurriculum[i] = newCurriculum[i].map((item: any) => {
          if (item.startDate) {
            item.startDate = moment(item.startDate).format('YYYY-MM-DD');
          }
          if (item.endDate) {
            item.endDate = moment(item.endDate).format('YYYY-MM-DD');
          }

          item.id = uuidv4();
          item.isTemporary = true;
          return item;
        });
      }
    }

    data.curriculum = newCurriculum;
    data.email = defaultEMail;

    loadData(data);
  };

  useEffect(() => {
    if (curriculum) {
      setIsLoading(false);
      handleData(curriculum);
    }
  }, [curriculum]);

  useEffect(() => {
    candidateService.checkCurriculumExtraction().then((res) => {
      if (res.data) {
        setHasPreviousCurriculum(true);
      }
    });
  }, []);

  const downloadPreviousCurriculum = () => {
    setIsLoading(true);
    candidateService
      .getS3CurriculumOutput()
      .then((res) => {
        const curriculum = JSON.parse(atob(res.data));
        handleData(curriculum);
      })
      .catch(() => {
        setError(
          'Erro ao baixar o arquivo do currículo. Tente novamente mais tarde.'
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <>
          <Typography className={styles.text} style={{ marginTop: 28 }}>
            Carregando arquivo...
          </Typography>
          <LinearProgress style={{ marginTop: 12 }} />
        </>
      ) : (
        <>
          {hasPreviousCurriculum && (
            <Alert
              severity="info"
              className={styles.alert}
              action={
                <Button
                  color="info"
                  variant="contained"
                  size="small"
                  className={styles.alertButton}
                  onClick={downloadPreviousCurriculum}
                >
                  prosseguir
                </Button>
              }
            >
              <Typography className={styles.alertText}>
                Você possui um currículo carregado anteriormente.
              </Typography>
            </Alert>
          )}
          {!!error && (
            <Alert severity="warning" className={styles.alert}>
              <Typography className={styles.alertText}>{error}</Typography>
            </Alert>
          )}
          <Dropzone onDrop={onDrop} />
        </>
      )}
    </>
  );
}

export default UploadResumeData;
