import clsx from 'clsx';
import React from 'react';

import { Theme } from 'src/theme';

import {
  Check,
  Close, LockOutlined
} from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';


interface Process {
  active: boolean;
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  order: number;
  companyJobId: string;
  process: string;
}

interface ProcessStatusType {
  active: boolean;
  applicationId: string;
  comment: string;
  companyJobSelectiveProcess: Process;
  companyJobSelectiveProcessId: string;
  createdAt: string;
  deletedAt: string | null;
  updatedAt: string;
  id: string;
  userId: string;
  status: 'REVIEW' | 'APPROVED' | 'UNAPPROVED' | 'QUITTER' | 'REGISTERED';
  type: string | null;
}

interface StepProps {
  isLast: boolean;
  prevStatus: string;
  processStatus?: ProcessStatusType;
  processOrder: number;
}

interface StepsListProps {
  processStatus: ProcessStatusType[];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  stepRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '50px',
    marginBottom: '2em',
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.down(650)]: {
      height: '120px',
      gap: '8px'
    }
  },
  stepContentContainer: {
    padding: '28px 0 28px 25px',
    width: '100%',
    borderRadius: '15px',
    backgroundColor: '#fcfafe', //TODO: Add color to theme
    transition: 'all 200ms ease-in-out',
    [theme.breakpoints.down('sm')]: {
      padding: '28px 6px 28px 16px'
    }
  },
  stepContentContainerDisabled: {
    backgroundColor: '#fcfcfc', //TODO: Add color to theme,
    color: '#c4c4c4' // TODO: Add color to theme
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1.125rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
  small: {
    fontSize: 12,
    fontWeight: 400,
    width: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    [theme.breakpoints.down(960)]: {
      width: 550
    },
    [theme.breakpoints.down(840)]: {
      width: 350
    },
    [theme.breakpoints.down(552)]: {
      width: 'auto'
    },
    [theme.breakpoints.down('xs')]: {
      width: 250
    },
    [theme.breakpoints.down(410)]: {
      width: 150
    }
  },
  status: {
    width: 'fit-content',
    padding: '1px 2px',
    fontSize: 12,
    fontWeight: 500,
    marginTop: 5,
    marginLeft: 32,
    borderRadius: 2
  },
  stepContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  stepNumber: {
    position: 'relative',
    width: '55px',
    height: '55px',
    textAlign: 'center',
    fontSize: 25,
    padding: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: '50%',
    transition: 'all 200ms ease-in-out',
    ...theme.typography.h3,
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '2px',
      height: '7em',
      backgroundColor: theme.palette.primary.main,
      zIndex: -1,
      marginLeft: 'auto',
      marginRight: 'auto',
      top: 0,
      right: 'auto',
      left: '25px',
      textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      fontSize: 14,
      minWidth: 0,
      minHeight: 0,
      '&:after': {
        content: '""',
        position: 'absolute',
        width: '2px',
        height: '10em',
        backgroundColor: theme.palette.primary.main,
        zIndex: -1,
        marginLeft: 'auto',
        marginRight: 'auto',
        top: '29px',
        right: 'auto',
        left: '25px',
        textAlign: 'center'
      }
    },
    [theme.breakpoints.down(650)]: {
      width: 40,
      height: 35,
      padding: 6,
      '&:after': {
        left: '17px'
      }
    },
    [theme.breakpoints.down('xs')]: {
      padding: '24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:after': {
        height: '16em'
      }
    }
  },
  stepNumberDisabled: {
    backgroundColor: '#c4c4c4 !important', // TODO: Add color to theme
    width: 55,
    height: 55,

    '&:after': {
      backgroundColor: '#c4c4c4 !important' // TODO: Add color to theme
    },
    [theme.breakpoints.down(650)]: {
      width: 40,
      height: 35,
      padding: 6
    }
  },
  lastStepNumber: {
    position: 'relative',
    padding: 14,
    width: 55,
    height: 55,
    backgroundColor: theme.palette.primary.main,
    transition: 'all 200ms ease-in-out',
    color: theme.palette.common.white,
    borderRadius: '50%',
    ...theme.typography.h3,
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      fontSize: 14,
      minWidth: 0,
      minHeight: 0
    },
    [theme.breakpoints.down(650)]: {
      width: 40,
      height: 35,
      padding: 6
    },
    [theme.breakpoints.down('xs')]: {
      padding: '24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
}));

const Step: React.FC<StepProps> = ({ isLast, processStatus, processOrder }) => {
  const classes = useStyles();
  const getProcessLabel = (label: string) => {
    if (label === 'SCREENING') return 'Inscrito';
    if (label === 'HIRING') return 'Contratação';
    return label;
  };

  const { status, companyJobSelectiveProcess, comment } = processStatus;
  const isQuitter = status === 'QUITTER';

  const getStepIcon = () => {
    switch (status) {
      case 'APPROVED':
        return <Check htmlColor="#FFFFFF" />;
      case 'UNAPPROVED':
      case 'QUITTER':
        return <Close htmlColor="#FFFFFF" />;
      case 'QUITTER':
        return <LockOutlined color="disabled" />;
      default:
        return null;
    }
  };

  return (
    <Box className={classes.stepRoot}>
      <Box
        className={`${clsx(
          !isLast ? classes.stepNumber : classes.lastStepNumber
        )} ${
          !processStatus || status === 'REVIEW'
            ? classes.stepNumberDisabled
            : ''
        }`}
      >
        {getStepIcon()}
      </Box>

      <Box
        className={clsx(classes.stepContentContainer, {
          [classes.stepContentContainerDisabled]:
            !processStatus || status === 'REVIEW'
        })}
      >
        <Box className={classes.stepContent}>
          {/* {status === 'APPROVED' && <CheckCircleRounded color="secondary" />}

          {status === 'ANALYZE' && <LockOpenOutlined color="disabled" />}
          {(status === 'WAITING' || status === 'CANCELLED' ) && <LockOutlined color="disabled" />}
          {status === 'UNAPPROVED' && <Cancel color="secondary" />}
          {status === 'QUITTER' && <Cancel color="secondary" />} */}

          <Typography className={classes.bold}>
            {getProcessLabel(companyJobSelectiveProcess.process)}
          </Typography>
        </Box>
        {/* { (status === 'APPROVED' || status === 'UNAPPROVED') && (
            <Typography 
              style={processStatusOptions[status].style} 
              className={classes.status}
            >
              {processStatusOptions[status].label}
            </Typography>
          )} */}
        {/* {status === 'ANALYZE' && (
          <Typography className={classes.small}>A empresa entrará em contato para informar sobre esta etapa</Typography>
          )} */}
        {isQuitter && (
          // <Typography style={processStatusOptions[status].style}  className={classes.status}>{processStatusOptions[status].label}</Typography>
          <Typography className={classes.small}>
            Você desistiu do processo
          </Typography>
        )}
        {comment && !isQuitter && (
          <Typography className={classes.small}>{comment}</Typography>
        )}
      </Box>
    </Box>
  );
};

export const StepsList: React.FC<StepsListProps> = ({ processStatus }) => {
  const findProcessStatus = (
    processId: string,
    processStatus: ProcessStatusType[]
  ) => {
    const statusIndex = processStatus.findIndex(
      (process) => process.companyJobSelectiveProcessId === processId
    );

    return statusIndex < 0 ? null : processStatus[statusIndex];
  };

  if (typeof processStatus === 'undefined') {
    return <></>;
  }

  // To control styling of the last step
  const processStatusLength = processStatus.length;

  return (
    <>
      {processStatus.map((status, idx) => {
        const isLast = idx === processStatusLength - 1;
        const processOrder = idx + 1;
        return (
          <Step
            processStatus={findProcessStatus(
              status.companyJobSelectiveProcessId,
              processStatus
            )}
            prevStatus={processStatus[idx - 1].status}
            isLast={isLast}
            key={idx}
            processOrder={processOrder}
          />
        );
      })}
    </>
  );
};
