import React from 'react';
import { Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from 'src/theme';
import {
  RadioButtonUncheckedOutlined,
  HighlightOffOutlined,
  BlockOutlined
} from '@mui/icons-material';
import {
  CompanyStateType,
  opportunityStateToLabel,
  opportunityStateType
} from 'src/utils/helpers';
import clsx from 'clsx';

// enum CompanyStateTypex {
//   Draft,
//   Open,
//   Pause,
//   Closed,
//   Canceled
// }

interface CompanyStateProps {
  stateType: CompanyStateType;
  stateLabel?: string;
  className?: string;
  hideIcon?: boolean;
  fontSize?: number;
  indexState?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(0.5),
    maxWidth: 'fit-content',
    textAlign: 'center'
  },
  text: {
    fontWeight: 400,
    letterSpacing: 0.83,
    textTransform: 'capitalize',
    paddingLeft: 6,
    paddingRight: 6
  },
  icon: {
    marginRight: 10,
    width: 12,
    height: 12,
    color: theme.palette.table.tableTitle
  }
}));

const CompanyState: React.FC<CompanyStateProps> = ({
  stateType,
  stateLabel,
  fontSize,
  hideIcon = false,
  className = '',
  indexState
}) => {
  const classes = useStyles();

  const handleWithOpportunityStatus = (status: string) => {
    switch (status) {
      case opportunityStateToLabel['OPEN'] ||
        opportunityStateToLabel['PAUSE'] ||
        opportunityStateToLabel['UNPAID']:
        return (
          <RadioButtonUncheckedOutlined
            style={{ fontSize }}
            className={classes.icon}
          />
        );
      case opportunityStateToLabel['CLOSED']:
        return (
          <HighlightOffOutlined style={{ fontSize }} className={classes.icon} />
        );
      case opportunityStateToLabel['CANCELED']:
        return <BlockOutlined style={{ fontSize }} className={classes.icon} />;
      default:
        return (
          <RadioButtonUncheckedOutlined
            style={{ fontSize }}
            className={classes.icon}
          />
        );
    }
  };

  const makeContainer = (state: string, background: string, color: string) => {
    return (
      <div className={clsx(classes.container, className)}>
        {!hideIcon && handleWithOpportunityStatus(state)}
        <Box
          style={{
            background,
            padding: '3px 2px',
            borderRadius: 4,
            border: state === 'Rascunho' && '1px solid #590A9D'
          }}
        >
          <Typography
            id={`inde-state-card-span-id${indexState}`}
            variant="caption"
            className={classes.text}
            style={{ color, fontSize: '13px', letterSpacing: '0.16px' }}
          >
            {state}
          </Typography>
        </Box>
      </div>
    );
  };

  return (
    <>
      {stateType === opportunityStateType.CLOSED &&
        makeContainer(stateLabel, 'rgb(224, 224, 224)', 'rgba(0, 0, 0, 0.87)')}
      {stateType === opportunityStateType.DRAFT &&
        makeContainer(stateLabel, '#FFF', '#590A9D')}
      {stateType === opportunityStateType.CANCELED &&
        makeContainer(stateLabel, 'rgba(235, 87, 87, 0.2)', '#E63C5B')}
      {(stateType === opportunityStateType.OPEN ||
        stateType === opportunityStateType.PAUSE) &&
        makeContainer(stateLabel, '#4CAF50', '#FFFFFF')}
      {stateType === opportunityStateType.UNPAID &&
        makeContainer(stateLabel, '#03A9F4', '#FFFFFF')}
    </>
  );
};

export default CompanyState;
