import { createSlice } from "@reduxjs/toolkit";
import CompanyService from "src/services/company.service";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { AppThunk } from "src/store";
import { GeneralInformationProfile } from "src/types/generalInformationProfile";

interface CandidateCurriculumState {
  candidateCurriculum: GeneralInformationProfile;
  curriculumFile: string | null;
};

const initialState: CandidateCurriculumState = {
  candidateCurriculum: null,
  curriculumFile: null
};

const slice = createSlice({
  name: "candidateCurriculum",
  initialState,
  reducers: {
    getCandidateCurriculum(
      state: CandidateCurriculumState,
      action: PayloadAction<any>
    ) {
      const candidate = action.payload;

      state.candidateCurriculum = {
        id: candidate?.id,
        name: candidate?.name,
        surname: candidate?.surname,
        specialty: candidate?.specialty,
        document: candidate?.document,
        email: candidate?.email,
        cellPhone: candidate?.cellPhone,
        homePhone: candidate?.homePhone,
        birthDate: candidate?.birthDate,
        address: {
          cep: candidate?.zipCode,
          street: candidate?.address,
          number: candidate?.addressNumber,
          additionalInfo: candidate?.additionalInfo,
          neighborhood: candidate?.burgh,
          city: candidate?.city,
          state: candidate?.state,
          country: candidate?.country
        },
        curriculum: candidate?.curriculum,
        avatar: candidate?.hasAvatar ? `${process.env.REACT_APP_MEDIA_URL}/users/${candidate.id}/avatar-medium.png` : "",
        currentPosition: candidate?.currentPosition,
        genre: candidate?.genre,
        socialNetworks: candidate?.socialNetworks || [],
        headline: candidate?.headline,
        abstract: candidate?.abstract
      };
    },
    setCurriculumFile(
      state: CandidateCurriculumState,
      action: PayloadAction<string>
    ) {
      state.curriculumFile = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getCandidateCurriculum = (id: string): AppThunk => async (dispatch) => {
  const response = await CompanyService.getUserProfileById(id);
  dispatch(slice.actions.getCandidateCurriculum(response.data));
}

export const setCandidateCurriculum = (data: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.getCandidateCurriculum(data));
}

export const setCandidateCurriculumFile = (data: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCurriculumFile(data));
}

export default slice;