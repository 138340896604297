import { Button, ListItem, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import type { Theme } from 'src/theme';

interface NavItemProps {
  className?: string;
  depth: number;
  href?: string;
  icon?: any;
  info?: any;
  open?: boolean;
  title: string;
  finishedRegister?: boolean;
  close?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    color: theme.palette.icon.default,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: '0.15px',
    width: '100%',
    fontWeight: 400,
    lineHeight: '24px',
    fontSize: '16px'
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  title: {
    fontFamily: 'Nunito',
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 700,
    letterSpacing: '0.15px',
    marginRight: 'auto',
    marginLeft: '8px',
    color: theme.palette.table.grayPrimary
  },
  active: {},
  tooltip: {
    maxWidth: 248,
    maxHeight: 100,
    marginLeft: 260,
    marginTop: -50,
    backgroundColor: '#232F34'
  },
  tooltipText: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    letterSpacing: '0em',
    textAlign: 'left'
  }
}));

const NavItem: FC<NavItemProps> = ({
  finishedRegister,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  close,
  title,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(openProp);

  const handleToggle = (): void => {
    close();
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  const tooltipMessage =
    !finishedRegister && href === '/company/jobs/create' ? (
      <Typography className={classes.tooltipText}>
        <strong>Dados cadastrais incompletos!</strong>
        <br></br>
        Os dados cadastrais da sua empresa estão incompletos. É preciso
        atualizar o cadastro para poder criar uma vaga.
      </Typography>
    ) : (
      ''
    );

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      data-open={window.location.pathname === href}
      key={title}
      {...rest}
    >
      <Tooltip title={tooltipMessage} classes={{ tooltip: classes.tooltip }}>
        <span style={{ width: '100%' }}>
          <Button
            activeClassName={classes.active}
            className={clsx(classes.buttonLeaf, `depth-${depth}`)}
            component={RouterLink}
            exact
            style={style}
            to={href}
            disabled={!finishedRegister && href === '/company/jobs/create'}
            onClick={handleToggle}
          >
            {Icon && <Icon className={classes.icon} size="20" />}
            <span className={classes.title}>{title}</span>
            {Info && <Info />}
          </Button>
        </span>
      </Tooltip>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  close: PropTypes.func,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  open: false
};

export default NavItem;
