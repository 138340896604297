import React from 'react';
import type { FC } from 'react';
import { Box, Button, Fade, Modal, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from 'src/theme';

import { LoadingButton } from '@mui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    color: '#000',
    [theme.breakpoints.down('sm')]: {
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalWrapper: {
    height: 180,
    width: 560,
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
    textAlign: "center",
    '&:focus': {
      outline: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
      marginRight: 10,
      width: "90%",
      height: 'auto'
    }
  },
  modalWrapperCompany: {
    maxHeight: 240,
    width: 560,
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
    textAlign: "center",
    '&:focus': {
      outline: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
      marginRight: 10,
      width: "90%",
      height: 'auto',
      maxHeight: 'max-content'
    }
  },
  modalContent: {
    textAlign: "center",
    margin: '20px 17px 40px 24px',
  },
  title: {
    fontSize: 20,
    marginBottom: 15,
    color: theme.palette.table.blackSecondary,
    fontWeight: 500,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    textAlign: 'left',
    letterSpacing: 0.15,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18
    }
  },
  subtitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: theme.palette.table.grayPrimary,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'justify'
    }
  },
  text: {
    color: theme.palette.table.grayPrimary,
    fontSize: 18,
    marginBottom: 30,
    width: '305px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'justify'
    }
  },
  button: {
    height: 36,
    margin: '0 10px 20px 0px',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      margin: '0 20px 20px 0px',
    }
  },
  buttonCompany: {
    height: 36,
    margin: '0 10px 8px 0px',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      margin: '0 20px 20px 0px',
    }
  }
}));

interface ModalProps {
  title?: string;
  subtitle?: string;
  content?: string;
  className?: string;
  open?: boolean;
  btnRightLabel?: string;
  btnLeftLabel?: string;
  btnCenterLabel?: string;
  onLeftButtonClick?: () => void;
  onRightButtonClick?: any;
  onCenterButtonClick?: () => void;
  modalCompany?: boolean;
  disableButtons?: boolean;
}

const ModalCurriculum: FC<ModalProps> = ({
  title,
  subtitle,
  content,
  open,
  btnRightLabel,
  btnLeftLabel,
  btnCenterLabel,
  onLeftButtonClick,
  onRightButtonClick,
  onCenterButtonClick,
  modalCompany,
  disableButtons

}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
      >
        <Fade in={open}>
          <Box className={modalCompany ? classes.modalWrapperCompany : classes.modalWrapper}>
            <Box className={classes.modalContent} >
              {title && <Typography component="h5" variant="h5" className={classes.title}>
                {title}
              </Typography>}
              {subtitle && <Typography component="h5" variant="h5" className={classes.subtitle}>
                {subtitle}
              </Typography>}
              {content && <Typography component="p" variant="body1" className={classes.text}>
                {content}
              </Typography>}
            </Box>
            <Box textAlign="right" marginTop="30px">
              {btnCenterLabel && <Button
                variant="outlined"
                color="primary"
                className={modalCompany ? classes.buttonCompany : classes.button}
                onClick={onCenterButtonClick}>
                {btnCenterLabel}
              </Button>}
              <Box textAlign="right" style={modalCompany ? { marginTop: -12 } : {}}>
                {btnLeftLabel && <Button
                  id="left-button-list-company-button-id"
                  variant="outlined"
                  color="primary"
                  style={{ backgroundColor: "#fff" }}
                  disabled={disableButtons}
                  className={modalCompany ? classes.buttonCompany : classes.button}
                  onClick={onLeftButtonClick}>
                  {btnLeftLabel}
                </Button>}
                {btnRightLabel && <LoadingButton
                  id="right-button-list-company-button-id"
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={disableButtons}
                  disabled={disableButtons}
                  className={modalCompany ? classes.buttonCompany : classes.button}
                  onClick={onRightButtonClick}>
                  {btnRightLabel}
                </LoadingButton>}
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};


export default ModalCurriculum;
