import React, { useState, useEffect } from 'react';
import type { FC } from 'react';

import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Box, Button, Portal, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    position: 'fixed',
    width: 'calc(100% - 40px)',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2000,
    bottom: 20,
    left: 0,
    margin: '0px 20px',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 16px)',
      margin: '0px 8px'
    }
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    maxWidth: '870px',
    backgroundColor: '#FAFAFA',
    minHeight: '72px',
    padding: '16px',
    border: '1px solid #0000001F',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    '& p': {
      fontFamily: 'Nunito',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.15px',
      color: theme.palette.text.primary
    },
    '& a': {
      color: theme.palette.primary.main,
      fontWeight: 700,
      textDecoration: 'none'
    },
    '& div': {
      '&:nth-child(2)': {
        marginTop: 0,
        [theme.breakpoints.down('sm')]: {
          marginTop: 20,
          marginLeft: 0
        }
      }
    }
  },
  action: {
    minHeight: '38px',
    minWidth: '122px',
    border: '1px solid #3F51B580',
    fontFamily: 'Nunito',
    fontSize: '13px',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '0.46px'
  }
}));

const CookiesNotification: FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    Cookies.set('@gria:accept-terms', 'true');
    setOpen(false);
  };

  useEffect(() => {
    const consent: string | undefined = Cookies.get('@gria:accept-terms');

    if (!consent) {
      setOpen(true);
    }
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <div className={classes.root}>
        <div className={classes.container}>
          <Box>
            <Typography variant="body1" color="inherit">
              Usamos cookies para melhorar sua experiência ao usar nosso site.
              Para saber mais sobre os cookies que usamos e os dados que
              coletamos, confira nossos{' '}
              <Link
                to="/termos-uso-politica-privacidade"
                id="term-privacy-policy-link-id"
              >
                Termos de Uso e Política de Privacidade
              </Link>
              .
            </Typography>
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              onClick={handleClose}
              id="accept-cookies-button-id"
              variant="outlined"
              className={classes.action}
              color="primary"
            >
              Ok, entendi
            </Button>
          </Box>
        </div>
      </div>
    </Portal>
  );
};

export default CookiesNotification;
