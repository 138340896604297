import type { FC, ReactNode } from 'react';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

interface SupportGuardProps {
  children?: ReactNode;
}

const SupportGuard: FC<SupportGuardProps> = ({ children }) => {
  const { isAuthenticated, userType, isLogout, userProfile } = useAuth();
  const history = useHistory();
  const path = history.location.pathname;
  const params = history.location.search;
  const AUTH_URL = process.env.REACT_APP_AUTH_URL;
  const encodedPath = window.btoa(path + params);

  const userIsSupport = userProfile?.associatedCompanies[0]?.role === 'SUPPORT';

  if (!isAuthenticated && !isLogout) {
    window.location.href = `${AUTH_URL}?action=job_open&reference=${encodedPath}`;
  } else if (isAuthenticated && userType.isCompany() && userIsSupport) {
    return <>{children}</>;
  } else {
    return <Redirect to="/404" />;
  }
};

export default SupportGuard;
