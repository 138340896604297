import { Box, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC } from 'react';
import { StepsListCompany } from 'src/components/molecules/StepsList';
import { ApplicationEventType } from 'src/types/candidacy';

interface ViewProgressProps {
  processStatus: ApplicationEventType[];
  application?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '684px'
  },
  itemContainer: {
    border: '0.5px solid #C4C4C4',
    maxWidth: '684px',
    background: '#FFFFFF',
    borderRadius: 6,
    height: 'fit-content'
  }
}));

export const TimeLineCardDrawer: FC<ViewProgressProps> = ({
  processStatus,
  application
}) => {
  const classes = useStyles();

  return (
    <Box width="100%" className={classes.root}>
      <Grid item className={classes.itemContainer} xs={12} md={12} lg={12}>
        <StepsListCompany
          processStatus={processStatus}
          application={application}
        />
      </Grid>
    </Box>
  );
};
