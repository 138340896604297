import { makeStyles } from '@mui/styles';
import type { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  text: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    letterSpacing: 0.15,
    textAlign: 'center',
    color: '#00000099'
  },
  alert: {
    alignItems: 'center',
    marginBottom: 16
  },
  alertText: {
    textAlign: 'left',
    fontFamily: 'Nunito',
    fontWeight: 400
  },
  alertButton: {
    fontSize: 13,
    fontFamily: 'Nunito',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: 0.46000000834465027,
  }
}));
