import {
  colors,
  createTheme as createMuiTheme,
  responsiveFontSizes
} from '@mui/material';

import {
  Palette as MuiPalette,
  Theme as MuiTheme,
  TypeBackground as MuiTypeBackground
} from '@mui/material/styles';

import { Shadows } from '@mui/material/styles/shadows';
import { THEMES } from 'src/constants';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';

interface TypeBackground extends MuiTypeBackground {
  dark: string;
  topbar: string;
  layout: string;
  roseSecondary: string;
}

interface TypeIcon extends MuiPalette {
  main: string;
  default: string;
}
interface TableProps extends MuiPalette {
  tableTitle: string;
  grayPrimary: string;
  blackSecondary: string;
}
interface ChipProps extends MuiPalette {
  main: string;
}

interface ButtonProps extends MuiPalette {
  redPrimary: string;
  whitePrimary: string;
}

interface PremiumPlanProps extends MuiPalette {
  main: string;
  border: string;
}

interface PremiumPlanProps extends MuiPalette {
  main: string;
  border: string;
}

interface SwitchProps extends MuiPalette {
  main: string;
}

interface SubtitleProps extends MuiPalette {
  main: string;
  subtitle: string;
}
interface Palette extends MuiPalette {
  background: TypeBackground;
  icon: TypeIcon;
  table: TableProps;
  chip: ChipProps;
  subtitle: SubtitleProps;
  switch: SwitchProps;
  button: ButtonProps;
  premium: PremiumPlanProps;
}

export interface Theme extends MuiTheme {
  name: string;
  palette: Palette;
}

type Direction = 'ltr' | 'rtl';

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  theme?: string;
}

interface DeprecatedThemeOptions {
  name?: string;
  direction?: Direction;
  typography?: Record<string, any>;
  overrides?: Record<string, any>;
  components?: Record<string, any>;
  palette?: Record<string, any>;
  shadows?: Shadows;
}

const baseOptions: DeprecatedThemeOptions = {
  direction: 'ltr',
  typography,
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontFamily: 'Nunito'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Nunito',
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '0.4px'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.075)'
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {}
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {}
      }
    },
    MuiIconButton: {
      styleOverrides: {
        sizeMedium: {
          color: '#546E7A'
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {}
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: 'Nunito',
          fontSize: '10px',
          fontWeight: 400,
          lineHeight: '14px',
          marginBottom: '20px',
          textAlign: 'center',
          padding: '4px 8px',
          alignItems: 'center'
        }
      }
    }
  }
};

const themesOptions: DeprecatedThemeOptions[] = [
  {
    name: THEMES.LIGHT,
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 1,
              color: '#590A9D'
            }
          }
        }
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover'
        }
      }
    },
    palette: {
      mode: 'light',
      action: {
        active: '#F6F1FD',
        hover: '#F6F1FD'
      },
      background: {
        default: colors.common.white,
        dark: '#F6F4F6',
        paper: colors.common.white,
        topbar: '#9D0A6B',
        layout: '#FAFAFA',
        roseSecondary: '#FDFAFC',
        gradient: {
          primary: 'linear-gradient(45deg, #f8f5fe 0%,#d2baf5 100%)'
        }
      },
      primary: {
        main: '#590A9D',
        light: '#7A3BB0',
        dark: '#3E076D'
      },
      success: {
        main: '#4CAF50'
      },
      disabled: {
        main: '#00000061'
      },
      error: {
        main: '#F44336'
      },
      warning: {
        main: '#ED6C02',
        dark: '#C77700'
      },
      secondary: {
        main: '#9D0A6B',
        light: '#B03B88',
        dark: '#6D074A',
        gray: '#F4F6F8',
        contrastText: '#FFFFFF'
      },
      text: {
        primary: '#000000DE',
        secondary: '#00000099',
        black: colors.common.black
      },
      icon: {
        main: '#546E7A',
        default: '#0000008A'
      },
      table: {
        tableTitle: '#000000',
        grayPrimary: '#757575',
        blackSecondary: '#232F34'
      },
      chip: {
        main: 'rgba(222, 204, 248, 0.25)'
      },
      subtitle: {
        main: '#232F34',
        subtitle: '#7E7272'
      },
      switch: {
        main: '#4C74AF'
      },
      button: {
        redPrimary: '#E53C5B',
        whitePrimary: '#FFFFFF'
      },
      premium: {
        main: '#F2EBF7',
        border: '#C0A3D8'
      }
    },
    shadows: softShadows
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34'
      },
      primary: {
        main: '#8a85ff'
      },
      secondary: {
        main: '#8a85ff'
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb'
      }
    },
    shadows: strongShadows
  },
  {
    name: THEMES.UNICORN,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#2a2d3d',
        dark: '#222431',
        paper: '#2a2d3d'
      },
      primary: {
        main: '#a67dff'
      },
      secondary: {
        main: '#a67dff'
      },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4'
      }
    },
    shadows: strongShadows
  }
];

export const createTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme({}, baseOptions, themeOptions, {
    direction: config.direction
  });

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme as Theme;
};
