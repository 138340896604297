import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  hoverMenuItem: {
    '&:hover': { background: '#3F51B514' }
  },
  listItemText: {
    '& span': { fontFamily: 'Nunito' }
  },
  listItemIcon: {
    marginLeft: '32px',
    marginRight: '-16px'
  },
  changeProductRoot: {
    '& .MuiPaper-root': {
      boxShadow: '0px 4px 10px 0px rgba(89, 10, 157, 0.08)',
      padding: '8px 16px',
      left: '70px !important',
      width: '200px'
    },
    '& .MuiMenuItem-root': {
      padding: 0,
      '&:hover': {
        background: 'transparent'
      }
    }
  },
  text: {
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.23px'
  },
  subtitle: {
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.23px'
  },
  boxTextMenu: {
    display: 'flex',
    gap: '8px',
    flexDirection: 'column',
    marginBottom: '20px',
    textAlign: 'center'
  },
  productButton: {
    height: '52px',
    textTransform: 'none',
    color: '#232F34',
    fontSize: '16px',
    fontFamily: 'Nunito'
  }
}));
