import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { Theme } from 'src/theme';
import {
  ApplicationEventType,
  ApplicationSituationStatusType
} from 'src/types/candidacy';

import {
  Avatar,
  Box,
  Divider,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
  Block,
  CheckCircleOutline,
  FactCheck,
  HighlightOff,
  Message,
  VerifiedUser
} from '@mui/icons-material';

import moment from 'moment';

import Linkify from 'react-linkify';
import { processStatusOptions } from 'src/components/molecules/StepsList/statusOptions';
interface Process {
  active: boolean;
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  order: number;
  companyJobId: string;
  process: string;
}
interface Responsible {
  id: string;
  name: string;
  email: string;
}

interface StepProps {
  processStatus?: ApplicationEventType;
  prevStatus: string | null;
  index?: number;
  eventsLimit?: number;
  formattedEvents?: ApplicationEventType[];
}

interface StepsListCompanyDrawerProps {
  processStatus: ApplicationEventType[];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '0'
  },
  stepRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 12,
    padding: 12,
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      gap: '12px'
    }
  },
  candidateComment: {
    backgroundColor: '#3F51B514'
  },
  stepContentContainer: {
    width: '100%',
    borderRadius: '15px',
    transition: 'all 200ms ease-in-out',
    wordBreak: 'break-word',
    [theme.breakpoints.down('sm')]: {
      height: '100%'
    }
  },
  stepContentContainerDisabled: {
    backgroundColor: '#fcfcfc', //TODO: Add color to theme,
    color: '#c4c4c4' // TODO: Add color to theme
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
  stepContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  stepNumber: {
    position: 'relative',
    backgroundColor: '#0000000A !important',
    color: '#0000008A !important',
    borderRadius: '50%',
    transition: 'all 200ms ease-in-out',
    fontSize: 14,
    fontWeight: 600,
    fontFamily: 'Nunito',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 42,
    height: 36,
    marginTop: 6,

    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      minWidth: 0,
      minHeight: 0
    }
  },
  status: {
    width: 'fit-content',
    padding: '1px 2px',
    fontSize: 12,
    fontWeight: 500,
    marginTop: 5,
    borderRadius: 2
  },
  contentText: {
    fontSize: 12,
    fontWeight: 400,
    wordBreak: 'break-word'
  },
  eventDescription: {
    fontSize: 14,
    fontFamily: 'Nunito'
  },
  stepInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    paddingTop: 5
  },
  stepInfoText: {
    fontSize: 12,
    fontFamily: 'Nunito',
    minWidth: 25
  },
  avatar: {
    height: 28,
    width: 28
  },
  responsibleMail: {
    fontSize: 12,
    fontFamily: 'Nunito',
    color: 'inherit',
    textDecoration: 'underline',
    textDecorationColor: 'rgba(0,0,0,0 .6)'
  },
  boxStepCommentary: {
    marginTop: 5,
    borderRadius: '4px',
    margin: '5px 0',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    fontSize: 13,
    fontFamily: 'Nunito',
    backgroundColor: 'transparent !important',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '9px',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',

    position: 'relative',
    '&>p.show': {
      width: '100%',
      marginBottom: '13px'
    },
    '&>p.hide': {
      maxHeight: '18px',
      overflow: 'hidden',
      width: 'calc(100% - 48px)',
      [theme.breakpoints.down('sm')]: {
        maxHeight: '36px'
      }
    }
  },
  hyperLink: {
    color: '#1071e6',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: '#1071e6',
      cursor: 'pointer'
    },
    textDecorationColor: '#1071e6',
    fontWeight: 700
  },
  viewMore: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 12,
    gap: 12,
    cursor: 'pointer',
    '& div, & div p': {
      fontSize: 13,
      fontWeight: 700,
      fontFamily: 'Nunito',
      color: '#590A9D',
      textTransform: 'uppercase',
      textAlign: 'center'
    }
  }
}));

const Step: React.FC<StepProps> = ({
  processStatus,
  prevStatus,
  index,
  eventsLimit,
  formattedEvents
}) => {
  const classes = useStyles();

  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  const getProcessLabel = (label: string) => {
    if (label === 'SCREENING') return 'Triagem';
    if (label === 'HIRING') return 'Contratação';
    return label;
  };

  const getComment = (comment: string) => {
    if (comment === 'Candidate user canceled his application')
      return 'Candidato desistiu do processo';
    if (comment?.includes('Solicitação para realização do teste')) {
      return null;
    }
    return comment;
  };

  const getAvatar = () =>
    processStatus?.responsible?.hasAvatar
      ? `${process.env.REACT_APP_MEDIA_URL}/users/${processStatus?.responsible?.id}/avatar-small.png`
      : '';

  const getEventIcon = () => {
    if (processStatus && processStatus?.type === 'MESSAGE') return <Message />;
    if (processStatus && processStatus?.type === 'REMINDER')
      return <VerifiedUser />;

    switch (processStatus?.status) {
      case 'APPROVED':
        return <CheckCircleOutline />;
      case 'REGISTERED':
        return <FactCheck />;
      case 'REVIEW':
      case 'UNAPPROVED_UNDO':
      case 'QUITTER_UNDO':
      case 'REGISTERED_UNDO':
        return <ArrowForward />;
      case 'UNAPPROVED':
      case 'QUITTER_REGISTERED':
      case 'QUITTER_PROCESS':
        return <Block />;
      case 'FINALIZED':
      case 'JOB_FINALIZED':
        return <HighlightOff />;
      default:
        return null;
    }
  };
  const formatComment = (comment: string) => {
    let lastItem = comment.split(':');
    return (
      <p>
        <b>Solicitação</b> para realização do teste <b>{lastItem[1]}</b>
      </p>
    );
  };
  const getEventDescription = () => {
    if (!processStatus) return <Skeleton variant="text" width={160} />;
    if (processStatus && processStatus?.type === 'MESSAGE') return null;
    if (processStatus && processStatus?.type === 'REMINDER') {
      return formatComment(processStatus?.comment);
    }

    switch (processStatusOptions[processStatus?.status]?.label) {
      case 'EM ANÁLISE':
        return (
          <span>
            <b>Movimentação</b> para etapa{' '}
            <b>
              {getProcessLabel(
                processStatus?.companyJobSelectiveProcess?.process
              )}
            </b>
          </span>
        );
      case 'INSCRITO':
        return (
          <span>
            <b>Inscrição</b> no processo seletivo
          </span>
        );

      case 'NÃO APROVADO':
        return (
          <span>
            <b>Reprovação</b> no processo seletivo
          </span>
        );

      case 'DESISTENTE':
        return (
          <span>
            <b>Desistiu</b> do processo seletivo
          </span>
        );

      case 'APROVADO':
        return (
          <span>
            <b>Aprovado</b>
          </span>
        );
      case 'REPROVAÇÃO DESFEITA':
        if (processStatus?.companyJobSelectiveProcess === null)
          return <b>Reprovação desfeita</b>;
        else
          return (
            <p>
              <b>Reprovação desfeita,</b> movimentação para etapa{' '}
              <b>
                {getProcessLabel(
                  processStatus?.companyJobSelectiveProcess?.process
                )}
              </b>
            </p>
          );
      case 'DESISTÊNCIA DESFEITA':
        if (!processStatus?.companyJobSelectiveProcessId)
          return <b>Desistência desfeita</b>;
        else
          return (
            <p>
              <b>Desistência desfeita,</b> movimentação para etapa{' '}
              <b>
                {getProcessLabel(
                  processStatus?.companyJobSelectiveProcess?.process
                )}
              </b>
            </p>
          );
      case 'TRIAGEM DESFEITA':
        if (!processStatus?.companyJobSelectiveProcessId)
          return <b>Desistência desfeita</b>;
        else
          return (
            <p>
              <b>Desistência desfeita,</b> movimentação para etapa{' '}
              <b>
                {getProcessLabel(
                  processStatus?.companyJobSelectiveProcess?.process
                )}
              </b>
            </p>
          );
      default:
        return `ERRO INESPERADO`;
    }
  };

  const eventDate = () => {
    let startDate = moment(processStatus?.createdAt);
    let current = moment();
    let dateDiff = moment.duration(current.diff(startDate)).asDays();
    return dateDiff >= 7
      ? moment(processStatus?.createdAt).format('D [de] MMMM [às] H:M')
      : moment(processStatus?.createdAt).calendar();
  };

  return (
    <>
      <Box
        className={clsx(classes.stepRoot, {
          [classes.candidateComment]: processStatus?.userType === 'CANDIDATE'
        })}
      >
        <Box className={classes.stepNumber}>{getEventIcon()}</Box>

        <Box
          className={clsx(classes.stepContentContainer, {
            [classes.stepContentContainerDisabled]: !processStatus
          })}
        >
          <Box>
            <Box className={classes.eventDescription}>
              {getEventDescription()}
            </Box>

            {getComment(processStatus?.comment) ? (
              (isSmallScreen && processStatus?.comment?.length >= 55) ||
              (!isSmallScreen && processStatus?.comment?.length >= 40) ? (
                <Box className={classes.boxStepCommentary}>
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a
                        target="_blank"
                        href={decoratedHref}
                        key={key}
                        style={{ fontWeight: 'bold' }}
                      >
                        {decoratedText}
                      </a>
                    )}
                  >
                    <p>{getComment(processStatus?.comment)}</p>
                  </Linkify>
                </Box>
              ) : (
                <Box className={classes.boxStepCommentary}>
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a
                        target="_blank"
                        href={decoratedHref}
                        key={key}
                        style={{ fontWeight: 'bold' }}
                      >
                        {decoratedText}
                      </a>
                    )}
                  >
                    <p>{getComment(processStatus?.comment)}</p>
                  </Linkify>
                </Box>
              )
            ) : (
              <></>
            )}
            {processStatus ? (
              processStatus?.status !== 'QUITTER_REGISTERED' ? (
                <Box className={classes.stepInfo}>
                  <>
                    {processStatus?.status !== 'REGISTERED' && (
                      <Avatar className={classes.avatar} src={getAvatar()} />
                    )}
                    <Typography
                      className={classes.stepInfoText}
                      style={{ color: '#00000099' }}
                    >
                      {processStatus?.status !== 'REGISTERED' && (
                        <>
                          <b>{processStatus?.responsible?.name}</b> {' • '}
                        </>
                      )}
                      {eventDate()}
                    </Typography>
                  </>
                </Box>
              ) : (
                processStatus?.status === 'QUITTER_REGISTERED' && (
                  <Typography
                    className={classes.stepInfoText}
                    style={{ color: '#00000099' }}
                  >
                    {eventDate()}
                  </Typography>
                )
              )
            ) : (
              <Skeleton variant="text" width={120} />
            )}
          </Box>
          {index + 1 < eventsLimit && formattedEvents?.length > 2 && (
            <Divider
              variant="fullWidth"
              style={{
                marginTop: processStatus?.type === 'MESSAGE' ? '12px' : '16px',
                marginBottom:
                  processStatus?.type === 'MESSAGE' ? '-12px' : '-16px',
                borderColor: '#e0e0e0',
                marginRight: '-12px'
              }}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export const StepsListCompanyDrawer: React.FC<StepsListCompanyDrawerProps> = ({
  processStatus
}) => {
  const classes = useStyles();

  const [formattedEvents, setFormattedEvents] = useState([]);

  useEffect(() => {
    setFormattedEvents(
      processStatus?.map((event) => {
        if (!event.status && event.companyJobSelectiveProcessId)
          return {
            ...event,
            status: 'REVIEW' as ApplicationSituationStatusType
          };
        return event;
      })
    );
  }, [processStatus]);

  let loadingTimeLine = Array(3)
    .fill(0)
    .map((item, index) => {
      return <Step key={index} prevStatus={null} processStatus={null} />;
    });

  const [eventsLimit, setEventsLimit] = useState(3);

  const listMoreEvents = () => {
    setEventsLimit((prev) =>
      prev === formattedEvents.length
        ? 3
        : prev + 10 > formattedEvents.length
        ? formattedEvents.length
        : prev + 10
    );
  };

  const timeLine =
    formattedEvents &&
    formattedEvents
      .slice(0, eventsLimit)
      .map((processStatusItem, idx) => (
        <Step
          key={idx}
          index={idx}
          eventsLimit={eventsLimit}
          formattedEvents={formattedEvents}
          prevStatus={processStatus[idx + 1]?.status}
          processStatus={processStatusItem}
        />
      ));

  return (
    <>
      {!formattedEvents ? (
        loadingTimeLine
      ) : (
        <>
          {timeLine}
          {formattedEvents.length > eventsLimit && (
            <Divider
              style={
                formattedEvents.length <= eventsLimit
                  ? { marginTop: 3, borderColor: '#e0e0e0' }
                  : { marginTop: -1, borderColor: '#e0e0e0' }
              }
            />
          )}
          {formattedEvents.length > eventsLimit ? (
            <Box className={classes.viewMore} onClick={listMoreEvents}>
              <Box>
                {eventsLimit === formattedEvents.length ? (
                  <ArrowUpward />
                ) : (
                  <ArrowDownward />
                )}
              </Box>
              <Box>
                <Typography>
                  {eventsLimit === formattedEvents.length
                    ? `Ver menos`
                    : `Ver mais`}{' '}
                  (
                  {formattedEvents.length < eventsLimit
                    ? formattedEvents.length
                    : eventsLimit}
                  /{formattedEvents.length})
                </Typography>
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
