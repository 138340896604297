import _ from 'lodash';

export const localeCurrency = (value: string) => {
  if (value === 'BRL') {
    return 'pt-BR';
  }
  if (value === 'EUR') {
    return 'nl-NL';
  }
  if (value === 'USD') {
    return 'en-US';
  }
  if (value === 'MXN') {
    return 'es-MX';
  }
  if (value === 'COP') {
    return 'es-CO';
  }
  return 'pt-BR';
};

export const formatCurrency = (option, currency) => {
  let currencyInput: string;

  if (currency) {
    currencyInput = new Intl.NumberFormat(`${localeCurrency(currency)}`, {
      style: 'currency',
      currency: `${currency}`
    }).format(option);
  } else {
    currencyInput = '';
  }

  if (currency === 'COP' || currency === 'MXN') {
    currencyInput = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(option);
  }
  return currencyInput;
};

export const generateSalaryOptions = (currency, firstOption?: number, lastOption?: number) => {
  const firstRangeStart = firstOption ?? 0,
    firstRangeEnd = 2000,
    firstRangeStep = 500;

  const secondRangeEnd = 15000,
    secondRangeStep = 1000;

  const thirdRangeEnd = 20000,
    thirdRangeStep = 5000;

  const fourthRangeEnd = lastOption ?? 40000,
  fourthRangeStep = 10000;

  let options = [
    ..._.range(firstRangeStart, firstRangeEnd, firstRangeStep),
    ..._.range(firstRangeEnd, secondRangeEnd, secondRangeStep),
    ..._.range(secondRangeEnd, thirdRangeEnd, thirdRangeStep),
    ..._.range(thirdRangeEnd, fourthRangeEnd, fourthRangeStep)
  ];

  if (_.last(options) !== fourthRangeEnd)
    options = [...options, fourthRangeEnd];

  return options.map(option => {
    return { label: formatCurrency(option, currency), value: option };
  });
};

export const getCorrectSalary = (
  options: Array<{ label: string; value: number }>,
  label: string
) => {
  const optionIndex = options.findIndex(option => option.label === label);
  if (optionIndex < 0) {
    return 0;
  }
  return options[optionIndex].value;
};

export const getSalaryLabel = (
  options: Array<{ label: string; value: number }>,
  value: number
) => {
  const optionIndex = options.findIndex(option => option.value === value);
  if (optionIndex < 0) {
    return ""
  }
  return options.find(option => option.value === value).label;
}