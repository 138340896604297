import React from 'react';
import type { FC } from 'react';

import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery
} from '@mui/material';
import { Business, Work, NavigateNext } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';

import { Theme } from 'src/theme';

interface UserCardTypeProps {
  userType: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: '312px',
    height: '256px',
    border: '1px solid #0000001F',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px 24px 30px 24px',
    textAlign: 'center'
  },
  title: {
    fontFamily: 'Nunito',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '0.15px',
    textAlign: 'center',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '22px',
      letterSpacing: '0.1px',
      textAlign: 'left'
    }
  },
  subtitle: {
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20.02px',
    letterSpacing: '0.15px',
    color: theme.palette.text.secondary
  },
  button: {
    minWidth: '155px',
    height: '40px',
    fontFamily: 'Nunito',
    fontWeight: 700,
    fontSize: '14px'
  },
  iconButton: {
    width: 62,
    height: 62,
    backgroundColor: ' #0000000A',
    cursor: 'default',
    pointerEvents: 'none',
    '&:hover': {
      backgroundColor: ' #0000000A'
    }
  },
  rootMobile: {
    width: '100%',
    maxWidth: '328px',
    height: '114px',
    border: '1px solid #0000001F',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px 4px 16px 16px',
    cursor: 'pointer'
  },
  iconButtonMobile: {
    width: 44,
    height: 44,
    backgroundColor: ' #0000000A',
    pointerEvents: 'none',
    '&:hover': {
      backgroundColor: ' #0000000A'
    }
  }
}));

const UserCardType: FC<UserCardTypeProps> = ({ userType }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true
  });

  const isCandidate = userType === 'candidate';
  const candidateTitle = isCandidate
    ? 'Acessar como candidato'
    : 'Acessar como empresa';
  const candidateSubtitle = isCandidate
    ? 'Quero acompanhar meus processos seletivos ou buscar um novo emprego.'
    : 'Quero divulgar vagas e gerenciar os processos seletivos da minha empresa.';
  const buttonLabel = isCandidate ? 'Sou candidato' : 'Sou de uma empresa';
  const idButtonLabel = isCandidate
    ? 'acess-candidate-login-button-id'
    : 'acess-company-login-button-id';

  const handleRedirectUser = () => {
    const AUTH_URL = process.env.REACT_APP_AUTH_URL;
    const action = isCandidate ? 'application' : 'job_open';
    const URL = `${AUTH_URL}?action=${action}`;

    window.location.href = URL;
  };

  return (
    <>
      {!isMediumScreen ? (
        <Box className={classes.root}>
          <IconButton className={classes.iconButton}>
            {isCandidate ? (
              <Work sx={{ color: '#626262' }} />
            ) : (
              <Business sx={{ color: '#626262' }} />
            )}
          </IconButton>
          <Box style={{ padding: '12px 0 20px 0' }}>
            <Typography component="h2" variant="h4" className={classes.title}>
              {candidateTitle}
            </Typography>
            <Typography
              component="h3"
              variant="body2"
              className={classes.subtitle}
            >
              {candidateSubtitle}
            </Typography>
          </Box>
          <Button
            id={idButtonLabel}
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleRedirectUser}
          >
            {buttonLabel}
          </Button>
        </Box>
      ) : (
        <Box className={classes.rootMobile} onClick={handleRedirectUser}>
          <IconButton className={classes.iconButtonMobile}>
            {isCandidate ? (
              <Work sx={{ color: '#626262' }} />
            ) : (
              <Business sx={{ color: '#626262' }} />
            )}
          </IconButton>
          <Box style={{ padding: 10 }}>
            <Typography component="h2" variant="h4" className={classes.title}>
              {candidateTitle}
            </Typography>
            <Typography
              component="h3"
              variant="body2"
              className={classes.subtitle}
            >
              {candidateSubtitle}
            </Typography>
          </Box>
          <NavigateNext sx={{ color: '#0000008A' }} />
        </Box>
      )}
    </>
  );
};

export default UserCardType;
