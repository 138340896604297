import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import CookiesNotification from './CookiesNotification';
import { useHistory } from 'react-router';

interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated, userType } = useAuth();
  const history = useHistory();

  if (isAuthenticated) {
    if (userType.isCompany()) return <Redirect to={`/company/jobs${history.location.search}`} />;
    return <Redirect to={`/candidate/jobs${history.location.search}`} />;
  }

  return (
    <>
      {children}
      <CookiesNotification />
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
