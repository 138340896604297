import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import useAuth from 'src/hooks/useAuth';
import { Modal, Box, AppBar, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as Logo } from 'src/assets/images/topbar-logo.svg';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ListLoadOptions from './ListLoadOptions';
import { useTheme } from '@mui/styles';
import { useStyles } from './styles';
import LoadLinkedinData from './LoadLinkedinData';
import UploadResumeData from './UploadResumeData';

interface AutoCompleteResumeModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  companyName?: string;
  opportunityId?: string;
  opportunityTitle?: string;
}

const AutoCompleteResumeModal = ({
  showModal,
  setShowModal,
  companyName,
  opportunityId,
  opportunityTitle
}: AutoCompleteResumeModalProps) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { setNewUserProfile, userProfile, userType } = useAuth();

  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const validParam = ['options', 'linkedin', 'upload'];

  const modeParam = validParam.includes(searchParams.get('autoCompleteResume'))
    ? searchParams.get('autoCompleteResume')
    : '';
  const jobId = searchParams.get('jobId');
  const match = jobId === opportunityId || (!jobId && !opportunityId);

  const [mode, setMode] = useState(modeParam || 'options');

  useEffect(() => {
    if (match) {
      if (showModal) {
        if (userProfile.user.finishedRegister || userType.isCompany()) {
          setShowModal(false);
        } else {
          if (!modeParam && match) {
            searchParams.set('autoCompleteResume', mode);
            searchParams.delete('modal');
          }
        }
      } else {
        searchParams.delete('autoCompleteResume');
      }

      history.replace({ search: searchParams.toString() });

      return () => {
        searchParams.delete('autoCompleteResume');
        history.replace({
          ...history.location,
          search: searchParams.toString()
        });
      };
    }
  }, [showModal]);

  useEffect(() => {
    if (match) {
      if (modeParam) {
        if (!showModal) {
          setShowModal(true);
        }
        if (mode !== modeParam) {
          searchParams.set('autoCompleteResume', mode);
        }

        history.replace({ search: searchParams.toString() });
      }
    }
  }, [modeParam, mode]);

  const isBigScreen = useMediaQuery(theme.breakpoints.up(596), {
    defaultMatches: true
  });

  const backAction = () => {
    mode === 'options' ? setShowModal(false) : setMode('options');
  };

  const goToResume = (userData?: any) => {
    if (userData) {
      const {
        id,
        email,
        name,
        specialty,
        currentPosition,
        roles,
        avatar,
        finishedRegister,
        ...rest
      } = userData;

      setNewUserProfile({
        user: {
          id,
          email,
          name,
          specialty,
          currentPosition,
          finishedRegister,
          systemRoles: roles,
          avatarFileName: avatar && avatar.path,
          ...rest
        }
      });
    }

    const params = opportunityId ? `?jobId=${opportunityId}` : '';

    history.push(`/candidate/profile/finish/${params}`);
    setShowModal(false);
  };

  const showTitle = () => {
    const titles = {
      options: (
        <span>
          Vamos lá! Escolha onde {isBigScreen && <br />} podemos buscar seu
          currículo
        </span>
      ),
      linkedin: (
        <span>
          Qual o link do seu perfil no <b>Linkedin</b>?
        </span>
      ),
      upload: <span>Subir arquivo do currículo</span>
    };

    return titles[mode];
  };

  const showContent = () => {
    const contents = {
      options: (
        <ListLoadOptions
          companyName={companyName}
          opportunityTitle={opportunityTitle}
          setMode={(value) => setMode(value)}
          goToResume={goToResume}
        />
      ),
      linkedin: <LoadLinkedinData loadData={goToResume} />,
      upload: <UploadResumeData loadData={goToResume} />
    };

    return contents[mode];
  };

  return (
    <Modal open={showModal}>
      <Box className={classes.container}>
        <AppBar position="static" style={{ boxShadow: 'none' }}>
          <Box className={classes.header}>
            <ArrowBack onClick={backAction} className={classes.arrow} />
            <Logo className={classes.logo} />
          </Box>
        </AppBar>
        <Box className={classes.body}>
          <Typography className={classes.title}>{showTitle()}</Typography>
          {showContent()}
        </Box>
      </Box>
    </Modal>
  );
};

export default AutoCompleteResumeModal;
