import React, { useState } from 'react';
import { candidateService } from 'src/services';
import { Alert, Typography, TextField, Box } from '@mui/material';
import { Formik, Form } from 'formik';
import { LinkedIn } from '@mui/icons-material';
import LinearProgress from '@mui/material/LinearProgress';
import * as Yup from 'yup';
import { useStyles } from './styles';
import NextButton from '../NextButton';

interface LoadLinkedinDataProps {
  loadData: (data: any) => void;
}

function LoadLinkedinData({ loadData }: LoadLinkedinDataProps) {
  const styles = useStyles();

  const [link, setLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const schema = Yup.object().shape({
    link: Yup.string()
      .matches(
        /(ftp|http|https):\/\/?((www|\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
        'Link inválido.'
      )
      .required('Informe o link.')
  });

  const syncLinkedin = (linkedinUrl: string) => {
    setIsLoading(true);
    setError(false);
    setLink(linkedinUrl);

    candidateService
      .syncLinkedin(linkedinUrl)
      .then((res) => {
        loadData(res.data);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => setIsLoading(false));
  };

  const form = () => (
    <Formik
      initialValues={{
        link: ''
      }}
      validationSchema={schema}
      onSubmit={(values) => syncLinkedin(values.link)}
    >
      {({ errors, handleBlur, handleChange, touched, values, isValid }) => (
        <Form className={styles.form}>
          {error && (
            <Alert severity="warning" className={styles.alert}>
              <Typography className={styles.alertText}>
                Não encontramos seu <b>perfil no Linkedin</b>. Confira se o link
                está correto e tente novamente.
              </Typography>
            </Alert>
          )}
          <TextField
            fullWidth
            error={touched.link && !!errors.link}
            helperText={touched.link && errors.link}
            variant="outlined"
            label="Link do perfil"
            margin="normal"
            name="link"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.link}
            className={styles.input}
          />
          <NextButton disabled={!values.link || !isValid || isLoading} />
        </Form>
      )}
    </Formik>
  );

  const loadingData = () => (
    <>
      <Box className={styles.linkContainer}>
        <LinkedIn className={styles.icon} />
        <Typography className={styles.text}>{link}</Typography>
      </Box>
      <Typography className={styles.text} style={{ marginTop: 28 }}>
        Buscando as informações...
      </Typography>
      <LinearProgress style={{ marginTop: 12 }} />
    </>
  );

  return isLoading ? loadingData() : form();
}

export default LoadLinkedinData;
