import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import { Box, Button, Typography, Fade, Modal } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalWrapper: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
    width: 560,
    '&:focus': {
      outline: 'none'
    },
    [theme.breakpoints.down('md')]: {
      overflow: 'scroll',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
      marginRight: 10,
    }
  },
  modalContent: {
    margin: '21px 24px 8px ',
  },
  title: {
    marginBottom: 14,
    color: theme.palette.table.blackSecondary
  },
  text: {
    color: theme.palette.table.grayPrimary,
    fontSize: 16,
    marginBottom: 5,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'justify'
    }
  },
  buttonContainer: {
    width: 'fit-content',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 'auto'
    }

  },
  button: {
    padding: "6px 16px",
    height: 36,
    '&:first-child': {
      marginRight: 5
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: "10px 0",
    }
  }
}));

interface ModalFinishResumeProps {
  className?: string;
  showModal?: boolean;
  onClose?: () => void;
  finishRegister: () => any;
}

const ModalFinishResume: FC<ModalFinishResumeProps> = ({ showModal, onClose, finishRegister }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(showModal)
  }, [showModal])

  const handleClose = () => {
    onClose && onClose();
    setOpen(false);
  };


  return (
    <Box className={classes.root} style={open ? {} : { display: 'none' }}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
      >
        <Fade in={Boolean(open || showModal)}>
          <Box className={classes.modalWrapper}>
            <Box className={classes.modalContent}>
              <Typography variant="h4" className={classes.title}>
                Complementar currículo
              </Typography>
              <Typography component="p" variant="body1" className={classes.text}>
                Os seus dados profissionais ainda não foram cadastrados. Sem eles não é possível se candidatar à vaga. Deseja atualizar seu cadastro?
              </Typography>
              <Box className={classes.buttonContainer}>
                <Button variant="outlined" color="primary" className={classes.button} onClick={handleClose}>
                  Fechar
                </Button>
                <Button 
                  variant="contained" 
                  color="primary" 
                  className={classes.button} 
                  onClick={() => {
                    finishRegister();
                  }}
                >
                  Atualizar cadastro
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default ModalFinishResume;