import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Upload, LinkedIn, FormatAlignLeft } from '@mui/icons-material';
import OptionCard from './OptionCard';
import { useStyles } from './styles';
import NextButton from '../NextButton';

interface ListLoadOptionsProps {
  companyName?: string;
  opportunityTitle?: string;
  setMode: (value: 'linkedin' | 'upload') => void;
  goToResume: () => void;
}

const ListLoadOptions = ({
  companyName,
  opportunityTitle,
  setMode,
  goToResume
}: ListLoadOptionsProps) => {
  const classes = useStyles();

  const [index, setIndex] = useState(0);

  const nextAction = () => {
    if (index === 2) {
      goToResume();
      return;
    }

    const mode = {
      0: 'linkedin',
      1: 'upload'
    };

    setMode(mode[index]);
  };

  return (
    <>
      {companyName && opportunityTitle && (
        <Typography className={classes.subTitle}>
          Você está se inscrevendo para <b>{opportunityTitle}</b> na{' '}
          <b>{companyName}</b>
        </Typography>
      )}
      <Box className={classes.list}>
        <OptionCard
          icon={<LinkedIn />}
          title="Importar do Linkedin"
          subTitle="Utilizando o link do seu perfil"
          selected={index === 0}
          onClick={() => setIndex(0)}
        />
        <OptionCard
          icon={<Upload />}
          title="Subir arquivo do currículo"
          subTitle="Nos formatos PDF ou DOC"
          selected={index === 1}
          onClick={() => setIndex(1)}
        />
        <OptionCard
          icon={<FormatAlignLeft />}
          title="Cadastrar manualmente"
          subTitle="Criando um currículo do zero"
          selected={index === 2}
          onClick={() => setIndex(2)}
        />
      </Box>
      <NextButton onClick={nextAction} />
    </>
  );
};

export default ListLoadOptions;
