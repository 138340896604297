import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_INSIGHTS_API_URL;
const insightsApi = axios.create({ baseURL: BASE_URL });

insightsApi.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);

export default insightsApi;
