import { makeStyles } from '@mui/styles';
import { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    maxWidth: 564,
    background: '#ffffff',
    padding: '16px 24px 24px 24px',
    outline: 'none',
    borderRadius: 4
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  icon: {
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  title: {
    fontFamily: 'Nunito',
    fontSize: 20,
    fontWeight: 400
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 24
  },
  button: {
    padding: '8px 16px',
    fontFamily: 'Nunito',
    fontWeight: 700
  }
}));
