import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { SvgIcon } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  text: {
    fill: theme.palette.primary.contrastText,
    fontSize: theme.typography.caption.fontSize,
    fontFamily: theme.typography.fontFamily,
  },
}));

interface StepIconProps {
  icon?: any;
  cx?: number;
  cy?: number;
  r?: number;
  color?: string;
  stroke?: string;
  strokeWidth?: number;
  style?: any;
  isIconComponent?: boolean;
}

const StepIcon: FC<StepIconProps> = ({ icon, cx, cy, r, color, stroke, strokeWidth, style, isIconComponent}) => {
  const classes = useStyles();

  if (!cx || !cy || !r) {
    cx = 12;
    cy = 12;
    r = 12;
  }

  const setColor = !color ? '#590A9D' : color;

  return (
    <SvgIcon style={style}>
      <circle cx={cx} cy={cy} r={r} fill={setColor} stroke={stroke} strokeWidth={strokeWidth} />
      {isIconComponent ? icon : <text className={classes.text} x="12" y="16" textAnchor="middle">
        {icon}
      </text>}
    </SvgIcon>

  );
};

export default StepIcon;