import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: '244px',
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: '244px',
      boxSizing: 'border-box',
      borderRight: 'none',
      boxShadow: '0px 4px 20px 0px rgba(157, 10, 107, 0.05)'
    }
  },
  list: {
    margin: '0 8px 0px 8px',
    '& .Mui-selected': {
      background: '#ECEFF1',
      borderRadius: '8px',
      '& .MuiTypography-root': {
        fontWeight: 700,
        color: '#757575'
      },
      '&:hover': {
        background: '#ECEFF1'
      }
    },
    '& .MuiListSubheader-root': {
      color: theme.palette.text.primary,
      fontWeight: 500
    }
  },
  icon: {
    color: theme.palette.icon.default
  },
  boxFooter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '8px',
    marginTop: 'auto',
    textAlign: 'center'
  },
  boxLogo: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    minHeight: '68px',
    padding: '0px 8px 0px 16px'
  }
}));
