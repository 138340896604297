import { useEffect, useState } from 'react';
import { companyService } from 'src/services';
import { Industry } from 'src/types/industry';

export const useIndustriesGetter = () => {

  const [industries, setIndustries] = useState<Industry[]>(window.localStorage.getItem('industries') ? JSON.parse(window.localStorage.getItem('industries')) : []) 
  
  const getIndustries = async () => {
    try {
      const response = await companyService.getIndustries()
      window.localStorage.setItem('industries', JSON.stringify(response.data))
      setIndustries(response.data)
    }
    catch (err) {
      console.error(err)
    }
  } 
  useEffect(() => {
    !window.localStorage.getItem('industries') && getIndustries()
  }, [window.localStorage.getItem('industries')]);

  return industries.sort((a, b) => {
    if ((a.name === "Outros") !== (b.name === "Outros")) {
      return (a.name === "Outros") ? 1 : -1;
    }
    return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
  })
}

export default useIndustriesGetter;