import {
  Document, Font, Link, Page, Path, StyleSheet,
  Svg, Text, View
} from '@react-pdf/renderer';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import lightFont from 'src/assets/fonts/Roboto-Light.ttf';
import mediumFont from 'src/assets/fonts/Roboto-Medium.ttf';
import regularFont from 'src/assets/fonts/Roboto-Regular.ttf';
import { GeneralInformationProfile } from 'src/types/generalInformationProfile';
import { degreeOptionsToLabels } from 'src/utils/helpers';
import { withMask } from 'src/utils/mask';
import { sortByPropAndDate } from 'src/utils/sortFunctions';
import Facebook from './Facebook';
import Github from './Github';
import Instagram from './Instagram';
import Linkedin from './Linkedin';
import Other from './Other';
import Tiktok from './Tiktok';
import YouTube from './Youtube';

Font.register({
  family: 'Roboto',
  src: regularFont,
  fonts: [
    {
      src: lightFont,
      fontWeight: 'light',
      fontStyle: 'normal'
    },
    {
      src: regularFont,
      fontWeight: 'normal',
      fontStyle: 'normal'
    },
    {
      src: mediumFont,
      fontWeight: 'medium'
    },
  ]
})

const levelOptionsToLabel = {
  "NATIVE": 'Nativo',
  "FLUENT": 'Fluente',
  "INTERMEDIARY": 'Intermediário',
  "BASIC": 'Básico',
  "OTHER": "Outro"
}

const styles = StyleSheet.create({
  root: {
    height: '50vh',
    padding: 24,
    fontSize: 10,
    fontFamily: 'Roboto'
  },
  fontLight: {
    fontWeight: 'light'
  },
  fontMedium: {
    fontWeight: 'medium'
  },
  fontXLarge: {
    fontSize: 14
  },
  fontLarge: {
    fontSize: 12
  },
  fontSmall: {
    fontSize: 8
  },
  colorDark: {
    color: '#263238'
  },
  topbar: {
    backgroundColor: '#F6F4F6',
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'row',
    padding: '8px 16px',
    marginBottom: 16
  },
  topbarContent: {
    flex: 1,
    width: '50%'
  },
  halfWidth: {
    width: '50%'
  },
  alignEnd: {
    alignItems: 'flex-end'
  },
  alignCenter: {
    alignItems: 'center'
  },
  avatarContainer: {
    height: 46,
    width: 46,
    backgroundColor: '#BDBDBD',
    borderRadius: '50%',
    marginRight: 16,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  avatar: {
    height: 35,
    width: 35
  },
  avatarImage: {
    height: 46,
    width: 46,
    borderRadius: '50%',
    position: 'absolute',
    top: 0,
    left: 0,
    objectFit: 'contain',
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  spacing8: {
    marginBottom: 8
  },
  spacing4: {
    marginBottom: 4
  },
  spacing16: {
    marginBottom: 16
  },
  spacing24: {
    marginBottom: 24
  },
  spacingTop24: {
    marginTop: 24
  },
  icon: {
    width: 10,
    height: 10,
    marginRight: 8
  },
  chipContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    marginBottom: 16
  },
  chip: {
    backgroundColor: 'rgba(222, 204, 248, 0.5)',
    padding: 8,
    flexDirection: 'row',
    marginRight: 8,
    borderRadius: 12
  },
  date: {
    marginRight: 16
  },
  divider: {
    width: '100%',
    border: '1px solid #ECEFF1',
    marginBottom: 16
  },
  limitText: {
    width: 150,
    wordBreak: 'break-word',
    textAlign: 'justify'
  },
  limitLink: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  wrap: {
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  credential: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'row',
    width: '90%'
  },
  credentialText: {
    width: '90%'
  }
})

const defaultCurriculum = {
  id: "",
  educations: [],
  experiences: [],
  accomplishments: [],
  professionalSkills: [],
  personalSkills: [],
  languages: [],
  synchronizedWithLinkedIn: false
};
interface ResumePDFProps {
  info: GeneralInformationProfile;
  avatarUrl?: string;
}

const ResumePDF: FC<ResumePDFProps> = ({ info, avatarUrl }) => {
  const [resume, setResume] = useState(info.curriculum || defaultCurriculum);
  const {
    name,
    surname,
    currentPosition,
    email,
    cellPhone,
    homePhone,
    socialNetworks,
    abstract,
    headline
  } = info;

  const {
    educations,
    experiences,
    accomplishments,
    professionalSkills,
    personalSkills,
    languages
  } = resume;

  useEffect(() => {
    if (info.curriculum) {
      setResume(info.curriculum)
    }
  }, [info])

  const getSocialImage = (socialMedia: string) => {
    switch (socialMedia) {
      case 'Linkedin':
        return <Linkedin />;
      case 'Facebook':
        return <Facebook />;
      case 'Instagram':
        return <Instagram />;
      case 'Tiktok':
        return <Tiktok />;
      case 'YouTube':
        return <YouTube />;
      case 'GitHub':
        return <Github />;
      default:
        return <Other />;
    }
  }

  const AvatarIcon = () => {
    return (
      <Svg style={styles.avatar} viewBox="0 0 24 24">
        <Path
          fill="#FFFFFF"
          d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
        />
      </Svg>
    )
  }

  const CalendarIcon = () => {
    return (
      <Svg style={styles.icon} viewBox="0 0 24 24">
        <Path
          fill="#989898"
          d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V10h16v11zm0-13H4V5h16v3z"
        />
      </Svg>
    )
  }

  const linkedin = socialNetworks?.find(item => item.name === "Linkedin")?.link;

  const IconCredentialCode = () => {
    return (
      <Svg style={styles.icon} viewBox="0 0 24 24">
        <Path
          fill="#000000"
          d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"
        />
      </Svg>
    )
  }

  const IconCredentialURL = () => {
    return (
      <Svg style={styles.icon} viewBox="0 0 24 24">
        <Path
          fill="#000000"
          d="M17 7h-4v2h4c1.65 0 3 1.35 3 3s-1.35 3-3 3h-4v2h4c2.76 0 5-2.24 5-5s-2.24-5-5-5zm-6 8H7c-1.65 0-3-1.35-3-3s1.35-3 3-3h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-2zm-3-4h8v2H8z"
        />
      </Svg>
    )
  }

  return (
    <Document>
      <Page wrap size="A4" style={styles.root}>
        <View style={styles.topbar}>
          <View style={[styles.flex, styles.topbarContent, styles.alignCenter]}>
            {/* <View style={styles.avatarContainer}> //TODO: ATS-1491-come back to this to resolve the issue
              <AvatarIcon /> 
              {Boolean(avatarUrl) ? <Image style={styles.avatarImage} src={{ uri: `${avatarUrl}`, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} /> : null }
            </View> */}
            <View style={styles.colorDark}>
              <Text style={[styles.fontLarge, styles.spacing4, styles.limitText]}>{`${name} ${surname}`}</Text>
              {Boolean(currentPosition) ? <Text style={styles.limitText}>{currentPosition}</Text> : null}
            </View>
          </View>
          <View style={[styles.colorDark, styles.topbarContent, styles.alignEnd, styles.fontSmall]}>
            <Text style={styles.spacing4}>{email}</Text>
            {Boolean(homePhone) ? <Text style={styles.spacing4}>{withMask(homePhone, 'tel')}</Text> : null}
            {Boolean(cellPhone) ? <Text style={styles.spacing4}>{withMask(cellPhone, 'cel')}</Text> : null}
            {Boolean(linkedin) ? <Text style={{ color: '#000000' }}>{linkedin}</Text> : null}
          </View>
        </View>

        {(headline != null && headline !== '') ? (
          <View style={styles.spacing16}>
            <Text
              style={[
                styles.spacing16,
                styles.colorDark,
                styles.fontLarge,
                styles.fontMedium
              ]}
            >
              Título
            </Text>
            <>
              <Text style={styles.spacing8}>{headline}</Text>
            </>
          </View>
        ) : null}

        {(abstract != null && abstract !== '') ? (
          <>
            <Text style={styles.divider}></Text>
            <View style={styles.spacing16}>
              <Text
                style={[
                  styles.spacing16,
                  styles.colorDark,
                  styles.fontLarge,
                  styles.fontMedium
                ]}
              >
                Resumo
              </Text>
              <>
                <Text style={styles.spacing8}>{abstract}</Text>
              </>
            </View>
          </>
        ) : null}

        {experiences?.length > 0 ? (
          <>
            <Text style={styles.divider}></Text>
            <View>
              <Text
                style={[
                  styles.spacing16,
                  styles.colorDark,
                  styles.fontLarge,
                  styles.fontMedium
                ]}
              >
                Experiências Profissionais
              </Text>
              {sortByPropAndDate(
                experiences,
                'currentWork',
                ["CURRENT", "NOT_CURRENT"]
              ).map((job, i) => (
                <View key={i} style={styles.spacing16}>
                  <Text style={[styles.spacing8, styles.fontMedium]}>{job.performance}</Text>
                  <View style={styles.fontLight}>
                    <Text style={styles.spacing8}>{job.company}</Text>
                    <View style={[styles.flex, styles.spacing8]}>

                      <Text style={styles.date}>{moment(job.startDate).format('L')}</Text>

                      {job.currentWork === "CURRENT" ? (
                        <Text>Trabalho atualmente neste cargo</Text>
                      ) : (
                        <Text>{moment(job.endDate).format('L')}</Text>
                      )
                      }
                    </View>
                    {Boolean(job.description) ? (
                      <>
                        <Text style={styles.spacing8}>Descrição</Text>
                        <Text style={styles.spacing8}>{job.description}</Text>
                      </>
                    ) : null}
                  </View>
                </View>
              ))}
            </View>
          </>
        ) : null}

        {((Boolean(educations) && educations?.length) > 0) ? (
          <>
            <Text style={styles.divider}></Text>
            <View>
              <Text
                style={[
                  styles.spacing16,
                  styles.colorDark,
                  styles.fontLarge,
                  styles.fontMedium
                ]}
              >
                Formações
              </Text>
              <View style={styles.wrap}>
                {sortByPropAndDate(
                  educations,
                  'progress',
                  ["IN_PROGRESS", "FINISHED", "UNFINISHED"]
                ).map((course, i) => (
                  <View key={i} wrap={false} style={styles.halfWidth}>
                    <Text style={[styles.spacing8, styles.fontMedium]}>{course.institution}</Text>
                    <Text style={styles.spacing8}>{degreeOptionsToLabels[course.degree]}</Text>
                    <Text style={[styles.spacing8, styles.fontLight]}>
                      {course.title}
                    </Text>
                    <View style={[styles.spacing16, styles.flex, styles.fontLight]}>
                      <Text style={{ marginRight: 16 }}>{moment(course.startDate).format('L')}</Text>
                      {course.progress === 'UNFINISHED' ? (
                        <Text style={{ marginRight: 4 }}>Incompleto</Text>
                      ) : (
                        <Text style={{ marginRight: 4 }}>{moment(course.endDate).format('L')}</Text>
                      )
                      }
                      {course.progress === "IN_PROGRESS" ? (
                        <Text style={[styles.fontLight, styles.fontSmall]}>*previsto</Text>
                      ) : null}
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </>
        ) : null}

        {(accomplishments?.length > 0) ? (
          <>
            <Text style={styles.divider}></Text>
            <View>
              <Text
                style={[
                  styles.spacing16,
                  styles.colorDark,
                  styles.fontLarge,
                  styles.fontMedium
                ]}
              >
                Certificações
              </Text>
              <View style={styles.wrap}>
                {accomplishments.map((item, i) => (
                  <View key={i} wrap={false} style={[styles.spacing16, styles.halfWidth]}>
                    <Text style={[styles.spacing8, styles.fontMedium]}>{item.title}</Text>
                    <View style={styles.fontLight}>
                      <Text style={styles.spacing8}>{item.institution}</Text>
                      <View style={styles.flex}>

                        <Text style={styles.date}>{moment(item.emissionDate).format('L')}</Text>
                        {item.expirationDate ? (
                          <>

                            <Text>{moment(item.expirationDate).format('L')}</Text>
                          </>
                        ) : null}
                      </View>
                      <View style={styles.credential}>
                        <IconCredentialCode />
                        {item.credentialCode ? (
                          <>
                            <Text style={styles.credentialText}>{item.credentialCode}</Text>
                          </>
                        ) : null}
                      </View>
                      <View style={styles.credential}>
                        <IconCredentialURL />
                        {item.credentialUrl ? (
                          <>
                            <Link src={item.credentialUrl}>
                              URL da credencial
                            </Link>
                          </>
                        ) : null}
                      </View>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </>
        ) : null}

        {(languages?.length > 0) ? (
          <>
            <Text style={styles.divider}></Text>
            <View>
              <Text
                style={[
                  styles.spacing16,
                  styles.colorDark,
                  styles.fontLarge,
                  styles.fontMedium
                ]}
              >
                Idiomas
              </Text>
              <View style={styles.chipContainer}>
                {languages.map((item, i) => (
                  <View wrap={false} key={i} style={[styles.chip, styles.spacing8]}>
                    <Text style={styles.fontMedium}>{item.language}{" "}</Text>
                    <Text style={styles.fontLight}>{levelOptionsToLabel[item.level]}</Text>
                  </View>
                ))}
              </View>
            </View>
          </>
        ) : null}

        {((professionalSkills?.length > 0 || personalSkills?.length > 0)) ? (
          <>
            <Text style={styles.divider}></Text>
            <View>
              <Text
                style={[
                  styles.spacing16,
                  styles.colorDark,
                  styles.fontLarge,
                  styles.fontMedium
                ]}
              >
                Habilidades
              </Text>
              {(professionalSkills?.length > 0) ? <Text style={styles.spacing8}>Técnicas</Text> : null}
              <View style={styles.chipContainer}>
                {professionalSkills?.map((skill, i) => (
                  <View wrap={false} key={i} style={[styles.chip, styles.spacing8]}>
                    <Text>{skill}</Text>
                  </View>
                ))}
              </View>
              {(personalSkills?.length > 0) ? <Text style={styles.spacing8}>Comportamentais</Text> : null}
              <View style={styles.chipContainer}>
                {personalSkills?.map((skill, i) => (
                  <View wrap={false} key={i} style={[styles.chip, styles.spacing8]}>
                    <Text>{skill}</Text>
                  </View>
                ))}
              </View>
            </View>
          </>
        ) : null}
      </Page>
    </Document>
  )
}

export default ResumePDF
