import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { CandidateBank } from 'src/types/candidateBank';
import talentsApi from 'src/api/talentsApi';

interface RequestParamsProps {
  page: number;
  limit: number;
  fuzzySearch?: string;
  favorite?: boolean;
  roleNames?: string;
}

interface CandidateBankState {
  candidatesBank: CandidateBank[];
  candidatesBankTotal: number;
  thereTalents: boolean;
}

const initialState: CandidateBankState = {
  candidatesBank: [],
  candidatesBankTotal: 0,
  thereTalents: false
};

const slice = createSlice({
  name: 'candidatesBank',
  initialState,
  reducers: {
    getCandidatesBank(
      state: CandidateBankState,
      action: PayloadAction<{
        meta: { totalItems: number;talents:boolean };
        items: CandidateBank[];
      }>
    ) {
      const { items, meta } = action.payload;

      state.candidatesBank = items;
      state.candidatesBankTotal = meta.totalItems;
      state.thereTalents = meta.talents;
    }
  }
});

export const reducer = slice.reducer;

export const getCandidatesBank =
  (params: RequestParamsProps, companyId: string): AppThunk =>
  async (dispatch) => {
    const response = await talentsApi.get<{
      meta: { totalItems: number;talents:boolean };
      items: CandidateBank[];
    }>(`/talents/${companyId}`, { params });

    dispatch(slice.actions.getCandidatesBank(response.data));
  };

export default slice;
