import React from 'react';

import { Fab, Tooltip, Typography } from '@mui/material';
import { WhatsApp } from '@mui/icons-material';

import { useStyles } from './styles';

const WhasApp = () => {
  const classes = useStyles();

  const phoneNumber = process.env.REACT_APP_WHATSAPP_REDIRECT;
  const message = encodeURIComponent(
    'Olá! Estou entrando em contato pois estou precisando de ajuda com a plataforma!'
  );
  const wppRedirect = `https://wa.me/${phoneNumber}?text=${message}`;

  const tooltipTitle = (
    <Typography className={classes.tooltipText}>Ajuda via WhatsApp</Typography>
  );

  return (
    <Tooltip
      title={tooltipTitle}
      arrow
      placement="left-end"
      classes={{ tooltip: classes.tooltip }}
    >
      <Fab
        component="a"
        href={wppRedirect}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.root}
      >
        <WhatsApp className={classes.icon} />
      </Fab>
    </Tooltip>
  );
};

export default WhasApp;
