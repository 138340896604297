export const formattedExamName = (name: string, level: number) => {
	let newLevel = 'Básico';

	if (level === 2) {
		newLevel = 'Intermediário';
	} else if (level === 3) {
		newLevel = 'Avançado';
	}

	return `${name} - ${newLevel}`;
};
