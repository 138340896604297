import React, { FC } from 'react';
import { CheckCircle, WarningAmber, Block } from '@mui/icons-material';

interface ProfileProps { status: any; attempts: number }

const StatusIcon: FC<ProfileProps> = ({ status, attempts }) => {
  let newStatus = <WarningAmber style={{ height: 20, width: 20, color: "#ED6C02" }}></WarningAmber>;

  if (status === "APPROVED") {
    newStatus = <CheckCircle style={{ height: 20, width: 20, color: "#4CAF50" }} ></CheckCircle>;
  }

  else if (status === "BLOCKED" || attempts > 1) {
    newStatus = <Block style={{ height: 20, width: 20, color: "rgba(0, 0, 0, 0.38)" }}></Block>;
  } 

  return newStatus;
};

export default StatusIcon;