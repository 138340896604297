import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Box} from '@mui/material';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    display: "flex",
    flexDirection: "column"
  },
}));

interface Props {
  children: React.ReactNode;
}

const Main = ({ children}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Box
      className={clsx({
        [classes.root]: true,
      })}
    >
      <main>
        {children}
      </main>
    </Box>
  );
};

export default Main;
