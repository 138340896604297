export const removeRouteSearchParams = (
  searchParams: string,
  paramsToRemove: string[]
) => {
  const queries = searchParams.replace('?', '').split('&');

  return queries.reduce((acc, query) => {
    const [key] = query.split('=');
    if (paramsToRemove.includes(key)) return acc;
    if (acc) return acc + '&' + query;
    if (!query) return ''
    return '?' + query;
  }, '');
};

export const insertRouteSearchParams = (
  searchParams: string,
  paramsToInsert: string
) => {
  let newSearchParams = searchParams;
  const queriesToInsert = paramsToInsert.replace('?', '').split('&');

  queriesToInsert.map(query => {
    const [key] = query.split('=')

    if (newSearchParams.includes(`${key}=`)) {
      newSearchParams = removeRouteSearchParams(newSearchParams, [key])
    }
  })

  if (newSearchParams && paramsToInsert) return newSearchParams + '&' + paramsToInsert;
  if (newSearchParams && !paramsToInsert) return newSearchParams
  return '?' + paramsToInsert;
};

export const replaceRouteSearchParams = (
  searchParams: string,
  queryToReplace: string,
  replacement: string
) => {
  const query = searchParams.replace(`${queryToReplace}`, `${replacement}`)

  if (searchParams) return query
  return
}

export const replaceRouteSearchMultipleParams = (
  searchParams: string,
  paramsToReplace: { [key: string]: string }
) => {
  const url = new URLSearchParams(searchParams);

  Object.entries(paramsToReplace).forEach(([key, value]) => {
    url.set(key, value);
  });

  if (searchParams) return `?${url.toString()}`;
  return
};

export const getModal = (searchParams: string, queriesParam?: string[]) => {
  if (searchParams) {
    const queries = searchParams.replace('?', '').split('&')

    return queries.reduce((acc, query) => {
      const paramsToGet = ['modal']
      if (queriesParam) paramsToGet.push(...queriesParam)
      const [key, value] = query.split('=')

      if (paramsToGet.includes(key)) return [...acc, value]
      return acc
    }, [])
  }
  return []
}
