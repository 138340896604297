import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  list: {
    '& .Mui-selected': {
      background: '#ECEFF1',
      borderRadius: '8px',
      '& .MuiTypography-root': {
        fontWeight: 700,
        color: '#757575'
      },
      '&:hover': {
        background: '#ECEFF1'
      }
    },
    '& .MuiListSubheader-root': {
      color: theme.palette.text.primary,
      fontWeight: 500
    }
  },
  icon: {
    color: theme.palette.icon.default
  }
}));
