import { makeStyles } from '@mui/styles';
import { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  subTitle: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: 16,
    color: '#00000099',
    [theme.breakpoints.down(596)]: {
      textAlign: 'left'
    }
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    marginTop: 18
  }
}));