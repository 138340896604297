import React from 'react';
import { Box, IconButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useStyles } from './styles';

interface NextButtonProps {
  onClick?: () => void;
  disabled?: boolean;
}

function NextButton({ onClick, disabled }: NextButtonProps) {
  const styles = useStyles();

  return (
    <Box className={styles.container}>
      <IconButton
        className={styles.iconButton}
        onClick={onClick}
        disabled={disabled}
        type="submit"
      >
        <ArrowForwardIcon />
      </IconButton>
    </Box>
  );
}

export default NextButton;
