import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { usePDF } from '@react-pdf/renderer';
import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { ResumePDF } from 'src/layouts';

import _ from 'lodash';
import download from 'src/assets/images/icons/download.svg';
import {
  CertificationCard,
  FormationCard,
  HabilidadesCard,
  LanguageCard,
  ProfessionalExperienceCard
} from 'src/components';
import { getCandidateCurriculum } from 'src/slices/candidateCurriculum';
import { useDispatch, useSelector } from 'src/store';
import { Theme } from 'src/theme';
import {
  Certification,
  Formation,
  Language,
  ProfessionalExperience
} from 'src/types';
import { GeneralInformationProfile } from 'src/types/generalInformationProfile';
import {
  fileExtensions,
  fileTypes,
  getDownloadFileLink,
  getDownloadFileName
} from 'src/utils/handleFiles';
import { sortByPropAndDate } from 'src/utils/sortFunctions';

const useStyles = makeStyles((theme: Theme) => ({
  cardHeader: {
    paddingBottom: 0
  },
  cardContent: {
    fontFamily: 'Nunito',
    '&:last-child': {
      paddingBottom: 0,
      maxWidth: 800,
      wordBreak: 'break-word',
      [theme.breakpoints.between(960, 1350)]: {
        maxWidth: 600
      }
    }
  },
  fileName: {
    color: '#5850EC',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.3px',
    fontWeight: 500,
    fontStyle: 'normal',
    fontFamily: 'Nunito',
    marginRight: 30
  },
  downloadTitle: {
    textAlign: 'center',
    marginBottom: 26
  },
  textArea: {
    width: '100%',
    resize: 'none',
    borderColor: 'transparent',
    background: theme.palette.background.default,
    fontFamily: 'Nunito',
    fontSize: '14px',
    overflow: 'hidden',
    '&:last-child': {
      [theme.breakpoints.between(960, 1350)]: {
        maxWidth: 600
      }
    }
  }
}));

const titleStyles = {
  fontFamily: 'Nunito',
  fontWeight: 700,
  fontSize: 14
}

interface Curriculum {
  educations: Formation[];
  experiences: ProfessionalExperience[];
  languages: Language[];
  accomplishments: Certification[];
  personalSkills: string[];
  professionalSkills: string[];
}

interface ViewResumeProps {
  info?: GeneralInformationProfile;
  showViewHeader?: boolean;
  enableDownload?: any;
  isCandidateBank?: boolean;
  isDrawerViwer?: boolean;
}

const ViewCurriculum: FC<ViewResumeProps> = ({
  info,
  enableDownload,
  showViewHeader,
  isCandidateBank,
  isDrawerViwer
}) => {
  const classes = useStyles();
  const { curriculum, abstract, headline } = info;

  const Doc = Boolean(info) && (
    <ResumePDF info={info} avatarUrl={info.avatar} />
  );
  const { candidateCurriculum } = useSelector(
    (state) => state.candidateCurriculum
  );
  const dispatch = useDispatch();
  const [instance, updateInstance] = usePDF({ document: Doc });

  useEffect(() => {
    if (info.id && candidateCurriculum?.id !== info.id) {
      dispatch(getCandidateCurriculum(info.id));
    }
  }, [dispatch]);

  useEffect(() => {
    updateInstance();
  }, [candidateCurriculum]);

  const hasValidCurriculum = () => {
    if (headline || abstract) return true;
    if (!info?.curriculum) return false;

    const curriculum = info?.curriculum;
    const { file = {}, fileId = '', ...rawCurriculum } = curriculum;

    if (!Object.keys(rawCurriculum).length) return false;

    if (
      curriculum?.educations?.length === 0 &&
      curriculum?.experiences?.length === 0 &&
      curriculum?.languages?.length === 0 &&
      curriculum?.accomplishments?.length === 0 &&
      curriculum?.personalSkills?.length === 0 &&
      curriculum?.professionalSkills?.length === 0 &&
      (headline === '' || headline == null) &&
      (abstract === '' || abstract == null)
    ) {
      return false;
    }
    return true;
  };

  const getFileType = (fileName: string) => {
    const nameArr = fileName.split('.');
    const extension = nameArr[nameArr.length - 1];
    const allowedExtensions = ['docx', 'doc'];

    if (extension === 'pdf') return 'PDF';
    if (allowedExtensions.includes(extension)) return 'WORD';
  };

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true
  });

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = '0px';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + 'px';
    }

    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [abstract, dimensions]);

  return (
    <Grid container spacing={0}>
      {!hasValidCurriculum() && !enableDownload ? (
        <Grid item xs={12}>
          <Box
            height="auto"
            minWidth='100%'
            p={2}
            display="flex"
            justifyContent='center'
          >
            <Typography variant="h4">Currículo sem Informações</Typography>
          </Box>
        </Grid>
      ) : (
        <></>
      )}

      {!hasValidCurriculum() && enableDownload && (
        <Grid item xs={12}>
          <Box
            height="auto"
            maxWidth="350px"
            margin="auto"
            marginBottom={16}
            p={2}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Typography className={classes.downloadTitle} variant="h4">
              Este candidato prefere compartilhar um currículo personalizado
            </Typography>
            <Button
              id="download-cv-id"
              component="a"
              href={
                info?.curriculum?.file &&
                getDownloadFileLink(
                  fileTypes[getFileType(info?.curriculum?.file?.path)],
                  enableDownload
                )
              }
              download={
                getDownloadFileName(info?.email, info?.name, info?.surname) +
                (info?.curriculum?.file &&
                  fileExtensions[getFileType(info?.curriculum?.file?.path)])
              }
              style={{ textDecoration: 'none' }}
              color="primary"
              variant="outlined"
            >
              <img src={download} alt="download" style={{ marginRight: 10 }} />
              BAIXAR CURRÍCULO
            </Button>
          </Box>
        </Grid>
      )}

      {showViewHeader && (
        <Grid item xs={12}>
          <Box
            marginTop={0.75}
            marginBottom={3.75}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4" style={{ fontSize: 18 }}>
              Resumo profissional
            </Typography>
            {!instance.loading && !instance.error && (
              <Typography
                style={{ textDecoration: 'none', marginRight: 16 }}
                component="a"
                href={instance.url}
                download="curriculo-gria.pdf"
              >
                <Button id="download-resume-id" variant="text" color="primary">
                  <span style={{ marginRight: 5 }}>Baixar</span>
                  <img src={download} alt="" />
                </Button>
              </Typography>
            )}
          </Box>
        </Grid>
      )}
      {hasValidCurriculum() && (
        <Card
          style={
            isCandidateBank
              ? { width: '100%', border: 'none', boxShadow: 'none' }
              : { width: '100%' }
          }
        >
          {headline && (
            <>
              <Grid item xs={12}>
                <Card elevation={0}>
                  <CardHeader
                    title={'Título'}
                    titleTypographyProps={titleStyles}
                    className={classes.cardHeader}
                  />
                  <Typography
                    className={classes.cardContent}
                    style={{ padding: '6px 32px' }}
                  >
                    {headline}
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={12} style={{ padding: '6px 32px' }}></Grid>
            </>
          )}

          {abstract && (
            <>
              <Grid item xs={12}>
                <Card elevation={0}>
                  <CardHeader
                    title={'Resumo'}
                    titleTypographyProps={titleStyles}
                    className={classes.cardHeader}
                  />
                  <Box>
                    <textarea
                      ref={textareaRef}
                      disabled
                      className={classes.textArea}
                      style={{ padding: '6px 32px 12px' }}
                      value={abstract}
                    ></textarea>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} style={{ padding: '6px 32px' }}></Grid>
            </>
          )}

          {curriculum?.experiences?.length > 0 && (
            <>
              <Grid item xs={12}>
                <Card elevation={0}>
                  <CardHeader
                    title={'Experiências Profissionais'}
                    titleTypographyProps={titleStyles}
                    className={classes.cardHeader}
                  />
                  <CardContent className={classes.cardContent}>
                    {sortByPropAndDate(curriculum.experiences, 'currentWork', [
                      'CURRENT',
                      'NOT_CURRENT'
                    ]).map((experience, index) => (
                      <ProfessionalExperienceCard
                        professionalExperience={experience}
                        key={experience.id}
                        index={index}
                        length={
                          sortByPropAndDate(
                            curriculum.experiences,
                            'currentWork',
                            ['CURRENT', 'NOT_CURRENT']
                          ).length
                        }
                      />
                    ))}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} style={{ padding: '6px 42px' }}></Grid>
            </>
          )}

          {curriculum?.educations?.length > 0 && (
            <>
              <Grid item xs={12}>
                <Card elevation={0}>
                  <CardHeader
                    title={'Formações'}
                    titleTypographyProps={titleStyles}
                    className={classes.cardHeader}
                  />
                  <CardContent className={classes.cardContent}>
                    {sortByPropAndDate(curriculum.educations, 'progress', [
                      'IN_PROGRESS',
                      'FINISHED',
                      'UNFINISHED'
                    ]).map((formation, index) => (
                      <FormationCard
                        formation={formation}
                        key={formation.id}
                        index={index}
                        length={
                          sortByPropAndDate(curriculum.educations, 'progress', [
                            'IN_PROGRESS',
                            'FINISHED',
                            'UNFINISHED'
                          ]).length
                        }
                      />
                    ))}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} style={{ padding: '6px 42px' }}></Grid>
            </>
          )}
          {curriculum?.accomplishments?.length > 0 && (
            <>
              <Grid item xs={12}>
                <Card elevation={0}>
                  <CardHeader
                    title={'Certificações'}
                    titleTypographyProps={titleStyles}
                    className={classes.cardHeader}
                  />
                  <CardContent className={classes.cardContent}>
                    <Grid container spacing={2}>
                      {curriculum.accomplishments.map(
                        (certification, index) => {
                          return (
                            <Grid item xs={12} key={certification.id}>
                              <CertificationCard
                                certification={certification}
                                index={index}
                                length={curriculum.accomplishments.length}
                              />
                            </Grid>
                          );
                        }
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} style={{ padding: '6px 42px' }}></Grid>
            </>
          )}
          {curriculum?.languages?.length > 0 && (
            <Grid item xs={12}>
              <Card elevation={0}>
                <CardHeader
                  title={'Idiomas'}
                  titleTypographyProps={titleStyles}
                  className={classes.cardHeader}
                />
                <CardContent
                  className={classes.cardContent}
                  style={{ marginLeft: 20 }}
                >
                  <Grid container spacing={2}>
                    {_.chunk(curriculum.languages, 2).map(
                      (row: Array<Language>, index) => (
                        <React.Fragment key={index}>
                          {row.map((language: Language, i) => (
                            <Grid
                              container
                              style={
                                isMediumScreen
                                  ? {
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }
                                  : null
                              }
                              key={i}
                            >
                              <Grid item md={4} sm={12} key={language.id}>
                                <LanguageCard language={language} />
                              </Grid>
                            </Grid>
                          ))}
                        </React.Fragment>
                      )
                    )}
                  </Grid>
                </CardContent>
              </Card>
              <Grid item xs={12} style={{ padding: '6px 42px' }}></Grid>
            </Grid>
          )}
          {curriculum?.personalSkills?.length > 0 ||
          curriculum?.professionalSkills?.length > 0 ? (
            <Grid item xs={12}>
              <Card elevation={0}>
                <CardHeader
                  title={'Habilidades'}
                  titleTypographyProps={titleStyles}
                  className={classes.cardHeader}
                />
                <CardContent className={classes.cardContent}>
                  <HabilidadesCard
                    personalSkills={curriculum.personalSkills}
                    professionalSkills={curriculum.professionalSkills}
                  />
                </CardContent>
              </Card>
            </Grid>
          ) : (
            <></>
          )}
        </Card>
      )}
    </Grid>
  );
};

export default ViewCurriculum;
