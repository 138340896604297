// tipo de contrato
export const jobTypes = [
  { label: 'Trainee', value: 'TRAINEE' },
  { label: 'Estágio', value: 'INTERNSHIP' },
  { label: 'CLT', value: 'CLT' },
  { label: 'PJ', value: 'PJ' },
  { label: 'Temporário', value: 'CONTRACT' },
  { label: 'Outro', value: 'OTHER' },
  { label: 'Autônomo', value: 'AUTONOMOUS' },
  { label: 'A definir', value: 'TO_BE_DEFINED' }
].sort(function (a, b) {
  if ((a.label === "Outro") !== (b.label === "Outro")) {
    return (a.label === "Outro") ? 1 : -1;
  }
  return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0);
});