import { ArrowUpward, ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { HideItem } from 'src/components/molecules/HideItem';
import type { Theme } from 'src/theme';
import getCoinType from 'src/utils/getCoinType';
import { availabilityOptions } from 'src/utils/helpers';
import { TimeLineCardDrawer } from '.';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiTypography-root, & .MuiInputLabel-formControl': {
      fontFamily: 'Nunito'
    },
    backgroundColor: 'rgba(250, 250, 250, 1)',
    color: '#000',
    '& .MuiListItem-root': {
      padding: 0,
      marginLeft: 0
    },
    padding: 16
  },
  expandButton: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  resumeTitle: {
    padding: '16px',
    textAlign: 'justify',
    fontFamily: 'Nunito',
    fontStyle: 'bold',
    fontSize: '11px',
    lineHeight: '190 %',
    align: 'left',
    verticalAlign: 'Center',
    letterSpacing: '0.5px'
  },
  resume: {
    fontFamily: 'Nunito',
    fontStyle: 'Regular',
    fontSize: '14px',
    lineHeight: '143%',
    align: 'Left',
    verticalAlign: 'Center',
    letterSpacing: '0.15px',
    padding: '16px',
    textAlign: 'justify',
    borderRadius: '4px'
  },
  itemList: {
    display: 'flex',
    border: '0.5px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    width: 'auto',
    justifyContent: 'space-between',
    background: 'white'
  },
  card: {
    border: '0.5px solid #C4C4C4',
    borderRadius: 6,
    padding: '20px 15px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px'
    }
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: 5
    }
  },
  questionsContainer: {
    maxHeight: 378,
    overflowY: 'scroll',
    [theme.breakpoints.down('md')]: {
      maxHeight: '100%'
    }
  },
  answers: {
    fontFamily: 'Nunito',
    fontStyle: 'Regular',
    fontSize: '14px',
    lineHeight: '143%',
    align: 'left',
    verticalAlign: 'Center',
    letterSpacing: ' 0.15px',
    padding: '10px 16px'
  },
  questions: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '157%',
    align: 'Left',
    verticalAlign: 'Center',
    letterSpacing: ' 0.1px'
  },
  resumeAbstract: {
    fontFamily: 'Nunito',
    fontStyle: 'Regular',
    fontSize: '14px',
    lineHeight: '143%',
    align: ' left',
    verticalAlign: 'top',
    letterSpacing: '0.15px',
    padding: '16px',
    wordBreak: 'break-word'
  },
  boxButton: {
    background: theme.palette.background.default,
    border: '0.5px solid rgba(0, 0, 0, 0.12)',
    borderTop: 'none',
    borderRadius: '0px 0px 4px 4px',
    padding: '12px 0',
    textAlign: 'center',
    '& .MuiButtonBase-root': {
      '&:hover': {
        background: theme.palette.background.default
      }
    }
  }
}));

interface QuestionAnswerType {
  question: string;
  questionId: string;
  answer: string;
}

interface ApplicationTableProps {
  infoApplication?: any;
  setCurrentTab?: (variant: string) => void;
  premiumSubscriber?: boolean;
}

export const ApplicationTable: FC<ApplicationTableProps> = ({
  infoApplication,
  setCurrentTab,
  premiumSubscriber
}) => {
  const classes = useStyles();
  const [openOption1, setOpenOption1] = useState(true);
  const [openOption2, setOpenOption2] = useState(true);
  const [openOption3, setOpenOption3] = useState(true);
  const [openOption4, setOpenOption4] = useState(true);
  const theme = useTheme();
  const salaryColor = infoApplication?.salaryRate
    ? infoApplication?.salaryRate?.salaryColor
    : '';
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(500), {
    defaultMatches: true
  });
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down(370), {
    defaultMatches: true
  });

  const rotate =
    infoApplication?.pretenseSalary < infoApplication?.job?.minSalary
      ? 'rotate(180deg)'
      : '';

  const generateAvailabilityLabel = (
    value,
    options: { label: string; value: string }[]
  ) => {
    const labelIndex = options.findIndex((opt) => opt.value === value);

    if (labelIndex < 0) return '';
    return options[labelIndex].label;
  };

  const handleClickOpen = (opption) => {
    switch (opption) {
      case 1:
        openOption1 ? setOpenOption1(false) : setOpenOption1(true);
        break;
      case 2:
        openOption2 ? setOpenOption2(false) : setOpenOption2(true);
        break;
      case 3:
        openOption3 ? setOpenOption3(false) : setOpenOption3(true);
        break;
      case 4:
        openOption4 ? setOpenOption4(false) : setOpenOption4(true);
        break;
    }
  };

  const handleChangeToCurriculum = () => {
    setCurrentTab('resume');
  };
  const generateSalaryRange = (
    minSalary: number,
    maxSalary: number,
    currency: string
  ) => {
    if (typeof minSalary === 'undefined' || typeof maxSalary === 'undefined') {
      return '-';
    }
    return `${getCoinType(currency, minSalary, true)} - ${getCoinType(
      currency,
      maxSalary,
      true
    )}`;
  };

  return (
    <Box className={clsx(classes.root)}>
      {!['REGISTERED', 'QUITTER_REGISTERED'].includes(
        infoApplication?.situation?.status
      ) && (
        <Box>
          <ListItemButton
            style={{ background: 'rgba(250, 250, 250, 1)', paddingLeft: 0 }}
            onClick={() => handleClickOpen(1)}
          >
            <Box>
              {openOption1 ? (
                <ExpandLess className={classes.expandButton} />
              ) : (
                <ExpandMore className={classes.expandButton} />
              )}
            </Box>
            <ListItemText
              style={{ color: theme.palette.text.secondary }}
              primary="Linha do tempo"
            />
          </ListItemButton>
          <Collapse in={openOption1} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem sx={{ padding: '0px 0px 0px 12px' }}>
                <TimeLineCardDrawer
                  processStatus={infoApplication && infoApplication.events}
                  application={infoApplication && infoApplication}
                />
              </ListItem>
            </List>
          </Collapse>
        </Box>
      )}
      <Box>
        <ListItemButton
          style={{ background: 'rgba(250, 250, 250, 1)', paddingLeft: 0 }}
          onClick={() => handleClickOpen(2)}
        >
          <Box>
            {openOption2 ? (
              <ExpandLess className={classes.expandButton} />
            ) : (
              <ExpandMore className={classes.expandButton} />
            )}
          </Box>
          <ListItemText
            style={{ color: theme.palette.text.secondary }}
            primary="Apresentação"
          />
        </ListItemButton>
        <Collapse in={openOption2} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              sx={{
                background: 'white',
                border: '0.5px solid rgba(0, 0, 0, 0.12)',
                borderRadius: '4px 4px 0px 0px',
                marginLeft: '12px',
                padding: '0px 0px 0px 12px',
                width: 'auto'
              }}
            >
              <Box>
                <Typography
                  style={
                    !infoApplication?.candidate?.abstract
                      ? { color: 'rgba(0, 0, 0, 0.38)' }
                      : { color: 'rgba(0, 0, 0, 0.87)' }
                  }
                  className={classes.resumeAbstract}
                >
                  {infoApplication?.candidate?.abstract
                    ? infoApplication?.candidate?.abstract
                    : 'Candidato não possui resumo'}
                </Typography>
              </Box>
            </ListItem>
            <Box className={classes.boxButton}>
              <Button onClick={handleChangeToCurriculum}>
                VER CURRÍCULO COMPLETO
              </Button>
            </Box>
          </List>
        </Collapse>
      </Box>
      <Box>
        <ListItemButton
          style={{ background: 'rgba(250, 250, 250, 1)', paddingLeft: 0 }}
          onClick={() => handleClickOpen(3)}
        >
          <Box>
            {openOption3 ? (
              <ExpandLess className={classes.expandButton} />
            ) : (
              <ExpandMore className={classes.expandButton} />
            )}
          </Box>
          <ListItemText
            style={{ color: theme.palette.text.secondary }}
            primary="Candidatura"
          />
        </ListItemButton>
        <Collapse in={openOption3} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem className={classes.itemList}>
              <Box>
                <Typography
                  style={isSmallScreen ? { padding: '16px' } : null}
                  className={classes.resumeTitle}
                >
                  FAIXA DA VAGA
                </Typography>
              </Box>
              <Box>
                <Typography
                  style={isSmallScreen ? { padding: '16px' } : null}
                  className={classes.resume}
                >
                  {generateSalaryRange(
                    infoApplication?.job?.minSalary,
                    infoApplication?.job?.maxSalary,
                    infoApplication?.job?.currency
                  )}
                </Typography>
              </Box>
            </ListItem>
            <ListItem className={classes.itemList}>
              <Box>
                <Typography className={classes.resumeTitle}>
                  PRETENSÃO SALARIAL
                </Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: salaryColor
                }}
              >
                <Typography className={classes.resume}>
                  {getCoinType(
                    infoApplication?.job?.currency,
                    infoApplication?.pretenseSalary
                  )}
                </Typography>
                {infoApplication &&
                infoApplication?.salaryRate?.salaryProportion ? (
                  <Typography
                    style={{ padding: '0px 16px 0px 0px' }}
                    className={classes.resume}
                  >
                    <ArrowUpward
                      style={{
                        height: '10.67',
                        width: '10.67',
                        marginLeft: '10.67',
                        marginRight: '4.67',
                        transform: rotate
                      }}
                    />
                    {infoApplication?.pretenseSalary <
                      infoApplication?.job?.minSalary && '-'}
                    {infoApplication?.salaryRate?.salaryProportion + '%'}
                  </Typography>
                ) : (
                  <></>
                )}
              </Box>
            </ListItem>
            {infoApplication?.salaryCurrent ? (
              <>
                <ListItem className={classes.itemList}>
                  <Box>
                    <Typography className={classes.resumeTitle}>
                      SALÁRIO ATUAL
                    </Typography>
                  </Box>

                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      color:
                        rotate === 'rotate(180deg)'
                          ? 'rgba(244, 67, 54, 1)'
                          : 'rgba(0, 0, 0, 0.87)'
                    }}
                  >
                    {premiumSubscriber ? (
                      <Typography className={classes.resume}>
                        {getCoinType(
                          infoApplication?.job?.currency,
                          infoApplication?.salaryCurrent
                        )}
                      </Typography>
                    ) : (
                      <HideItem
                        rootStyle={{ margin: '16px' }}
                        showTooltip
                        location="salary"
                      />
                    )}
                  </Box>
                </ListItem>
              </>
            ) : (
              <></>
            )}
            <ListItem className={classes.itemList}>
              <Box>
                <Typography className={classes.resumeTitle}>
                  DISPONIBILIDADE PARA INÍCIO
                </Typography>
              </Box>
              <Box>
                <Typography className={classes.resume}>
                  {generateAvailabilityLabel(
                    infoApplication?.availability,
                    availabilityOptions
                  )}
                </Typography>
              </Box>
            </ListItem>
          </List>
        </Collapse>
      </Box>
      <Box>
        <ListItemButton
          style={{ background: 'rgba(250, 250, 250, 1)', paddingLeft: 0 }}
          onClick={() => handleClickOpen(4)}
        >
          <Box>
            {openOption4 ? (
              <ExpandLess className={classes.expandButton} />
            ) : (
              <ExpandMore className={classes.expandButton} />
            )}
          </Box>
          <ListItemText
            style={{ color: theme.palette.text.secondary }}
            primary="Perguntas iniciais"
          />
        </ListItemButton>
        <Collapse in={openOption4} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              style={{
                padding: '16px 16px 0px 16px',
                textAlign: 'justify',
                alignItems: 'inherit',
                display: 'flex',
                flexDirection: 'column'
              }}
              className={classes.itemList}
            >
              {infoApplication?.job?.questions.length ? (
                infoApplication?.job?.questions?.map((questionAnswer, i) => (
                  <>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography
                        className={classes.questions}
                        variant="subtitle2"
                      >
                        {i + 1}. {questionAnswer.question}
                      </Typography>
                      <Typography
                        style={
                          !infoApplication?.answers[i]?.answer
                            ? { color: 'rgba(0, 0, 0, 0.38)' }
                            : {}
                        }
                        className={classes.answers}
                        variant="body2"
                      >
                        {infoApplication?.answers[i]?.answer
                          ? infoApplication?.answers[i]?.answer
                          : 'Candidato não respondeu'}
                      </Typography>
                      {i + 1 === infoApplication?.answers?.length ? (
                        <></>
                      ) : (
                        <Divider style={{ marginBottom: '16px' }} />
                      )}
                    </Box>
                  </>
                ))
              ) : (
                <>
                  <Typography
                    style={{
                      color: 'rgba(0, 0, 0, 0.87)',
                      padding: '0px 0px 16px 0px'
                    }}
                  >
                    Essa vaga ainda não possui <b>perguntas iniciais</b>{' '}
                    configuradas
                  </Typography>
                </>
              )}
            </ListItem>
          </List>
        </Collapse>
      </Box>
    </Box>
  );
};
