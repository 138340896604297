import { Box, Divider, TextField, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Theme } from 'src/theme';

interface Question {
  id: string;
  question: string;
  order?: number;
}

interface Answer {
  answer: string;
}

interface QuestionsProps {
  questions: Question[];
  answers: Answer[];
  onChange(value: string, index: number): void;
  editable: boolean;
  value?: string;
  setIsValid?: Dispatch<SetStateAction<boolean>>;
}

interface AnswerProps {
  onChange(value: string): void;
  label?: string;
  initialValue: string;
  isDisabled: boolean;
}

const AnswerField: FC<AnswerProps> = ({
  label,
  onChange,
  initialValue,
  isDisabled
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'), { defaultMatches: true })
  const [value, setValue] = useState(initialValue);

  return (
    <TextField
      onChange={el => {
        setValue(el.target.value);
        onChange(el.target.value);
      }}
      value={!isDisabled ? value : value || 'Candidato não respondeu'}
      label={label}
      color="primary"
      style={{ width: isSmallScreen ? '100%' : '680px', alignSelf: 'center', wordBreak: 'break-word' }}
      fullWidth
      multiline
      disabled={isDisabled}
      inputProps={{
        maxLength: 160
      }}
      sx={{
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: value ? "" : "red",
        },
      }}
      InputLabelProps={{
        style: {
          WebkitLineClamp: 1,
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
        }
      }}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    fontSize: '12px',
    color: theme.palette.text.primary,
    textAlign: 'justify',
  },
}));

const Questions: FC<QuestionsProps> = ({
  questions = [],
  answers = [],
  onChange,
  editable,
  setIsValid,
}) => {

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'), { defaultMatches: true })
  const classes = useStyles();
  const getIsValid = () => {
    return answers.every((answer) => {
      if (answer.answer === '') return true
      return answer.answer.search(/[a-z, A-Z, 0-9]/) > -1
    })
  }
  useEffect(() => { setIsValid && setIsValid(getIsValid()) }, [answers, setIsValid])

  return (
    <Box style={isMediumScreen ? { padding: `${editable ? '20px 0' : '20px'}`, width: 'auto' } : { padding: `${editable ? '20px 0' : '20px 20px 40px'}`, width: 'auto' }} >
      <Box style={{ marginBottom: '30px' }}>
        <Typography
          style={{
            fontSize: '16px',
            fontFamily: 'Roboto',
            lineHeight: '20px',
            letterSpacing: '-0.05px',
            fontStyle: 'normal',
            fontWeight: 'normal',
            color: '#263238'
          }}
        >
          Queremos conhecer um pouco sobre você
        </Typography>
        <Typography
          style={{
            fontSize: '12px',
            fontFamily: 'Roboto',
            lineHeight: '12px',
            letterSpacing: '-0.05px',
            fontStyle: 'normal',
            fontWeight: 'normal',
            color: '#7E7272'
          }}
        >
          As perguntas abaixos foram pré-definidas para esta vaga
        </Typography>
      </Box>

      {!editable && (<Divider style={{ marginBottom: 20, marginLeft: "-40px", width: '789px' }} />)}

      <Box id="response-candidate-list-div-id" style={{ maxHeight: 'none', minWidth: 'auto' }}>
        {answers && answers.length > 0 && questions.length > 0 &&
          questions?.slice().sort((a, b) => a.order - b.order).map((qt, index) => (
            <Box
              key={index}
              width="100%"
              style={{
                border: '0.5px solid #C4C4C4',
                borderRadius: 6,
                padding: 15,
                marginBottom: '20px',
                width: isMediumScreen ? 'auto' : '100%',
                backgroundColor: `${editable ? '' : '#FEFCFC'}`
              }}
            >
              <Typography variant="h6" className={classes.text}>{qt.question}</Typography>
              <AnswerField
                key={index}
                onChange={value => onChange(value, index)}
                initialValue={answers[index].answer}
                isDisabled={!editable}
              ></AnswerField>
            </Box>
          ))}
      </Box>
    </Box >
  );
};

export default Questions;
