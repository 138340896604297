import React from 'react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Link } from 'react-router-dom';

import Logo from 'src/components/Logo';
import UserCardType from './UserCardType';
import { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',

    [theme.breakpoints.down('md')]: {
      height: '100vh'
    }
  },
  title: {
    fontFamily: 'Nunito',
    fontSize: '34px',
    fontWeight: 400,
    lineHeight: '42px',
    letterSpacing: '0.25px',
    textAlign: 'center',
    marginBottom: '32px',
    padding: '0px 16px'
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '21px',
    '& > img': {
      width: 78,
      height: 53,
      maxHeight: '100%'
    }
  },
  boxTerms: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '48px',
    flexDirection: 'column'
  },
  terms: {
    color: theme.palette.primary.main,
    fontFamily: 'Nunito',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '0.4px'
  },
  subtitleTerms: {
    fontFamily: 'Nunito',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.4px',
    color: '#666666'
  },
  boxLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(1100)]: {
      flexDirection: 'column'
    }
  },
  boxFakeFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    textAlign: 'center',
    margin: '30px 0px 30px',
    width: 'calc(100vw - 426px)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: 'auto',
      paddingTop: '16px'
    },
    '&:first-child::after': {
      content: "''",
      position: 'absolute',
      inset: 0,
      backgroundColor: '#fff',
      zIndex: 999
    }
  },
  boxCards: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '24px',
    [theme.breakpoints.down(1100)]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.down('md')]: {
      gap: '6px',
      flexDirection: 'column'
    }
  },
  link: {
    color: theme.palette.primary.main,
    fontFamily: 'Nunito',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '17px',
    letterSpacing: '0.2px',
    textDecoration: 'none'
  }
}));

const Home = () => {
  const classes = useStyles();
  const theme = useTheme();

  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true
  });

  return (
    <>
      <Box className={classes.root}>
        {isMediumScreen && (
          <Box className={classes.logo}>
            <Logo />
          </Box>
        )}

        {!isMediumScreen && (
          <Box className={classes.boxFakeFooter}>
            <Box className={classes.boxLink}>
              <Box display="flex" alignItems="center">
                <Typography className={classes.subtitleTerms}>
                  Conheça nossos&nbsp;
                  <Link
                    to="/termos-uso-politica-privacidade"
                    style={{ textDecoration: 'none' }}
                  >
                    <Typography
                      className={classes.terms}
                      style={{ display: 'inline' }}
                    >
                      Termos de Uso e Política de Privacidade
                    </Typography>
                  </Link>
                  &nbsp;e&nbsp;
                </Typography>
              </Box>
              <Link
                to="/termos-condicoes-anuncios-vagas"
                style={{ textDecoration: 'none' }}
              >
                <Typography className={classes.terms}>
                  Termos para Anúncios de Vagas.
                </Typography>
              </Link>
            </Box>
          </Box>
        )}
        <div>
          <Typography component="h1" variant="h4" className={classes.title}>
            Acesse ou crie sua conta
          </Typography>
          <Box className={classes.boxCards}>
            <UserCardType userType="candidate" />
            <UserCardType userType="company" />
          </Box>
        </div>
        {isMediumScreen && (
          <Box className={classes.boxLink} style={{ marginTop: '22px' }}>
            <Box display="flex" alignItems="center">
              <Typography
                className={classes.subtitleTerms}
                style={{ padding: '0 20px', textAlign: 'center' }}
              >
                Conheça nossos{' '}
                <Link
                  id="term-of-use-politic-privacy-link-id"
                  to="/termos-uso-politica-privacidade"
                  className={classes.link}
                >
                  Termos de Uso e Política de Privacidade
                </Link>{' '}
                e{' '}
                <Link
                  id="terms-conditions-ads-vacancies-link-id"
                  to="/termos-condicoes-anuncios-vagas"
                  className={classes.link}
                >
                  Termos para Anúncios de Vagas.
                </Link>
              </Typography>
            </Box>
          </Box>
        )}
        <Box className={classes.boxFakeFooter}>
          {!isMediumScreen && (
            <Box className={classes.boxLink}>
              <Box display="flex" alignItems="center">
                <Typography className={classes.subtitleTerms}>
                  Conheça nossos&nbsp;
                  <Link
                    id="term-of-use-politic-privacy-link-id"
                    to="/termos-uso-politica-privacidade"
                    style={{ textDecoration: 'none' }}
                  >
                    <Typography
                      className={classes.terms}
                      style={{ display: 'inline' }}
                    >
                      Termos de Uso e Política de Privacidade
                    </Typography>
                  </Link>
                  &nbsp;e&nbsp;
                </Typography>
              </Box>
              <Link
                id="terms-conditions-ads-vacancies-link-id"
                to="/termos-condicoes-anuncios-vagas"
                style={{ textDecoration: 'none' }}
              >
                <Typography className={classes.terms}>
                  Termos para Anúncios de Vagas.
                </Typography>
              </Link>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Home;
