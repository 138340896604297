import type { FC } from 'react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  AddCircle,
  ArrowDropDownOutlined,
  Business,
  LockOpenOutlined,
  LogoutOutlined,
  WarningAmber,
  Person,
  ReceiptLong,
  Repeat,
  Receipt,
  WhatsApp,
  NoteAddOutlined,
  NoteAltOutlined,
  Edit
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  ButtonBase,
  Menu,
  MenuItem,
  Paper,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import brokenImage from 'src/assets/images/broken-image.svg';
import noImage from 'src/assets/images/no-image.svg';
import SwitchCompanyModal from './SwitchCompanyModal';
import useAuth from 'src/hooks/useAuth';
import { companyService } from 'src/services';
import { Theme } from 'src/theme';
import { convertImageSize } from 'src/utils/handleImagePath';
import AccountMobile from './AccountMobile';
import { MessageTab } from './index';
import Messages from './Messages';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    height: 32,
    width: 32
  },
  popover: {
    width: '100%',
    maxWidth: 280,
    backgroundColor: theme.palette.background.default,
    color: '#000000DE'
  },
  textUser: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
    maxWidth: '129px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Nunito',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.15px'
  },
  memberName: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(2),
    maxWidth: '129px',
    whiteSpace: 'nowrap',
    textAlign: 'initial',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.15px'
  },
  changeHover: {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Nunito',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    '&:hover': {
      backgroundColor: '#F2ECF7'
    }
  },
  iconColor: {
    color: theme.palette.text.disabled,
    cursor: 'pointer'
  },
  titleSection: {
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '0.10px',
    color: theme.palette.text.secondary,
    paddingLeft: '16px',
    paddingBottom: '8px'
  },
  readFlag: {
    minWidth: 12,
    minHeight: 12,
    marginRight: 16,
    borderRadius: '50%'
  },
  menuAvatar: {
    height: 40,
    width: 40,
    marginRight: 12
  },
  menuCandidacy: {
    fontSize: 14,
    fontWeight: 700
  },
  menuOpportunity: {
    fontSize: 14,
    fontWeight: 400
  },
  menuData: {
    fontSize: 12,
    fontWeight: 400
  },
  menuSubmenu: {
    margin: 0,
    padding: 0
  },
  messageOptions: {
    display: 'flex',
    marginLeft: 12
  },
  menuIcon: {
    marginRight: '8px',
    color: theme.palette.icon.default
  }
}));

export interface AccountProps {
  allMessages: Array<MessageTab>;
  messagesCount: Number;
  unreadMessages: Number;
  moreMessages: () => void;
  isCompany: Boolean;
  handleNotificationRead: (token, read) => void;
}

const Account: FC<AccountProps> = ({
  allMessages,
  messagesCount,
  unreadMessages,
  moreMessages,
  isCompany,
  handleNotificationRead
}) => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef<any>(null);
  const { userProfile, logout, userType } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [hasShownWarning, setHasShownWarning] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const accessRouteIsInsights = history.location.pathname.includes('/insights');

  const userIsSupport = userProfile.associatedCompanies[0]?.role === 'SUPPORT';
  const phoneNumber = process.env.REACT_APP_WHATSAPP_REDIRECT;

  const name = userType.isCandidate()
    ? userProfile?.user?.name
      ? userProfile?.user?.name
      : userProfile?.user?.email?.split('@')[0]
    : (
        userProfile?.associatedCompanies[0]?.company?.name
          ? userProfile?.associatedCompanies[0]?.company?.name
          : userProfile?.associatedCompanies[0]?.company?.email?.split('@')[0]
      )
    ? userProfile?.associatedCompanies[0]?.company?.businessName
      ? userProfile?.associatedCompanies[0]?.company?.businessName
      : userProfile?.associatedCompanies[0]?.company?.email?.split('@')[0]
    : '';

  const fantasyName = userType.isCompany()
    ? userProfile?.associatedCompanies[0]?.company?.fantasyName
    : name;
  const memberName =
    userType.isCompany() && userProfile?.user?.name !== ''
      ? userProfile?.user?.name
      : userProfile?.user?.email?.split('@')[0];

  useEffect(() => {
    if (
      isCompany &&
      !userProfile.associatedCompanies[0].company.businessName &&
      !hasShownWarning &&
      !accessRouteIsInsights
    ) {
      enqueueSnackbar(
        'Não foi possível preencher as informações de perfil da empresa automaticamente',
        {
          variant: 'error',
          autoHideDuration: 3500
        }
      );
      setHasShownWarning(true);
    }
  });

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const redirectLink = () => {
    let link;
    if (process.env.REACT_APP_PUBLIC_URL.includes('stg'))
      link = 'https://www.stg.gria.com.br';
    else if (
      process.env.REACT_APP_PUBLIC_URL.includes('dev') ||
      process.env.REACT_APP_PUBLIC_URL.includes('localhost')
    )
      link = 'https://www.dev.gria.com.br';
    else link = 'https://www.gria.com.br';
    return window.location.replace(link);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      redirectLink();
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Não foi possível sair', {
        variant: 'error'
      });
    }
  };

  let tempAvatarUrl = userProfile.user.avatarFileName;

  const [avatarUrl, setAvatarUrl] = useState(tempAvatarUrl);

  useEffect(() => {
    setAvatarUrl(tempAvatarUrl);
  }, [tempAvatarUrl]);

  const handleErrorAvatar = () => {
    setAvatarUrl(brokenImage);
  };

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true
  });
  const noImageCompany = `${process.env.REACT_APP_MEDIA_URL}/static/emails/assets/business.png`;
  const avatar = userProfile.user.hasAvatar
    ? convertImageSize(userProfile?.user.avatarFileName, 'avatar', 'small')
    : noImage;

  const companyId = userProfile?.associatedCompanies[0]?.company.id;

  const getBilingHistoryLink = async () => {
    try {
      const response = await companyService.getCompanyBillingHistoryLink(
        companyId
      );
      window.open(response.data.url, '_blank', 'noopener,noreferrer,popup=0');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {userProfile.associatedCompanies[0]?.role === 'SUPPORT' && (
        <SwitchCompanyModal
          open={showModal}
          onClose={() => setShowModal(false)}
        />
      )}
      {!isMediumScreen ? (
        <Menu
          elevation={3}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          keepMounted
          PaperProps={{ className: classes.popover }}
          anchorEl={ref.current}
          open={isOpen}
        >
          {isCompany && (
            <>
              <Typography className={classes.titleSection}>
                Perfil da empresa
              </Typography>

              {!accessRouteIsInsights ? (
                <>
                  <MenuItem
                    id="company-data-link-id"
                    component={RouterLink}
                    to={'/company/profile'}
                    onClick={handleClose}
                    className={classes.changeHover}
                  >
                    <Business className={classes.menuIcon} />
                    Dados da empresa
                  </MenuItem>
                  <MenuItem
                    id="company-data-link-id"
                    component={RouterLink}
                    to={'/company/profile/invoice-management'}
                    onClick={handleClose}
                    className={classes.changeHover}
                  >
                    <ReceiptLong className={classes.menuIcon} />
                    Faturamento
                  </MenuItem>
                  {['OWNER', 'ADMIN', 'SUPPORT'].includes(
                    userProfile.associatedCompanies[0]?.role
                  ) && (
                    <MenuItem
                      id="company-data-link-id"
                      component={RouterLink}
                      to={'/company/profile/user-management'}
                      onClick={handleClose}
                      className={classes.changeHover}
                    >
                      <SupervisedUserCircleIcon className={classes.menuIcon} />
                      Gerenciar usuários
                    </MenuItem>
                  )}
                </>
              ) : (
                <>
                  <MenuItem
                    id="company-data-link-id"
                    component={RouterLink}
                    to={'/insights/tests-history'}
                    onClick={handleClose}
                    className={classes.changeHover}
                  >
                    <Receipt className={classes.menuIcon} />
                    Histórico de testes
                  </MenuItem>
                </>
              )}
            </>
          )}
          {isCompany && (
            <Typography
              className={classes.titleSection}
              sx={isCompany ? { marginTop: '24px' } : {}}
            >
              Suporte
            </Typography>
          )}
          {isCompany && userIsSupport && (
            <>
              <MenuItem
                id="acess-support-link-id"
                component={RouterLink}
                to={'/company/support'}
                onClick={handleClose}
                className={classes.changeHover}
              >
                <AddCircle className={classes.menuIcon} />
                Criar conta
              </MenuItem>
              <MenuItem
                onClick={() => setShowModal(true)}
                className={classes.changeHover}
              >
                <Repeat className={classes.menuIcon} />
                Trocar de empresa
              </MenuItem>
              <MenuItem
                id="create-insights-role-id"
                component={RouterLink}
                to="/company/support/create-roles"
                onClick={handleClose}
                className={classes.changeHover}
              >
                <NoteAddOutlined className={classes.menuIcon} />
                Criar cargos
              </MenuItem>
              <MenuItem
                id="alter-insights-role-id"
                component={RouterLink}
                to="/company/support/alter-roles"
                onClick={handleClose}
                className={classes.changeHover}
              >
                <NoteAltOutlined className={classes.menuIcon} />
                Inserir cargos
              </MenuItem>

              <MenuItem
                id="edit-companies-support-id"
                component={RouterLink}
                to={'/company/support/edit-companies'}
                onClick={handleClose}
                className={classes.changeHover}
              >
                <Edit className={classes.menuIcon} />
                Editar empresas
              </MenuItem>
            </>
          )}
          {isCompany && accessRouteIsInsights && (
            <>
              <MenuItem
                id="suport-report-problem"
                onClick={() => {
                  window.open(
                    `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=Ol%C3%A1%21+Estou+entrando+em+contato+pois+estou+precisando+de+ajuda+com+a+plataforma%21&type=phone_number&app_absent=0`,
                    '_blank'
                  );
                  handleClose();
                }}
                className={classes.changeHover}
              >
                <WhatsApp className={classes.menuIcon} />
                Ajuda via WhatsApp
              </MenuItem>
            </>
          )}
          {isCompany && !accessRouteIsInsights && (
            <>
              <MenuItem
                id="suport-report-problem"
                onClick={() => {
                  window.open(
                    'https://gria.surveysparrow.com/s/Reporte-de-problema/tt-2vTzvuFvZRwcNBDfW1XkEy',
                    '_blank'
                  );
                  handleClose();
                }}
                className={classes.changeHover}
              >
                <WarningAmber className={classes.menuIcon} />
                Reportar problema
              </MenuItem>
            </>
          )}
          {isCompany && (
            <Typography
              className={classes.titleSection}
              sx={isCompany ? { marginTop: '24px' } : {}}
            >
              Seu perfil
            </Typography>
          )}

          <MenuItem
            id="personal-date-company-link-id"
            component={RouterLink}
            to={
              isCompany && accessRouteIsInsights
                ? '/insights/member/profile'
                : isCompany && !accessRouteIsInsights
                ? '/company/member/profile'
                : '/candidate/profile/view'
            }
            onClick={handleClose}
            className={classes.changeHover}
          >
            <Person className={classes.menuIcon} />
            Dados pessoais
          </MenuItem>

          {!accessRouteIsInsights && (
            <MenuItem
              id="acess-account-company-link-id"
              component={RouterLink}
              to={
                isCompany
                  ? '/company/profile/settings'
                  : '/candidate/profile/settings'
              }
              onClick={handleClose}
              className={classes.changeHover}
            >
              <LockOpenOutlined className={classes.menuIcon} />
              Acesso à conta
            </MenuItem>
          )}

          <MenuItem
            id="exit-account-company-id"
            onClick={handleLogout}
            className={classes.changeHover}
          >
            <LogoutOutlined className={classes.menuIcon} />
            Sair
          </MenuItem>
        </Menu>
      ) : (
        <Box style={{ display: 'flex' }}>
          <AccountMobile
            handleOpen={isOpen}
            handleClose={handleClose}
            setShowModal={() => setShowModal(true)}
          />
          {!accessRouteIsInsights && (
            <Messages
              allMessages={allMessages}
              messagesCount={messagesCount}
              unreadMessages={unreadMessages}
              moreMessages={moreMessages}
              isCompany={isCompany}
              handleNotificationRead={(token, read) =>
                handleNotificationRead(token, read)
              }
            />
          )}
        </Box>
      )}

      <Box
        id="information-company-button-id"
        display="flex"
        alignItems="center"
        // @ts-ignore
        ref={ref}
        onClick={handleOpen}
        component={ButtonBase}
      >
        <Paper sx={{ display: { xs: 'none', md: 'block' } }} elevation={0}>
          <Box display="flex" flexDirection="column">
            <Typography
              className={classes.memberName}
              variant="h6"
              color="inherit"
            >
              {!isCompany ? name : memberName}
            </Typography>
            {isCompany && (
              <Typography
                className={classes.textUser}
                variant="h6"
                color="inherit"
              >
                {fantasyName !== null ? fantasyName : name || ' '}
              </Typography>
            )}
          </Box>
        </Paper>
        <Avatar
          alt="User"
          className={classes.avatar}
          src={avatar}
          imgProps={{
            onError: (event: any) => {
              event.target.src = handleErrorAvatar();
            }
          }}
        >
          {avatarUrl && (
            <img
              src={brokenImage}
              className={classes.avatar}
              style={{ marginRight: 0 }}
            />
          )}
        </Avatar>
        {!isMediumScreen && (
          <ArrowDropDownOutlined
            onClick={handleOpen}
            className={classes.iconColor}
          />
        )}
      </Box>
    </>
  );
};

export default Account;
