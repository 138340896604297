import React, { FC, useEffect, useState } from 'react';

import {
  AccessAlarmOutlined,
  Close,
  Lock, RoomOutlined, Work
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Autocomplete, Box, Button, Checkbox, Dialog, Divider, FormControlLabel, Grid, IconButton, MenuItem, TextField, Typography, useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import * as Yup from 'yup';

import { useDispatch } from 'react-redux';
import brokenImage from 'src/assets/images/broken-image.svg';
import { ReactComponent as PersonIconOutlined } from 'src/assets/images/icons/person-icon-outlined.svg';
import noImage from 'src/assets/images/no-image.svg';
import Modal from 'src/components/ModalCurriculum/ModalCurriculum';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { candidateService } from 'src/services';
import { getOpportunities, handleApply } from 'src/slices/opportunities';
import type { Theme } from 'src/theme';
import errorMapper from 'src/utils/errorMapper';
import { getAddress } from 'src/utils/getAddress';
import getCoinType from 'src/utils/getCoinType';
import { removeRouteSearchParams } from 'src/utils/handleRoutes';
import {
  currencyArray, levelToLabel,
  workloadDescriptionToLabel
} from 'src/utils/helpers';
import { removedCurrencies } from 'src/views/opportunities/opportunitieCreateView/objectLabels';
import {
  generateSalaryOptions,
  getCorrectSalary,
  getSalaryLabel
} from '../../../../utils/salary';
import Questions from './Questions';

const availabilityOptions = [
  {
    label: 'Imediato',
    value: 'NOW'
  },
  {
    label: '1-2 semanas',
    value: 'ONE_OR_TWO_WEEKS'
  },
  {
    label: '1 mês',
    value: 'ONE_MONTH'
  },
  {
    label: 'Mais de 1 mês',
    value: 'MORE_THAN_ONE_MONTH'
  }
];

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  mediumSize: {
    '& .MuiDialog-paperWidthMd': {
      minWidth: 605,
      maxWidth: 605,
      padding: '0 20px 20px 20px',
      borderRadius: 4,
      [theme.breakpoints.down('md')]: {
        width: '100%',
        minWidth: 'auto',
        height: 'auto',
        maxHeight: 'calc(100% - 64px)'
      },

      [theme.breakpoints.down('sm')]: {
        margin: '100px 20px',
        maxHeight: '100vh'
      }
    },
    '& input': {
      color: theme.palette.table.grayPrimary
    },
    '& .MuiInputBase-root': {
      color: theme.palette.table.grayPrimary
    }
  },
  fullSize: {
    '& .MuiDialog-paperWidthMd': {
      minWidth: 605,
      maxWidth: 790,
      padding: '0 20px 20px 20px',
      borderRadius: 4,
      [theme.breakpoints.down('md')]: {
        height: 'auto',
        width: '100%',
        minWidth: 'auto'
      },

      [theme.breakpoints.down('sm')]: {
        padding: 0,
        margin: 20
      }
    }
  },
  titleBox: {
    paddingLeft: theme.spacing(2.5),
    passingRight: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 50,
    '& h5': {
      lineHeight: 1.25,

      '&::first-letter': {
        textTransform: 'uppercase'
      }
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0
    }
  },
  closeButton: {
    width: 40,
    height: 40,
    textAlign: 'end',

    color: 'rgba(0, 0, 0, 0.54)',

    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  logo: {
    maxWidth: 60,
    maxHeight: 60,
    borderRadius: '50%',
    objectFit: 'contain',
    '& .MuiAvatar-img': {
      height: 'auto',
      background: '#FFFFFF'
    }
  },
  majorInformationsBox: {
    margin: theme.spacing(2.5, 2.5, 2, 2.5),
    display: 'flex',
    height: 80,
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2.5, 2.5, 2, 0)
    }
  },
  opportunityInformation: {
    marginLeft: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    maxWidth: '85%'
  },
  companyName: {
    fontSize: 12,
    lineHeight: '14px',

    color: 'rgba(0, 0, 0, 0.87)'
  },
  opportunityTitle: {
    lineHeight: 1.71,
    letterSpacing: 0.15,

    color: 'rgba(0, 0, 0, 0.87)'
  },
  level: {
    marginLeft: theme.spacing(1.25),

    fontSize: 12,
    lineHeight: 2,

    color: '#000'
  },
  workloadDescription: {
    marginLeft: theme.spacing(1.25),

    fontSize: 12,
    lineHeight: 1.67,
    letterSpacing: 0.25,

    color: '#000'
  },
  locationBox: {
    marginLeft: theme.spacing(2.5),
    marginTop: theme.spacing(1.25),
    display: 'flex',
    alignItems: 'center'
  },
  location: {
    marginLeft: theme.spacing(1.25),

    fontSize: 12,
    lineHeight: 1.33,
    letterSpacing: 0.4,

    color: 'rgba(0, 0, 0, 0.87)'
  },
  checkbox: {
    '& svg': {
      fontSize: 17,

      color: theme.palette.switch.main
    },
    '&:hover': {
      background: 'none'
    },
    '&.MuiCheckbox-colorPrimary.Mui-checked:hover': {
      background: 'none'
    }
  },
  checkboxLabel: {
    fontWeight: 'normal',
    lineHeight: 1.71,
    color: '#7E7272'
  },
  gridContainer: {
    width: 'auto',
    margin: 0,
    padding: theme.spacing(0, 1, 0, 1)
  },
  gridItem: {
    '&.MuiGrid-item': {
      paddingBottom: 0,
      paddingLeft: 0
    }
  },
  textField: {
    minHeight: '5rem',
    width: 170,

    '& svg': {
      color: '#000000'
    },

    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  textFieldBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down(620)]: {
      alignItems: 'flex-start',
      flexDirection: 'column'
    }
  },
  textFieldRequestSalary: {
    minHeight: '5rem',
    width: '50%',
    marginRight: 16,
    '& svg': {
      color: '#000000'
    },
    [theme.breakpoints.down(620)]: {
      width: '100%',
      minHeight: '4rem'
    }
  },
  buttonsBox: {
    textAlign: 'end',

    '@media (max-width:370px)': {
      flexDirection: 'column'
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      display: 'flex',
      gap: 10
    }
  },
  buttonApply: {
    marginLeft: theme.spacing(2.5),

    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(0)
    }
  },
  button: {
    margin: '0 10px 20px 10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '10px 0'
    }
  },
  curriculumTitle: {
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: 0.25,
    color: '#000000',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 300
  },
  gridItemSmallScreen: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0px 15px 0px 15px',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '0px 15px 0px 15px'
    }
  },
  lineClamp: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    [theme.breakpoints.down('md')]: {
      display: 'initial'
    }
  },
  iconContainer: {
    width: '60px',
    height: '60px',
    padding: '12px',
    borderRadius: '48px',
    backgroundColor: 'rgba(63, 81, 181, 0.08)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  divider: {
    '&.MuiDivider-root': {
      margin: '0 -20px',
      color: '#0000001F'
    }
  }
}));

interface DialogOpportunityApplyProps extends RouteComponentProps {
  opportunity: any;
  open: boolean;
  onClose: () => void;
  className?: string;
  onSubmit?: (...args) => void;
  onError?: (...args) => void;
}

const DialogOpportunityApply: FC<DialogOpportunityApplyProps> = ({
  open,
  onClose,
  opportunity,
  className,
  history,
  staticContext,
  onSubmit: callback,
  onError,
  ...rest
}) => {
  const {
    id,
    city,
    country,
    skillLevel,
    state,
    title,
    workLoad,
    company,
    questions,
    requestSalary
  } = opportunity;

  const classes = useStyles();
  const fullScreen = useMediaQuery('(max-width:450px)');
  const [hasDeficiency, setDeficiency] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const address = getAddress(country, state, city);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const [currentView, setCurrentView] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [answersCopy, setAnswersCopy] = useState([]);
  const goToHistory = useHistory();
  const [getIdApplication, setGetIdApplication] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [shouldValidate, setShouldValidate] = useState(false);
  const hasAvatar = company.hasAvatar;

  const dispatch = useDispatch();

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true
  });

  const handleShowSuccessDialog = () => {
    setOpenSuccessDialog(true);
    onClose();
  };

  const removeFromRoute = (paramsToRemove: string[]) => {
    const searchParams = removeRouteSearchParams(
      history.location?.search,
      paramsToRemove
    );
    history.push(`/candidate/jobs${searchParams}`);
  };

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
    removeFromRoute(['modal', 'jobId']);
  };

  const handleClick = () => {
    dispatch(handleApply(opportunity.id));
  };

  const handleClose = () => {
    clearStates();
    removeFromRoute(['modal', 'jobId']);
    onClose();
  };

  const handleCheckbox = () => {
    setDeficiency(!hasDeficiency);
  };

  const clearStates = () => {
    setCurrentView(0);
  };

  useEffect(() => {
    if (questions && questions?.length > 0) {
      let anws = [];
      questions.map((question) => {
        let obj = {};
        obj['companyJobSelectiveQuestionsId'] = question.id;
        obj['answer'] = '';
        anws.push(obj);
        return question;
      });
      setAnswers(anws);
      setAnswersCopy(anws);
    }
  }, [questions]);

  const openCandidacy = () => {
    const pathname = `/candidate/applications/${getIdApplication}`;
    goToHistory.push({ pathname });
  };

  const getAvatar = () => {
    if (!opportunity.hideCompanyName) {
      if (company?.hasAvatar)
        return `${process.env.REACT_APP_MEDIA_URL}/companies/${company.id}/avatar-medium.png`;
      return noImage;
    }
    return null;
  };

  const submitIsDisabled = (values) => {
    let salaryClaim = getCorrectSalary(
      generateSalaryOptions(opportunity.currency, 0, 40000),
      values.salaryClaim
    );
    if (
      opportunity?.salaryBlock === true &&
      (salaryClaim < opportunity?.minSalary ||
        salaryClaim > opportunity?.maxSalary)
    )
      return true;
    return false;
  };

  return (
    <>
      <Dialog
        className={
          currentView === 0 || isMediumScreen
            ? classes.mediumSize
            : classes.fullSize
        }
        fullWidth
        fullScreen={fullScreen}
        maxWidth="md"
        open={open}
        onClose={handleClose}
      >
        <Box>
          <Box className={classes.titleBox}>
            <Typography variant="h5">candidatar-se</Typography>
            <IconButton
              id="begin-apply-intern-id"
              className={classes.closeButton}
              onClick={handleClose}
              size="large"
            >
              <Close />
            </IconButton>
          </Box>
          <Divider variant="fullWidth" className={classes.divider} />
          {currentView === 0 ? (
            <Box
              className={classes.majorInformationsBox}
              style={isMediumScreen ? { height: 130 } : {}}
            >
              {opportunity.hideCompanyName ? (
                <Box className={classes.iconContainer}>
                  <Lock style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
                </Box>
              ) : (
                <>
                  {hasAvatar ? (
                    <img
                      className={classes.logo}
                      alt="Logo da empresa"
                      src={getAvatar()}
                      onError={(event: any) => (event.target.src = brokenImage)}
                    />
                  ) : (
                    <Box
                      className={classes.iconContainer}
                      style={{ padding: '16px' }}
                    >
                      <Work style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
                    </Box>
                  )}
                </>
              )}
              <Box className={classes.opportunityInformation}>
                <Typography
                  className={`${classes.companyName} ${classes.lineClamp}`}
                >
                  {company &&
                    (company.fantasyname !== null
                      ? company.fantasyName
                      : company.name)}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={`${classes.opportunityTitle} ${classes.lineClamp}`}
                >
                  {title}
                </Typography>
                <Box
                  display="flex"
                  flexDirection={isMediumScreen ? 'column' : 'row'}
                >
                  <Box display="flex" alignItems="center">
                    <PersonIconOutlined />
                    <Typography className={classes.level}>
                      {levelToLabel[skillLevel]}
                    </Typography>
                  </Box>
                  <Box
                    ml={isMediumScreen ? 0 : 2.5}
                    display="flex"
                    alignItems="center"
                  >
                    <AccessAlarmOutlined
                      style={{ color: 'rgba(0, 0, 0, 0.54', fontSize: 12 }}
                    />
                    <Typography className={classes.workloadDescription}>
                      {workloadDescriptionToLabel[workLoad]}
                    </Typography>
                  </Box>
                  <Box
                    ml={isMediumScreen ? 0 : 2.5}
                    display="flex"
                    alignItems="center"
                  >
                    <RoomOutlined style={{ color: '#828282', fontSize: 12 }} />
                    <Typography className={classes.location}>
                      {address}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Questions
              questions={questions}
              onChange={(value, index) => {
                answers[index].answer = value;
                setAnswers([...answers]);
              }}
              answers={answers}
              editable={true}
              setIsValid={setIsValid}
            />
          )}
        </Box>

        <Formik
          initialValues={{
            salaryClaim: '',
            availability: '',
            deficiency: '',
            currency: currencyArray.find((c) => c.value === 'BRL').value,
            salaryCurrent: '',
            opportunityCurrency: currencyArray.find(
              (c) => c.value === opportunity.currency
            ).label,
            answers: [],
            submit: null
          }}
          validationSchema={Yup.object().shape({
            salaryClaim: Yup.string().required(
              'A pretensão salarial é obrigatória'
            ),
            availability: Yup.string().required(
              'Selecione uma disponibilidade para início'
            ),
            deficiency:
              hasDeficiency &&
              Yup.string()
                .max(165)
                .matches(
                  /[a-zA-Z]/,
                  'O tipo de deficiência deve conter letras.'
                )
                .required('O tipo de deficiência é obrigatório')
          })}
          onSubmit={async (values, { setStatus, setErrors, setSubmitting }) => {
            try {
              const {
                salaryClaim,
                availability,
                deficiency,
                currency,
                salaryCurrent
              } = values;

              const formData = new FormData();
              formData.append('availability', availability);
              formData.append('answers', JSON.stringify(answers));
              formData.append(
                'pretenseSalary',
                getCorrectSalary(
                  generateSalaryOptions(opportunity.currency, 0, 40000),
                  salaryClaim
                ).toString()
              );
              formData.append('deficiency', deficiency);

              if (requestSalary) {
                formData.append('currency', currency);
                formData.append(
                  'salaryCurrent',
                  getCorrectSalary(
                    generateSalaryOptions(currency, 0, 40000),
                    salaryCurrent
                  ).toString()
                );
              }

              const response = await candidateService.applyOpportunity(
                id,
                formData
              );
              setGetIdApplication(response.data.id);
              clearStates();
              if (callback) callback(response);
              else if (isMountedRef.current) {
                handleShowSuccessDialog();
                handleClick();
                setStatus({ success: true });
                setSubmitting(false);
              }

              const params = removeRouteSearchParams(
                history.location?.search, ['modal', 'jobId']
              ).replaceAll('?', '');
              
              dispatch(
                getOpportunities(`${params}&limit=12`)
              );
            } catch (err) {
              if (err.message.includes('You already applied for this job')) {
                enqueueSnackbar('Você já se candidatou a esta vaga', {
                  variant: 'error'
                });
                handleClose();
              } else if (
                err.message.includes('Opportunity With Different Open Status')
              ) {
                enqueueSnackbar('Esta vaga não está mais aberta', {
                  variant: 'error'
                });
                handleClose();
              } else if (
                err.message.includes('answers contains only special characters')
              ) {
                setErrors({
                  submit:
                    'As respostas não devem conter apenas caracteres especiais'
                });
                setShouldValidate(true);
              } else {
                enqueueSnackbar(
                  'Não foi possível se candidatar para essa vaga, verifique os campos novamente.',
                  {
                    variant: 'error'
                  }
                );
                setErrors({ submit: errorMapper(err.message) });
              }

              console.error(err);
              setStatus({ success: false });
              setSubmitting(false);
              if (onError) onError(err);
            }
          }}
        >
          {({
            handleSubmit,
            touched,
            errors,
            isSubmitting,
            handleBlur,
            handleChange,
            values,
            setErrors,
            setFieldValue,
            setTouched,
            submitForm
          }) => (
            <form
              noValidate
              className={clsx(classes.root, className)}
              onSubmit={handleSubmit}
              {...rest}
            >
              {currentView === 0 ? (
                <Grid
                  container
                  xs={12}
                  item
                  className={classes.gridContainer}
                  spacing={2}
                >
                  <Box
                    className={classes.gridItem}
                    style={{ padding: '15px 0px 0px 0px' }}
                  >
                    <Typography className={classes.curriculumTitle}>
                      Pretensão salarial
                    </Typography>
                  </Box>

                  <Grid
                    item
                    xs={12}
                    className={`${classes.gridItem} ${classes.textFieldBox}`}
                  >
                    <TextField
                      error={Boolean(
                        touched.opportunityCurrency &&
                          errors.opportunityCurrency
                      )}
                      onBlur={handleBlur}
                      helperText={
                        touched.opportunityCurrency &&
                        errors.opportunityCurrency
                      }
                      value={values.opportunityCurrency || ''}
                      label="Moeda"
                      variant="outlined"
                      name="opportunityCurrency"
                      className={classes.textFieldRequestSalary}
                      disabled
                    ></TextField>

                    <Autocomplete
                      id="minSalary"
                      className={classes.textFieldRequestSalary}
                      disableClearable
                      filterOptions={createFilterOptions({
                        matchFrom: 'start',
                        stringify: (option) => String(option.value)
                      })}
                      getOptionLabel={(option) =>
                        typeof option === 'string' ? option : option.label
                      }
                      isOptionEqualToValue={(option) =>
                        option.label === values.salaryClaim
                      }
                      options={generateSalaryOptions(
                        opportunity.currency,
                        0,
                        40000
                      )}
                      onChange={(_, value) => {
                        setFieldValue(
                          'salaryClaim',
                          value === null
                            ? ''
                            : typeof value === 'string'
                            ? value
                            : value.label
                        );
                      }}
                      onBlur={() => setTouched({ salaryClaim: true })}
                      value={values.salaryClaim}
                      renderInput={(params) => (
                        <TextField
                          error={Boolean(
                            touched.salaryClaim && errors.salaryClaim
                          )}
                          helperText={touched.salaryClaim && errors.salaryClaim}
                          id="salaryClaim"
                          name="salaryClaim"
                          label="Pretensão salarial"
                          required
                          variant="outlined"
                          disabled={isSubmitting}
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off'
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {requestSalary ? (
                    <>
                      <Box
                        className={classes.gridItem}
                        style={{ padding: '5px 0px 15px 0px' }}
                      >
                        <Typography className={classes.curriculumTitle}>
                          Último salário
                        </Typography>
                      </Box>
                      <Box
                        className={`${classes.gridItem} ${classes.textFieldBox}`}
                      >
                        {requestSalary ? (
                          <TextField
                            error={Boolean(touched.currency && errors.currency)}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              const currentCurrency = e.target.value;
                              handleChange(e);
                              setFieldValue(
                                'salaryCurrent',
                                getSalaryLabel(
                                  generateSalaryOptions(
                                    currentCurrency,
                                    0,
                                    40000
                                  ),
                                  getCorrectSalary(
                                    generateSalaryOptions(
                                      values.currency,
                                      0,
                                      40000
                                    ),
                                    values.salaryCurrent
                                  )
                                )
                              );
                            }}
                            helperText={touched.currency && errors.currency}
                            value={values.currency || ''}
                            label="Moeda"
                            name="currency"
                            variant="outlined"
                            select
                            className={classes.textFieldRequestSalary}
                          >
                            {currencyArray
                              .filter(
                                (option) =>
                                  !removedCurrencies.includes(option.value)
                              )
                              .map((option, id) => (
                                <MenuItem key={id} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                          </TextField>
                        ) : (
                          <></>
                        )}

                        {requestSalary ? (
                          <Autocomplete
                            id="salaryCurrentId"
                            className={classes.textFieldRequestSalary}
                            disableClearable
                            filterOptions={createFilterOptions({
                              matchFrom: 'start',
                              stringify: (option) => String(option.value)
                            })}
                            getOptionLabel={(option) =>
                              typeof option === 'string' ? option : option.label
                            }
                            isOptionEqualToValue={(option) =>
                              option.label === values.salaryCurrent
                            }
                            options={generateSalaryOptions(
                              values.currency,
                              0,
                              40000
                            )}
                            onChange={(_, value) => {
                              setFieldValue(
                                'salaryCurrent',
                                value === null
                                  ? ''
                                  : typeof value === 'string'
                                  ? value
                                  : value.label
                              );
                            }}
                            onBlur={() => setTouched({ salaryCurrent: true })}
                            value={values.salaryCurrent}
                            renderInput={(params) => (
                              <TextField
                                error={Boolean(
                                  touched.salaryCurrent && errors.salaryCurrent
                                )}
                                helperText={
                                  touched.salaryCurrent && errors.salaryCurrent
                                }
                                id="salaryCurrent"
                                name="salaryCurrent"
                                label="Último salário"
                                variant="outlined"
                                disabled={isSubmitting}
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'off'
                                }}
                              />
                            )}
                          />
                        ) : (
                          <></>
                        )}
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}

                  <Grid
                    item
                    xs={12}
                    style={{
                      padding: `${
                        isMediumScreen ? '16px 0px 16px 0px' : '0px 0px 16px'
                      }`
                    }}
                  >
                    <Typography className={classes.curriculumTitle}>
                      Disponibilidade
                    </Typography>
                  </Grid>

                  <Grid style={isMediumScreen ? { width: '100%' } : {}}>
                    <TextField
                      className={classes.textFieldRequestSalary}
                      select
                      error={Boolean(
                        touched.availability && errors.availability
                      )}
                      helperText={touched.availability && errors.availability}
                      disabled={isSubmitting}
                      fullWidth
                      variant="outlined"
                      label="Disponibilidade para início"
                      name="availability"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.availability}
                      required
                      style={
                        isMediumScreen
                          ? { width: '100%', minHeight: 56 }
                          : { width: '259px', minHeight: 56, marginLeft: 0 }
                      }
                    >
                      {availabilityOptions.map(({ label, value }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item sm={10} xs={12} className={classes.gridItem}>
                    <FormControlLabel
                      className={classes.checkboxLabel}
                      control={
                        <Checkbox
                          checked={hasDeficiency}
                          className={classes.checkbox}
                          disableRipple
                        />
                      }
                      label="Declaração pessoa com deficiência (PCD)"
                      onChange={handleCheckbox}
                    />
                  </Grid>
                  <Grid item sm={10} xs={12} className={classes.gridItem}>
                    {hasDeficiency && (
                      <TextField
                        className={classes.textFieldRequestSalary}
                        error={Boolean(touched.deficiency && errors.deficiency)}
                        helperText={touched.deficiency && errors.deficiency}
                        disabled={isSubmitting}
                        fullWidth
                        label="Necessito de acessibilidade para"
                        name="deficiency"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="string"
                        value={values.deficiency}
                        inputProps={{ maxLength: 165 }}
                        style={isMediumScreen ? null : { width: 400 }}
                      />
                    )}
                  </Grid>
                  {opportunity.salaryBlock && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        padding: `${
                          isMediumScreen ? '16px 0px 16px 0px' : '0px 0px 16px'
                        }`
                      }}
                    >
                      <Alert variant="outlined" severity="info">
                        Esta vaga só aceita candidaturas dentro da faixa
                        salarial (
                        {`${getCoinType(
                          opportunity.currency,
                          opportunity.minSalary
                        )} - ${getCoinType(
                          opportunity.currency,
                          opportunity.maxSalary
                        )}`}
                        )
                      </Alert>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <></>
              )}
              {currentView === 1 && !isValid && errors.submit && (
                <Box mt={2.5} mr={2.5} ml={2.5}>
                  <Alert severity="error">{errors.submit}</Alert>
                </Box>
              )}
              <Box
                className={classes.buttonsBox}
                style={
                  isMediumScreen
                    ? { flexDirection: 'column-reverse', marginTop: 10 }
                    : null
                }
              >
                <Button
                  id="cancel-apply-id"
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                  style={isMediumScreen ? { flex: 1 } : { marginRight: 10 }}
                >
                  Cancelar
                </Button>
                {currentView === 1 ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setCurrentView(0);
                      setAnswersCopy(answers);
                    }}
                    style={isMediumScreen ? { flex: 1 } : null}
                  >
                    Voltar
                  </Button>
                ) : (
                  <></>
                )}
                {currentView === 0 && questions?.length > 0 ? (
                  <Button
                    className={classes.buttonApply}
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => {
                      setCurrentView(1);
                    }}
                    style={isMediumScreen ? { flex: 1 } : { width: 175 }}
                    disabled={
                      submitIsDisabled(values) ||
                      values.availability === '' ||
                      (hasDeficiency && values.deficiency?.length <= 0) ||
                      !values.salaryClaim
                    }
                  >
                    Próximo
                  </Button>
                ) : (
                  <LoadingButton
                    loading={isSubmitting}
                    id="apply-opport-id"
                    className={classes.buttonApply}
                    variant="contained"
                    color="primary"
                    disabled={
                      submitIsDisabled(values) ||
                      isSubmitting ||
                      values.availability === '' ||
                      (hasDeficiency && values.deficiency?.length <= 0) ||
                      !values.salaryClaim ||
                      (shouldValidate && !isValid)
                    }
                    onClick={() => {
                      submitForm();
                    }}
                    style={isMediumScreen ? { flex: 1 } : null}
                  >
                    Me candidatar
                  </LoadingButton>
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Dialog>
      <Modal
        open={openSuccessDialog}
        title="Candidatura realizada com sucesso!"
        subtitle="A sua candidatura foi realizada, veja quais são as próximas etapas."
        btnLeftLabel="Cancelar"
        btnRightLabel="Visualizar candidatura"
        onLeftButtonClick={() => handleCloseSuccessDialog()}
        onRightButtonClick={openCandidacy}
      />
    </>
  );
};

export default withRouter(DialogOpportunityApply);
