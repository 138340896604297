import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated, userType, isLogout, userProfile } = useAuth();
  const history = useHistory();
  const path = history.location.pathname;
  const params = history.location.search;
  const AUTH_URL = process.env.REACT_APP_AUTH_URL;
  const userIsInsights =
    userProfile?.associatedCompanies[0]?.company?.typeService?.includes(
      'insights'
    );
  if (!isAuthenticated && !isLogout) {
    const encodedPath = window.btoa(path + params);
    window.location.href = `${AUTH_URL}?action=application&reference=${encodedPath}`;
  } else if (!userType.isCandidate()) {
    if (userType.isCompany() && !userIsInsights) {
      return <Redirect to={{ pathname: '/company/jobs' }} />;
    }
    if (userType.isCompany() && userIsInsights) {
      return <Redirect to={{ pathname: '/insights' }} />;
    }

    return <Redirect to="/404" />;
  }

  if (userType.isCandidate()) {
    return <>{children}</>;
  } else {
    return <Redirect to="/404" />;
  }
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
