import {
  Box,
  Collapse,
  Divider,
  Grid,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
  useTheme
} from '@mui/material';
import React, { FC, useState } from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import type { Theme } from 'src/theme';
import getSocialImageDrawer from 'src/utils/getSocialImageDrawer';
import { HideItem } from 'src/components/molecules/HideItem';
import { getShortAddress } from 'src/utils/getShortAddress';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: '4px',
    '& .MuiTabPanel-root': {
      border: '1px solid rgba(0, 0, 0, 0.13)',
      borderRadius: '1px'
    },
    '& .MuiTypography-root, & .MuiInputLabel-formControl': {
      fontFamily: 'Nunito',
      fontWeight: 400
    },
    backgroundColor: theme.palette.background.default,
    color: '#000',
    [theme.breakpoints.down('xs')]: {}
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingRight: '16px'
  },
  infoBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid rgba(0, 0, 0, 0.13)',
    borderRadius: '4px',
    padding: '12px 0px 12px 12px'
  },
  title: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '11px',
    lineHeight: '190%',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.6)',
    marginRight: '5px'
  },
  info: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  cropText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.table.blackSecondary,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.10000000149011612px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 13
    }
  },
  hyperLink: {
    color: '#1071e6',
    textDecorationColor: '#1071e6',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  truncate: {
    marginRight: '13px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 200,
    backgroundColor: 'transparent',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.chip.main
    }
  },
  pcdBox: {},
  pcdTitle: {
    fontFamily: 'Nunito',
    fontStyle: 'Bold',
    fontSize: '14px',
    lineHeight: '157%',
    align: 'left',
    verticalAlign: 'top',
    letterSpacing: '0.1px'
  },
  divider: {
    width: '100%',
    margin: '12px 0px 12px 0px'
  },
  expandButton: {
    marginRight: '8px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  pcdText: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: 'Nunito',
    fontStyle: 'Regular',
    fontSize: '14px',
    lineHeight: '143%',
    align: 'left',
    verticalAlign: 'center',
    letterSpacing: '0.15px'
  },
  boxDeficiencyInfo: {
    border: '1px solid rgba(0, 0, 0, 0.13)',
    borderRadius: '4px',
    background: theme.palette.background.default,
    padding: '16px'
  }
}));

interface PersonalDataTableProps {
  infoApplication: any;
  isLoading?: boolean;
  premiumSubscriber?: boolean;
}

export const PersonalDataTable: FC<PersonalDataTableProps> = ({
  infoApplication,
  isLoading,
  premiumSubscriber
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const addressLabel = getShortAddress(
    infoApplication?.candidate?.country || infoApplication?.country,
    infoApplication?.candidate?.state || infoApplication?.state,
    infoApplication?.candidate?.city || infoApplication?.city
  );

  const cellPhone =
    infoApplication?.candidate?.cellPhone || infoApplication?.cellPhone;
  const socialNetworks =
    infoApplication?.candidate?.socialNetworks ||
    infoApplication?.socialNetworks;
  const [open, setOpen] = useState(true);

  const handleClickOpen = (opption) => {
    open ? setOpen(false) : setOpen(true);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.infoBox}>
        {(infoApplication?.candidate?.birthDate ||
          infoApplication?.birthDate) && (
          <>
            <Box className={classes.contentBox}>
              <Typography variant="caption" className={classes.title}>
                IDADE
              </Typography>
              {premiumSubscriber ? (
                <Typography variant="caption" className={classes.info}>
                  {infoApplication ? (
                    moment().diff(
                      infoApplication?.candidate?.birthDate ||
                        infoApplication?.birthDate,
                      'years'
                    ) + ' anos'
                  ) : (
                    <Skeleton variant="text" width={40} />
                  )}
                </Typography>
              ) : (
                <HideItem hideStyle={{ minWidth: '52px', height: '20px' }} />
              )}
            </Box>
            <Divider variant="fullWidth" className={classes.divider} />
          </>
        )}
        <Box className={classes.contentBox}>
          <Typography variant="caption" className={classes.title}>
            LOCALIZAÇÃO
          </Typography>
          <Typography className={classes.info}>
            {addressLabel ? (
              addressLabel
            ) : (
              <Skeleton variant="text" width={120} />
            )}
          </Typography>
        </Box>
        <Divider variant="fullWidth" className={classes.divider} />
        <Box className={classes.contentBox}>
          <Typography variant="caption" className={classes.title}>
            EMAIL
          </Typography>
          {(infoApplication?.candidate?.email || infoApplication?.email) &&
          premiumSubscriber ? (
            <Typography
              component="a"
              href={`mailto:${
                infoApplication?.candidate?.email ?? infoApplication?.email
              }`}
              variant="caption"
              className={`${classes.info} ${classes.cropText} ${classes.hyperLink}`}
            >
              {infoApplication ? (
                infoApplication?.candidate?.email || infoApplication?.email
              ) : (
                <Skeleton variant="text" width={120} />
              )}
            </Typography>
          ) : (
            <HideItem hideStyle={{ minWidth: '106px', height: '20px' }} />
          )}
        </Box>
        {cellPhone && (
          <>
            <Divider variant="fullWidth" className={classes.divider} />
            <Box className={classes.contentBox}>
              <Typography variant="caption" className={classes.title}>
                TELEFONE
              </Typography>
              {premiumSubscriber ? (
                <Typography className={classes.info}>
                  {cellPhone ? (
                    cellPhone
                  ) : (
                    <Skeleton variant="text" width={120} />
                  )}
                </Typography>
              ) : (
                <HideItem hideStyle={{ minWidth: '117px', height: '20px' }} />
              )}
            </Box>
          </>
        )}
        {!isLoading && socialNetworks?.length ? (
          <>
            <Divider variant="fullWidth" className={classes.divider} />
            <Box className={classes.contentBox}>
              <Typography variant="caption" className={classes.title}>
                REDES SOCIAIS
              </Typography>
              {premiumSubscriber ? (
                <Box style={{ display: 'flex' }}>
                  {socialNetworks.map(({ name, link }, i) => (
                    <Grid item key={i}>
                      <Link
                        component="a"
                        href={link}
                        target="_blank"
                        style={{ textDecoration: 'none', marginRight: '12px' }}
                      >
                        <img src={getSocialImageDrawer(name)} />
                      </Link>
                    </Grid>
                  ))}
                </Box>
              ) : (
                <Box style={{ display: 'flex', gap: '12px' }}>
                  <HideItem hideStyle={{ minWidth: '20px', height: '20px' }} />
                  <HideItem hideStyle={{ minWidth: '20px', height: '20px' }} />
                  <HideItem hideStyle={{ minWidth: '20px', height: '20px' }} />
                  <HideItem hideStyle={{ minWidth: '20px', height: '20px' }} />
                </Box>
              )}
            </Box>
          </>
        ) : (
          <></>
        )}
      </Box>
      {infoApplication?.deficiency && (
        <>
          <ListItemButton
            style={{
              background: 'rgba(250, 250, 250, 1)',
              padding: '0px',
              paddingTop: '20px',
              paddingBottom: '12px'
            }}
            onClick={() => handleClickOpen(1)}
          >
            <Box>
              {open ? (
                <ExpandLess className={classes.expandButton} />
              ) : (
                <ExpandMore className={classes.expandButton} />
              )}
            </Box>
            <ListItemText
              style={{ color: theme.palette.text.secondary }}
              primary="Declaração pessoa com deficiência (PCD)"
            />
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={classes.pcdBox}>
              <Box className={classes.boxDeficiencyInfo}>
                <Typography className={classes.pcdText}>
                  {infoApplication?.deficiency}
                </Typography>
              </Box>
            </Box>
          </Collapse>
        </>
      )}
    </Box>
  );
};
