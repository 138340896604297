import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Divider,
  Fade,
  Modal,
  TextField,
  Typography
} from '@mui/material';
import type { FC } from 'react';
import React, { useState } from 'react';

import { LoadingButton } from '@mui/lab';

import {
  AccountCircle,
  Close,
  SupervisedUserCircle
} from '@mui/icons-material';

import { Skeleton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import useAuth from 'src/hooks/useAuth';
import { companyService } from 'src/services';
import type { Theme } from 'src/theme';

import * as Yup from 'yup';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiTypography-root, & .MuiInputLabel-formControl': {
      fontFamily: 'Nunito',
      fontWeight: 400
    },
    backgroundColor: theme.palette.background.default,
    color: '#000',
    [theme.breakpoints.down('xs')]: {}
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalWrapper: {
    height: 'min-content',
    maxWidth: '564px',
    width: '100%',
    borderRadius: 4,
    backgroundColor: '#fff',
    border: '0.5px solid #c4c4c4',
    textAlign: 'center',
    '&:focus': {
      outline: 'none'
    },
    [theme.breakpoints.down('md')]: {
      margin: '16px'
    }
  },
  modalWrapperUpdate: {
    '@media (max-height: 775px)': {
      overflow: 'scroll',
      height: '100%'
    },
    [theme.breakpoints.down(640)]: {
      margin: 10
    }
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 24px 24px',
    textAlign: 'left'
  },
  title: {
    fontSize: '20px',
    margin: '16px 0px 8px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Nunito'
  },
  subtitle: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Nunito'
  },
  greyText: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 14,
    fontWeight: 400
  },
  comment: {
    '& > label': {
      transform: 'translate(14px, -6px) scale(0.75)',
      backgroundColor: '#FFFFFF'
    },
    '& > div > fieldset > legend': {
      maxWidth: 1000
    }
  },
  avatarBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '-10px',
    '& > svg': {
      color: '#0000008A'
    },
    '& .MuiAvatar-img': {
      width: '17px',
      height: '17px',
      borderRadius: '50%'
    }
  },
  radioText: {
    '& > span': {
      fontSize: 15
    }
  },
  buttonsBox: {
    '& :last-child': {
      color: '#FFFFFF'
    },
    '& :first-child': {
      border: 0
    },
    marginTop: 16,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      gap: 16
    }
  },
  button: {
    padding: '11px 16px 13px',
    height: 38,
    [theme.breakpoints.down('xs')]: {
      margin: 0
    }
  },
  stepName: {
    maxWidth: 150,
    wordBreak: 'break-all'
  },
  checkboxText: {
    '& > span': {
      fontSize: '15px',
      fontWeight: 400
    }
  },
  span: {
    fontFamily: 'Nunito',
    fontSize: '12px',
    color: theme.palette.text.secondary,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.4px',
    textAlign: 'end'
  },
  boxNameAndSurname: {
    fontSize: 14,
    fontFamily: 'Nunito',
    color: theme.palette.text.primary,
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',

    position: 'relative',
    '&>p.show': {
      width: '100%',
      marginBottom: '13px'
    },
    '&>p.hide': {
      height: '18px',
      overflow: 'hidden',
      width: 'calc(100% - 60px)',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }
  },
  showHide: {
    minHeight: '18px',
    whiteSpace: 'nowrap',
    color: '#2196F3',
    textDecoration: 'underline',
    cursor: 'pointer',
    display: 'block',
    position: 'absolute',
    right: '37px',
    bottom: '0px',
    height: '16px',
    backgroundColor: 'white'
  }
}));

interface ModalAproveOrReproveProps {
  open?: boolean;
  onClose?: () => void;
  loading?: boolean;
  application?: any;
  isCandidateProfile?: boolean;
  applicationIdCandadidateProfile?: string;
  updateSituation?: (
    newSituation: any,
    newEvents: any,
    applicationId: string[] | string
  ) => void;
  updateApplication?: (
    applicationList: { situation: any; id: any; events: any }[]
  ) => void;
  isNotification?: boolean;
  isOpenDrawerToMessage?: boolean;
}

const ModalAproveOrReprove: FC<ModalAproveOrReproveProps> = ({
  open,
  onClose,
  loading,
  application,
  isCandidateProfile,
  applicationIdCandadidateProfile,
  updateSituation,
  updateApplication,
  isOpenDrawerToMessage
}) => {
  const classes = useStyles();
  const history = useHistory();
  const modalName = new URLSearchParams(history.location.search).get('modal');
  const [modalType, setModalType] = useState(modalName || '');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { userType, userProfile } = useAuth();
  const [expandedComment, setExpandedComment] = useState(false);
  const isCompany = userType?.isCompany();
  const [isLoading, setIsLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);

  const handleExpanded = () => {
    setExpandedComment(!expandedComment);
  };

  const getModalTitle = () => {
    switch (modalName) {
      case 'hire':
        return 'Aprovação de candidatos';
      case 'reprove':
        return 'Reprovação de candidatos';
      case 'undo':
        return 'Desfazer reprovação de candidato';
      case 'message':
        return `Mensagem ${isCompany ? 'ao candidato' : 'à empresa'}`;
      case 'quitter':
        return 'Desfazer desistência de candidato';
      default:
        return <Skeleton variant="text" width={120} />;
    }
  };
  const getModalStatus = () => {
    switch (modalName) {
      case 'hire':
        return 'APPROVED';
      case 'reprove':
        return 'UNAPPROVED';
      default:
        return 'REGISTERED';
    }
  };
  const getModalSnackbarMessage = () => {
    switch (modalName) {
      case 'hire':
        return (
          <p>
            Ação de <b>aprovar candidatos</b> realizada com sucesso
          </p>
        );
      case 'reprove':
        return (
          <p>
            Ação de <b>reprovar candidatos</b> realizada com sucesso
          </p>
        );
      case 'undo':
        return (
          <p>
            Ação de <b>desfazer reprovação de candidatos</b> realizada com
            sucesso
          </p>
        );
      case 'message':
        return (
          <p>
            Ação de{' '}
            <b>enviar comentário {isCompany ? 'ao candidato' : 'à empresa'}</b>{' '}
            realizada com sucesso
          </p>
        );
      case 'quitter':
        return (
          <p>
            Ação de <b>desfazer desistência de candidatos</b> realizada com
            sucesso
          </p>
        );
      default:
        return '-';
    }
  };

  const getModalAction = () => {
    switch (modalName) {
      case 'hire':
        return 'aprovar';
      case 'reprove':
        return 'reprovar';
      case 'undo':
        return 'desfazer reprovação';
      case 'message':
        return 'enviar mensagem';
      case 'quitter':
        return 'desfazer desistência';
      default:
        return '-';
    }
  };

  const getModalButtonColor = () => {
    switch (modalName) {
      case 'hire':
        return 'success';
      case 'reprove':
        return 'error';
      default:
        return 'primary';
    }
  };

  const getModalSubmitText = () => {
    switch (modalName) {
      case 'hire':
        return 'Aprovar';
      case 'reprove':
        return 'Reprovar';
      case 'undo':
        return 'Desfazer';
      case 'message':
        return 'Enviar';
      case 'quitter':
        return 'Desfazer';
      default:
        return '-';
    }
  };

  const getFullName = () => {
    if (application?.length > 0 && !isCandidateProfile) {
      return application
        ?.map((app) => {
          const nameAndSurname = `${app.name} ${app.surname} `;
          return nameAndSurname;
        })
        .join(', ');
    } else if (isCandidateProfile) {
      return `${application[0]?.candidate?.name} ${application[0]?.candidate?.surname}`;
    } else if (isOpenDrawerToMessage) {
      return `${application?.candidate?.name} ${application?.candidate?.surname}`;
    } else {
      return `${application?.name} ${application?.surname} `;
    }
  };

  const commentValidationToggle = {
    true: Yup.string()
      .matches(/[a-zA-Z]/, 'A mensagem deve conter letras.')
      .nullable(),
    false: Yup.string()
      .matches(/[a-zA-Z]/, 'A mensagem deve conter letras.')
      .required('Mensagem é um campo obrigatório.')
  };

  const getApplicationIds = () => {
    if (application?.length > 0) {
      return application?.map((candidate) => {
        return isCandidateProfile ? candidate?.id : candidate.application.id;
      });
    } else if (isOpenDrawerToMessage) {
      return application?.id;
    } else {
      return application?.application.id;
    }
  };

  const getIds = () => {
    if (application?.length > 0) {
      return application?.map((app) => {
        return isCandidateProfile ? app?.candidateId : app.id;
      });
    } else if (isOpenDrawerToMessage) {
      return application?.candidate?.id;
    } else {
      return application?.id;
    }
  };

  const applicationHasAvatar = () => {
    if (application?.length > 0) {
      return isCandidateProfile
        ? application[0]?.candidate?.hasAvatar
        : application[0].hasAvatar;
    } else if (isOpenDrawerToMessage) {
      return application?.candidate?.hasAvatar;
    } else {
      return application?.hasAvatar;
    }
  };

  return (
    <Box className={clsx(classes.root)}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        onClose={onClose}
      >
        <Fade in={open}>
          <Box
            className={clsx(
              classes.modalWrapper,
              modalType === 'update' && classes.modalWrapperUpdate
            )}
          >
            <Box className={classes.modalContent}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  component="h3"
                  variant="h5"
                  className={classes.title}
                >
                  {getModalTitle()}
                </Typography>
                <Close
                  onClick={onClose}
                  style={{
                    fontSize: '24px',
                    color: '#0000008A',
                    cursor: 'pointer'
                  }}
                />
              </Box>

              {loading ? (
                <Box display="flex" width="100%" style={{ marginBottom: 25 }}>
                  <Skeleton
                    style={{ margin: 'auto 8px auto 0px' }}
                    variant="circular"
                    width={40}
                    height={40}
                  />
                  <div style={{ flex: 1 }}>
                    <Skeleton height={40} />
                  </div>
                </Box>
              ) : (
                <Box style={{ display: 'flex', marginBottom: 24 }}>
                  {application?.length > 1 ? (
                    <></>
                  ) : isCompany ? (
                    applicationHasAvatar() ? (
                      <Box className={classes.avatarBox}>
                        <Avatar
                          src={`${
                            process.env.REACT_APP_MEDIA_URL
                          }/users/${getIds()}/avatar-small.png`}
                        />
                      </Box>
                    ) : (
                      <AccountCircle
                        fontSize="small"
                        style={{ marginRight: '6px', color: '#0000008A' }}
                      />
                    )
                  ) : application[0]?.job?.company?.hasAvatar ? (
                    <Box className={classes.avatarBox}>
                      <Avatar
                        src={`${process.env.REACT_APP_MEDIA_URL}/companies/${application[0]?.job?.company?.id}/avatar-small.png`}
                      />
                    </Box>
                  ) : (
                    <SupervisedUserCircle
                      fontSize="small"
                      style={{ marginRight: '6px', color: '#0000008A' }}
                    />
                  )}
                  {isCompany ? (
                    <Box className={classes.boxNameAndSurname}>
                      <p className={!expandedComment ? 'hide' : 'show'}>
                        {application && getFullName()}
                      </p>
                      {application && application?.length > 1 && (
                        <p
                          className={classes.showHide}
                          onClick={handleExpanded}
                        >
                          {' '}
                          {expandedComment ? 'menos' : 'mais'}
                        </p>
                      )}
                    </Box>
                  ) : (
                    <Box className={classes.boxNameAndSurname}>
                      <p className={!expandedComment ? 'hide' : 'show'}>
                        {application[0]?.job?.company?.fantasyName}
                      </p>
                      {application && application?.length > 1 && (
                        <p
                          className={classes.showHide}
                          onClick={handleExpanded}
                        >
                          {' '}
                          {expandedComment ? 'menos' : 'mais'}
                        </p>
                      )}
                    </Box>
                  )}
                </Box>
              )}

              <Formik
                enableReinitialize={false}
                initialValues={{
                  comment: ''
                }}
                validationSchema={Yup.object().shape({
                  comment: commentValidationToggle[`${modalType === 'undo'}`]
                })}
                onSubmit={async (values, { resetForm }) => {
                  const status = getModalStatus();
                  const comment = values.comment;
                  const check = false;
                  const userType = isCompany ? 'COMPANY' : 'CANDIDATE';

                  try {
                    if (modalName === 'message') {
                      const { data } =
                        await companyService.uniqueMessageInApplication(
                          getApplicationIds(),
                          comment,
                          userType
                        );

                      updateSituation(
                        [data.situation],
                        [data.events],
                        getApplicationIds()
                      );
                    } else if (modalName === 'undo') {
                      const { data } =
                        await companyService.undoUnapproveCandidate({
                          id: getApplicationIds(),
                          status: status,
                          privateComment: check,
                          comment: comment
                        });

                      updateSituation(
                        [data.situation],
                        [data.events],
                        getApplicationIds()
                      );
                    } else if (modalName === 'quitter') {
                      const { data } =
                        await companyService.undoQuittedCandidate({
                          id: getApplicationIds(),
                          privateComment: check,
                          comment: comment
                        });

                      updateSituation(
                        [data.situation],
                        [data.events],
                        getApplicationIds()
                      );
                    } else {
                      if (application?.length > 1) {
                        const { data } =
                          await companyService.multipleApprovalOrReprovation({
                            applicationsId: getApplicationIds(),
                            status,
                            privateComment: check,
                            comment: comment
                          });
                        const situations = data?.map((item) => {
                          return item.situation;
                        });
                        const events = data?.map((item) => {
                          return item.events;
                        });
                        updateSituation(
                          situations,
                          events,
                          getApplicationIds()
                        );
                      } else {
                        const { data } =
                          await companyService.uniqueApprovalOrReprovation({
                            id: getApplicationIds(),
                            status,
                            privateComment: check,
                            comment: comment
                          });
                        updateSituation(
                          [data.situation],
                          [data.events],
                          getApplicationIds()
                        );
                      }
                    }
                    onClose();
                    resetForm({});
                    setModalType('');
                    enqueueSnackbar(getModalSnackbarMessage(), {
                      variant: 'success',
                      autoHideDuration: 5000,
                      action: (id) => (
                        <Close
                          onClick={() => closeSnackbar(id)}
                          fontSize="small"
                          style={{ cursor: 'pointer' }}
                        />
                      )
                    });
                  } catch (err) {
                    console.error(err);
                    if (err.message === 'candidate withdrew from the job') {
                      enqueueSnackbar(
                        'Esta candidatura não pôde ser atualizada por motivo de desistência',
                        {
                          variant: 'error'
                        }
                      );
                    } else {
                      enqueueSnackbar(
                        'Não foi possível atualizar a candidatura',
                        {
                          variant: 'error'
                        }
                      );
                    }

                    onClose();
                    resetForm({});
                    setModalType('');
                  }
                }}
              >
                {({
                  values,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  errors,
                  touched,
                  isSubmitting
                }) => (
                  <form className={classes.root} onSubmit={handleSubmit}>
                    {loading ? (
                      <Skeleton height={130} />
                    ) : (
                      <Box>
                        <TextField
                          required
                          multiline
                          variant="outlined"
                          rows={4}
                          label="Mensagem"
                          className={classes.comment}
                          value={values.comment}
                          error={Boolean(touched.comment && errors.comment)}
                          helperText={touched.comment && errors.comment}
                          name="comment"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          style={{ width: '100%' }}
                          inputProps={{ maxLength: 250 }}
                        />
                        <Box style={{ textAlign: 'end' }}>
                          <Typography component="span" className={classes.span}>
                            {values.comment.length} / 250
                          </Typography>
                        </Box>
                      </Box>
                    )}

                    <Divider style={{ margin: '15px 0px' }} />
                    <Box display="flex">
                      <Typography
                        className={classes.greyText}
                        component="span"
                        style={{ marginBottom: 24, fontSize: 12 }}
                      >
                        Responsável:{' '}
                        <span
                          style={{
                            textDecoration: 'underline',
                            textDecorationColor: '#00000099'
                          }}
                        >
                          {userProfile?.user?.name !== ''
                            ? userProfile?.user?.name
                            : ''}
                        </span>{' '}
                        ({userProfile?.user?.email})
                      </Typography>
                    </Box>
                    {loading ? (
                      <Box
                        display="flex"
                        width="100%"
                        style={{ marginBottom: 25 }}
                      >
                        <div style={{ flex: 1 }}>
                          <Skeleton height={100} />
                        </div>
                      </Box>
                    ) : (
                      <Alert severity="warning">
                        <AlertTitle style={{ fontWeight: 700 }}>
                          {isCompany
                            ? application?.length > 1
                              ? `Os ${application?.length} candidatos serão notificados por email`
                              : 'O candidato será notificado por email'
                            : `A empresa será notificada por email`}
                        </AlertTitle>
                        <Typography style={{ fontWeight: 400 }}>
                          Ao clicar em <b>{getModalAction()}</b>, iremos enviar
                          um email para{' '}
                          {isCompany
                            ? application?.lenth > 1
                              ? 'os candidatos'
                              : 'o candidato'
                            : `empresa`}
                          .
                        </Typography>
                      </Alert>
                    )}

                    <Box className={classes.buttonsBox} textAlign="right">
                      <Button
                        id="left-button-manage-job-button-id"
                        type="button"
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={onClose}
                      >
                        Cancelar
                      </Button>
                      <LoadingButton
                        loading={isSubmitting}
                        id="right-button-manage-job-button-id"
                        type="submit"
                        variant="contained"
                        className={classes.button}
                        color={getModalButtonColor()}
                        disabled={values.comment.length < 1 || isSubmitting}
                        style={isSubmitting ? { width: 102 } : {}}
                      >
                        {!isSubmitting && getModalSubmitText()}
                      </LoadingButton>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default ModalAproveOrReprove;
