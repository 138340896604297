import React, { Dispatch, FC, SetStateAction, useState } from 'react';

import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { usePDF } from '@react-pdf/renderer';
import noImage from 'src/assets/images/no-image.svg';
import useAuth from 'src/hooks/useAuth';
import { ResumePDF } from 'src/layouts';
import { useDispatch } from 'src/store';
import type { Theme } from 'src/theme';
import ViewCurriculum from 'src/views/account/AccountView/ViewCurriculum/ViewCurriculum';

interface ResumeTableProps {
  application?: any;
  loading?: boolean;
  setLoading?: Dispatch<SetStateAction<boolean>>;
  isCandidateBank?: boolean;
  info?: any;
  infoApplication?: any;

}

const useStyles = makeStyles((theme: Theme) => ({
  curriculum: {
    width: '100%',
    minWidth: '100% !important',
    maxWidth: '684px',
  },
  header: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '157%',
    letterSpacing: '0.1px',
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: 24,
    marginBottom: 8
  }
}));

export const ResumeTable: FC<ResumeTableProps> = ({
  info,
  loading,
  setLoading,
  isCandidateBank,
  infoApplication
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { userProfile } = useAuth();
  const [candidateCurriculum, setCandidateCurriculum] = useState(infoApplication?.candidate);
  const Doc = Boolean(candidateCurriculum) && (
    <ResumePDF
      info={candidateCurriculum}
      avatarUrl={
        candidateCurriculum?.hasAvatar
          ? `${process.env.REACT_APP_MEDIA_URL}/users/${candidateCurriculum.id}/avatar-small.png`
          : noImage
      }
    />
  );
  const [instance, updateInstance] = usePDF({ document: Doc });

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true
  });

  const id = infoApplication?.candidate?.id

  return (
    <Box style={{ width: '100%' }} className={classes.curriculum}>
          <ViewCurriculum
            enableDownload={false}
            info={candidateCurriculum}
        isCandidateBank={isCandidateBank}
        isDrawerViwer={true}
      />
    </Box>
  );
};

