import React from 'react';
import type { FC } from 'react';
import TopbarLogo from 'src/assets/images/logo-jobs-small.png';
import { useLocation } from 'react-router';

interface LogoProps {
  [key: string]: any;
  colorful?: string;
}

const Logo: FC<LogoProps> = (props) => {
  const imgLogo = props.colorful === 'true' ? TopbarLogo : '/static/logo.svg';

  const location = useLocation();
  const logoRedirectLink = () => {
    if (location.pathname === '/') {
      let link;
      if (process.env.REACT_APP_PUBLIC_URL.includes('stg'))
        link = 'https://www.stg.gria.com.br';
      else if (
        process.env.REACT_APP_PUBLIC_URL.includes('dev') ||
        process.env.REACT_APP_PUBLIC_URL.includes('localhost')
      )
        link = 'https://www.dev.gria.com.br';
      else link = 'https://www.gria.com.br';
      return (window.location.href = link);
    }

    return null;
  };

  return (
    <img
      id="logo-type-gria-img-id"
      alt="Logo"
      src={imgLogo}
      onClick={logoRedirectLink}
      {...props}
    />
  );
};

export default Logo;
