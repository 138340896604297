import React from 'react';

import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';

import { Theme } from 'src/theme';
import Logo from 'src/components/Logo';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: 'linear-gradient(96.61deg, #920D65 0.44%, #D81C98 99.14%)',
    width: '100%',
    maxWidth: '426px',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  logo: {
    padding: '50px 0 0 48px',
    position: 'absolute',
    '& > img': {
      width: 64,
      height: 27,
      maxHeight: '100%'
    }
  },
  boxContent: {
    paddingLeft: 48,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh'
  },
  title: {
    color: '#FAFAFA',
    fontFamily: 'Nunito',
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '32px',
    width: '330px',
    marginBottom: '8px'
  },
  subtitle: {
    color: '#FAFAFA',
    fontFamily: 'Nunito',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20.02px',
    letterSpacing: '0.15px',
    width: '330px',
    marginBottom: '26px'
  },
  button: {
    fontFamily: 'Nunito',
    fontWeight: '700',
    fontSize: '13px',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.secondary.main,
    height: '38px',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main
    }
  }
}));

const Card = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleRedirectCompany = () => {
    const AUTH_URL = process.env.REACT_APP_AUTH_URL;
    const URL = `${AUTH_URL}?action=job_open`;
    window.location.href = URL;
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.logo}>
        <Logo colorful="true" style={{ cursor: 'pointer' }} />
      </Box>
      <Box className={classes.boxContent}>
        <Box>
          <Typography component="h4" variant="h4" className={classes.title}>
            Recrutamento rápido, inteligente e acessível
          </Typography>
          <Typography
            component="h6"
            variant="body2"
            className={classes.subtitle}
          >
            Vagas em menos de 5 minutos, com pré-seleção dos melhores perfis
            para agilizar a sua contratação
          </Typography>
          <Button
            id="start-for-free-button-id"
            variant="contained"
            className={classes.button}
            onClick={handleRedirectCompany}
          >
            COMEÇAR GRATUITAMENTE
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Card;
