import React from 'react';

import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';

import flow from 'src/assets/images/flow-logo.png';
import flowSmall from 'src/assets/images/insights/logo-flow-small.png';

import { useStyles } from './styles';

export const FlowLogo = () => {
  const classes = useStyles();
  const history = useHistory();

  const redirectToHome = () => {
    history.push(`/insights`);
  };

  return (
    <Box className={classes.logoBox} onClick={redirectToHome}>
      <Box className={classes.logo}>
        <img
          src={flowSmall}
          loading="lazy"
          style={{
            width: '100%',
            maxWidth: '200px',
            height: '100%',
            maxHeight: '40px'
          }}
        />
      </Box>
    </Box>
  );
};
