import { Box } from '@mui/material';
import type { FC } from 'react';
import React from 'react';



import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiTypography-root, & .MuiInputLabel-formControl': {
      fontFamily: 'Nunito',
      fontWeight: 400
    },
    backgroundColor: theme.palette.background.default,
    color: '#000',
    [theme.breakpoints.down('xs')]: {}
  },
}));

interface AssessmentTableProps {
  open?: boolean;
}

export const AssessmentTable: FC<AssessmentTableProps> = ({
  open
}) => {
  const classes = useStyles();



  return (
    <Box className={clsx(classes.root)}>
      box
    </Box>
  );
};