import { getStateAcronym } from './getStateAcronym';

export const getShortAddress = (
  country: string,
  state: string,
  city: string
) => {
  if (state && ['Brazil', 'Brasil'].includes(country)) {
    return [city, getStateAcronym(state), country].join(', ');
  } else if (state && !['Brazil', 'Brasil'].includes(country)) {
    return [city, getStateAcronym(state), country].join(', ');
  } else {
    return 'Remoto';
  }
};
