import type { FC } from 'react';
import React, { useState } from 'react';
import { Formation } from 'src/types';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  Fab
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import {
  CalendarTodayOutlined,
  SchoolOutlined,
  AccountBalance
} from '@mui/icons-material';
import moment, { Moment } from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { degreeOptionsToLabels } from 'src/utils/helpers';
import { progressOptionsLabels } from 'src/utils/helpers';
import type { Theme } from 'src/theme';
import Modal from 'src/components/ModalCurriculum/ModalCurriculum';

const useStyles = makeStyles((theme: Theme) => ({
  wrapIcon: {
    verticalAlign: 'middle',
    color: '#000000',
    display: 'inline-flex',
    fontSize: '12px',
    fontFamily: 'Nunito',
    fontWeight: 400,
    lineHeight: '12px',
    letterSpacing: 0.25,
    alignItems: 'center'
  },
  cardContent: {
    '&:last-child': {
      paddingBottom: 0
    }
  },
  marginItems: {
    paddingLeft: '4px !important'
  },
  fontsText: {
    verticalAlign: 'middle',
    alignItems: 'center',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.25px'
  },
  titleText: {
    fontSize: '12px',
    fontFamily: 'Nunito',
    fontWeight: 400
  },
  predict: {
    color: '#000000',
    fontSize: '10px',
    fontFamily: 'Nunito',
    fontWeight: 300,
    lineHeight: '12px',
    letterSpacing: 0.25,
    alignItems: 'center',
    marginLeft: '4px'
  },
  iconBtns: {
    marginTop: 'inherit',
    color: '#DADADA'
  },
  iconSVG: {
    color: theme.palette.icon.main,
    width: '15px'
  },
  iconSVGPerformance: {
    color: 'rgba(0, 0, 0, 0.54)',
    width: 22,
    height: 22,
    fontSize: 22
  },
  fab: {
    padding: '10px 15px',
    marginTop: '10px',
    border: 'none',
    '&:first-child': {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.primary.main
    },
    '&:last-child': {
      backgroundColor: '#F44336',
      color: '#FFF'
    }
  },
  button: {
    padding: '10px 15px',
    marginTop: '10px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    '&:first-child': {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.primary.main,
      border: 'none'
    }
  },
  texts: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    alignItems: 'center',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '12px',
    letterSpacing: '0.25px',
    color: '#000000'
  }
}));

interface FormationCardProps {
  formation: Formation;
  withActions?: boolean;
  index: number;
  length: number;
  onEdit?: React.MouseEventHandler<HTMLButtonElement>;
  onDelete?: React.MouseEventHandler<HTMLButtonElement>;
  elevation?: number;
}

const FormationCard: FC<FormationCardProps> = ({
  formation,
  withActions,
  index,
  length,
  onEdit,
  onDelete,
  elevation
}) => {
  const classes = useStyles();
  const [deleteData, setDeleteData] = useState(false);

  const closeModalMessage = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDeleteData(false);
    onDelete(event);
    return;
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true
  });

  const handleWithFormatDate = (timestamp: Date | Moment) => {
    if (timestamp) {
      let date = moment.utc(timestamp);

      return date.isValid() ? date.format('DD/MM/YYYY') : '';
    }
    return '';
  };

  return (
    <Card elevation={elevation} style={{ boxShadow: 'unset' }}>
      <CardContent>
        <Grid spacing={2}>
          <Grid xs={12}>
            <Grid spacing={2}>
              <Grid
                xs={12}
                style={{ display: 'flex', marginBottom: 8, gap: 10 }}
              >
                <AccountBalance className={classes.iconSVGPerformance} />
                <Typography variant="h5" className={classes.fontsText}>
                  {formation.institution}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={12} spacing={2}>
              {formation.degree !== 'OTHER' && (
                <Grid
                  className={classes.marginItems}
                  style={{ width: 'fit-content', gap: 10 }}
                  justifyContent="center"
                >
                  {formation.degree && (
                    <Grid
                      style={{ marginBottom: '13px', display: 'flex', gap: 5 }}
                    >
                      <SchoolOutlined
                        style={{
                          color: 'rgba(0, 0, 0, 0.54)',
                          fontSize: 18,
                          height: 14,
                          width: 14
                        }}
                      />
                      <Typography
                        className={`${classes.wrapIcon} ${classes.titleText}`}
                      >
                        {degreeOptionsToLabels[formation.degree]}
                      </Typography>
                    </Grid>
                  )}
                  <Grid
                    style={
                      formation.degree === 'OTHER'
                        ? { display: 'none' }
                        : {
                            display: 'flex',
                            alignItems: 'center',
                            height: 18,
                            gap: '10px 15px',
                            flexWrap: 'wrap'
                          }
                    }
                    item
                  >
                    <Typography variant="subtitle1" className={classes.texts}>
                      <CalendarTodayOutlined className={classes.iconSVG} />
                      &ensp;
                      {handleWithFormatDate(formation.startDate)}
                    </Typography>
                    {formation.progress === 'IN_PROGRESS' && (
                      <>
                        <Typography
                          variant="subtitle1"
                          className={classes.texts}
                        >
                          <CalendarTodayOutlined className={classes.iconSVG} />
                          &ensp;
                          {handleWithFormatDate(formation.endDate)}
                        </Typography>
                        <p className={classes.predict}>*previsto</p>
                      </>
                    )}
                    {formation.progress === 'FINISHED' && (
                      <>
                        <Typography
                          variant="subtitle1"
                          className={classes.texts}
                        >
                          <CalendarTodayOutlined className={classes.iconSVG} />
                          &ensp;
                          {handleWithFormatDate(formation.endDate)}
                        </Typography>
                      </>
                    )}
                    {formation.progress === 'UNFINISHED' && (
                      <>
                        <Typography
                          variant="subtitle1"
                          className={classes.texts}
                        >
                          <CalendarTodayOutlined className={classes.iconSVG} />
                          &ensp;
                          {progressOptionsLabels[formation.progress]}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
              )}
              {formation.title && (
                <Grid
                xs={12}
                style={{ marginTop: 10 }}
                justifyContent="space-between"
              >
                <Grid xs={12}>
                  <Typography
                    style={isSmallScreen ? { marginBottom: 8 } : null}
                    className={`${classes.wrapIcon} ${classes.titleText}`}
                  >
                    {formation.title}
                  </Typography>
                </Grid>
              </Grid>
              )}
              {formation.degree === 'OTHER' && (
                <Grid
                  xs={8}
                  spacing={2}
                  style={
                    isSmallScreen
                      ? { width: 'fit-content', marginLeft: 40 }
                      : {
                          width: 'fit-content',
                          marginLeft: 8,
                          alignItems: 'center'
                        }
                  }
                >
                  <Grid>
                    <Typography
                      className={`${classes.wrapIcon} ${classes.titleText}`}
                    >
                      <CalendarTodayOutlined className={classes.iconSVG} />
                      &ensp;{handleWithFormatDate(formation.startDate)}
                    </Typography>
                  </Grid>
                  <Grid
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: 18
                    }}
                  >
                    {formation.progress === 'IN_PROGRESS' && (
                      <Box display="flex">
                        <Typography className={`${classes.wrapIcon} ${classes.titleText}`}>
                          <CalendarTodayOutlined className={classes.iconSVG} />
                          &ensp;
                          {handleWithFormatDate(formation.endDate)}
                        </Typography>
                        <p className={classes.predict}>*previsto</p>
                      </Box>
                    )}
                    {formation.progress === 'FINISHED' && (
                      <Typography className={`${classes.wrapIcon} ${classes.titleText}`}>
                        <CalendarTodayOutlined className={classes.iconSVG} />
                        &ensp;
                        {handleWithFormatDate(formation.endDate)}
                      </Typography>
                    )}
                    {formation.progress === 'UNFINISHED' && (
                      <Typography className={classes.titleText}>
                        {progressOptionsLabels[formation.progress]}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {formation.isTemporary && (
            <Box style={{ marginTop: 12 }}>
              <Typography 
                style={{ fontFamily: 'Nunito', fontSize: 12, color: 'red' }}
              >
                Complete as informações obrigatórias ou a formação não será salva
              </Typography>
            </Box>
          )}
          {!isSmallScreen && withActions && (
            <Grid item lg={2} md={2} sm={2} xs={12}>
              <Box
                display="flex"
                alignItems="flex-end"
                p={1}
                m={1}
                height="100%"
              >
                <IconButton
                  aria-label="edit"
                  onClick={onEdit}
                  className={classes.iconBtns}
                  size="large"
                >
                  <EditIcon className={classes.iconSVG} />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => setDeleteData(true)}
                  className={classes.iconBtns}
                  size="large"
                >
                  <DeleteIcon className={classes.iconSVG} />
                </IconButton>
              </Box>
            </Grid>
          )}
        </Grid>

        {isSmallScreen && withActions && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            style={{ margin: '0px 30px 20px' }}
          >
            <Fab
              size="small"
              aria-label="edit"
              onClick={onEdit}
              className={classes.fab}
            >
              <EditIcon />
            </Fab>

            <Fab
              size="small"
              aria-label="delete"
              onClick={() => setDeleteData(true)}
              className={classes.fab}
            >
              <DeleteIcon />
            </Fab>
          </Box>
        )}

        {index !== length - 1 && (
          <Box mt={2} mr={1} ml={1}>
            <Divider
              variant="middle"
              style={{ backgroundColor: '0000001F' }}
            />
          </Box>
        )}
      </CardContent>
      <Modal
        open={deleteData}
        title="Você deseja excluir a informação do currículo?"
        subtitle="Se você confirmar, os dados salvos serão apagados permanentemente."
        btnLeftLabel="Cancelar"
        btnRightLabel="Sim, desejo excluir"
        onLeftButtonClick={() => setDeleteData(false)}
        onRightButtonClick={closeModalMessage}
      />
    </Card>
  );
};

FormationCard.defaultProps = {
  withActions: false,
  onEdit: () => {},
  onDelete: () => {},
  elevation: 0
};

export default FormationCard;
