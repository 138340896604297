import { makeStyles } from '@mui/styles';

import type { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    color: '#000',
    [theme.breakpoints.down('sm')]: {}
  },
  content: {
    width: '100%',
    maxWidth: '365px',
    backgroundColor: theme.palette.background.default,
    padding: '32px 24px',
    borderRadius: '16px',
    '&:focus': {
      outline: 'none'
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 16px'
  },
  title: {
    color: theme.palette.text.primary,
    textAlign: 'center',
    fontFamily: 'Nunito',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: '0.15px'
  },
  subtitle: {
    color: theme.palette.text.primary,
    textAlign: 'center',
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.15px'
  },
  button: {
    width: 'auto',
    height: '38px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  boxActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: '24px',
    gap: '46px'
  },
  boxButtons: {
    borderRadius: '60px',
    padding: '20px',
    width: '99px',
    height: '99px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 200ms ease-in-out',
    cursor: 'pointer',
    color: theme.palette.background.default,

    '&:hover': {
      transition: 'all 0ms ease-in-out',
      transform: 'scale(1.2)'
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.background.default
    },
    '& .MuiTypography-root': {
      fontFamily: 'Nunito',
      fontSize: '16px',
      fontWeight: 700
    },
    '&.primary': {
      background: theme.palette.primary.main,
      boxShadow: '0px 4px 20px 0px rgba(89, 10, 157, 0.08)',
      gap: '8px'
    },
    '&.secondary': {
      background: theme.palette.secondary.main,
      boxShadow: '0px 4px 20px 0px rgba(157, 10, 107, 0.08)',
      gap: '8px'
    }
  }
}));
