import { makeStyles } from '@mui/styles';
import type { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  alert: {
    alignItems: 'center',
    marginBottom: 20
  },
  alertText: {
    textAlign: 'left',
    fontFamily: 'Nunito',
    fontWeight: 400
  },
	input: {
		margin: 0
	},
  linkContainer: {
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    borderRadius: 4,
    padding: 12,
    border: '1px solid #0000001F'
  },
  text: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: 16,
    textAlign: 'left'
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 12,
    width: 44,
    height: 44,
    background: 'rgba(63, 81, 181, 0.08)',
    borderRadius: 48,
    color: 'rgba(0, 0, 0, 0.6)'
  },
}));