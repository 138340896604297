import { Opportunity } from 'src/types/Opportunity';
import { OpportunityFormCreate } from 'src/types/opportunityFormCreate';
import api from '../api';

export class CompanyService {
  private static instance: CompanyService;

  constructor() {
    if (!CompanyService.instance) {
      CompanyService.instance = this;
    }
    return CompanyService.instance;
  }

  getProfile = (id: string) => {
    return api.get(`/companies/${id}`);
  };

  getAllCompanies = () => {
    return api.get('companies');
  };

  getCompanyBilling = (id: string) => {
    return api.get(`companies/${id}/billing`);
  };

  updateGeneral = (id: string, data: any) => {
    return api.put(`/companies/${id}`, data);
  };

  updateMember = (data: any) => {
    return api.put('/company/member', data);
  };

  changeCover = (image, id: string) => {
    var formData = new FormData();
    formData.append('file', image);
    formData.append('_method', 'PATCH');

    return api.patch(`/companies/files/company/${id}/cover`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };

  changeAvatar = (data, id: string) => {
    return api.patch(`/companies/files/company/${id}/avatar`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };

  changeMemberAvatar = (data, id: string) => {
    return api.patch(`/company/${id}/member-avatar`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };

  changeGalleryFile = (data, fileIndex: number, id: string) => {
    return api.patch(`/files/company/${id}/gallery/${fileIndex}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };

  deleteCover = (id: string) => {
    return api.delete(`/companies/files/company/${id}/cover`);
  };

  deleteAvatar = (id: string) => {
    return api.delete(`/companies/files/company/${id}/avatar`);
  };

  deleteGalleryFile = (id: string, fileId: string) => {
    return api.delete(`/files/company/${id}/gallery/${fileId}`);
  };

  changePassword = (
    password: string,
    newPassword: string,
    passwordConfirm: string
  ) => {
    return api.post('/authentication/change-password', {
      password,
      newPassword,
      passwordConfirm
    });
  };

  getCandidateProfile = (idCandidate: string) => {
    return api.get(`/users/${idCandidate}`);
  };

  addScore = (idRegister, idTest, idOpportunity, payload) => {
    const path = '/note';
    return api.post(path, {
      register: idRegister,
      test: idTest,
      opportunity: idOpportunity,
      ...payload
    });
  };

  setScore = (idRegister, idTest, idOpportunity, idNota, payload) => {
    const path = `/note/${idNota}`;
    return api.put(path, {
      register: idRegister,
      test: idTest,
      opportunity: idOpportunity,
      ...payload
    });
  };

  addCommentary = (applicationID: string, commentary: string) => {
    return api.post(`/applications/${applicationID}/comment`, { commentary });
  };

  deleteCommentary = (applicationID: string, commentaryID: string) => {
    return api.delete(`/applications/${applicationID}/comment/${commentaryID}`);
  };

  contact = (payload) => {
    const { name, type, to, message } = payload;
    return api.post('/contact/doubt', { name, type, to, message });
  };

  schedulingDemo = (payload) => {
    const {
      responsible,
      cell,
      company,
      segment,
      network,
      to,
      amount_work,
      office
    } = payload;
    return api.post('/contact/scheduling ', {
      responsible,
      cell,
      company,
      segment,
      network,
      to,
      amount_work,
      office
    });
  };

  getExternalOpportunity = (companySlug: string, jobSlug: string) => {
    return api.get(`/companies/${companySlug}/jobs/${jobSlug}`);
  };

  getCandidaciesOpportunity = (payload) => {
    const { id: idOpportunity } = payload;
    return api.get(`/jobs/applications/${idOpportunity}/progress`);
  };

  finishOpportunity = (payload, message?) => {
    const { id: idOpportunity } = payload;
    return api.patch(`/jobs/${idOpportunity}/close`, { message });
  };

  pauseOpportunity = (payload) => {
    const { id: idOpportunity } = payload;
    return api.patch(`/jobs/${idOpportunity}/pause`);
  };

  resumeOpportunity = (payload) => {
    const { id: idOpportunity } = payload;
    return api.patch(`/jobs/${idOpportunity}/open`);
  };

  createOpportunity = (payload: OpportunityFormCreate) => {
    const { id, ...form } = payload;
    return api.post(`companies/${id}/jobs`, { ...form });
  };

  editOpportunity = (payload: OpportunityFormCreate) => {
    const { id, ...form } = payload;
    return api.put(`jobs/${id}`, { ...form });
  };

  getOpportunityById = (id) => {
    return api.get(`/jobs/${id}`);
  };

  duplicateOpportunity = (id) => {
    return api.post(`/jobs/${id}/duplicate`);
  };

  cancelOpportunity = (payload) => {
    const { id, ...opportunity } = payload;
    return api.delete(`/jobs/${id}/cancel`, { ...opportunity });
  };

  getAllOpportunitiesByCompnayId = (payload) => {
    const { id, params } = payload;
    return api.get<{ opportunity: Opportunity[] }>(
      `companies/${id}/jobs?${params || ''}`
    );
  };

  getApplicationById = (id: string) => {
    return api.get(`/applications/${id}`);
  };

  getAllAplicationsByJobId = (id: string, params?: any) => {
    return api.get(`/jobs/${id}/applications`, { params });
  };

  getIndustries = () => {
    return api.get('/industries');
  };

  /**
   * Status disponíveis: to-test | to-interview | to-approved | to-unapproved
   */
  setStatusApplication = (payload) => {
    const { id, status, message } = payload;
    return api.patch(`/applications/${id}/status/${status}`, { message });
  };

  updateApplicationProcess = (jobId: string, flowType: number) => {
    return api.post(`/applications/process-state-logs/jobId/${jobId}`, {
      flowType
    });
  };

  verifyCandidates = (id: string) => {
    return api.get(`/jobs/${id}/applications/in-progress`);
  };

  getUserProfileById = (id: string) => {
    return api.get(`/users/${id}`);
  };

  getPDFCurriculumByApplicationId = (applicationId: string) => {
    return api.get(`/applications/${applicationId}/file/curriculum`);
  };

  updateApplicationToNextStatus = (payload) => {
    const { applicationId, processId, status, message, showCommentary } =
      payload;
    return api.patch(
      `/applications/${applicationId}/update-status/process/${processId}`,
      { status, message, showCommentary }
    );
  };

  getApplicationInfo = (applicationId: string) => {
    return api.get(`/applications/${applicationId}`);
  };

  uniqueMessageInApplication = (
    applicationId: string,
    comment: string,
    userType: string
  ) => {
    return api.post(`/application/${applicationId}/message`, {
      comment,
      userType
    });
  };

  uniqueApprovalOrReprovation = (payload) => {
    const { id, status, comment, privateComment } = payload;
    return api.patch(`/applications/${id}/status`, {
      status,
      comment,
      privateComment
    });
  };

  multipleApprovalOrReprovation = (payload) => {
    const { applicationsId, status, comment, privateComment } = payload;
    return api.patch(`/applications/status`, {
      applicationsId,
      status,
      comment,
      privateComment
    });
  };

  undoUnapproveCandidate = (payload) => {
    const { id, status, comment, privateComment } = payload;
    return api.patch(`/applications/${id}/undo/UNAPPROVED`, {
      status,
      comment,
      privateComment
    });
  };

  getCandidateCurriculumFile = (id: string, companyId: string) => {
    return api.get(`companies/${companyId}/candidate/${id}/file-curriculum`);
  };

  undoQuittedCandidate = (payload) => {
    const { id, comment, privateComment } = payload;
    return api.patch(`/applications/${id}/undo/QUITTER`, {
      comment,
      privateComment
    });
  };

  getCompanyPositions = () => {
    const url = process.env.REACT_APP_DATA_API_URL;
    return api.get(`${url}/roles/all`, {
      headers: { 'Accept-Language': 'pt-br' }
    });
  };

  getAllPositionsPrefixes = () => {
    const url = process.env.REACT_APP_DATA_API_URL;
    return api.get(`${url}/prefixes/all`, {
      headers: { 'Accept-Language': 'pt-br' }
    });
  };

  getPositionsByPrefix = (prefix: string) => {
    const url = process.env.REACT_APP_DATA_API_URL;
    return api.get(`${url}/roles?prefix=${prefix}`, {
      headers: { 'Accept-Language': 'pt-br' }
    });
  };

  getPositionsByUnsetPrefix = (prefix: string) => {
    const url = process.env.REACT_APP_DATA_API_URL;
    return api.get(`${url}/roles?term=${prefix}`, {
      headers: { 'Accept-Language': 'pt-br' }
    });
  };

  getCompanyPositionsByRoleOrCode = (code: string, role?: string) => {
    const url = process.env.REACT_APP_DATA_API_URL;
    const param = code ? `code=${code}` : `term=${role}`;
    return api.get(`${url}/role?${param}`, {
      headers: { 'Accept-Language': 'pt-br' }
    });
  };

  getCompanyCog = (companyId?: string) => {
    const url = process.env.REACT_APP_DATA_API_URL;
    return api.get(`${url}/companies/${companyId}`, {
      headers: { 'Accept-Language': 'pt-br' }
    });
  };

  getCompanyJobs = (companyId?: string) => {
    const url = process.env.REACT_APP_DATA_API_URL;
    return api.get(`${url}/jobs?companyId=${companyId}`, {
      headers: { 'Accept-Language': 'pt-br' }
    });
  };

  getCompanyTalents = (companyId?: string) => {
    const url = process.env.REACT_APP_DATA_API_URL;
    return api.get(`${url}/talents/${companyId}`, {
      headers: { 'Accept-Language': 'pt-br' }
    });
  };

  getCompanyAverageTime = (companyId?: string) => {
    const url = process.env.REACT_APP_DATA_API_URL;
    return api.get(`${url}/companies/${companyId}/average-job-time`, {
      headers: { 'Accept-Language': 'pt-br' }
    });
  };

  getCompanyScore = (companyId?: string) => {
    const url = process.env.REACT_APP_DATA_API_URL;
    return api.get(`${url}/scores/${companyId}`, {
      headers: { 'Accept-Language': 'pt-br' }
    });
  };

  getCompanyMessageNotifications = (
    companyId: string,
    page?: number,
    limit?: number
  ) => {
    return api.get(
      `/companies/${companyId}/notifications?page=${page}&limit=${limit}`
    );
  };

  setMessageRead = (companyId: string, messageId: string) => {
    return api.patch(`/company/${companyId}/notification/${messageId}/view`);
  };

  inviteMember = (payload) => {
    const { members, companyId, companyName, role } = payload;
    return api.post('/member/invite', {
      members,
      companyId,
      companyName,
      role
    });
  };

  acceptInvite = (payload) => {
    const { token, name, password, email, keyCaptcha: captcha } = payload;
    return api.patch(
      `/member/accept-invite`,
      { name, password, email, captcha },
      { headers: { Authorization: 'Bearer ' + token } }
    );
  };

  membersList = (page, rowsPerPage, order) => {
    return api.get(
      `/company/members?page=${page}&quantity=${rowsPerPage}&name=&order=${order}`
    );
  };

  searchMembersList = (nameSearch, page, rowsPerPage, order) => {
    return api.get(
      `/company/members?page=1&quantity=${rowsPerPage}&name=${nameSearch}&order=${order}`
    );
  };

  deleteMember = (payload) => {
    const { id } = payload;
    return api.delete(`/member/${id}`);
  };

  memberChangeStatus = (payload) => {
    const { id } = payload;
    return api.patch(`/member/${id}/change-status`);
  };

  memberChangeRole = (member, token, role) => {
    const { id } = member;
    return api.patch(`/member/${id}/change-role/${role}`, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  memberReSendEmail = (member, company) => {
    const { id: companyId, fantasyName: companyName } = company;
    const { email, id } = member.user;
    return api.patch('/member/resend-invite', {
      email,
      id,
      companyId,
      companyName
    });
  };

  getCompanyBillingHistoryLink = (id: string) => {
    return api.get(`companies/${id}/billing-client`);
  };

  getCompanyInvoiceSettings = (id: string) => {
    return api.get(`companies/${id}/invoice`);
  };

  getCompanyCheckoutLink = (payload) => {
    const { id, cancelUrl, successUrl } = payload;
    return api.post(`companies/${id}/checkout-session`, {
      cancelUrl,
      successUrl
    });
  };

  examRequestByCompany = (payload) => {
    const { id, examId, examName } = payload;
    return api.post(`application/${id}/notify-exam`, { examId, examName });
  };

  getAll = () => {
    return api.get('companies');
  };

  switchCompany = (id: string) => {
    return api.patch(`member/support/switch-company/${id}`);
  };

  clientStripeSecret = (id: string) => {
    return api.get(`companies/${id}/setup-intent`);
  };

  intentSetupNotification = (id: string) => {
    return api.get(`companies/${id}/subscription/notification`);
  };

  amountVacanciesOpen = (id: string) => {
    return api.get(`companies/${id}/count-jobs-billable`);
  };

  cancelSubscription = (payload) => {
    const { id, description } = payload;
    return api.patch(`companies/${id}/unsubscribe`, { description });
  };

  sendCandidatesToReview = (payload) => {
    const { id, applicationIds } = payload;
    return api.post(`jobs/${id}/applications/approved-screening`, {
      applicationIds
    });
  };

  undoCandidatesToReview = (id) => {
    return api.patch(`/applications/${id}/undo-registered`);
  };

  //kanban options

  updateApplicationProcessStep = (payload) => {
    const { applicationId, processId, order } = payload;
    return api.post(`/applications/${applicationId}/process/${processId}`, {
      order
    });
  };

  createProcessColumn = (payload) => {
    const { jobId, process } = payload;
    return api.post(`jobs/${jobId}/process`, { process });
  };

  deleteProcessColumn = (payload) => {
    const { jobId, processId } = payload;
    return api.delete(`jobs/${jobId}/process/${processId}`);
  };

  updateProcessColumn = (payload) => {
    const { jobId, processId, updateType, process, order } = payload;
    return api.patch(
      `jobs/${jobId}/process/${processId}?updateType=${updateType}`,
      { process, order }
    );
  };

  orderingCandidateProcessColumn = (payload) => {
    const { id, processId, order } = payload;
    return api.patch(`applications/${id}/order/process/${processId}`, {
      order
    });
  };

  checkProcessCandidateAmount = (payload) => {
    const { id } = payload;
    return api.get(`jobs/${id}/applications/process-count`);
  };
}

const companyService = new CompanyService();
export default companyService;
