import React, { useState, useEffect } from 'react';

import {
  Alert,
  Box,
  Typography,
  Skeleton,
  useTheme,
  Button,
  CircularProgress
} from '@mui/material';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { FlashOn } from '@mui/icons-material';

import { useStyles } from './styles';
import { companyService } from 'src/services';
import useAuth from 'src/hooks/useAuth';
import { PremiumIcon } from 'src/components/molecules/PremiumIcon';
import { StripeApp } from 'src/components/molecules/Payment/StripeApp';

export interface PaymentAlertProps {
  loading?: boolean;
  messageType?: string;
  hideIcon?: boolean;
  processCandidateAmount?: number | null;
  changeJobStatus?: any;
}

export const PaymentAlert: React.FC<PaymentAlertProps> = ({
  loading,
  messageType,
  hideIcon,
  processCandidateAmount,
  changeJobStatus
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { userProfile, userType } = useAuth();
  const companyId = userProfile?.associatedCompanies[0]?.company.id;
  let actualMonth = moment().format('MMMM');
  const history = useHistory();

  const setAlertMessage = (type: string) => {
    let message;
    switch (type) {
      case 'createJob':
        message =
          'Adicione testes para validar as habilidades dos candidatos na vaga.';
        break;
      case 'jobManagement':
        message =
          processCandidateAmount < 5 ? (
            <p>
              Você só pode triar <b>5 candidatos</b> para o processo. Assine o
              <b> Premium</b> para acesso ilimitado.
            </p>
          ) : (
            <p>
              Você atingiu o <b>limite de 5 candidatos</b> em processo. Assine o
              <b> Premium</b> para acesso ilimitado.
            </p>
          );
        break;

      default:
        message = (
          <p>
            Assine o <b>Premium</b> e tenha acesso ilimitado
          </p>
        );
    }
    return message;
  };

  const [openStripe, setOpenStripe] = useState(false);

  const handleOpenStripe = () => {
    setOpenStripe(true);
    if (changeJobStatus) {
      changeJobStatus();
    }
  };

  const handleCloseStripe = () => {
    setOpenStripe(false);
  };

  return (
    <>
      {userType?.isCompany() && (
        <Box sx={{ width: '100%' }}>
          {loading && (
            <Skeleton
              variant="text"
              style={{ width: '100%', minHeight: '120px', height: '100%' }}
            />
          )}
          {!loading && (
            <Alert
              icon={false}
              severity="info"
              className={classes.root}
              iconMapping={{
                info: <PremiumIcon />
              }}
            >
              <Box className={classes.boxContent}>
                <Box className={classes.boxMessage}>
                  <Typography className={classes.message}>
                    {!hideIcon && (
                      <PremiumIcon style={{ marginRight: '12px' }} />
                    )}
                    {setAlertMessage(messageType)}
                  </Typography>
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    className={classes.button}
                    startIcon={
                      <FlashOn style={{ color: theme.palette.primary.dark }} />
                    }
                    onClick={handleOpenStripe}
                  >
                    Assine o Premium
                  </Button>
                </Box>
              </Box>
            </Alert>
          )}
        </Box>
      )}
      {openStripe && (
        <StripeApp open={openStripe} handleClose={handleCloseStripe} />
      )}
    </>
  );
};
