import type { FC, ReactNode } from 'react';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

interface PerformanceGuardProps {
  children?: ReactNode;
}

const PerformanceGuard: FC<PerformanceGuardProps> = ({ children }) => {
  const { isAuthenticated, userType, isLogout, userProfile } = useAuth();
  const history = useHistory();
  const path = history.location.pathname;
  const params = history.location.search;
  const AUTH_URL = process.env.REACT_APP_AUTH_URL;

  const userIsInsights =
    userProfile?.associatedCompanies[0]?.company?.typeService?.includes(
      'insights'
    );

  const userIsJobs =
    userProfile?.associatedCompanies[0]?.company?.typeService?.includes('jobs');
  const hasPerformancePlans =
    userProfile?.associatedCompanies[0]?.company?.subscriptionType?.includes(
      'performance_people'
    ) ||
    userProfile?.associatedCompanies[0]?.company?.subscriptionType?.includes(
      'performance_teams'
    );
  if (!isAuthenticated && !isLogout) {
    const encodedPath = window.btoa(path + params);
    window.location.href = `${AUTH_URL}?action=job_open&reference=${encodedPath}`;
  } else if (!userType.isCompany()) {
    if (userType.isCandidate()) {
      return <Redirect to={{ pathname: '/candidate/jobs' }} />;
    }

    if (userType.isCompany() && userIsInsights) {
      return <Redirect to={{ pathname: '/insights' }} />;
    }

    return <Redirect to="/insights/404" />;
  }

  if (userType.isCompany() && userIsJobs) {
    return <Redirect to={{ pathname: '/company/jobs' }} />;
  } else if (userType.isCompany() && !userIsJobs && hasPerformancePlans) {
    return <>{children}</>;
  } else {
    return <Redirect to="/insights/404" />;
  }
};

export default PerformanceGuard;
