import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_TALENTS_API_URL;
const talentsApi = axios.create({ baseURL: BASE_URL });

talentsApi.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);

export default talentsApi;
