import {
  ArrowBack,
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
  Close,
  MoreVert,
  Person,
  Remove,
  Star,
  StarBorder,
  ThumbUpAlt,
  Undo,
  Work
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Button,
  Divider,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  ListItemText,
  Tooltip,
  CircularProgress
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { usePDF } from '@react-pdf/renderer';
import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSelector as useSelectorStore } from 'src/store';
import { useHistory } from 'react-router';
import api from 'src/api';
import noImage from 'src/assets/images/no-image.svg';
import { PaymentAlert } from 'src/components/molecules/PaymentAlert';
import { PremiumIcon } from 'src/components/molecules/PremiumIcon';
import StepIcon from 'src/components/StepIcon';
import useAuth from 'src/hooks/useAuth';
import useFilterRoute from 'src/hooks/useFilterRoute';
import { ResumePDF } from 'src/layouts';
import { companyService } from 'src/services';
import {
  setCandidateCurriculum,
  setCandidateCurriculumFile
} from 'src/slices/candidateCurriculum';
import { setOpportunityCandidates } from 'src/slices/opportunityCandidates';
import {
  CandidateApplicationsItemType,
  ScoreBackgroundColorType
} from 'src/types/candidacy';
import { Opportunity } from 'src/types/Opportunity';
import {
  FilterParams,
  RequestParamsProps
} from 'src/types/opportunityCandidate';
import { removeRouteSearchParams } from 'src/utils/handleRoutes';
import ExamsCard from 'src/views/applications/applicationView/Components/ExamsCard';
import { ApplicationTable, PersonalDataTable, ResumeTable } from '..';
import JobStepsManagement from '../JobStepsManagement';

interface DrawerLateralProps {
  listCandidatesInOpportunity?: CandidateApplicationsItemType[];
  indexCandidate?: number;
  isOpenDrawerLateral?: boolean;
  setIndexCandidate?: any;
  setStatusOptions?: any;
  opportunity?: Opportunity;
  isFistRequest?: boolean;
  setIsFistRequest?: (variant: boolean) => void;
  infoApplication?: any;
  setInfoApplication?: any;
  isScreeningRequesting?: boolean;
  setButtonScreeningId?: any;
  buttonScreeningId?: any;
  handleScreeningCandidates?: any;
  handleUndoQuittedCandidate?: any;
  location?: string;
  processCandidateAmount?: number | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  '&  .MuiPaper-root-MuiDrawer-paper': {
    overflow: 'overlay'
  },
  contentBox: {
    width: '700px',
    height: '100vh',
    background: 'rgba(250, 250, 250, 1)',
    [theme.breakpoints.down(700)]: {
      width: '100vw'
    }
  },
  headerBox: {
    height: '46px',
    padding: '14px 21px 14px 16px'
  },
  iconBox: {
    width: '100%',
    display: 'flex',
    padding: '14px 16px 0px 16px',
    background: theme.palette.background.default
  },
  arrows: {
    color: 'rgba(0, 0, 0, 0.54)',
    width: '16px',
    height: '16px'
  },
  buttonArrow: {
    border: '1px solid rgba(0, 0, 0, 0.13) ',
    width: '32px',
    height: '32px',
    padding: '8px',
    minWidth: 'unset'
  },
  buttonExit: {
    minWidth: 'unset',
    marginLeft: 'auto'
  },
  avatar: {
    width: 40,
    height: 40,
    '& .MuiAvatar-img': {
      height: 'auto',
      background: '#FFFFFF'
    }
  },
  nameBox: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column'
  },
  name: {
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.15px',
    color: theme.palette.text.primary,
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    wordBreak: 'break-all',
    maxWidth: '290px',
    [theme.breakpoints.down(700)]: {
      maxWidth: '250px'
    },
    [theme.breakpoints.down(400)]: {
      maxWidth: '180px'
    }
  },
  textSpan: {
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.17px',
    color: theme.palette.text.secondary,
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    wordBreak: 'break-all',
    maxWidth: '290px',
    [theme.breakpoints.down(700)]: {
      maxWidth: '250px'
    },
    [theme.breakpoints.down(400)]: {
      maxWidth: '180px'
    }
  },
  avatarBox: {
    padding: '0px 16px 0px 16px',
    display: 'flex',
    alignItems: 'center',
    height: '65px',
    background: theme.palette.background.default,
    justifyContent: 'space-between',
    width: '100%'
  },
  menuItemOptions: {
    width: 160,
    fontWeight: 400
  },
  expandButton: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  textExamsLess: {
    background: 'white',
    padding: 16,
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    width: '100%'
  },
  boxContentLists: {
    padding: '0px 16px 0px',
    borderBottom: 1,
    borderColor: 'divider',
    width: '100%',
    background: theme.palette.background.default
  },
  tabsBox: {
    background: theme.palette.background.default
  },
  header: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '157%',
    letterSpacing: '0.1px',
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: 24,
    marginBottom: 8
  },
  noExams: {
    background: theme.palette.background.default,
    padding: '12px',
    borderRadius: '4px',
    TextStyle: 'Typography/Body 2',
    fontFamily: 'Nunito',
    fontStyle: 'Mixed',
    fontSize: '14px',
    lineHeight: '143%',
    align: 'left',
    verticalAlign: 'Top',
    letterSpacing: '0.15px'
  },
  title: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.15px',
    color: theme.palette.text.secondary,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '600px',
    width: '100%'
  },
  boxTitle: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    padding: '12px 16px',
    backgroundColor: theme.palette.background.default
  },
  divider: {
    marginLeft: '16px'
  },
  screeningCandidateIcon: {
    color: '#0000008A',
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}));

interface ModalEditMode {
  editMode: boolean;
  dataToEdit: string;
}

const DrawerLateral: FC<DrawerLateralProps> = ({
  listCandidatesInOpportunity,
  indexCandidate,
  setIndexCandidate,
  setStatusOptions,
  opportunity,
  isFistRequest,
  setIsFistRequest,
  infoApplication,
  setInfoApplication,
  isScreeningRequesting,
  setButtonScreeningId,
  handleScreeningCandidates,
  handleUndoQuittedCandidate,
  buttonScreeningId,
  location,
  processCandidateAmount
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(500), {
    defaultMatches: true
  });
  const dispatch = useDispatch();
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const [isOpen, setIsOpen] = useState(true);
  const [value, setValue] = useState('1');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuShowing = Boolean(anchorEl);
  const {
    userProfile,
    userType,
    candidateListParams,
    setCandidateListParams,
    setCurrentJobId
  } = useAuth();
  const companyId = userProfile.associatedCompanies[0].company.id;
  const [profileAvatarUrl, setProfileAvatarUrl] = useState(noImage);
  const [curriculumId, setCurriculumId] = useState('');
  const [isLoadingPrevious, setIsLoadingPrevious] = useState(false);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const isNotification = history.location.search.includes('notification=true');
  const [currentTab, setCurrentTab] = useState<string>('application');
  const urlParams = new URLSearchParams(window.location.search);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isOpenDrawerLateral, setIsOpenDrawerLateral] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const premiumSubscriber =
    userProfile.associatedCompanies[0].company.account?.subscriptionCreatedAt &&
    userProfile.associatedCompanies[0].company.account?.invoice?.status ===
      'PAID';

  const isOutOfProcess = (status: string) => {
    if (status === 'UNAPPROVED' || status === 'QUITTER') return true;
    else return false;
  };

  const handleIconScore = (score: string, isOutOfProcess?: boolean) => {
    if (score === 'green')
      return <ArrowUpward viewBox="-3 -3 30 30" style={{ color: '#ffffff' }} />;
    if (score === 'orange')
      return <Remove viewBox="-3 -3 30 30" style={{ color: '#ffffff' }} />;
    if (score === 'red')
      return (
        <ArrowDownward viewBox="-3 -3 30 30" style={{ color: '#ffffff' }} />
      );
  };

  const handleScoreBackgroundColor = (
    score: ScoreBackgroundColorType,
    isOutOfProcess?: boolean
  ) => {
    if (score === 'green') return '#4CAF50';
    if (score === 'orange') return '#FFB400';
    if (score === 'red') return '#F44336';
  };

  const handleFavoriteCandidate = (
    isFavorite,
    candidateId,
    companyId,
    infoApplication
  ) => {
    let newIsFavorite = isFavorite;
    if (!isFavorite) {
      api
        .post(`/companies/${companyId}/favorite-candidates`, {
          userId: candidateId
        })
        .then((res) => {
          newIsFavorite = true;
          const newApplication = { ...infoApplication };
          newApplication.isFavorite = newIsFavorite;
          setInfoApplication(newApplication);
          enqueueSnackbar(
            `Candidato ${
              isFavorite ? 'desfavoritado' : 'favoritado'
            } com sucesso.`,
            { variant: 'success' }
          );
        })
        .catch((error) => {
          enqueueSnackbar(error.message, {
            variant: 'error'
          });
        });
    } else {
      api
        .delete(`/companies/${companyId}/favorite-candidates/${candidateId}`)
        .then((res) => {
          newIsFavorite = false;
          const newApplication = { ...infoApplication };
          newApplication.isFavorite = newIsFavorite;
          setInfoApplication(newApplication);
          enqueueSnackbar(
            `Candidato ${
              isFavorite ? 'desfavoritado' : 'favoritado'
            } com sucesso.`,
            { variant: 'success' }
          );
        })
        .catch((error) => {
          enqueueSnackbar(error.message, {
            variant: 'error'
          });
        });
    }
    dispatch(
      setOpportunityCandidates(
        state.opportunityCandidates.opportunityCandidates.map((application) => {
          if (application?.application?.id === infoApplication?.id) {
            const newApplication = { ...application };
            newApplication.favorite = !isFavorite ? true : false;

            return newApplication;
          }
          return application;
        })
      )
    );
  };

  const tabs = [
    { value: 'application', label: 'CANDIDATURA' },
    { value: 'resume', label: 'CURRÍCULO' },
    { value: 'personal', label: 'DADOS PESSOAIS' },
    { value: 'exams', label: 'AVALIAÇÕES' }
  ];

  const handleTabsChange = (
    event: React.SyntheticEvent,
    value: string
  ): void => {
    setCurrentTab(value);
  };

  useEffect(() => {
    if (history.location.pathname.includes('/application')) {
      setCurrentTab('application');
    }
    if (history.location.pathname.includes('/resume')) {
      setCurrentTab('resume');
    }
    if (history.location.pathname.includes('/personal')) {
      setCurrentTab('personal');
    }
    if (history.location.pathname.includes('/exams')) {
      setCurrentTab('exams');
    }
  }, [history.location.pathname]);

  const handleAproveCandidates = () => {
    return history.replace(
      `${history.location.search}&modal=hire&candidateIds=${encodeURI(
        infoApplication?.id
      )}`
    );
  };

  const handleReproveCandidates = () => {
    return history.replace(
      `${history.location.search}&modal=reprove&candidateIds=${encodeURI(
        infoApplication?.id
      )}`
    );
  };

  const handleUndoUnapproveCandidate = () => {
    return history.replace(
      `${history.location.search}&modal=undo&candidateIds=${encodeURI(
        infoApplication?.id
      )}`
    );
  };

  const handleUndoQuittedCandidates = () => {
    return history.replace(
      `${history.location.search}&modal=quitter&candidateIds=${encodeURI(
        infoApplication?.id
      )}`
    );
  };

  const handleCommentCandidates = () => {
    return history.replace(
      `${history.location.search}&modal=message&candidateIds=${encodeURI(
        infoApplication?.id
      )}`
    );
  };

  const state = useSelector((state: any) => state);
  const { candidateCurriculum, curriculumFile } = useSelector(
    (state: any) => state.candidateCurriculum
  );

  const { countJobStatus } = useSelectorStore(
    (state) => state.opportunityCandidates
  );

  const processCandidateAmountStore = countJobStatus?.totalImmutableProcess;

  const Doc = Boolean(candidateCurriculum) && (
    <ResumePDF info={candidateCurriculum} avatarUrl={profileAvatarUrl} />
  );
  const [instance, updateInstance] = usePDF({ document: Doc });

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOnClickOption = (eventOnClick: any, onClickReturn: any) => {
    setAnchorEl(null);
    return eventOnClick(onClickReturn);
  };

  const showOptionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const formatFilterParams = useCallback((key: string, value: string) => {
    const isNumber = ['page', 'limit', 'match'].includes(key);
    const isArray = ['process', 'status', 'candidatesIds', 'modal'].includes(
      key
    );
    const isBoolean = ['favorite', 'shouldUpdate', 'updatedFilter'].includes(
      key
    );

    if (isNumber) {
      return Number(value);
    }
    if (isArray) {
      return value?.split(',').filter((value) => Boolean(value));
    }
    if (isBoolean) {
      return Boolean(value);
    }
    return value;
  }, []);

  const { params, updateFilter } = useFilterRoute<FilterParams>(
    formatFilterParams,
    ['limit', 'match']
  );

  useEffect(() => {
    if (
      (!history.location.search.includes('notification=true') &&
        (infoApplication?.situation?.status === 'UNAPPROVED' ||
          infoApplication?.situation?.status === 'REVIEW') &&
        !history.location.search.includes('UNAPPROVED')) ||
      (!history.location.search.includes('notification=true') &&
        infoApplication?.situation?.status === 'REGISTERED' &&
        history.location.search.includes('QUITTER_REGISTERED'))
    ) {
      setIsLoadingNext(true);
      setIsLoading(true);
      setIsFistRequest(true);
      if (listCandidatesInOpportunity?.length < 1) {
        onClose();
      } else if (
        listCandidatesInOpportunity?.length > 1 &&
        listCandidatesInOpportunity.length !== indexCandidate + 1
      ) {
        urlParams.set(
          'applicationId',
          `${listCandidatesInOpportunity[indexCandidate + 1].application?.id}`
        );
        history.push({
          pathname: history.location.pathname,
          search: urlParams.toString()
        });
      } else {
        urlParams.set(
          'applicationId',
          `${listCandidatesInOpportunity[0].application?.id}`
        );
        setIndexCandidate(0);
        history.push({
          pathname: history.location.pathname,
          search: urlParams.toString()
        });
      }
    }
  }, [infoApplication?.situation?.status]);

  const handlePreviousCandidate = () => {
    setIndexCandidate(indexCandidate - 1);
    setIsLoadingPrevious(true);
    setIsLoading(true);
    setIsFistRequest(true);
    urlParams.set(
      'applicationId',
      listCandidatesInOpportunity[indexCandidate - 1].application?.id
    );
    history.push({
      pathname: history.location.pathname,
      search: urlParams.toString()
    });
  };
  const handleNextCandidate = () => {
    setIndexCandidate(indexCandidate + 1);
    setIsLoadingNext(true);
    setIsLoading(true);
    setIsFistRequest(true);
    urlParams.set(
      'applicationId',
      listCandidatesInOpportunity[indexCandidate + 1].application?.id
    );
    history.push({
      pathname: history.location.pathname,
      search: urlParams.toString()
    });
  };

  const getApplicationInfo = async () => {
    setIsLoading(true);
    try {
      const { data } = await companyService.getApplicationInfo(
        urlParams.get('applicationId')
      );
      dispatch(setCandidateCurriculum(data.candidate));
      setCurriculumId(data.candidate?.curriculum?.id);
      setInfoApplication(data);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Candidato não encontrado!', { variant: 'error' });
      handleCloseDrawerLateral();
    } finally {
      setIsLoading(false);
      setIsLoadingNext(false);
      setIsLoadingPrevious(false);
    }
  };

  useEffect(() => {
    setIsOpenDrawerLateral(history.location.search.includes('drawer=true'));
    getApplicationInfo();
    setIsFistRequest(false);
  }, [indexCandidate]);

  useEffect(() => {
    if (
      !history.location.search.includes('modal=') &&
      listCandidatesInOpportunity
    ) {
      let currentCandidate = listCandidatesInOpportunity?.find(
        (candidate) =>
          candidate?.application?.id === urlParams.get('applicationId')
      );
      setIndexCandidate(listCandidatesInOpportunity?.indexOf(currentCandidate));
    }
  });

  useEffect(() => {
    updateInstance();

    if (
      candidateCurriculum &&
      candidateCurriculum?.id === infoApplication?.id
    ) {
      if (candidateCurriculum?.curriculum?.file?.path && !curriculumFile) {
        getApplicationInfo();
      }
    } else {
      dispatch(setCandidateCurriculumFile(null));
    }
  }, [candidateCurriculum]);

  useEffect(() => {
    if (
      (userType.isCandidate() && userProfile) ||
      (!userType.isCandidate() && infoApplication)
    ) {
      let tempId = userType.isCandidate()
        ? userProfile.user?.id
        : infoApplication?.id;
      setProfileAvatarUrl(
        `${process.env.REACT_APP_MEDIA_URL}/users/${tempId}/avatar-small.png`
      );
    }
  }, []);

  const removeFromRoute = (paramsToRemove: string[]) => {
    const searchParams = removeRouteSearchParams(
      history.location?.search,
      paramsToRemove
    );
    const correctSearch = searchParams.includes('??')
      ? searchParams.replace('?', '')
      : searchParams;
    history.push({ search: correctSearch, state: history.location?.state });
  };

  let labelToValue = {
    Inscritos: 'REGISTERED',
    Aprovados: 'APPROVED',
    Reprovados: 'UNAPPROVED',
    Desistentes: 'QUITTER'
  };

  const requestParams: RequestParamsProps = {
    page: params?.page || 1,
    limit: params?.limit || 5,
    userName: params?.userName?.trim() || '',
    process: params?.process?.map((process) => process).join(',') || '',
    status:
      params?.status
        ?.map((status) => labelToValue[status] || status)
        .join(',') || '',
    favorite: Boolean(params?.favorite) ? 'true' : '',
    match: params?.match || 2,
    shouldUpdate: params?.shouldUpdate || true,
    updatedFilter: params?.updatedFilter || false,
    state: params?.state || null,
    salaryRate: params?.salaryRate || '',
    drawer: params.drawer || '',
    applicationId: params.applicationId || '',
    notification: params.notification || ''
  };

  const onClose = () => {
    removeFromRoute([
      'drawer',
      'applicationId',
      'notification',
      'candidateIds'
    ]);
    setCandidateListParams({
      ...requestParams,
      drawer: '',
      applicationId: '',
      notification: ''
    });
  };

  const handleClickOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleCloseDrawerLateral = () => {
    setIsOpenDrawerLateral(false);
    setInfoApplication({});
    onClose();
  };

  const currentTabView = urlParams.get('tab');

  const selectFunctionByOption = (option: string) => {
    if (option === 'Reprovar') {
      handleReproveCandidates();
    }

    if (option === 'Desfazer reprovação') {
      handleUndoUnapproveCandidate();
    }

    if (option === 'Desfazer desistência') {
      handleUndoQuittedCandidates();
    }

    if (option === 'Aprovar') {
      handleAproveCandidates();
    }
  };

  const changeColor = (option: string) => {
    if (option === 'Apagar' || option === 'Reprovar') {
      return '#F44336';
    } else if (option === 'Aprovar') {
      return '#4CAF50';
    } else {
      return '#000000DE';
    }
  };

  const tooltipText = (status: string) => {
    let text;
    if (
      !premiumSubscriber &&
      processCandidateAmount >= 5 &&
      status !== 'QUITTER_REGISTERED'
    ) {
      text = 'Assine o Premium para acesso ilimitado';
    } else {
      if (status !== 'QUITTER_REGISTERED') {
        text = 'Triar candidato';
      } else {
        text = 'Desfazer desistência';
      }
    }
    return text;
  };

  const menuListOptions = [
    {
      option: (
        <Typography
          style={{
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center'
          }}
          component="a"
          href={instance.url}
          download="curriculo-gria.pdf"
        >
          Baixar currículo
        </Typography>
      ),
      onClick: () => {},
      disabled: false
    },
    {
      option: infoApplication?.situation?.status === 'REVIEW' ? 'Reprovar' : '',
      onClick: () => {
        selectFunctionByOption(
          infoApplication?.situation?.status === 'REVIEW' ? 'Reprovar' : ''
        );
      },
      disabled: false
    },
    {
      option:
        infoApplication?.situation?.status === 'UNAPPROVED'
          ? 'Desfazer reprovação'
          : '',
      onClick: () => {
        selectFunctionByOption(
          infoApplication?.situation?.status === 'UNAPPROVED'
            ? 'Desfazer reprovação'
            : ''
        );
      },
      disabled:
        !premiumSubscriber &&
        (processCandidateAmount || processCandidateAmountStore) >= 5
    },
    {
      option:
        infoApplication?.situation?.status === 'QUITTER_PROCESS'
          ? 'Desfazer desistência'
          : '',
      onClick: () => {
        selectFunctionByOption(
          infoApplication?.situation?.status === 'QUITTER_PROCESS'
            ? 'Desfazer desistência'
            : ''
        );
      },
      disabled:
        !premiumSubscriber &&
        (processCandidateAmount || processCandidateAmountStore) >= 5
    },
    {
      option: infoApplication?.situation?.status === 'REVIEW' ? 'Aprovar' : '',
      onClick: () => {
        selectFunctionByOption(
          infoApplication?.situation?.status === 'REVIEW' ? 'Aprovar' : ''
        );
      },
      disabled: false
    }
  ];

  const currentStepInfoApplication = (infoApplication: any) => {
    const applicationEvents: any = infoApplication?.events;
    const resultado = applicationEvents?.filter(
      (event) => event.type === 'MANAGING'
    )[0];
    return resultado?.status === null
      ? { ...resultado, status: 'REVIEW' }
      : resultado;
  };

  const exibeInformacoes = opportunity?.process
    ?.map((process) => process.process)
    .join(',');

  useEffect(() => {
    if (opportunity) {
      urlParams.set('process', exibeInformacoes);
      history.push(
        `/company/jobs/view/${opportunity.id}?${urlParams.toString()}`
      );
    }
  }, [opportunity && opportunity]);

  const updateApplication = (
    applicationList: { situation: any; id: any; events: any }[]
  ) => {
    const newApplication = { ...infoApplication };
    newApplication.events = applicationList[0]?.events;
    newApplication.situation = applicationList[0]?.situation;
    setInfoApplication(newApplication);
    dispatch(
      setOpportunityCandidates(
        state.opportunityCandidates.opportunityCandidates.map((application) => {
          if (application?.application?.id === infoApplication?.id) {
            const newApplication = { ...application };
            newApplication.events = applicationList[0]?.events;
            newApplication.situation = applicationList[0]?.situation;
            return newApplication;
          }
          return application;
        })
      )
    );
  };

  const updateExamRequest = (data: any) => {
    const newApplication = { ...infoApplication };
    newApplication.events = data[0];
    setInfoApplication(newApplication);
  };

  useEffect(() => {
    if (!isLoading && infoApplication?.candidate?.name === undefined) {
      onClose();
      history.push(`/company/jobs?opportunity=not-found`);
    }
  });

  return (
    <Drawer
      open={isOpenDrawerLateral}
      variant="temporary"
      anchor="right"
      onClose={(_, reason) =>
        reason === 'backdropClick' && handleCloseDrawerLateral()
      }
    >
      <Box className={classes.contentBox}>
        <Box className={classes.iconBox}>
          {!history.location.search.includes('notification=true') && (
            <LoadingButton
              disabled={
                indexCandidate === 0 || isLoading || (isLoadingNext && true)
              }
              loading={isLoadingPrevious}
              onClick={handlePreviousCandidate}
              style={{ marginRight: '8px' }}
              className={classes.buttonArrow}
            >
              {!isLoadingPrevious && (
                <ArrowBack
                  style={
                    isLoadingNext || indexCandidate === 0
                      ? { color: theme.palette.text.disabled }
                      : {}
                  }
                  className={classes.arrows}
                />
              )}
            </LoadingButton>
          )}
          {!history.location.search.includes('notification=true') && (
            <LoadingButton
              disabled={
                indexCandidate === listCandidatesInOpportunity?.length - 1 ||
                isLoading ||
                (isLoadingPrevious && true)
              }
              loading={isLoadingNext}
              onClick={handleNextCandidate}
              className={classes.buttonArrow}
            >
              {!isLoadingNext && (
                <ArrowForward
                  style={
                    isLoadingPrevious ||
                    indexCandidate === listCandidatesInOpportunity?.length - 1
                      ? { color: theme.palette.text.disabled }
                      : {}
                  }
                  className={classes.arrows}
                />
              )}
            </LoadingButton>
          )}
          <Button
            onClick={() => handleCloseDrawerLateral()}
            className={classes.buttonExit}
          >
            <Close style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
          </Button>
        </Box>
        {!isLoading ? (
          <Box className={classes.avatarBox}>
            <Box style={{ display: 'flex', marginRight: '12px' }}>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                {premiumSubscriber ||
                !['REGISTERED', 'QUITTER_REGISTERED'].includes(
                  infoApplication?.situation?.status
                ) ? (
                  <Avatar
                    className={classes.avatar}
                    src={
                      infoApplication?.candidate?.hasAvatar
                        ? `${mediaUrl}/users/${infoApplication?.candidate?.id}/avatar-small.png`
                        : ''
                    }
                  />
                ) : (
                  <Avatar className={classes.avatar}>
                    <Person />
                  </Avatar>
                )}
                <StepIcon
                  style={{
                    width: '20px',
                    height: '20px',
                    marginLeft: '-16px',
                    marginTop: '18px',
                    zIndex: 1
                  }}
                  color={handleScoreBackgroundColor(
                    !isNotification
                      ? infoApplication?.colorScore
                      : infoApplication?.colorScore,
                    isOutOfProcess(
                      !isNotification
                        ? infoApplication?.situation?.status
                        : infoApplication?.situation?.status
                    )
                  )}
                  icon={handleIconScore(
                    !isNotification
                      ? infoApplication?.colorScore
                      : infoApplication?.colorScore,
                    isOutOfProcess(
                      !isNotification
                        ? infoApplication?.situation?.status
                        : infoApplication?.situation?.status
                    )
                  )}
                  isIconComponent
                />
                <Box className={classes.nameBox}>
                  <Typography component="span" className={classes.name}>
                    {infoApplication?.candidate?.name}
                    {` ${infoApplication?.candidate?.surname}`}
                  </Typography>
                  <Typography component="span" className={classes.textSpan}>
                    {infoApplication?.candidate?.currentPosition}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              {location === 'jobs' &&
                opportunity?.status !== 'CLOSED' &&
                (infoApplication?.situation?.status === 'QUITTER_REGISTERED' ||
                  infoApplication?.situation?.status === 'REGISTERED') && (
                  <Tooltip
                    title={tooltipText(infoApplication?.situation?.status)}
                  >
                    <span>
                      <IconButton
                        id={`screening-candidate-table-id${infoApplication?.id}`}
                        className={classes.screeningCandidateIcon}
                        disabled={
                          isScreeningRequesting ||
                          (!premiumSubscriber &&
                            processCandidateAmount >= 5 &&
                            infoApplication?.situation.status !==
                              'QUITTER_REGISTERED')
                        }
                        onClick={(e) => {
                          setButtonScreeningId([infoApplication?.id]);
                          if (
                            infoApplication?.situation?.status ===
                            'QUITTER_REGISTERED'
                          ) {
                            handleUndoQuittedCandidate(infoApplication?.id);
                          } else {
                            handleScreeningCandidates(infoApplication?.id);
                          }
                        }}
                      >
                        {infoApplication?.situation?.status !==
                        'QUITTER_REGISTERED' ? (
                          isScreeningRequesting &&
                          buttonScreeningId?.includes(infoApplication?.id) ? (
                            <CircularProgress size={20} color="primary" />
                          ) : (
                            <ThumbUpAlt />
                          )
                        ) : isScreeningRequesting &&
                          buttonScreeningId?.includes(infoApplication?.id) ? (
                          <CircularProgress size={20} color="primary" />
                        ) : (
                          <Undo color="disabled" />
                        )}
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              {infoApplication?.isFavorite ? (
                <Star
                  fontSize="medium"
                  onClick={() =>
                    handleFavoriteCandidate(
                      infoApplication?.isFavorite,
                      infoApplication?.candidate.id,
                      companyId,
                      infoApplication
                    )
                  }
                  style={{
                    cursor: 'pointer',
                    marginRight: '9px',
                    marginLeft: '18px',
                    color: '#6B20DD'
                  }}
                />
              ) : (
                <StarBorder
                  fontSize="medium"
                  onClick={() =>
                    handleFavoriteCandidate(
                      infoApplication?.isFavorite,
                      infoApplication?.candidate.id,
                      companyId,
                      infoApplication
                    )
                  }
                  style={{
                    cursor: 'pointer',
                    marginRight: '9px',
                    marginLeft: '18px',
                    color: 'rgba(0, 0, 0, 0.54)'
                  }}
                />
              )}
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-expanded={isMenuShowing ? 'true' : undefined}
                aria-haspopup="true"
                onClick={showOptionMenu}
                style={{ paddingRight: 0 }}
              >
                <MoreVert
                  style={{ color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }}
                />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button'
                }}
                anchorEl={anchorEl}
                open={isMenuShowing}
                onClose={handleCloseMenu}
                PaperProps={{
                  style: {
                    maxWidth: 160,
                    borderRadius: 4
                  }
                }}
              >
                {menuListOptions?.map(
                  ({ option, onClick, disabled }, index) => (
                    <>
                      {option !== '' ? (
                        <Tooltip
                          title={
                            [
                              'Desfazer reprovação',
                              'Desfazer desistência'
                            ].includes(
                              typeof option === 'string' ? option : ''
                            ) &&
                            !premiumSubscriber &&
                            (processCandidateAmount ||
                              processCandidateAmountStore) >= 5
                              ? 'Você possui 5 pessoas em processo'
                              : ''
                          }
                          placement="top"
                        >
                          <span>
                            <MenuItem
                              id={`column-options-menu-item-${index}`}
                              key={index}
                              onClick={() => {
                                onClick();
                                handleCloseMenu();
                              }}
                              className={classes.menuItemOptions}
                              disabled={disabled}
                              divider={
                                typeof option !== 'string' &&
                                option?.props?.children ===
                                  'Baixar currículo' &&
                                infoApplication?.situation?.status !==
                                  'APPROVED' &&
                                currentTabView !== 'registered'
                              }
                            >
                              <ListItemText
                                style={{
                                  color: changeColor(
                                    typeof option === 'string' ? option : ''
                                  )
                                }}
                              >
                                {option}
                              </ListItemText>
                            </MenuItem>
                          </span>
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                    </>
                  )
                )}
              </Menu>
            </Box>
          </Box>
        ) : (
          <Box
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              background: theme.palette.background.default,
              padding: '12px 0px'
            }}
          >
            <Skeleton
              variant="circular"
              animation="wave"
              width={'70px'}
              height={'40px'}
              style={{ marginLeft: '16px' }}
            />
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                marginLeft: 12
              }}
            >
              <Skeleton animation="wave" width={'50%'} height={'22px'} />
              <Skeleton animation="wave" width={'30%'} height={'22px'} />
            </Box>
            <Box>
              <Skeleton animation="wave" width={'170px'} height={'45px'} />
            </Box>
            <StarBorder
              fontSize="medium"
              style={{
                cursor: 'pointer',
                marginRight: '9px',
                marginLeft: '18px',
                color: 'rgba(0, 0, 0, 0.54)'
              }}
            />
            <MoreVert
              style={{
                color: 'rgba(0, 0, 0, 0.54)',
                cursor: 'pointer',
                marginRight: '16px'
              }}
            />
          </Box>
        )}
        {!isLoading ? (
          <Box>
            <Divider />
            <Box className={classes.boxTitle}>
              <Work style={{ color: '#00000061' }} />
              <Typography className={classes.title}>
                {infoApplication?.job?.title}
              </Typography>
            </Box>
            <Divider />
          </Box>
        ) : (
          <Skeleton
            animation="wave"
            width={'80%'}
            height={'22px'}
            style={{ margin: '16px' }}
          />
        )}
        <Box className={classes.tabsBox}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="primary"
            indicatorColor="primary"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                disabled={isLoading}
              />
            ))}
          </Tabs>
          <Divider />
        </Box>
        {!isLoading ? (
          <>
            {currentTab === 'application' && (
              <ApplicationTable
                premiumSubscriber={premiumSubscriber}
                infoApplication={infoApplication}
                setCurrentTab={setCurrentTab}
              />
            )}

            {currentTab === 'resume' && (
              <Box style={{ margin: '16px' }}>
                <ResumeTable infoApplication={infoApplication} />
              </Box>
            )}

            {currentTab === 'personal' && (
              <Box style={{ margin: '16px' }}>
                {!premiumSubscriber && <PaymentAlert />}
                <PersonalDataTable
                  infoApplication={infoApplication}
                  premiumSubscriber={premiumSubscriber}
                />
              </Box>
            )}
            {currentTab === 'exams' && (
              <Box style={{ margin: '16px' }}>
                <Typography
                  className={classes.header}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: !premiumSubscriber && theme.palette.primary.main
                  }}
                >
                  Certificação de habilidades{' '}
                  {!premiumSubscriber && (
                    <PremiumIcon style={{ marginLeft: '12px' }} />
                  )}
                </Typography>
                {!premiumSubscriber ? (
                  <PaymentAlert />
                ) : infoApplication?.job?.exams.length && premiumSubscriber ? (
                  <ExamsCard
                    companyExams={infoApplication?.job?.exams}
                    candidateExams={
                      infoApplication?.candidate?.curriculum?.exams
                    }
                    isCompanyView={true}
                    idApplication={infoApplication?.id}
                    updateExamRequest={updateExamRequest}
                    isClosed={opportunity?.status === 'CLOSED'}
                  />
                ) : (
                  <Box className={classes.noExams}>
                    Essa vaga ainda não possui{' '}
                    <b> certificações de habilidades </b> configuradas
                  </Box>
                )}
              </Box>
            )}
          </>
        ) : (
          <>
            {currentTab === 'application' &&
              new Array(3).fill(0).map((_, index) => {
                return (
                  <Box key={index}>
                    <Skeleton
                      animation="wave"
                      width={'30%'}
                      height={'22px'}
                      style={{ margin: '16px' }}
                    />
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        border: '1px solid rgba(0, 0, 0, 0.13)',
                        borderRadius: '4px',
                        padding: '16px',
                        margin: '0px 16px'
                      }}
                    >
                      <Skeleton
                        animation="wave"
                        width={'50%'}
                        height={'22px'}
                      />
                      <Skeleton
                        animation="wave"
                        width={'30%'}
                        height={'22px'}
                      />
                      <Skeleton
                        animation="wave"
                        width={'50%'}
                        height={'22px'}
                      />
                      <Skeleton
                        animation="wave"
                        width={'30%'}
                        height={'22px'}
                      />
                    </Box>
                  </Box>
                );
              })}
            {currentTab === 'resume' && (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  border: '1px solid rgba(0, 0, 0, 0.13)',
                  borderRadius: '4px',
                  padding: '16px',
                  margin: '16px'
                }}
              >
                {new Array(5).fill(0).map((_, index) => {
                  return (
                    <Box key={index}>
                      <Skeleton
                        animation="wave"
                        width={'50%'}
                        height={'22px'}
                      />
                      <Skeleton
                        animation="wave"
                        width={'30%'}
                        height={'22px'}
                      />
                      <Skeleton
                        animation="wave"
                        width={'40%'}
                        height={'22px'}
                      />
                      <Skeleton
                        animation="wave"
                        width={'30%'}
                        height={'22px'}
                        style={{ marginBottom: '12px' }}
                      />
                    </Box>
                  );
                })}
              </Box>
            )}
            {currentTab === 'personal' && (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  border: '1px solid rgba(0, 0, 0, 0.13)',
                  borderRadius: '4px',
                  padding: '16px',
                  margin: '16px'
                }}
              >
                {new Array(5).fill(0).map((_, index) => {
                  return (
                    <Box key={index}>
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Skeleton
                          animation="wave"
                          width={'30%'}
                          height={'40px'}
                        />
                        <Skeleton
                          animation="wave"
                          width={'30%'}
                          height={'40px'}
                        />
                      </Box>
                      {index + 1 !== 5 && <Divider />}
                    </Box>
                  );
                })}
              </Box>
            )}
            {currentTab === 'exams' && (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  border: '1px solid rgba(0, 0, 0, 0.13)',
                  borderRadius: '4px',
                  padding: '0px 16px',
                  margin: '16px'
                }}
              >
                {new Array(5).fill(0).map((_, index) => {
                  return (
                    <Box key={index}>
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          margin: '12px 0px'
                        }}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            width: '100%'
                          }}
                        >
                          <Skeleton
                            animation="wave"
                            width={'40%'}
                            height={'25px'}
                          />
                          <Skeleton
                            animation="wave"
                            width={'30%'}
                            height={'20px'}
                          />
                        </Box>
                        <Skeleton
                          variant="circular"
                          animation="wave"
                          width={'40px'}
                          height={'40px'}
                          style={{ marginLeft: '16px', alignSelf: 'center' }}
                        />
                      </Box>
                      {index + 1 !== 5 && <Divider />}
                    </Box>
                  );
                })}
              </Box>
            )}
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default DrawerLateral;
