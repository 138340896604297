import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import insightsApi from 'src/api/insightsApi';
import type { AppThunk } from 'src/store';
import type { IPerformance } from 'src/types/perfomance';

interface PerformanceState {
  performance: IPerformance[];
  thereUsers: boolean;
  totalItems: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
  surveyId: string;
  surveyLink: string;
}

interface RequestParamsProps {
  page: number;
  limit: number;
  hiring_email: string;
  company_id: string;
  customer: string;
  fuzzySearch?: string;
  role: string;
  user_id: string;
}

const initialState: PerformanceState = {
  performance: [],
  thereUsers: false,
  totalItems: 0,
  itemsPerPage: 0,
  totalPages: 0,
  currentPage: 0,
  surveyId: '',
  surveyLink: ''
};

const slice = createSlice({
  name: 'performance',
  initialState,
  reducers: {
    getPerformance(
      state: PerformanceState,
      action: PayloadAction<{
        meta: {
          totalItems: number;
          totalPages: number;
          users: boolean;
          survey_id: string;
          survey_link: string;
        };
        items: IPerformance[];
      }>
    ) {
      const { items, meta } = action.payload;

      state.performance =
        items.map((performance, index) => {
          return performance;
        }) || [];

      state.performance = items;
      state.thereUsers = meta.users;
      state.totalItems = meta.totalItems;
      state.totalPages = meta.totalPages;
      state.surveyId = meta.survey_id;
      state.surveyLink = meta.survey_link;
    }
  }
});

export const reducer = slice.reducer;

export const getPerformance =
  (params: RequestParamsProps): AppThunk =>
  async (dispatch) => {
    const response = await insightsApi.post<{
      meta: {
        totalItems: number;
        totalPages: number;
        users: boolean;
        survey_id: string;
        survey_link: string;
      };
      items: IPerformance[];
    }>(`/performance/list-people`, {
      company_id: params?.company_id,
      limit: params?.limit,
      page: params?.page,
      customer: params?.customer,
      hiring_email: params?.hiring_email,
      fuzzySearch: params?.fuzzySearch ?? '',
      role: params?.role,
      user_id: params?.user_id
    });

    dispatch(slice.actions.getPerformance(response.data));
  };

export default slice;
