export const opportunityStatus = {
  DEFAULT: [
    {
      label: 'Rascunho',
      value: 'DRAFT'
    },
    {
      label: 'Ativa',
      value: 'OPEN'
    }
  ],
  DRAFT: [
    {
      label: 'Rascunho',
      value: 'DRAFT'
    },
    {
      label: 'Ativa',
      value: 'OPEN'
    }
  ],
  PAUSE: [{
    label: 'Pausada',
    value: 'PAUSE'
  },
  {
    label: 'Ativa',
    value: 'OPEN'
  }],
  CANCELED: [
    {
      label: 'Cancelada',
      value: 'CANCELED'
    }
  ],
  OPEN: [
    {
      label: 'Ativa',
      value: 'OPEN'
    },
    {
      label: 'Pausada',
      value: 'PAUSE'
    },
    {
      label: 'Finalizada',
      value: 'CLOSED'
    },
    {
      label: 'Cancelada',
      value: 'CANCELED'
    }
  ],
  CLOSED: [
    {
      label: 'Finalizada',
      value: 'CLOSED'
    }
  ],
  UNPAID: [
    { 
      label: 'Pendente', 
    value: 'UNPAID' 
    }
  ]
};
export const currencyArray = [
  { label: 'Real', value: 'BRL' },
  { label: 'Euro', value: 'EUR' },
  { label: 'Dólar', value: 'USD' },
  { label: "Peso mexicano", value: 'MXN' },
  { label: "Peso colombiano", value: 'COP' },
].sort(function (a, b) {
  return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0);
});

export const removedCurrencies = ['MXN','COP']

export const skillLevelOptions = [
  { label: 'Assistente', value: 'ASSISTANT', code: 1 },
  { label: 'Auxiliar', value: 'AUXILIARY', code: 2 },
  { label: 'Coordenação', value: 'COORDINATOR', code: 3 },
  { label: 'Diretoria', value: 'DIRECTOR', code: 4 },
  { label: 'Especialista', value: 'SPECIALIST', code: 5 },
  { label: 'Estágio', value: 'INTERNSHIP', code: 6 },
  { label: 'Gerencial', value: 'MANAGER', code: 7 },
  { label: 'Júnior', value: 'JUNIOR', code: 8 },
  { label: 'Operacional', value: 'OPERACIONAL', code: 9 },
  { label: 'Pleno', value: 'INTERMEDIATE', code: 10 },
  { label: 'Sênior', value: 'SENIOR', code: 11 },
  { label: 'Trainee', value: 'TRAINEE', code: 12 },
].sort((a, b) => a.code - b.code);

//quantidade de vagas
export const quantityRange = Array(10).fill(null).map((_, i) => String(i + 1))

// tipo de contrato
export const jobTypes = [
  { label: 'Trainee', value: 'TRAINEE' },
  { label: 'Estágio', value: 'INTERNSHIP' },
  { label: 'CLT', value: 'CLT' },
  { label: 'PJ', value: 'PJ' },
  { label: 'Temporário', value: 'CONTRACT' },
  { label: 'Autônomo', value: 'AUTONOMOUS' },
  { label: 'A definir', value: 'TO_BE_DEFINED' }
].sort(function (a, b) {
  return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0);
});

// período
export const workLoadTypes = [
  { label: 'Integral', value: 'FULL_TIME', code: 1 },
  { label: 'Meio período', value: 'PART_TIME', code: 2 },
  { label: 'Flexível', value: 'FLEX', code: 3 },
].sort((a, b) => a.code - b.code);;

// Alocação
export const workPlaceTypes = [
  { label: 'Remoto', value: 'REMOTE', code: 1 },
  { label: 'Híbrido', value: 'HYBRID', code: 2 },
  { label: 'Presencial', value: 'PRESENTIAL', code: 3 },
  { label: 'Offshore', value: 'OFFSHORE', code: 4 }
].sort((a, b) => a.code - b.code);


export const amountWorkers = [
  { label: '1-10', value: '1-10' },
  { label: '11-50', value: '11-50' },
  { label: '51-100', value: '51-100' },
  { label: '101-200', value: '101-200' },
  { label: '201-500', value: '201-500' },
  { label: '501-1.000', value: '501-1000' },
  { label: '1.001-5.000', value: '1001-5000' },
  { label: '5.001-10.000', value: '5001-10000' },
  { label: 'mais de 10.000', value: '10000' },
];