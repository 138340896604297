export const allowedFileTypes = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/msword', // .doc
  'application/pdf'
]

export const fileTypes = {
  WORD: 'application/msword',
  PDF: 'application/pdf'
}

export const fileExtensions = {
  WORD: '.doc',
  PDF: '.pdf'
}

export const getDownloadFileName = (email: string, name?: string, surname?: string) => {
  let downloadName = 'Currículo de ';
  downloadName += name ? `${name} ${surname.split(' ').splice(-1).join(' ')}` : email;

  return downloadName
}

export const getDownloadFileLink = (fileType: string, file: string) => `data:${fileType};base64,${file}`