import React from 'react';
import { Card, Box, Typography, Radio } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useStyles } from './styles';

interface OptionCardProps {
  icon: JSX.Element;
  title: string;
  subTitle: string;
  selected: boolean;
  onClick: () => void;
}

function OptionCard({
  icon,
  title,
  subTitle,
  selected,
  onClick
}: OptionCardProps) {
  const styles = useStyles();
  const theme = useTheme();

  const selectedColor = selected ? theme.palette.primary.main : '';
  const stylesContainer = selected ? styles.containerActive : styles.container;

  return (
    <Card onClick={onClick} className={stylesContainer}>
      <Box className={styles.icon} style={{ color: selectedColor }}>
        {icon}
      </Box>
      <Box className={styles.text}>
        <Typography className={styles.title} style={{ color: selectedColor }}>
          {title}
        </Typography>
        <Typography className={styles.subTitle}>{subTitle}</Typography>
      </Box>
      <Radio
        checked={selected}
        className={styles.radio}
        style={{ color: selectedColor }}
      />
    </Card>
  );
}

export default OptionCard;
