import api from '../api/insightsApi';
export class InsightsService {
  private static instance: InsightsService;

  constructor() {
    if (!InsightsService.instance) {
      InsightsService.instance = this;
    }
    return InsightsService.instance;
  }

  getInsightsNinebox = (groupRoundId: string) => {
    return api.post('/generate-ninebox', {
      group_round_id: groupRoundId
    });
  };

  getInsightsHeatmap = (groupRoundId: string) => {
    return api.post('/generate-heatmap', {
      group_round_id: groupRoundId
    });
  };

  uploadCsvFile = (data, companyId: string) => {
    return api.post(`/insights/companies/${companyId}/csv-lists`, data, {
      headers: {
        'Content-Type': 'text/csv'
      }
    });
  };

  createInsightsAccount = (data) => {
    return api.post(`/create-company/v2`, data);
  };

  downloadListInsights = (id: string) => {
    return api.post('/team-analysis-link', {
      survey_id: id
    });
  };

  createAndRequestSurvey = (
    id: string,
    customer: string,
    surveyLanguage: string,
    service: string,
    email: string
  ) => {
    return api.post('/create-hiring-company-survey', {
      company_id: id,
      customer: customer,
      survey_language: surveyLanguage,
      service: service,
      hiring_email: email
    });
  };

  getSurveyIdByCompanyId = (id: string, service?: string) => {
    return api.post('/get-survey-id', {
      company_id: id,
      service: service
    });
  };

  getCompareFlow = (groupRoundId: string) => {
    return api.post('/team-analysis/how-is', {
      group_round_id: groupRoundId
    });
  };

  getTestsHistory = (id: string) => {
    return api.post('/payment-history/history', {
      company_id: id
    });
  };
  getPersonDetails = (id: string) => {
    return api.post('/person-details/details', {
      person_id: id
    });
  };
  getIndividualAnalysis = (id: string) => {
    return api.post('/individual-analysis/details', {
      person_id: id
    });
  };

  getCompilationTeamLink = (id: string) => {
    return api.post('insights-teams/get-compiled-link', {
      groupRoundId: id
    });
  };

  getHistoryCreateInsightsAccount = (page: number, limit: number) => {
    return api.get(`/company-history/?page=${page}&limit=${limit}`);
  };

  // performance teams
  createTeam = (id: string, name: string, blockPersonalInfo: boolean) => {
    return api.post('/insights-teams/create-group', {
      companyId: id,
      groupName: name,
      blockPersonalInfo: blockPersonalInfo
    });
  };

  listAllTeams = (
    id: string,
    role: string,
    userId: string,
    page?: number,
    limit?: number
  ) => {
    return api.post('/insights-teams/get-groups-info', {
      companyId: id,
      role,
      userId,
      page,
      limit
    });
  };

  listPeopleWithoutTeam = (id: string) => {
    return api.post('/insights-teams/get-peoples-without-group', {
      companyId: id
    });
  };

  listAllPerformanceSurveys = (id: string) => {
    return api.post('/insights-teams/get-surveys', {
      companyId: id
    });
  };

  updateTeamName = (companyId: string, teamId: string, newName: string) => {
    return api.post('insights-teams/edit-group-name', {
      companyId: companyId,
      groupId: teamId,
      newGroupName: newName
    });
  };

  movePeoplesToTeams = (
    personId: string,
    companyId: string,
    teamId: string
  ) => {
    return api.post('/insights-teams/add-person-in-group', {
      personId: personId,
      companyId: companyId,
      groupId: teamId
    });
  };

  removePeopleFromTeam = (personId: string, teamId: string) => {
    return api.post('/insights-teams/remove-person-from-group', {
      personId: personId,
      groupId: teamId
    });
  };

  deleteTeam = (id: string, teamId: string) => {
    return api.post('insights-teams/remove-group', {
      companyId: id,
      groupId: teamId
    });
  };

  getActiveTeams = (id: string) => {
    return api.post('/insights-teams/check-actives-groups', { companyId: id });
  };

  getRoundsInfo = (groupId: string) => {
    return api.post('/insights-teams/get-rounds-info', { groupId: groupId });
  };

  createNewRound = (id: string, groupId: string) => {
    return api.post('/insights-teams/create-new-round', {
      companyId: id,
      groupId
    });
  };

  // groups management
  createGroups = (
    companyId: string,
    permissionGroupName: string,
    users: string[],
    teams: string[],
    userId: string
  ) => {
    return api.post('/insights-permission-groups/create-permission-group', {
      companyId,
      permissionGroupName,
      users,
      teams,
      userId
    });
  };

  getGroupsInfo = (
    companyId: string,
    page: number,
    limit: number,
    fuzzySearch?: string
  ) => {
    return api.post('/insights-permission-groups/get-permission-groups-info', {
      companyId,
      page,
      limit,
      fuzzySearch
    });
  };

  eeditGrouspInfo = (
    companyId: string,
    userId: string,
    groupId: string,
    groupName?: string,
    addUsers?: any,
    removedUsers?: any,
    addTeams?: any,
    removedTeams?: any
  ) => {
    return api.post('/insights-permission-groups/edit-permission-group', {
      newGroupName: groupName,
      usersToAdd: addUsers,
      usersToRemove: removedUsers,
      teamsToAdd: addTeams,
      teamsToRemove: removedTeams,
      permissionGroupId: groupId,
      companyId,
      userId
    });
  };

  deleteGroup = (companyId: string, groupId: string, userId: string) => {
    return api.post('/insights-permission-groups/delete-permission-group', {
      companyId,
      permissionGroupId: groupId,
      userId
    });
  };

  getTeamsIntoGroups = (companyId: string) => {
    return api.post('/insights-permission-groups/get-teams', { companyId });
  };

  getUsersIntoGroups = (companyId: string) => {
    return api.post('/insights-permission-groups/get-users', { companyId });
  };

  getHiringCard = (id: string) => {
    return api.get(`/hiring-analysis/?id=${id}`);
  };
}

const insightsService = new InsightsService();
export default insightsService;
