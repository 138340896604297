import makeStyles from '@mui/styles/makeStyles';
import { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
modal:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
},
modalContent:{
  width: '100%',
  maxWidth: '500px',
  height: 'auto',
  minHeight: '400px',
  backgroundColor: theme.palette.background.default,
  padding: '24px',
  borderRadius: '4px',
  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,
    height: '100%',
  }
},
boxTitle:{
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
},
title:{
  fontFamily: 'Nunito',
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '26px',
  letterSpacing: '0.15px',
  color: theme.palette.text.primary
},
subtitle:{
  fontFamily: 'Nunito',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.15px',
  color: theme.palette.text.secondary
},
iconButton:{
  '& .MuiIcon-root':{
    color: theme.palette.icon.default,
  },
  padding:0,
  alignSelf: 'baseline'
},
button:{
  height: '50px',
  fontFamily: 'Nunito',
  marginTop:'12px',
  [theme.breakpoints.down('sm')]: {
    height: '40px',
  }
},
splashScreenRoot:{
  backgroundRepeat: 'no-repeat',
  width: '100%',
  height: '100%',
  minHeight: '500px',
  maxWidth: '1145px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
},
splashScreenContent:{
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
},
splashBoxTitle:{
  margin: '16px 0',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  textAlign: 'center',
  maxWidth: '557px',
  width: '100%'
},
splashScreenTitle:{
  fontFamily: 'Nunito',
  fontWeight: 400,
  fontSize: '24px',
  lineHeight: '32px',
  [theme.breakpoints.down('sm')]: {
    padding:'0 16px'
  },
  [theme.breakpoints.down(380)]: {
    padding:0
  }
},
splashScreenSubtitle:{
  fontFamily: 'Nunito',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  [theme.breakpoints.down('sm')]: {
    padding:'0 16px'
  }
},
image:{
  width:'58px',
  height:'58px',
},
subscribeTitle:{
  fontFamily: 'Nunito',
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '26px',
  letterSpacing: '0.15px',
  color: theme.palette.text.primary
},
subscribeSubtitle:{
fontFamily: 'Nunito',
fontWeight: 400,
fontSize: '14px',
lineHeight: '20px',
letterSpacing: '0.15px',
color: theme.palette.text.secondary
},
boxButtonSubscribe:{
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',
  flexWrap: 'wrap',
  
}

}));