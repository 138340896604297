import React from 'react';
import type { FC } from 'react';

import { Box, Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';

import { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  hide: {
    width: '100%',
    minWidth: '117px',
    height: '12px',
    background: '#0000000A',
    borderRadius: '4px'
  },
  icon: {
    color: '#00000061',
    fontSize: '20px'
  }
}));

interface HideItemProps {
  rootStyle?: any;
  hideStyle?: any;
  location?: string;
  showTooltip?: boolean;
}

export const HideItem: FC<HideItemProps> = ({
  rootStyle,
  hideStyle,
  location,
  showTooltip
}) => {
  const classes = useStyles();

  const changeTooltipTitle = (location: string) => {
    let title;
    if (location === 'exams') {
      title = 'Assine o premium para utilizar as certificações de habilidades';
    } else if (location === 'salary') {
      title = 'Assine o premium para visualizar o último salário do candidato';
    } else {
      title = '';
    }
    return title;
  };

  return (
    <Box className={classes.root} style={rootStyle ?? {}}>
      <Box className={classes.hide} style={hideStyle ?? {}} />
      {showTooltip && (
        <Tooltip title={changeTooltipTitle(location)} placement="top">
          <InfoOutlined className={classes.icon} />
        </Tooltip>
      )}
    </Box>
  );
};
