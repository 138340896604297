import React, { useState } from 'react';
import type { FC } from 'react';
import { Box, Button, Typography, Grow, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from 'src/theme';
import { useHistory } from 'react-router';
import useAuth from 'src/hooks/useAuth';
import AutoCompleteResumeModal from 'src/views/opportunities/opportunitieModals/AutoCompleteResumeModal';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalWrapper: {
    backgroundColor: '#fff',
    border: '0.5px solid #c4c4c4',
    width: '100%',
    '&:focus': {
      outline: 'none'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
      marginRight: 10,
      width: '100%'
    }
  },
  modalContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 24,
    [theme.breakpoints.down('md')]: {
      marginLeft: 10,
      marginRight: 10,
      display: 'inherit'
    }
  },
  text: {
    color: '#232F34',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
    width: 720,
    [theme.breakpoints.down('md')]: {
      textAlign: 'justify'
    },
    [theme.breakpoints.down(750)]: {
      width: 'auto',
      textAlign: 'justify',
      padding: '0px 20px'
    }
  },
  button: {
    padding: '6px 16px',
    height: 36,
    '&:first-child': {
      marginRight: 5
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '10px 0',
      '& .MuiButton-label': {
        fontSize: 'small'
      }
    }
  }
}));

interface ModalRegistrationProps {
  className?: string;
  showModal?: boolean;
  onClose?: () => void;
}

const ModalRegistration: FC<ModalRegistrationProps> = ({
  showModal,
  onClose
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { userType, userProfile } = useAuth();
  const { finishedRegister, resumeSyncType } = userProfile.user;

  const registeredData = userType.isCandidate()
    ? finishedRegister || resumeSyncType === 'LINKEDIN'
    : userProfile.associatedCompanies[0].company.finishedRegister;

  const [open, setOpen] = useState(!registeredData);
  const [openModalSyncResume, setOpenModalSyncResume] = useState(false);

  const handleClose = () => {
    onClose && onClose();
    setOpen(false);
  };

  const actionButton = () => {
    if (userType.isCompany()) {
      history.push('/company/profile/edit');
    } else {
      setOpenModalSyncResume(true);
    }
  };

  const modalMessage = userType?.isCompany()
    ? 'Os dados cadastrais da sua empresa ainda não foram atualizados. Sem eles não será possível criar vagas. Deseja atualizar o cadastro?'
    : 'Os dados cadastrais da sua conta ainda não foram atualizados. Sem eles não será possível efetuar candidaturas. Deseja atualizar o cadastro?';
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true
  });

  return (
    <Box className={classes.root} style={open ? {} : { display: 'none' }}>
      <Grow in={Boolean(open || showModal)}>
        <Box className={classes.modalWrapper}>
          <Box className={classes.modalContent}>
            <Typography component="p" variant="body1" className={classes.text}>
              {modalMessage}
            </Typography>
            <Box display="flex" alignItems="center">
              <Button
                id="close-updating-cadastral-data-button-id"
                variant={isExtraSmallScreen ? 'contained' : 'text'}
                color="primary"
                className={classes.button}
                onClick={handleClose}
              >
                Fechar
              </Button>
              <Button
                id="updating-cadastral-data-button-id"
                variant={isExtraSmallScreen ? 'outlined' : 'text'}
                color="primary"
                className={classes.button}
                onClick={actionButton}
              >
                Atualizar Cadastro
              </Button>
            </Box>
          </Box>
        </Box>
      </Grow>
      <AutoCompleteResumeModal
        showModal={openModalSyncResume}
        setShowModal={(value) => setOpenModalSyncResume(value)}
      />
    </Box>
  );
};

export default ModalRegistration;
