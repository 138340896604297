import React, { useState, useEffect } from 'react';

import type { FC } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Fade,
  Skeleton,
  CircularProgress
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { StripeForm } from './StripeForm';
import { useStyles } from './style';
import { companyService } from 'src/services';
import useAuth from 'src/hooks/useAuth';

interface StripeAppProps {
  open?: boolean;
  handleOpen?: () => void;
  handleClose?: () => void;
}

export const StripeApp: FC<StripeAppProps> = ({
  handleOpen,
  open,
  handleClose
}) => {
  const classes = useStyles();
  const { userProfile } = useAuth();
  const [loading, setLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState(null);
  const companyId = userProfile.associatedCompanies[0].company.id;
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN);

  const getClientSecret = async () => {
    try {
      const response = await companyService.clientStripeSecret(companyId);
      setClientSecret(response?.data?.clientSecret);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getClientSecret();
  }, []);

  const options = {
    clientSecret: clientSecret,
    appearance: {
      rules: {
        '.Label': {
          color: '#00000099'
        }
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-stripe-title"
      aria-describedby="modal-stripe-description"
      className={classes.modal}
    >
      <Fade in={open}>
        <Box className={classes.modalContent}>
          {!loading && (
            <Box className={classes.boxTitle}>
              <Box style={{ marginBottom: '12px', gap: '4px' }}>
                <Typography className={classes.title}>
                  Dados de pagamento
                </Typography>
                <Typography className={classes.subtitle}>
                  Por favor, insira os dados do seu cartão de crédito
                </Typography>
              </Box>
              <IconButton className={classes.iconButton} onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
          )}
          {clientSecret ? (
            <Elements stripe={stripePromise} options={options}>
              <StripeForm
                onReady={setLoading}
                companyId={companyId}
                handleClose={handleClose}
              />
            </Elements>
          ) : (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '300px'
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};
