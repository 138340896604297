const getMinMax = (arrNumbers, max?, min?, index = 0) => {
    const currentNumber = arrNumbers[index++];

    if (currentNumber === undefined) return { min, max, arrNumbers };

    if (max === undefined && min === undefined) {
        max = currentNumber;
        min = currentNumber;
        return getMinMax(arrNumbers, max, min, index);
    }

    if (currentNumber > max) max = currentNumber;
    if (currentNumber < min) min = currentNumber;

    return getMinMax(arrNumbers, max, min, index);
};


const masks = {
    cpf: {
        mask: "###.###.###-##",
        size: 11
    },
    cnpj: {
        mask: "##.###.###/####-##",
        size: 14
    },
    cep: {
        mask: "#####-###",
        size: 5
    },
    tel: {
        mask: "## ####-####",
        size: 10
    },
    cel: {
        mask: "## # ####-####",
        size: 11
    }
};

export const withMask = (value, maskParams) => {
    maskParams = Array.isArray(maskParams) ? maskParams : [maskParams];
    value = value ? value.toString().replace(/\D/g, "") : '';
    const selectedMasks = maskParams.map(param => masks[param]);
    const { max } = getMinMax(selectedMasks.map(selected => selected.mask.length));
    let [i, j] = [0, 0];
    let result = "";
    let currentMask = getCurrentMask(selectedMasks, value);

    while (currentMask && max > j && value.length > i) {
        const currentMaskValue = currentMask.mask[j];

        if (currentMaskValue === "#") {
            result += value[i++];
        } else if (currentMaskValue) {
            result += currentMaskValue;
        }
        j++
    };

    return result;
};

const getCurrentMask = (arrMasks, text, currentMask?, index = 0) => {
    if (index > arrMasks.length) return currentMask;

    if (!currentMask) {
        currentMask = arrMasks[0];
    }
    if (text.length > currentMask.size && arrMasks.length > 1) {
        currentMask = arrMasks[++index];
        getCurrentMask(arrMasks, text, currentMask, index);
    }
    return currentMask;
};