import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Upload } from '@mui/icons-material';
import { useStyles } from './styles';

function Dropzone({ onDrop }) {
  const styles = useStyles();

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      //@ts-ignore
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx']
    },
    onDrop,
    noClick: true,
    noKeyboard: true
  });

  return (
    <Box {...getRootProps()} className={styles.dropzone}>
      <input {...getInputProps()} />
      <Upload className={styles.icon} />
      <Box>
        <Typography className={styles.textBold}>
          selecione um arquivo do currículo
        </Typography>
        <Typography className={styles.text}>ou arraste ele pra cá.</Typography>
      </Box>
      <Button
        onClick={open}
        className={styles.button}
        variant="outlined"
        type="button"
      >
        procurar arquivo
      </Button>
    </Box>
  );
}

export default Dropzone;
