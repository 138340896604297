import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_SERVER_URL;
export const BASE_URL_DATA = process.env.REACT_APP_DATA_API_URL;
const api = axios.create({ baseURL: BASE_URL });

api.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);

export default api;
