import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from 'src/theme';

interface OpportunitiesDetailProps {
  options: object[];
  className?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    flexWrap: "wrap"
  },
  info: {
    border: '0.5px solid #c4c4c4',
    borderRadius: 6,
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    padding: '10px 5px',
    marginRight: 10,
    height: '56px',
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
      marginLeft: 0,
      marginBottom: 10,
      width: "100%"
    },
  },
  info__box: {
    display: "flex",
    flexWrap: 'wrap',
    justifyContent: "space-between",
    "& div:first-child": {
      width: "100%"
    },
    margin: '0 5px',
    flexGrow: 1,
    maxWidth: 193
  },
  info__details: {
    display: 'flex',
    alignItems: 'flex-start',
    whiteSpace: 'nowrap', 
    overflow: 'hidden', 
    textOverflow: 'ellipsis' 
  },
  info__title_highlight: {
    fontSize: 12,
    fontWeight: 500,
    marginRight: 5
  },
  info__title_text: {
    fontSize: 12,
    fontWeight: 300,
  }
}));

const InfoCard: FC<OpportunitiesDetailProps> = ({ className, options }) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.root, className)}>
      <Box className={classes.info}>
        <Box className={classes.info__box}>
          {options.map((option) => {
            return (
              Object.entries(option).map(([title, text]) => {
                return (
                  <Box className={classes.info__details} key={title}>
                    <Typography className={classes.info__title_highlight}>
                      {title}
                    </Typography>
                    <Typography 
                      title={text} 
                      className={classes.info__title_text} 
                      style={{ 
                        flex: 1,
                        maxWidth: '100%', 
                        whiteSpace: 'nowrap', 
                        overflow: 'hidden', 
                        textOverflow: 'ellipsis' 
                      }}
                    >
                      {text}
                    </Typography>
                  </Box>
                )
              })
            )
          })
          }
        </Box>
      </Box>
    </Box>
  );
};

InfoCard.propTypes = {
  className: PropTypes.string,
};

export default InfoCard;
