export const getRndNewValue = (imageLabel: 'cover' | 'avatar', isCompanyImage?: boolean) => {
  let rndLabel = imageLabel + '_rnd_value';
  if (isCompanyImage) rndLabel = 'company_' + rndLabel;

  const date = new Date();
  const rndValue = String(date.getTime()).substring(7);

  sessionStorage.setItem(rndLabel, rndValue)

  return rndValue;
}

const getRndSavedValue = (label: 'cover' | 'avatar', isCompany: boolean) => {
  let rndLabel = label + '_rnd_value';
  if (isCompany) rndLabel = 'company_' + rndLabel;

  return sessionStorage.getItem(rndLabel) || '';
}

export const getImageFileName = (
  label: 'cover' | 'avatar',
  size: 'default' | 'small' | 'medium',
  imagePath: string,
  prevPath?: string
) => {
  let newImagePath = imagePath + `${label}`;
  const isCompanyImage = imagePath.includes('companies');

  if (size === 'default') {
    newImagePath += '.png';
  } else {
    newImagePath += `-${size}.png`;
  }

  const savedRndValue = getRndSavedValue(label, isCompanyImage)
  if (prevPath) {
    const rndValue = getRndNewValue(label, isCompanyImage);
    return `${newImagePath}?rnd=${rndValue}`;
  }
  if (savedRndValue) return `${newImagePath}?rnd=${savedRndValue}`;
  
  return newImagePath;
};

export const convertImageSize = (imageFileName: string, label: 'cover' | 'avatar', newSize: 'small' | 'medium') => {
  if (imageFileName) return imageFileName.split(label).join(`${label}-${newSize}`);
  return "";
}