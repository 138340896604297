import api from '../api';

export class CandidateService {
  private static instance: CandidateService;

  constructor() {
    if (!CandidateService.instance) {
      CandidateService.instance = this;
    }
    return CandidateService.instance;
  }

  getGeneral = () => {
    return api.get('/profile');
  };

  updateGeneral = (data) => {
    return api.put('/profile/', data);
  };

  uploadCurriculum = (data) => {
    return api.post('/profile/load', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };

  checkCurriculumExtraction = () => {
    return api.get('/profile/check-extraction');
  };

  getS3CurriculumOutput = () => {
    return api.get('/profile/s3-output');
  };

  getCurriculum = () => {
    return api.get('/profile/curriculum');
  };

  updateCurriculum = (data) => {
    return api.patch('/profile/curriculum', data);
  };

  updateCurriculumFile = (data) => {
    return api.patch('/profile/curriculum/file', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };

  deleteCurriculumFile = () => {
    return api.delete('/profile/curriculum/file');
  };

  getCurriculumFile = () => {
    return api.get('/profile/curriculum/file');
  };

  changePassword = (
    password: string,
    newPassword: string,
    passwordConfirm: string
  ) => {
    return api.post('/authentication/change-password', {
      password,
      newPassword,
      passwordConfirm
    });
  };

  changeAvatar = (data) => {
    return api.patch('/users/profile/avatar', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };

  deleteAvatar = () => {
    return api.delete('/users/profile/avatar');
  };

  deleteAccount = () => {
    return api.delete('/users/delete-account');
  };

  getApplicationById = (id: string) => {
    return api.get(`/application/${id}/candidate`);
  };

  getApplicationByJobId = (jobId: string) => {
    const params = { jobId };
    return api.get('/profile/applications', { params });
  };

  giveUpApplication = (id: string, data: any) => {
    return api.patch(`/applications/${id}/quitter`, data);
  };

  getAllOpportunities = (payload) => {
    const { params } = payload;
    return api.get(`/candidates/jobs?${params}`);
  };

  getAllRolenames = () => {
    return api.get('/profile/applications/roles-name');
  };

  applyOpportunity = (jobID: string, data: any) => {
    return api.post(`/jobs/${jobID}/apply`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };

  syncLinkedin = (linkedinUrl: string) => {
    return api.patch('/profile/curriculum/linkedin', { url: linkedinUrl });
  };

  getUserMessageNotifications = (page?: number, limit?: number) => {
    return api.get(`/user/notifications?page=${page}&limit=${limit}`);
  };

  setMessageRead = (messageId: string) => {
    return api.patch(`/user/notification/${messageId}/view`);
  };
}

const candidateService = new CandidateService();
export default candidateService;
