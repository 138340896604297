import React, { useState, useEffect } from 'react';

import type { FC } from 'react';

import {
  Modal,
  Button,
  Box,
  Alert,
  Typography,
  Fade,
  IconButton,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Close, FlashOn } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { useStyles } from './style';
import subscribe from 'src/assets/images/subscribe-plan.png';
import checkout from 'src/assets/images/checkout.png';
import subscribeMobile from 'src/assets/images/subscribe-plan-mobile.png';
import checkoutMobile from 'src/assets/images/checkout-mobile.png';
import premium from 'src/assets/images/gria-premium.svg';
import useAuth from 'src/hooks/useAuth';
import { useSelector } from 'src/store';
import { companyService } from 'src/services';

interface SubscribeModalProps {
  open?: boolean;
  handleOpen?: () => void;
  handleClose?: () => void;
  clickButtonLeft?: () => void;
  clickButtonRight?: () => void;
  view?: string;
  expireDate?: boolean;
  amountVacancyOpen?: number;
  type?: any;
  form?: any;
  billingData?: any;
  amountJobs?: number;
}

export const SubscribeModal: FC<SubscribeModalProps> = ({
  open,
  handleOpen,
  handleClose,
  clickButtonLeft,
  clickButtonRight,
  view,
  amountVacancyOpen,
  type,
  form,
  billingData,
  amountJobs
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(361), {
    defaultMatches: true
  });
  const { userProfile } = useAuth();
  const isTalentBank = view === 'talentBank';
  const isJobs = view === 'jobs';

  const actualDay = new Date();
  const { countByStatus } = useSelector((state) => state.opportunities);

  const hasVacancyOpen = amountJobs >= 1;
  const noHasVacancyOpen = amountJobs === 0;

  const freeSubscriber =
    !userProfile.associatedCompanies[0]?.company.account
      ?.subscriptionCreatedAt &&
    !userProfile.associatedCompanies[0]?.company.account?.freeTrialCreatedAt;

  const trialSubscriber =
    userProfile.associatedCompanies[0].company.account?.subscriptionCreatedAt &&
    actualDay <
      new Date(
        userProfile.associatedCompanies[0].company.account?.subscriptionCreatedAt
      );

  const premiumSubscriber =
    userProfile.associatedCompanies[0].company.account?.subscriptionCreatedAt &&
    actualDay >
      new Date(
        userProfile.associatedCompanies[0].company.account?.subscriptionCreatedAt
      ) &&
    userProfile.associatedCompanies[0].company.account?.invoice?.status ===
      'PAID';

  const exPremiumSusbcriber =
    !userProfile.associatedCompanies[0].company.account
      ?.subscriptionCreatedAt &&
    userProfile.associatedCompanies[0].company.account?.freeTrialCreatedAt;

  let diff = moment(
    userProfile.associatedCompanies[0].company.account?.subscriptionCreatedAt
  ).diff(moment(actualDay));

  let days = Math.round(moment.duration(diff).asDays());

  const history = useHistory();

  const createVacancy = history.location.pathname?.includes('/jobs/create');
  const duplicateVacancy =
    history.location.search?.includes('duplicate=true') ||
    history.location.pathname?.includes('/jobs/edit');

  const handleChangeTitle = () => {
    let title;
    if (isJobs && freeSubscriber && !createVacancy) {
      title = (
        <span>
          Experimente o plano <span style={{ color: '#590A9D' }}>Premium</span>{' '}
          por 10 dias grátis
        </span>
      );
    }
    if (isJobs && (premiumSubscriber || exPremiumSusbcriber)) {
      title = (
        <span>
          Assine o plano <span style={{ color: '#590A9D' }}>Premium</span>
        </span>
      );
    }
    if (
      (isJobs && amountVacancyOpen >= 1) ||
      (isJobs &&
        duplicateVacancy &&
        hasVacancyOpen &&
        (freeSubscriber || exPremiumSusbcriber))
    ) {
      title = (
        <span>
          Assine o <span style={{ color: '#590A9D' }}>Premium</span> para
          publicar mais vagas
        </span>
      );
    }
    if (isJobs && (trialSubscriber || premiumSubscriber)) {
      title = <span>Seu anúncio está pronto para ser publicado</span>;
    }
    if (
      isJobs &&
      noHasVacancyOpen &&
      (freeSubscriber || exPremiumSusbcriber) &&
      (createVacancy || duplicateVacancy)
    ) {
      title = <span>Seu anúncio está pronto para ser publicado</span>;
    }
    if (isTalentBank) {
      title = <span>Agora seus talentos estão em um lugar só</span>;
    }
    return title;
  };

  const handleChangeSubtitle = () => {
    let subtitle;
    if (
      (isJobs && freeSubscriber && !createVacancy) ||
      (isJobs && premiumSubscriber) ||
      (isJobs && amountVacancyOpen >= 1) ||
      (isJobs && exPremiumSusbcriber && !createVacancy) ||
      (isJobs && amountVacancyOpen >= 1) ||
      (isJobs &&
        duplicateVacancy &&
        hasVacancyOpen &&
        (freeSubscriber || exPremiumSusbcriber))
    ) {
      subtitle = (
        <span>
          Publique mais de uma vaga por vez e tenha acesso a todos talentos que
          já passaram por seus processos seletivos
        </span>
      );
    }
    if (isJobs && trialSubscriber && hasVacancyOpen) {
      subtitle = (
        <span>
          Você terá um acréscimo de <b>R$ 95,00</b> à assinatura que começa em{' '}
          <b>
            {moment(
              userProfile.associatedCompanies[0].company.account
                ?.subscriptionCreatedAt
            ).format('D/MM')}
          </b>
        </span>
      );
    }
    if (isJobs && trialSubscriber && !hasVacancyOpen) {
      subtitle = 'Aproveite, essa vaga será totalmente gratuita';
    }
    if (isJobs && premiumSubscriber) {
      subtitle = (
        <span>
          Você terá um acréscimo de aprox.{' '}
          {billingData?.value && (
            <b>
              R$ {(billingData?.value / 100)?.toFixed(2).replace('.', ',')}{' '}
            </b>
          )}
          proporcionais aos próximos <b>{billingData?.days ?? 0} dias</b>, que
          serão debitados na renovação de sua assinatura em{' '}
          <b>{moment(billingData?.currentPeriodEnd).format('D/MM')}</b>
        </span>
      );
    }
    if (isTalentBank) {
      subtitle = (
        <span>
          Tenha acesso a todos os talentos que já passaram por suas vagas
        </span>
      );
    }
    if (
      isJobs &&
      noHasVacancyOpen &&
      (freeSubscriber || exPremiumSusbcriber) &&
      (createVacancy || duplicateVacancy)
    ) {
      subtitle = 'Aproveite, essa vaga será totalmente gratuita';
    }
    return subtitle;
  };

  const handleChangeLabelButonLeft = () => {
    let label;
    if ((isJobs && freeSubscriber) || (isJobs && exPremiumSusbcriber)) {
      label = 'Agora não';
    }
    if (isTalentBank) {
      label = 'Entendi';
    }
    if (
      (isJobs && amountVacancyOpen >= 1) ||
      (isJobs && trialSubscriber) ||
      (isJobs && premiumSubscriber) ||
      (isJobs &&
        (freeSubscriber || exPremiumSusbcriber) &&
        (createVacancy || duplicateVacancy))
    ) {
      label = null;
    }
    return label;
  };

  const handleChangeLabelButonRight = () => {
    let label;
    if (isJobs && freeSubscriber) {
      label = 'Quero experimentar';
    }
    if (isJobs && exPremiumSusbcriber && !createVacancy) {
      label = 'Assine o Premium';
    }
    if (
      (isJobs && amountVacancyOpen >= 1) ||
      (isJobs &&
        hasVacancyOpen &&
        (freeSubscriber || exPremiumSusbcriber) &&
        (createVacancy || duplicateVacancy))
    ) {
      label = 'Assine o Premium';
    }
    if (
      (isJobs && trialSubscriber) ||
      (isJobs && premiumSubscriber) ||
      (isJobs &&
        noHasVacancyOpen &&
        (freeSubscriber || exPremiumSusbcriber) &&
        (createVacancy || duplicateVacancy))
    ) {
      label = 'Confirmar e criar a vaga';
    }
    if (isTalentBank) {
      label = null;
    }
    return label;
  };

  let imageSelection =
    (createVacancy || duplicateVacancy) && isMobile
      ? checkoutMobile
      : (createVacancy || duplicateVacancy) && !isMobile
      ? checkout
      : (!createVacancy || !duplicateVacancy) && !isMobile
      ? subscribe
      : subscribeMobile;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        margin: !isMobile ? '0px 10px' : '0px'
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            width: '100%',
            maxWidth: '500px',
            backgroundColor: '#ffffff',
            height: isMobile ? '100%' : 'auto',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: !isMobile ? '4px' : '',
            position: 'absolute',
            '&:focus': {
              outline: 'none'
            }
          }}
        >
          <Box flexGrow={1}>
            <Box>
              <IconButton
                onClick={handleClose}
                style={{ position: 'relative', left: '90%', zIndex: 1 }}
              >
                <Close />
              </IconButton>
              <img
                src={imageSelection}
                loading="lazy"
                alt="subscribe-plan"
                style={{
                  borderRadius: !isMobile ? '4px 4px 0px 0px' : '',
                  width: '100%',
                  height: !isMobile ? '180px' : 'auto',
                  position: 'relative',
                  top: '-40px',
                  zIndex: 0,
                  objectFit: 'cover'
                }}
              />
              <div style={{ position: 'relative', width: '100%' }}>
                <img
                  src={premium}
                  loading="lazy"
                  alt="premium"
                  style={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '58px',
                    height: '58px',
                    top: '-70px',
                    objectFit: 'cover'
                  }}
                />
              </div>
            </Box>
            {trialSubscriber && !isTalentBank && (
              <Box style={{ padding: '0 24px' }}>
                <Alert
                  severity="info"
                  style={{
                    color: '#315978',
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    lineHeight: '20px',
                    letterSpacing: '0.15px'
                  }}
                >
                  Seu período de testes termina em <b>{days} dias</b>.
                </Alert>
              </Box>
            )}
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                padding: '13px 24px 2px',
                gap: '4px'
              }}
            >
              <Typography className={classes.subscribeTitle}>
                {handleChangeTitle()}
              </Typography>
              <Typography className={classes.subscribeSubtitle}>
                {handleChangeSubtitle()}
              </Typography>
            </Box>
          </Box>
          <Box p={3} className={classes.boxButtonSubscribe}>
            {handleChangeLabelButonLeft() !== null && (
              <Button
                id="id-button-left"
                fullWidth={isMobile}
                variant="outlined"
                onClick={clickButtonLeft}
                style={{
                  height: '40px',
                  fontFamily: 'Nunito',
                  fontWeight: 700,
                  border: isTalentBank ? ' 1px solid #3F51B580' : 'none'
                }}
              >
                {handleChangeLabelButonLeft()}
              </Button>
            )}
            {handleChangeLabelButonRight() !== null && (
              <Button
                id="id-button-right"
                fullWidth={isMobile}
                variant="contained"
                onClick={clickButtonRight}
                startIcon={
                  isJobs && amountVacancyOpen >= 1 && freeSubscriber ? (
                    <FlashOn />
                  ) : null
                }
                style={{
                  height: '40px',
                  fontFamily: 'Nunito',
                  fontWeight: 700
                }}
                type={type}
                form={form}
              >
                {handleChangeLabelButonRight()}
              </Button>
            )}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
