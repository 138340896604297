import React from 'react';
import type { FC } from 'react';

import { Modal, Fade, Box, Typography } from '@mui/material';
import { AllInclusive, BusinessCenter } from '@mui/icons-material';
import { useHistory, useLocation } from 'react-router';

import { useStyles } from './styles';

interface SwitchProductsProps {
  open: boolean;
  handleClose: () => void;
}

export const SwitchProducts: FC<SwitchProductsProps> = ({
  open,
  handleClose
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const products = [
    {
      name: 'Jobs',
      icon: <BusinessCenter fontSize="large" />,
      redirect: '/company/jobs',
      colorScheme: 'primary'
    },
    {
      name: 'Flow',
      icon: <AllInclusive fontSize="large" />,
      redirect: '/insights',
      colorScheme: 'secondary'
    }
  ];

  const handleRedirect = (redirect: string) => {
    if (location.pathname === '/insights' && redirect === '/insights') {
      handleClose();
    } else if (
      location.pathname === '/company/jobs' &&
      redirect === '/company/jobs'
    ) {
      handleClose();
    } else {
      handleClose();
      history.push(redirect);
    }
  };

  return (
    <Box className={classes.root}>
      <Modal
        aria-labelledby="switch-products-modal"
        aria-describedby="switch-products-modal"
        open={open}
        closeAfterTransition
        className={classes.modal}
      >
        <Fade in={open}>
          <Box className={classes.content}>
            <Box>
              <Typography className={classes.title}>
                Boas-vindas a Gria!
              </Typography>
              <Typography className={classes.subtitle}>
                Qual produto deseja explorar agora?
              </Typography>
            </Box>
            <Box className={classes.boxActions}>
              {products.map((item, index) => {
                return (
                  <Box
                    key={index}
                    className={`${classes.boxButtons} ${
                      item.colorScheme === 'primary' ? 'primary' : 'secondary'
                    }`}
                    onClick={() => handleRedirect(item.redirect)}
                  >
                    {item.icon}
                    <Typography>{item.name}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};
