export const processStatusOptions = {
  "REGISTERED": { 
    label: "INSCRITO", 
    style: { 
      color: '#4C74AF', 
      backgroundColor:'rgba(86, 204, 242, 0.2)'
    }
  },
  "UNAPPROVED": { 
    label: "NÃO APROVADO", 
    style: { 
      color: '#E63C5B', 
      backgroundColor:'rgba(235, 87, 87, 0.2)'
    }
  },
  "UNAPPROVED_UNDO": { 
    label: "REPROVAÇÃO DESFEITA", 
    style: { 
      color: '##7E7272', 
      backgroundColor:'#E8F5E9'
    },
  },
  "APPROVED": { 
    label: "APROVADO", 
    style: { 
      color: '#4CAF50',
      backgroundColor: '#E8F5E9'
    }
  },
  "CANCELLED": { 
    label: "CANCELADO", 
    style: { 
      color: '#7E7272',
      backgroundColor: '#E8F5E9'
    }
  },
  "REVIEW": { 
    label: "EM ANÁLISE", 
    style: { 
      color: '#7E7272',
      backgroundColor: '#E8F5E9'
    }
  },
  "QUITTER": { 
    label: "DESISTENTE", 
    style: { 
      color: '#F57C00',
      backgroundColor: "#FFF3E0",
    }
  },
  "QUITTER_REGISTERED": { 
    label: "DESISTENTE", 
    style: { 
      color: '#F57C00',
      backgroundColor: "#FFF3E0",
    }
  },
  "QUITTER_PROCESS": { 
    label: "DESISTENTE", 
    style: { 
      color: '#F57C00',
      backgroundColor: "#FFF3E0",
    }
  },
  "QUITTER_UNDO": { 
    label: "DESISTÊNCIA DESFEITA", 
    style: { 
      color: '#F57C002', 
      backgroundColor:'#E8F5E9'
    },
  },
  "REGISTERED_UNDO": { 
    label: "TRIAGEM DESFEITA", 
    style: { 
      color: '#F57C002', 
      backgroundColor:'#E8F5E9'
    },
  },
  "FINALIZED": { 
    label: "FINALIZADO", 
    style: { 
      color: '#7E7272', 
      backgroundColor:'#E8F5E9'
    },
  },
  "JOB_FINALIZED": { 
    label: "FINALIZADO", 
    style: { 
      color: '#7E7272', 
      backgroundColor:'#E8F5E9'
    },
  }
}