import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import type { Dispatch, FC, ReactNode } from 'react';
import React, { createContext, useEffect, useReducer, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import api from 'src/api/';
import talentApi from 'src/api/talentsApi';
import insightsApi from 'src/api/insightsApi';
import SplashScreen from 'src/components/SplashScreen';
import UserType from 'src/components/UserType';
import setLocale from 'src/locales/';
import { companyService } from 'src/services';
import AuthenticationService from 'src/services/authentication.service';
import CandidateService from 'src/services/candidate.service';
import { RequestParamsProps } from 'src/types/opportunityCandidate';
import type {
  AssociatedCompany,
  Company,
  UserProfile
} from 'src/types/userProfile';
import getEnvironment from 'src/utils/getEnvironment';
import { getImageFileName } from 'src/utils/handleImagePath';

interface AuthState {
  isInitialised: boolean;
  userType: UserType;
  isAuthenticated: boolean;
  userProfile: UserProfile | null;
  isLogout: boolean;
}

interface AuthContextValue extends AuthState {
  method: 'JWT';
  login: (params: any) => Promise<void>;
  logout: () => void;
  register: (
    token: string,
    id: string,
    email: string,
    systemRoles: string[],
    associatedCompanies: AssociatedCompany[]
  ) => Promise<void>;
  switchCompany: (companyId: string) => void;
  gaRegisterEvent: (isCompany: boolean, id: string) => void;
  triggerJobRelatedGaEvent: (data: {
    event: string;
    [key: string]: any;
  }) => any;
  // It has 'Repet' because the backend use 'PasswordRepet'
  changePassword: (
    password: string,
    newPassword: string,
    passwordConfirm: string
  ) => Promise<void>;
  setUserAttribute: (value: any, attribute: string) => void;
  setCompanyAttribute: (value: any, attribute: string) => void;
  setNewUserProfile: (newUserProfile: UserProfile) => void;
  setNewCompanyData: (companyData: Company) => void;
  candidateListParams: RequestParamsProps | null;
  setCandidateListParams: Dispatch<RequestParamsProps>;
  setCurrentJobId: Dispatch<string>;
  locale: (args) => any;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    userProfile: UserProfile | null;
    userType?: UserType;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    userProfile: UserProfile;
    userType: UserType;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    userProfile: UserProfile;
    userType: UserType;
  };
};

type ChangePasswordAction = {
  type: 'CHANGEPASSWORD';
};

type SetUserAttributeAction = {
  type: 'SETUSERATTRIBUTE';
  payload: {
    value: any;
    attribute: string;
  };
};

type SetCompanyAttributeAction = {
  type: 'SETCOMPANYATTRIBUTE';
  payload: {
    value: any;
    attribute: string;
  };
};

type SetUserProfileAction = {
  type: 'SETUSERPROFILE';
  payload: {
    newUserProfile: UserProfile;
  };
};

type SetCompanyDataAction = {
  type: 'SETCOMPANYDATA';
  payload: {
    companyData: Company;
  };
};

type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | ChangePasswordAction
  | SetUserAttributeAction
  | SetCompanyAttributeAction
  | SetUserProfileAction
  | SetCompanyDataAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  userType: new UserType(),
  isInitialised: false,
  userProfile: null,
  isLogout: false
};

const getCookiePropName = (domain: string, prop: string) => {
  const envName = getEnvironment(domain);

  if (envName) {
    return `gria_${envName}_${prop}`;
  }
  return `gria_${prop}`;
};

const isValidToken = (token: string): boolean => {
  if (!token) {
    return false;
  }

  const decoded: any = jwtDecode(token);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (token: string | null): void => {
  const domain = process.env.REACT_APP_DOMAIN;
  const tokenPropName = getCookiePropName(domain, 'token');
  const userTypePropName = getCookiePropName(domain, 'userType');

  if (token) {
    if (localStorage.getItem('GRIA:token')) {
      localStorage.removeItem('GRIA:token');
    }

    Cookies.set(tokenPropName, token, { domain: domain, secure: true });
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    talentApi.defaults.headers.common.Authorization = `Bearer ${token}`;
    insightsApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    localStorage.clear();
    Cookies.remove(tokenPropName, { domain: domain, secure: true });
    Cookies.remove(userTypePropName, { domain: domain });
    delete api.defaults.headers.common.Authorization;
    delete talentApi.defaults.headers.common.Authorization;
    delete insightsApi.defaults.headers.common.Authorization;
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, userProfile, userType } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        userProfile,
        userType
      };
    }
    case 'LOGIN': {
      const { userProfile, userType } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        userProfile,
        userType
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        userProfile: null
      };
    }
    case 'REGISTER': {
      const { userProfile, userType } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        userProfile,
        userType
      };
    }
    case 'CHANGEPASSWORD': {
      return {
        ...state,
        isAuthenticated: true
      };
    }
    case 'SETUSERATTRIBUTE': {
      const { value, attribute } = action.payload;

      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          user: {
            ...state.userProfile.user,
            [attribute]: value
          }
        }
      };
    }
    case 'SETCOMPANYATTRIBUTE': {
      const { value, attribute } = action.payload;

      let associatedCompanies = state.userProfile.associatedCompanies;

      associatedCompanies[0].company[attribute] = value;

      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          associatedCompanies
        }
      };
    }
    case 'SETUSERPROFILE': {
      const { newUserProfile } = action.payload;

      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          ...newUserProfile
        }
      };
    }
    case 'SETCOMPANYDATA': {
      const { companyData } = action.payload;

      let associatedCompanies = state.userProfile.associatedCompanies;

      associatedCompanies[0].company = companyData;

      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          associatedCompanies
        }
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  switchCompany: () => Promise.resolve(),
  gaRegisterEvent: () => Promise.resolve(),
  triggerJobRelatedGaEvent: () => Promise.resolve(),
  changePassword: () => Promise.resolve(),
  setUserAttribute: () => Promise.resolve(),
  setCompanyAttribute: () => Promise.resolve(),
  setNewUserProfile: () => Promise.resolve(),
  setNewCompanyData: () => Promise.resolve(),
  candidateListParams: null,
  setCandidateListParams: () => Promise.resolve(),
  setCurrentJobId: () => Promise.resolve(),
  locale: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(history.location?.search);
  const [candidateListParams, setCandidateListParams] =
    useState<RequestParamsProps>({
      page: Number(urlSearchParams.get('page')) || 1,
      limit:
        (history.location.state &&
          history.location?.state['filterInvisibleParams']?.limit) ||
        5,
      userName: urlSearchParams.get('userName') || '',
      process:
        urlSearchParams
          .get('process')
          ?.split(',')
          ?.map((item) => item)
          .join(',') || '',
      status:
        urlSearchParams
          .get('status')
          ?.split(',')
          ?.map((item) => item)
          .join(',') || '',
      favorite: urlSearchParams.get('favorite') || '',
      state: urlSearchParams.get('state') || null,
      salaryRate: urlSearchParams.get('salaryRate') || null,
      match:
        (history.location.state &&
          history.location?.state['filterInvisibleParams']?.match) ||
        2,
      drawer: urlSearchParams.get('drawer') || '',
      applicationId: urlSearchParams.get('applicationId') || '',
      notification: urlSearchParams.get('notification') || '',
      tab: urlSearchParams.get('tab') || ''
    });
  const location = useLocation();
  const [currentJobId, setCurrentJobId] = useState('');

  const [language] = useState(
    setLocale[navigator.language.toLowerCase()] ||
      setLocale[process.env.REACT_APP_DEFAULT_LANGUAGE]
  );

  useEffect(() => {
    const defaultCandidateListParams: RequestParamsProps = {
      page: 1,
      limit: 5,
      userName: '',
      process: '',
      status: '',
      favorite: '',
      match: 2,
      salaryRate: '',
      state: '',
      drawer: '',
      applicationId: '',
      notification: '',
      tab: ''
    };
    if (
      !location.pathname.includes(currentJobId) &&
      !_.isEqual(candidateListParams, defaultCandidateListParams)
    ) {
      setCandidateListParams(defaultCandidateListParams);
    }
  }, [location.pathname]);

  interface ObjectData {
    user_id: string;
    user_phone: string;
    user_email: string;
    user_name: string;
    user_hash: string | number;
    user_context: string;
    user_created_at: string | number;
    company_name: string | null;
    company_website: string | null;
    company_corporate_name: string | null;
    company_phone: string | null;
    company_document: string | null;
    company_id: string | null;
    company_profile_url: string | null;
    company_size: string | null;
    company_industry_id: number | null;
    company_industry: string | null;
    company_plan: string | null;
    company_created_at: string | number | null;
  }

  const objectDataDefault = {
    user_id: '',
    user_phone: '',
    user_email: '',
    user_name: '',
    user_hash: '',
    user_context: '',
    user_created_at: '',
    company_name: '',
    company_website: '',
    company_corporate_name: '',
    company_phone: '',
    company_document: '',
    company_id: '',
    company_profile_url: '',
    company_size: '',
    company_industry_id: 0,
    company_industry: '',
    company_plan: 'Free',
    company_created_at: ''
  };

  const [objectData, setObjectData] = useState<ObjectData>(objectDataDefault);

  const [eventCopy, setEventCopy] = useState({});

  useEffect(() => {
    Object.keys(eventCopy)?.length !== 0 && triggerGaEvent(eventCopy);
  }, [objectData, eventCopy]);

  const triggerGaEvent = (eventObject: any) => {
    // Trigger Google Analytics Event
    window.dataLayer = window.dataLayer || [];
    setEventCopy(eventObject);

    if (objectData?.user_id !== '' && eventObject?.event !== 'session_end') {
      window.dataLayer.push({ ...objectData, ...eventObject });
    } else if (eventObject?.event === 'session_end') {
      window.dataLayer.push(eventObject);
    }
  };

  const gaRegisterEvent = (isCompany: boolean, id: string) => {
    triggerGaEvent({
      event: 'sign_up',
      logged: false
    });
  };

  const triggerJobRelatedGaEvent = (data: {
    event: string;
    [key: string]: any;
  }) => {
    const { userProfile } = state;
    const company = userProfile.associatedCompanies[0].company;
    triggerGaEvent({
      ...data,
      logged: true
    });
  };

  const login = async ({ captcha, email, password, isCompanyLogin, data }) => {
    let userData = data;
    if (email && password) {
      userData = await AuthenticationService.login(
        email,
        password,
        isCompanyLogin,
        captcha
      ).then((response) => response.data);
    } else if (userData.firstLogin) {
      gaRegisterEvent(false, userData.user.id);
    }
    setSession(userData.token);

    // TEMPORARY
    const roles = [
      userData.associatedCompanyRoles.length > 0 ? 'ADMIN' : 'USER'
    ];

    const userType = new UserType({ roles });

    const perfilAlert = userType.isCompany()
      ? !userData.associatedCompanyRoles[0].company.finishedRegister
      : !userData.user.finishedRegister;

    const showAlert = {
      fromLogin: true,
      perfilAlert
    };
    window.localStorage.setItem(
      'GRIA:showPerfilAlert',
      JSON.stringify(showAlert)
    );

    const verifyLoginMetodh = () => {
      switch (userData.loginType) {
        case 1:
          return 'email';
        case 2:
          return 'linkedin';
        case 3:
          return 'google';
        case 4:
          return 'facebook';
        default:
          return;
      }
    };

    let authEvent = {};

    if (userType.isCompany()) {
      const company = userData.associatedCompanyRoles[0].company;
      authEvent = {
        event: 'login',
        auth_method: email ? 'email' : 'linkedin',
        logged: true
      };
    } else if (!userData.loginType) {
      authEvent = {
        event: 'sign_up',
        logged: false
      };
    } else {
      authEvent = {
        event: 'login',
        auth_method: verifyLoginMetodh(),
        logged: true
      };
    }

    await initialise('login');
    triggerGaEvent(authEvent);
  };

  const logout = () => {
    setSession(null);
    sessionStorage.clear();

    triggerGaEvent({
      event: 'logout',
      logged: false
    });

    triggerGaEvent({
      event: 'session_end',
      logged: false,
      user_id: ''
    });

    dispatch({ type: 'LOGOUT' });

    setObjectData(objectDataDefault);
    setEventCopy({});
  };

  const register = async (
    token: string,
    id: string,
    email: string,
    systemRoles: string[],
    associatedCompanies: AssociatedCompany[]
  ) => {
    setSession(token);

    // TEMPORARY
    const roles = [associatedCompanies.length > 0 ? 'ADMIN' : 'USER'];

    const userType = new UserType({ roles });

    const userProfile: UserProfile = {
      user: {
        id,
        email,
        systemRoles
      },
      associatedCompanies
    };

    const showAlert = {
      fromLogin: true,
      perfilAlert: true
    };
    window.localStorage.setItem(
      'GRIA:showPerfilAlert',
      JSON.stringify(showAlert)
    );

    dispatch({
      type: 'REGISTER',
      payload: {
        userProfile,
        userType
      }
    });
  };

  const switchCompany = (companyId) => {
    companyService.switchCompany(companyId).then((res) => {
      setSession(res.data.token.token);
      window.location.href = `${process.env.PUBLIC_URL}/company/jobs`;
    });
  };

  const getMsg = (keys = [], mapLanguage, index = 0) => {
    const key = keys[index++];

    if (key && mapLanguage) {
      return getMsg(keys, mapLanguage[key], index);
    }
    return mapLanguage || '';
  };

  const locale = (path) => {
    return getMsg(path.split('.'), language);
  };

  // refactor
  const changePassword = async (
    password: string,
    newPassword: string,
    passwordConfirm: string
  ) => {
    const response = await api.post('/authentication/change-password', {
      password,
      newPassword,
      passwordConfirm
    });
    const { token } = response.data;

    setSession(token);

    dispatch({ type: 'CHANGEPASSWORD' });
  };

  const setUserAttribute = (value: any, attribute: string) => {
    dispatch({
      type: 'SETUSERATTRIBUTE',
      payload: {
        value,
        attribute
      }
    });
  };

  const setNewUserProfile = (newUserProfile: UserProfile) => {
    dispatch({
      type: 'SETUSERPROFILE',
      payload: {
        newUserProfile
      }
    });
  };

  const setNewCompanyData = (companyData: Company) => {
    dispatch({
      type: 'SETCOMPANYDATA',
      payload: {
        companyData
      }
    });
  };

  const setCompanyAttribute = (value: any, attribute: string) => {
    dispatch({
      type: 'SETCOMPANYATTRIBUTE',
      payload: {
        value,
        attribute
      }
    });
  };

  const initialise = async (method?: string) => {
    try {
      const domain = process.env.REACT_APP_DOMAIN;
      const tokenPropName = getCookiePropName(domain, 'token');
      const userTypePropName = getCookiePropName(domain, 'userType');
      const token = Cookies.get(tokenPropName);

      if (token && isValidToken(token)) {
        setSession(token);

        const { associatedCompanyRoles: tokenCompanyRoles, user } = jwtDecode<{
          associatedCompanyRoles: any[];
          user: any;
        }>(token);
        const associatedCompanyRoles = tokenCompanyRoles || [];

        // TEMPORARY
        const roles = [associatedCompanyRoles.length > 0 ? 'ADMIN' : 'USER'];

        const userType = new UserType({ roles });

        var userProfile: UserProfile = null;

        const convertDateToUnix = (gmtDate: string) => {
          const date = new Date(gmtDate);
          const unixTime = Math.floor(date.getTime() / 1000);
          return unixTime;
        };

        if (method !== 'login') {
          triggerGaEvent({
            logged: true
          });
        }

        if (userType.isCandidate()) {
          Cookies.set(userTypePropName, 'candidate', { domain: domain });
          const {
            id,
            email,
            name,
            specialty,
            currentPosition,
            hasAvatar,
            roles,
            avatar,
            finishedRegister,
            ...rest
          } = await CandidateService.getGeneral().then(
            (response) => response.data
          );
          const userImagePath = `${process.env.REACT_APP_MEDIA_URL}/users/${id}/`;

          userProfile = {
            user: {
              id,
              email,
              name,
              specialty,
              currentPosition,
              hasAvatar,
              finishedRegister,
              systemRoles: roles,
              avatarFileName: hasAvatar
                ? getImageFileName('avatar', 'default', userImagePath)
                : null,
              imagePath: userImagePath,
              ...rest
            },
            associatedCompanies: associatedCompanyRoles
          };

          setObjectData({
            user_id: id,
            user_phone: rest.cellPhone,
            user_email: email,
            user_name: name ? `${name} ${rest.surname}` : '',
            user_hash: rest?.hash,
            user_context: userType.isCompany() ? 'recruiter' : 'candidate',
            user_created_at: convertDateToUnix(rest?.createdAt) ?? '',
            company_name: null,
            company_website: null,
            company_corporate_name: null,
            company_phone: null,
            company_document: null,
            company_id: null,
            company_profile_url: null,
            company_size: null,
            company_industry_id: null,
            company_industry: null,
            company_plan: null,
            company_created_at: null
          });
        } else if (userType.isCompany()) {
          Cookies.set(userTypePropName, 'company', { domain: domain });
          const associatedCompanies = associatedCompanyRoles.slice(1);

          const {
            userId,
            user,
            role,
            company,
            businessName,
            hasAvatar,
            hasCover,
            fantasyName,
            ...rest
          } = await api
            .get('/profile/associated-companies')
            .then((response) => response.data[0]);

          const userImagePath = `${process.env.REACT_APP_MEDIA_URL}/users/${user.id}/`;
          const companyImagePath = `${process.env.REACT_APP_MEDIA_URL}/companies/${company.id}/`;

          userProfile = {
            user: {
              id: user.id,
              email: user.email,
              name: user.name,
              surname: user.surname,
              cellPhone: user.cellPhone,
              hasAvatar: user.hasAvatar,
              systemRoles: user.roles,
              imagePath: userImagePath,
              avatarFileName: user.hasAvatar
                ? getImageFileName('avatar', 'default', userImagePath)
                : null,
              currentPosition: user.currentPosition
            },
            associatedCompanies: [
              ...associatedCompanies,
              {
                role,
                company: {
                  id: company.id,
                  email: company.email,
                  name: company.name,
                  businessName: company.businessName,
                  fantasyName: company.fantasyName,
                  hasAvatar: company.hasAvatar,
                  hasCover: company.hasCover,
                  avatarFileName: company.hasAvatar
                    ? getImageFileName('avatar', 'default', companyImagePath)
                    : null,
                  coverFileName: company.hasCover
                    ? getImageFileName('cover', 'default', companyImagePath)
                    : null,
                  imagePath: companyImagePath,
                  finishedRegister: company.finishedRegister,
                  industry: company.industry,
                  sector: company.sector,
                  hasJobs: company?.jobs || false,
                  amountIntervalWorkers: company.amountIntervalWorkers,
                  abstract: company.abstract,
                  active: company.active,
                  additionalInfo: company.additionalInfo,
                  address: company.address,
                  addressNumber: company.addressNumber,
                  avatarId: company.avatarId,
                  burgh: company.burgh,
                  cellPhone: company.cellPhone,
                  website: company.website,
                  city: company.city,
                  country: company.country,
                  descriptionVideo: company.descriptionVideo,
                  document: company.document,
                  gallery: company.gallery || [],
                  homePhone: company.homePhone,
                  linkVideo: company.linkVideo,
                  maxMembers: company.maxMembers,
                  slug: company.slug,
                  socialNetworks: company.socialNetworks || [],
                  state: company.state,
                  usageContract: company.usageContract,
                  zipCode: company.zipCode,
                  invoice: company.invoice,
                  account: company.account,
                  insightLists: company.insightLists || [],
                  typeService: company.typeService || '',
                  canUploadFile: company.canUploadFile,
                  subscriptionType: company.subscriptionType || '',
                  blockPersonalInfo: company.blockPersonalInfo || false
                }
              }
            ]
          };

          setObjectData({
            user_id: user.id,
            user_phone: company.cellPhone,
            user_email: user.email,
            user_name: user.name,
            user_hash: user?.hash,
            user_context: userType.isCompany() ? 'recruiter' : 'candidate',
            user_created_at: convertDateToUnix(user?.createdAt) ?? '',
            company_name: company.fantasyName,
            company_website: company.website,
            company_corporate_name: company.businessName,
            company_phone: company.homePhone,
            company_document: company.document,
            company_id: company.id,
            company_profile_url: `https://www.gria.com.br/${company.slug}`,
            company_size: company.amountIntervalWorkers,
            company_industry_id: company.industry.id ?? '',
            company_industry: company.industry.name ?? '',
            company_plan: 'Free',
            company_created_at: convertDateToUnix(company?.createdAt) ?? ''
          });
        }

        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: true,
            userProfile,
            userType
          }
        });
      } else {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            userProfile: null
          }
        });
      }
    } catch (err) {
      dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: false,
          userProfile: null
        }
      });
    }
  };

  useEffect(() => {
    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        switchCompany,
        gaRegisterEvent,
        triggerJobRelatedGaEvent,
        changePassword,
        setUserAttribute,
        setCompanyAttribute,
        setNewUserProfile,
        setNewCompanyData,
        candidateListParams,
        setCandidateListParams,
        setCurrentJobId,
        locale
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
