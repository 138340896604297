import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { InsightsEmployeed } from 'src/types/insightsTable';
import insightsApi from 'src/api/insightsApi';

interface RequestParamsProps {
  page: number;
  limit: number;
  fuzzySearch?: string;
}

interface InsightsState {
  insightsTable: InsightsEmployeed[];
  totalItems: number;
  totalPages: number;
  thereUsers: boolean;
}

const initialState: InsightsState = {
  insightsTable: [],
  totalItems: 0,
  totalPages: 0,
  thereUsers: false
};

const slice = createSlice({
  name: 'insightsTable',
  initialState,
  reducers: {
    getInsightsTable(
      state: InsightsState,
      action: PayloadAction<{
        meta: { totalItems: number; totalPages: number; users: boolean };
        items: InsightsEmployeed[];
      }>
    ) {
      const { items, meta } = action.payload;

      state.insightsTable = items;
      state.totalItems = meta.totalItems;
      state.totalPages = meta.totalPages;
      state.thereUsers = meta.users;
    }
  }
});

export const reducer = slice.reducer;

export const getInsightsTable =
  (params: RequestParamsProps, companyId: string, listId: string): AppThunk =>
  async (dispatch) => {
    const response = await insightsApi.get<{
      meta: { totalItems: number; totalPages: number; users: boolean };
      items: InsightsEmployeed[];
    }>(`/insights/companies/${companyId}/list/${listId}`, { params });

    dispatch(slice.actions.getInsightsTable(response.data));
  };

export default slice;
