import { makeStyles } from '@mui/styles';
import { Theme } from 'src/theme';

const containerBase = {
  padding: '18px 12px',
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  border: '1px solid',
  cursor: 'pointer'
};

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    ...containerBase,
    '&.MuiCard-root': {
      borderColor: '#0000001F'
    }
  },
  containerActive: {
    ...containerBase,
    '&.MuiCard-root': {
      borderColor: theme.palette.primary.main,
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 12,
    width: 44,
    height: 44,
    background: 'rgba(63, 81, 181, 0.08)',
    borderRadius: 48,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  text: {
    textAlign: 'start',
    flex: 1
  },
  title: {
    fontFamily: 'Nunito',
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: 0.15000000596046448,
  },
  subTitle: {
    fontFamily: 'Nunito',
    color: '#00000099',
    fontWeight: 400,
    letterSpacing: 0.15000000596046448,
  },
  radio: {
    height: 20,
    width: 20,
    '&.MuiRadio-root': {
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }
  }
}));
