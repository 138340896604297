import { useMemo } from "react";
import { useHistory } from "react-router";

const useFilterRoute = <ParamsType>(formatParams?: (key: string, value: string) => any, invisibleParams?: string[]) => {
    const history = useHistory();
    const params = useMemo(() => {
      const historySearchParams = new URLSearchParams(history.location?.search);
      const filterInvisibleParams = history.location.state && history.location?.state['filterInvisibleParams'];
      const rawParams = { ...Object.fromEntries(historySearchParams), ...filterInvisibleParams }
      return Object.keys(rawParams).reduce((accumlator, key) => {
        if (formatParams) {
          accumlator[key] = formatParams(key, rawParams[key]);
        } else {
          accumlator[key] = rawParams[key];
        }

        return accumlator;
      }, {} as Partial<ParamsType> );
    }, [history.location])

    const handleFilterParams = (params: Partial<ParamsType>, invisibleParams?: string[]) => {
      if (invisibleParams) {
        return Object.keys(params).reduce((accumulator, currentParam) => {
          if (invisibleParams.includes(currentParam)) {
            accumulator.stateParams[currentParam] = params[currentParam];
          } else {
            accumulator.searchParams[currentParam] = params[currentParam];
          }

          return accumulator;
        }, { searchParams: {}, stateParams: {} } as { searchParams: Partial<ParamsType>, stateParams: Partial<ParamsType> })
      }

      return { searchParams: params, stateParams: {} }
    }

    const updateFilter = (newParams: Partial<ParamsType>) => {
      const { searchParams, stateParams } = handleFilterParams({ ...params, ...newParams }, invisibleParams);
      const urlParams = Object.fromEntries(Object.entries(searchParams).filter(([_, value]) => Boolean(String(value).length && String(value) !== 'false')))
      const newUrlParams = new URLSearchParams(Object(urlParams));

      history.replace({ search: `?${newUrlParams.toString()}`, state: { filterInvisibleParams: stateParams } })
    }

    return { params, updateFilter }
}


export default useFilterRoute;