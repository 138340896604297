import { createSlice } from "@reduxjs/toolkit";
import api from "src/api";
import CandidateService from "src/services/candidate.service";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { AppThunk } from "src/store";
import type { Candidacy } from "src/types/candidacy";

interface RequestParamsProps {
  page: number;
  limit: number;
  businessName?: string;
  skillLevel?: string;
  city?: string;
  status?: string;
}

interface CandidacyState {
  candidacies: Candidacy[];
  candidaciesTotal: number;
  candidaciesMeta: any;
};

const initialState: CandidacyState = {
  candidacies: [],
  candidaciesTotal: 0,
  candidaciesMeta: {},
};

const slice = createSlice({
  name: "candidacies",
  initialState,
  reducers: {
    getCandidacies(
      state: CandidacyState,
      action: PayloadAction<{ meta: any, items: Candidacy[] }>
    ) {
      const { items, meta } = action.payload;

      state.candidacies = items;
      state.candidaciesTotal = meta.totalItems;
      state.candidaciesMeta = meta;
    },
    giveUpCandidacy(state: CandidacyState, action: PayloadAction<{ id: string, status: string }>) {
      const { id, status } = action.payload;

      state.candidacies.find(candidacy => candidacy.application.id === id).application.status = 'CANCELLED';
    }
  }
});

export const reducer = slice.reducer;

export const getCandidacies = (params: RequestParamsProps): AppThunk => async (dispatch) => {
  const response = await api.get<{ meta: { totalItems: number }, items: Candidacy[] }>(
    "/profile/applications", { params });

  dispatch(slice.actions.getCandidacies(response.data));
}

export const giveUpCandidacy = (id: string, message: string): AppThunk => async (dispatch) => {
  const response = await CandidateService.giveUpApplication(id, { message });

  dispatch(slice.actions.giveUpCandidacy(response.data));
}

export default slice;