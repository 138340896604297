import {
  AllInclusiveOutlined,
  AppsOutlined,
  BusinessOutlined,
  ChangeCircle,
  MenuOutlined,
  Menu
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery
} from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React, { useContext, useEffect, useState } from 'react';
//import { Menu as MenuIcon } from 'react-feather';
import HelpIcon from '@mui/icons-material/Help';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

import Logo from 'src/components/Logo';
import { PositionModal } from 'src/components/molecules/Modals/PositionModal';
import { THEMES } from 'src/constants';
import useAuth from 'src/hooks/useAuth';
import type { Theme } from 'src/theme';
import Account from './Account';

import Messages from './Messages';

import { StripeApp } from 'src/components/molecules/Payment/StripeApp';
import { SubscribeModal } from 'src/components/molecules/Payment/SubscribeModal';
import { getPositionByTerm } from 'src/hooks/usePositionsList';
import { candidateService, companyService } from 'src/services';
import { useSelector } from 'src/store';
import { FlowLogo } from 'src/views/insights/FlowLogo';
import { ChangeProduct } from 'src/views/insights/components/Menus';
import introJs from 'intro.js';
import 'intro.js/introjs.css';

export interface MessageTab {
  user: {
    id: string;
    name: string;
    hasAvatar: boolean;
  };
  company: {
    id: string;
    fantasyName: string;
    hasAvatar: boolean;
  };
  application: {
    id: string;
  };
  job: {
    id: string;
    name: string;
  };
  notification: {
    createdAt: string;
    id: string;
    view: boolean;
  };
}
interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
  handleOpenDrawer?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // THIS comment below is to left menu bar stay over the appbar
    // zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.background.default
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {}),
    backgroundColor: theme.palette.secondary.contrastText
  },
  toolbar: {
    minHeight: 68,
    boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.1)'
  },
  icon: {
    '& > img': {
      width: '100%',
      maxWidth: '200px',
      height: '100%',
      maxHeight: '40px'
    }
  },
  iconMiddle: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  tab: {
    textTransform: 'none',
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0.15px',
    padding: '0px 16px 16px'
  },
  tabsIndicator: {
    '& .MuiTabs-indicator': {
      height: '3px'
    }
  },
  tooltipText: {
    fontFamily: 'Nunito',
    fontSize: '12px',
    fontWeight: 400,
    textAlign: 'center',
    lineHeight: '14px',
    padding: '4px 8px'
  },
  tooltipPopper: {
    transform: 'translate(28px,40px) !important',
    width: '90px',
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontWeight: 700
  }
}));

const TopBar: FC<TopBarProps> = ({
  className,
  onMobileNavOpen,
  handleOpenDrawer,
  ...rest
}) => {
  const classes = useStyles();
  const { userType, userProfile } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const isCandidate = userType?.isCandidate();
  const isCompany = userType?.isCompany();
  const accessRouteIsInsights = history.location.pathname.includes('/insights');
  const userIsJobs =
    userProfile?.associatedCompanies[0]?.company?.typeService?.includes('jobs');
  const [currentTab, setCurrentTab] = useState<string | boolean>(
    isCandidate
      ? 'opportunityCandidate'
      : isCompany && accessRouteIsInsights
      ? 'individualAnalysis'
      : 'opportunity'
  );
  const [segmentCorrect, setSegmentCorrect] = useState(false);
  const [allMessages, setAllMessages] = useState<MessageTab[]>([]);
  const [messagesCount, setMessagesCount] = useState(0);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [messagesPage, setMessagesPage] = useState(1);
  const { countByStatus } = useSelector((state) => state.opportunities);
  const plans = userProfile?.associatedCompanies[0]?.company?.subscriptionType;

  const isStringifiedArray = (plan) => {
    try {
      const parsedValue = JSON.parse(plan);
      if (Array.isArray(parsedValue)) {
        return JSON.parse(plan);
      }
    } catch (error) {
      return plan;
    }
  };

  const hasTeamsPlan = isStringifiedArray(plans)?.includes('performance_teams');
  const hasPeoplePlan =
    isStringifiedArray(plans)?.includes('performance_people');
  const hasHiringPlan = isStringifiedArray(plans)?.includes('hiring');

  useEffect(() => {
    const messageMenu = async () => {
      let result: any = [];
      if (isCompany) {
        try {
          result = await companyService.getCompanyMessageNotifications(
            userProfile.associatedCompanies[0].company.id,
            1,
            5
          );
        } catch (error) {
          console.error('Ocorreu um erro: ', error);
        }
      } else {
        try {
          result = await candidateService.getUserMessageNotifications(1, 5);
        } catch (error) {
          console.error('Ocorreu um erro: ', error);
        }
      }

      setAllMessages(result.data.items);
      setUnreadMessages(result.data.meta.totalItemsNotView);
      setMessagesCount(result.data.meta.totalItems);
      setMessagesPage(1);
    };
    messageMenu().catch(console.error);
  }, [history.location]);

  const moreMessages = async () => {
    let result: any;
    setMessagesPage(messagesPage + 1);

    if (isCompany)
      result = await companyService.getCompanyMessageNotifications(
        userProfile.associatedCompanies[0].company.id,
        messagesPage + 1,
        5
      );
    else
      result = await candidateService.getUserMessageNotifications(
        messagesPage + 1,
        5
      );
    setAllMessages((prev) => [...prev.concat(result.data.items)]);
  };

  const handleNotificationRead = async (token: string, read?: boolean) => {
    if (isCompany)
      await companyService.setMessageRead(
        userProfile.associatedCompanies[0].company.id,
        token
      );
    else await candidateService.setMessageRead(token);

    setUnreadMessages((prev) => (read ? prev + 1 : prev - 1));

    setAllMessages((prev) => {
      let newMessages = prev.map((message) => {
        if (message.notification.id === token)
          message.notification.view = !message.notification.view;
        return message;
      });
      return newMessages;
    });
  };

  const handleCreateOpportunity = async (value: any) => {
    let newValue;
    if (!value.code) {
      newValue = await getPositionByTerm(value.role);
      value = { role: value.role, code: newValue.code };
    }
    history.location?.search
      ? history.push('/company/jobs/create', {
          searchState: history.location.search,
          position: value
        })
      : history.push(`/company/jobs/create`, {
          ...(history.location.state as any),
          position: value
        });
    setOpenPositionModal(false);
  };

  const isCreateView = history.location.pathname.includes(
    '/company/jobs/create'
  );
  const finishedRegister =
    !isCandidate && userProfile.associatedCompanies[0].company.finishedRegister;

  const paymentPendence =
    userType.isCompany() &&
    userProfile.associatedCompanies[0].company.account?.invoice?.status &&
    userProfile.associatedCompanies[0].company.account?.invoice?.status !==
      'PAID';

  const invoirementLocale = process.env.REACT_APP_INVOIREMENT_LOCALE;
  let disableJobCreation = paymentPendence && invoirementLocale !== 'localhost';

  const tooltipMessage = !finishedRegister ? (
    <Typography className={classes.tooltipText}>
      <strong>Dados cadastrais incompletos!</strong>
      <br></br>
      Os dados cadastrais da sua empresa estão incompletos. É preciso atualizar
      o cadastro para poder criar uma vaga.
    </Typography>
  ) : paymentPendence ? (
    <Typography className={classes.tooltipText}>
      <strong>Pagamento pendente!</strong>
      <br></br>A criação de vagas será liberada após confirmação de pagamento.
    </Typography>
  ) : (
    ''
  );
  const userIsInsights = history.location.pathname.includes('/insights');

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true
  });

  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      padding: '8px 16px',
      maxWidth: '304px',
      width: '100%',
      maxHeight: '64px',
      fontSize: '14px',
      fontFamily: 'Nunito',
      fontWeight: 600,
      background: '#616161',
      textAlign: 'start'
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#616161'
    }
  });

  const tabs = [
    isCandidate
      ? { value: '', label: '' }
      : {
          value: 'dashboard',
          label: 'Dashboard',
          id: 'top-bar-dashboard-button-id'
        },
    isCandidate
      ? { value: 'opportunityCandidate', label: 'Vagas' }
      : {
          value: 'opportunity',
          label: 'Vagas',
          id: 'top-bar-vacancy-button-id'
        },
    isCandidate
      ? { value: 'myApplications', label: 'Candidaturas' }
      : {
          value: 'talentBank',
          label: 'Banco de talentos',
          id: 'top-bar-talent-banck-button-id'
        }
  ];

  const insightsTabs = {
    hiring: [
      {
        value: 'individualAnalysis',
        label: 'Listagem',
        id: 'top-bar-hiring-individual-analysis-button-id'
      },
      {
        value: 'addHiring',
        label: 'Adicionar pessoas',
        id: 'top-bar-hiring-add-button-id'
      }
    ],
    flow: [
      {
        value: 'linkFlow',
        label: 'Link pro FLOW',
        id: 'top-bar-link-flow-button-id'
      },
      {
        value: 'roundFlow',
        label: 'Prazos pra responder o FLOW',
        id: 'top-bar-awnser-flow-button-id'
      }
    ]
  };

  const handleTabsChange = (event: any, value: string): void => {
    if (
      value === 'myApplications' &&
      !history.location.pathname.includes('/candidate/applications')
    ) {
      history.push(`/candidate/applications`);
    }
    if (
      value === 'opportunityCandidate' &&
      !history.location.pathname.includes('/candidate/jobs')
    ) {
      history.push(`/candidate/jobs`);
    }
    if (value === 'opportunity') {
      history.push(`/company/jobs`);
    }
    if (
      value === 'talentBank' &&
      !history.location.pathname.includes('/company/registered-candidates')
    ) {
      history.push(`/company/registered-candidates`);
    }
    if (value === 'dashboard') {
      history.push(`/company/dashboard`);
    }
    if (value === 'insightsHomepage') {
      history.push(`/insights`);
    }
    if (value === 'individualAnalysis') {
      history.push(`/insights/hiring`);
    }
    if (value === 'addHiring') {
      history.push(`/insights/hiring/add/external-link`);
    }
    if (value === 'linkFlow') {
      history.push('/insights/survey-share-links');
    }
    if (value === 'teamsManagement') {
      history.push('/insights/teams/team-management');
    }
    if (value === 'roundFlow') {
      history.push('/insights/deadline-flow-responses');
    }
    if (value === 'peopleDevelopment') {
      history.push('/insights/peoples/people-development');
    }
  };

  useEffect(() => {
    if (userType.isCompany()) {
      if (
        userProfile.associatedCompanies[0].company.industry.name != 'Outros'
      ) {
        setSegmentCorrect(true);
      }
    }
  });

  useEffect(() => {
    if (history.location.pathname === '/company/jobs') {
      setCurrentTab('opportunity');
    } else if (
      history.location.pathname.includes('/company/registered-candidates')
    ) {
      setCurrentTab('talentBank');
    } else if (history.location.pathname.includes('/candidate/applications')) {
      setCurrentTab('myApplications');
    } else if (history.location.pathname.includes('/candidate/jobs')) {
      setCurrentTab('opportunityCandidate');
    } else if (history.location.pathname.includes('/company/dashboard')) {
      setCurrentTab('dashboard');
    } else if (location.pathname === '/insights/hiring') {
      setCurrentTab('individualAnalysis');
    } else if (location.pathname === '/insights/hiring/add/external-link') {
      setCurrentTab('addHiring');
    } else if (location.pathname === '/insights/survey-share-links') {
      setCurrentTab('linkFlow');
    } else if (location.pathname === '/insights/teams/team-management') {
      setCurrentTab('teamsManagement');
    } else if (location.pathname === '/insights/deadline-flow-responses') {
      setCurrentTab('roundFlow');
    } else if (
      location.pathname === '/insights/peoples/people-development' ||
      location.pathname === '/insights/peoples/analysis-people'
    ) {
      setCurrentTab('peopleDevelopment');
    } else if (location.pathname === '/insights') {
      setCurrentTab('insightsHomepage');
    } else {
      setCurrentTab(false);
    }
  }, [history.location.pathname]);

  const [openPositionModal, setOpenPositionModal] = useState(false);

  const freeSubscriber =
    userType.isCompany() &&
    !userProfile.associatedCompanies[0]?.company.account
      ?.subscriptionCreatedAt &&
    !userProfile.associatedCompanies[0]?.company.account?.freeTrialCreatedAt;

  const exPremiumSusbcriber =
    !userProfile.associatedCompanies[0]?.company.account
      ?.subscriptionCreatedAt &&
    userProfile.associatedCompanies[0]?.company.account?.freeTrialCreatedAt;

  const [openSubscribe, setOpenSubscribe] = useState(false);
  const [openStripe, setOpenStripe] = useState(false);
  const handleCloseSubscribe = () => {
    setOpenSubscribe(false);
  };
  const handleOpenSubscribe = () => {
    setOpenSubscribe(true);
  };

  const handleOpenStripe = () => {
    setOpenStripe(true);
    handleCloseSubscribe();
  };

  const handleCloseStripe = () => {
    setOpenStripe(false);
  };

  const handleRedirectRouter = () => {
    history.location.pathname.includes('/insights')
      ? history.push('/company/jobs')
      : history.push('/insights');
    handleClose();
  };

  const handleShowTutorial = () => {
    localStorage.removeItem('dontShowAgain');
    window.location.reload();
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const productsList = [
    {
      option: accessRouteIsInsights ? 'Jobs' : 'Flow',
      icon: accessRouteIsInsights ? (
        <BusinessOutlined color="primary" />
      ) : (
        <AllInclusiveOutlined color="primary" />
      ),
      onClick: () => {
        handleRedirectRouter();
      }
    }
  ];

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        {isCompany &&
          !userIsJobs &&
          !isMediumScreen &&
          (!accessRouteIsInsights ? (
            <Tooltip
              title="Gria Apps"
              arrow
              placement="bottom-start"
              classes={{ popper: classes.tooltipPopper }}
            >
              <IconButton
                onClick={accessRouteIsInsights ? handleOpenDrawer : handleOpen}
                size="large"
              >
                {accessRouteIsInsights ? (
                  <Menu sx={{ color: '#00000099' }} />
                ) : (
                  <AppsOutlined sx={{ color: '#00000099' }} />
                )}
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton
              onClick={accessRouteIsInsights ? handleOpenDrawer : handleOpen}
              size="large"
            >
              {accessRouteIsInsights ? (
                <Menu sx={{ color: '#00000099' }} />
              ) : (
                <AppsOutlined sx={{ color: '#00000099' }} />
              )}
            </IconButton>
          ))}
        {isMediumScreen && (
          <IconButton onClick={onMobileNavOpen} size="large">
            <MenuOutlined fontSize="small" sx={{ color: '#00000099' }} />
          </IconButton>
        )}

        {accessRouteIsInsights ? (
          <FlowLogo />
        ) : (
          <RouterLink
            className={isMediumScreen ? classes.iconMiddle : null}
            to={`/${isCompany ? 'company' : 'candidate'}/jobs`}
          >
            <div className={classes.icon}>
              <Logo colorful="true" />
            </div>
          </RouterLink>
        )}
        {!isMediumScreen && (
          <Box marginLeft="58px" marginBottom="-18px">
            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              value={currentTab}
              variant={isMediumScreen ? 'fullWidth' : 'scrollable'}
              centered={isMediumScreen}
              textColor="secondary"
              indicatorColor="secondary"
              className={classes.tabsIndicator}
            >
              {accessRouteIsInsights
                ? (location.pathname === '/insights/hiring' ||
                  location.pathname === '/insights/hiring/add/external-link'
                    ? insightsTabs['hiring']
                    : location.pathname ===
                        '/insights/deadline-flow-responses' ||
                      location.pathname === '/insights/survey-share-links'
                    ? insightsTabs['flow']
                    : []
                  ).map((tab) => (
                    <Tab
                      id={tab.id}
                      className={classes.tab}
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                    />
                  ))
                : tabs.map((tab) => (
                    <Tab
                      id={tab.id}
                      className={classes.tab}
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                    />
                  ))}
            </Tabs>
          </Box>
        )}
        <Box ml={2} flexGrow={1} />

        {!isMediumScreen && !accessRouteIsInsights && (
          <Messages
            allMessages={allMessages}
            messagesCount={messagesCount}
            unreadMessages={unreadMessages}
            moreMessages={moreMessages}
            isCompany={isCompany}
            handleNotificationRead={(token, read) =>
              handleNotificationRead(token, read)
            }
          />
        )}
        {accessRouteIsInsights && (
          <Box marginRight="24px">
            <CustomTooltip
              title="Tutorial sobre as funcionalidades da plataforma."
              arrow
              placement="bottom-end"
            >
              <HelpIcon
                color="secondary"
                sx={{ width: '24px', height: '24px', cursor: 'pointer' }}
                onClick={handleShowTutorial}
              />
            </CustomTooltip>
          </Box>
        )}
        {!isMediumScreen && isCompany && (
          <Divider orientation="vertical" variant="middle" flexItem />
        )}
        <Box ml={2} style={{ display: 'flex' }}>
          <Account
            allMessages={allMessages}
            messagesCount={messagesCount}
            unreadMessages={unreadMessages}
            moreMessages={moreMessages}
            isCompany={isCompany}
            handleNotificationRead={(token, read) =>
              handleNotificationRead(token, read)
            }
          />
        </Box>
      </Toolbar>
      {openPositionModal && (
        <PositionModal
          open={openPositionModal}
          close={() => setOpenPositionModal(false)}
          title="Vamos começar?"
          subtitle="Crie sua vaga em menos de 5 minutos"
          btnRightLabel="Começar"
          onRightButtonClick={handleCreateOpportunity}
          isPositionFound={false}
          isEditing={false}
        />
      )}
      {openSubscribe && (
        <SubscribeModal
          view="jobs"
          open={openSubscribe}
          handleClose={handleCloseSubscribe}
          clickButtonLeft={handleCloseSubscribe}
          clickButtonRight={handleOpenStripe}
          amountVacancyOpen={
            countByStatus?.countOpen + countByStatus?.countPaused
          }
        />
      )}

      {openStripe && (
        <StripeApp
          open={openStripe}
          handleOpen={handleOpenStripe}
          handleClose={handleCloseStripe}
        />
      )}

      {!isMediumScreen && anchorEl && (
        <ChangeProduct
          anchor={anchorEl}
          listItems={productsList}
          handleClose={handleClose}
        />
      )}
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
