import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import welcomeImage from 'src/assets/images/welcome-tutorial-image.png';
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Box,
  IconButton,
  Divider,
  Collapse,
  Typography,
  Button
} from '@mui/material';

import {
  Menu,
  AddBusiness,
  Person,
  OpenInNew,
  ArrowBack,
  House,
  Link,
  Schema,
  ArrowDropDown,
  ArrowDropUp,
  SearchOff,
  ManageAccounts,
  AccountTree,
  Group,
  PostAdd
} from '@mui/icons-material';
import { useHistory, useLocation } from 'react-router';
import { FlowLogo } from 'src/views/insights/FlowLogo';
import { useStyles } from './styles';
import useAuth from 'src/hooks/useAuth';
import 'intro.js/introjs.css';
import 'src/components/ModalTour/custom-intro-styles.css';
import introJs from 'intro.js';

interface MenuDrawerProps {
  open: boolean;
  handleClose: () => void;
}

interface MenuItem {
  id?: number;
  name?: string;
  icon?: JSX.Element | string;
  redirect?: string;
  subheader?: string;
  subitems?: MenuItem[];
  section?: string;
  subsection?: string;
}

export const MenuDrawer: FC<MenuDrawerProps> = ({ open, handleClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { userProfile } = useAuth();
  const name = userProfile?.user?.name;
  const [openItems, setOpenItems] = useState<number[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [selectedSubitemId, setSelectedSubitemId] = useState<number | null>(
    null
  );
  const role: string = userProfile?.associatedCompanies[0]?.role;

  const plans = userProfile?.associatedCompanies[0]?.company?.subscriptionType;
  const userIsAll =
    userProfile?.associatedCompanies[0]?.company?.typeService?.includes('all');

  const isStringifiedArray = (plan) => {
    try {
      const parsedValue = JSON.parse(plan);
      if (Array.isArray(parsedValue)) {
        return JSON.parse(plan);
      }
    } catch (error) {
      return plan;
    }
  };

  const hasTeamsPlan = isStringifiedArray(plans)?.includes('performance_teams');
  const hasPeoplePlan =
    isStringifiedArray(plans)?.includes('performance_people');
  const hasHiringPlan = isStringifiedArray(plans)?.includes('hiring');
  const menuItems: MenuItem[] = [
    {
      id: 0,
      name: 'Página inicial',
      icon: <House className={classes.icon} />,
      redirect: '/insights',
      section: 'sectionInitial'
    },
    {
      id: 1,
      name: 'Área de administradores',
      redirect: '',
      section: 'adminSection',
      subitems: [
        {
          id: 11,
          name: 'Configurar usuários',
          icon: <SearchOff className={classes.icon} />,
          redirect: '/insights/profile/user-management'
        },
        hasTeamsPlan || hasPeoplePlan
          ? {
              id: 12,
              name: 'Configurar times',
              icon: <ManageAccounts className={classes.icon} />,
              redirect: '/insights/teams/settings'
            }
          : {},
        hasTeamsPlan || hasPeoplePlan
          ? {
              id: 13,
              name: 'Grupos de permissão',
              icon: <AccountTree className={classes.icon} />,
              redirect: '/insights/groups'
            }
          : {}
        // {
        //   id: 14,
        //   name: 'Cargos personalizados',
        //   icon: <PostAdd className={classes.icon} />,
        //   redirect: '/insights/request-quote-roles'
        // }
      ].filter((object) => Object.keys(object).length > 0)
    },
    hasHiringPlan
      ? {
          id: 2,
          name: 'Candidatos',
          redirect: '',
          section: 'sectionSelective',
          subitems: [
            {
              id: 21,
              name: 'Gestão de candidatos',
              icon: <AddBusiness className={classes.icon} />,
              redirect: '/insights/hiring'
            }
          ]
        }
      : {},
    hasTeamsPlan || hasPeoplePlan
      ? {
          id: 3,
          name: 'Colaboradores',
          section: 'sectionCollaborators',
          subitems: [
            {
              id: 31,
              name: 'Link pro FLOW',
              icon: <Link className={classes.icon} />,
              redirect: '/insights/survey-share-links'
            },
            {
              id: 32,
              name: 'Resultados individuais',
              icon: <Person className={classes.icon} />,
              redirect: '/insights/peoples/people-development',
              section: 'sectionIndividual'
            },
            {
              id: 33,
              name: 'Resultados coletivos',
              icon: <Group className={classes.icon} />,
              redirect: '/insights/teams/group-analysis'
            }
          ]
        }
      : {}
  ].filter((object) => Object.keys(object).length > 0);

  const recruiterMenuItems: MenuItem[] = [
    {
      id: 0,
      name: 'Página inicial',
      icon: <House className={classes.icon} />,
      redirect: '/insights',
      subheader: '',
      section: 'sectionInitial'
    },
    hasHiringPlan
      ? {
          id: 21,
          name: 'Gestão de candidatos',
          icon: <AddBusiness className={classes.icon} />,
          redirect: '/insights/hiring',
          subheader: 'Candidatos',
          section: 'sectionSelective'
        }
      : {}
    // {
    //   id: 14,
    //   name: 'Cargos personalizados',
    //   icon: <PostAdd className={classes.icon} />,
    //   redirect: '/insights/request-quote-roles',
    //   subheader: '',
    //   section: 'sectionSelective'
    // }
  ].filter((object) => Object.keys(object).length > 0);

  const leadershipMenuItems: MenuItem[] = [
    {
      id: 0,
      name: 'Página inicial',
      icon: <House className={classes.icon} />,
      redirect: '/insights',
      subheader: '',
      section: 'sectionInitial'
    },
    hasTeamsPlan || hasPeoplePlan
      ? {
          id: 31,
          name: 'Link pro FLOW',
          icon: <Link className={classes.icon} />,
          redirect: '/insights/survey-share-links',
          subheader: 'Colaboradores',
          section: 'sectionCollaborators'
        }
      : {},
    hasTeamsPlan || hasPeoplePlan
      ? {
          id: 32,
          name: 'Resultados individuais',
          icon: <Person className={classes.icon} />,
          redirect: '/insights/peoples/people-development',
          section: 'sectionCollaborators-1'
        }
      : {},
    hasTeamsPlan || hasPeoplePlan
      ? {
          id: 33,
          name: 'Resultados coletivos',
          icon: <Group className={classes.icon} />,
          redirect: '/insights/teams/group-analysis',
          section: 'sectionCollaborators-2'
        }
      : {}
  ].filter((object) => Object.keys(object).length > 0);

  const handleItemClick = (itemId: number) => {
    setSelectedItemId(itemId);
    if (openItems.includes(itemId)) {
      setOpenItems(openItems.filter((id) => id !== itemId));
    } else {
      setOpenItems([...openItems, itemId]);
    }

    if (
      (role === 'RECRUITER' || role === 'LEADERSHIP') &&
      (itemId !== Number(14) ||
        itemId !== Number(21) ||
        itemId !== Number(31) ||
        itemId !== Number(32) ||
        itemId !== Number(33))
    ) {
      setSelectedSubitemId(null);
    }
  };

  const handleRedirectItems = (redirect: string) => {
    history.push(redirect);
  };

  useEffect(() => {
    if (location.pathname === '/insights') {
      setSelectedItemId(0);
      setSelectedSubitemId(null);
    } else if (location.pathname === '/insights/profile/user-management') {
      setSelectedSubitemId(11);
    } else if (location.pathname === '/insights/teams/settings') {
      setSelectedSubitemId(12);
    } else if (location.pathname === '/insights/groups') {
      setSelectedSubitemId(13);
    } else if (location.pathname === '/insights/request-quote-roles') {
      role === 'RECRUITER' || role === 'LEADERSHIP'
        ? setSelectedItemId(14)
        : setSelectedSubitemId(14);
    } else if (
      location.pathname === '/insights/hiring' ||
      location.pathname === '/insights/hiring/add/external-link' ||
      location.pathname === '/insights/hiring/details-result'
    ) {
      role === 'RECRUITER' || role === 'LEADERSHIP'
        ? setSelectedItemId(21)
        : setSelectedSubitemId(21);
    } else if (
      location.pathname === '/insights/survey-share-links' ||
      location.pathname === '/insights/deadline-flow-responses'
    ) {
      role === 'RECRUITER' || role === 'LEADERSHIP'
        ? setSelectedItemId(31)
        : setSelectedSubitemId(31);
    } else if (
      location.pathname === '/insights/peoples/people-development' ||
      location.pathname === '/insights/peoples/analysis-people' ||
      location.pathname === '/insights/peoples/analysis-people/development' ||
      location.pathname === '/insights/peoples/analysis-people/possibilities'
    ) {
      role === 'RECRUITER' || role === 'LEADERSHIP'
        ? setSelectedItemId(32)
        : setSelectedSubitemId(32);
    } else if (
      location.pathname === '/insights/teams/group-analysis' ||
      location.pathname === '/insights/teams'
    ) {
      role === 'RECRUITER' || role === 'LEADERSHIP'
        ? setSelectedItemId(33)
        : setSelectedSubitemId(33);
    } else if (location.pathname === '/insights/teams/team-management') {
      setSelectedSubitemId(42);
    } else {
      setSelectedSubitemId(null);
      setSelectedItemId(null);
    }
  }, [history.location.pathname, window.location.pathname]);

  const handleRedirectRouter = () => {
    //return window.location.replace('/company/jobs');
    history.push('/company/jobs');
  };

  const intro = introJs();
  const startIntro = () => {
    const steps = [
      {
        title: `Boas vindas, ${name}!`,
        intro: `<img src="${welcomeImage}" alt="Welcome Image"/>  <br/>
            Que bom ter você por aqui! Vamos fazer um breve tour pela plataforma, pra você conhecer os recursos que a Gria oferece?`
      },
      {
        element: '#drawerLateral',
        title: 'Menu lateral',
        intro:
          'Aqui você encontra um atalho para todas as funcionalidades. Confira a seguir o que é cada uma delas.',
        position: 'right'
      },
      {
        element: '#sectionInitial',
        title: 'Página inicial',
        intro:
          'É onde sua jornada inicia toda vez que acessar a plataforma. Aqui você encontra uma breve descrição das funcionalidades que temos aqui na Gria.',
        position: 'right'
      },
      ...(['ADMIN', 'OWNER', 'SUPPORT'].includes(role)
        ? [
            {
              element: '#adminSection',
              title: 'Área de administradores',
              intro:
                'Defina quais pessoas da sua empresa podem acessar os resultados dos colaboradores aqui, na plataforma. Na página "Configurar usuários", convide administradores, recrutadores e líderes de equipe. Na página "Configurar times", cadastre o nome de todos os times da empresa. E em "Grupos de permissão", determine quais times cada administrador(a), recrutador(a) e liderança de time pode acessar .',
              position: 'right'
            }
          ]
        : []),
      ...(hasHiringPlan && role !== 'LEADERSHIP'
        ? [
            {
              element: '#sectionSelective',
              title: 'Processos seletivos',
              intro:
                'Aqui você encontra <strong>tanto o link quanto o resultado da análise FLOW para candidatos</strong>, ou seja, para quem está participando do processo seletivo para alguma vaga na sua empresa.',
              position: 'right'
            }
          ]
        : []),
      ...(role !== 'RECRUITER' && (hasTeamsPlan || hasPeoplePlan)
        ? [
            {
              element: '#sectionCollaborators',
              title: 'Área de colaboradores',
              intro:
                role === 'LEADERSHIP'
                  ? 'Na página "Link para a análise FLOW", obtenha o link para quem já é contratado da sua empresa responder ao formulário.'
                  : 'Na página "Link para a análise FLOW", obtenha o link para quem já é contratado da sua empresa responder ao formulário. Depois que os colaboradores responderem, seus resultados individuais estarão disponíveis na página "Resultados individuais". Além disso, a análise do time do colaborador será atualizada com esta nova resposta, na página "Resultados coletivos".',
              position: 'right'
            }
          ]
        : []),
      ...(role === 'LEADERSHIP' && (hasTeamsPlan || hasPeoplePlan)
        ? [
            {
              element: '#sectionCollaborators-1',
              title: 'Área de colaboradores',
              intro:
                'Depois que os colaboradores responderem, seus resultados individuais estarão disponíveis na página "Resultados individuais".',
              position: 'right'
            }
          ]
        : []),
      ...(role === 'LEADERSHIP' && (hasTeamsPlan || hasPeoplePlan)
        ? [
            {
              element: '#sectionCollaborators-2',
              title: 'Área de colaboradores',
              intro:
                'Além disso, a análise do time do colaborador será atualizada com esta nova resposta, na página "Resultados coletivos".',
              position: 'right'
            }
          ]
        : []),
      ...(userIsAll
        ? [
            {
              element: '#sectionSwitchPlataform',
              title: 'Plataforma de ATS',
              intro:
                'Sabia que a Gria não só analisa colaboradores, mas também tem uma ferramenta de recrutamento chamada Jobs?! Clique aqui para trocar de plataforma facilmente.',
              position: 'right'
            }
          ]
        : [])
    ];

    const filteredSteps = steps
      .map((step) => (Object.keys(step).length > 0 ? step : null))
      .filter(Boolean);

    intro.setOptions({
      steps: filteredSteps as any,
      showBullets: false,
      exitOnOverlayClick: false,
      exitOnEsc: false,
      showStepNumbers: false,
      dontShowAgain: false,
      doneLabel: 'Finalizar',
      nextLabel: 'Continuar',
      prevLabel: 'Voltar',
      hidePrev: true
    });
    intro.start();
  };

  function handleAddButton() {
    var tooltipButtonsDiv = document.querySelector('.introjs-tooltipbuttons');
    if (tooltipButtonsDiv) {
      var containerButtonDontShow = document.createElement('div');
      var buttonDontShow = document.createElement('button');
      buttonDontShow.classList.add('button-dontShow');
      containerButtonDontShow.classList.add('div-button');
      containerButtonDontShow.appendChild(buttonDontShow);
      buttonDontShow.textContent = 'Pular tour';
      tooltipButtonsDiv.insertBefore(
        containerButtonDontShow,
        tooltipButtonsDiv.firstChild
      );
      buttonDontShow.addEventListener('click', function () {
        localStorage.setItem('dontShowAgain', 'true');
        intro.exit(true);
      });
    } else {
      console.error(
        'A div com a classe .introjs-tooltipbuttons não foi encontrada.'
      );
    }
  }

  useEffect(() => {
    const dontShowIntro = localStorage.getItem('dontShowAgain') ?? false;
    if (!dontShowIntro) {
      startIntro();
    }
    handleAddButton();
  }, []);

  let selectList =
    role === 'RECRUITER'
      ? recruiterMenuItems
      : role === 'LEADERSHIP'
      ? leadershipMenuItems
      : menuItems;

  const hideItems =
    role === String('LEADERSHIP') || role === String('RECRUITER');

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <Box
        id="drawerLateral"
        style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
      >
        <Box className={classes.boxLogo}>
          <IconButton onClick={handleClose} size="large">
            <Menu sx={{ color: '#00000099' }} />
          </IconButton>
          <FlowLogo />
        </Box>
        <Divider />
        <List component="nav" className={classes.list}>
          {selectList?.map((item: MenuItem) => {
            return (
              <Box key={item.id} id={item?.section ?? String(item?.id)}>
                <ListSubheader>{item.subheader}</ListSubheader>
                <ListItemButton
                  selected={
                    hideItems
                      ? selectedItemId === item.id
                      : selectedItemId === item.id &&
                        item.id === 0 &&
                        selectedSubitemId === null
                  }
                  id={item?.section ?? String(item?.id)}
                  onClick={() => {
                    item.redirect && handleRedirectItems(item.redirect);
                    handleItemClick(item.id);
                  }}
                >
                  {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                  <ListItemText
                    primary={item.name}
                    sx={
                      hideItems
                        ? {}
                        : item.id !== 0 && {
                            '& .MuiTypography-root': {
                              fontWeight: 700
                            }
                          }
                    }
                  />
                  {item.subitems &&
                    (openItems.includes(item.id) ? (
                      <IconButton
                        size="small"
                        className={classes.icon}
                        onClick={() => handleItemClick(item.id)}
                      >
                        <ArrowDropDown />
                      </IconButton>
                    ) : (
                      <IconButton
                        className={classes.icon}
                        size="small"
                        onClick={() => handleItemClick(item.id)}
                      >
                        <ArrowDropUp />
                      </IconButton>
                    ))}
                </ListItemButton>
                {item.id === 0 && (
                  <Divider
                    variant="fullWidth"
                    style={{ margin: '16px -8px 8px -8px' }}
                  />
                )}
                {item.subitems && (
                  <>
                    <Collapse
                      in={!openItems.includes(item.id)}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {item.subitems.map((subitem) => (
                          <ListItemButton
                            key={subitem.id}
                            selected={selectedSubitemId === subitem.id}
                            onClick={() => {
                              setSelectedSubitemId(subitem.id);
                              handleRedirectItems(subitem.redirect);
                            }}
                          >
                            <ListItemIcon>{subitem.icon}</ListItemIcon>
                            <ListItemText primary={subitem.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                    {(item.id === 1 || item.id === 2) && (
                      <Divider
                        variant="fullWidth"
                        style={{ margin: '16px -8px 8px -8px' }}
                      />
                    )}
                  </>
                )}
              </Box>
            );
          })}
        </List>
        <Box className={classes.boxFooter}>
          {userIsAll && (
            <Box id="sectionSwitchPlataform">
              <Divider variant="fullWidth" style={{ marginBottom: '18px' }} />
              <Typography style={{ color: '#242220', fontWeight: 600 }}>
                Trocar de plataforma
              </Typography>
              <Button
                color="secondary"
                variant="text"
                endIcon={<OpenInNew />}
                sx={{
                  '&:hover': { background: 'transparent' },
                  textTransform: 'none'
                }}
                onClick={handleRedirectRouter}
              >
                Ir para o Jobs
              </Button>
            </Box>
          )}

          <Divider
            variant="fullWidth"
            style={{ marginBottom: '18px', marginTop: 'auto' }}
          />
          <Button
            color="secondary"
            variant="text"
            startIcon={<ArrowBack />}
            sx={{
              '&:hover': { background: 'transparent' },
              marginBottom: '8px',
              textTransform: 'none'
            }}
            onClick={handleClose}
          >
            Minimizar menu
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};
