import type { FC } from 'react';
import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Icon,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { HowToRegOutlined } from '@mui/icons-material';
import TagInput from 'src/views/opportunities/opportunitieCreateView/TagInput';

const useStyles = makeStyles(() => ({
  cardHeader: {
    paddingBottom: 0
  },
  cardContent: {
    '&:last-child': {
      paddingBottom: 0
    }
  },
  text: {
    fontFamily: 'Nunito'
  }
}));

interface HabilidadesCardProps {
  personalSkills: string[];
  professionalSkills: string[];
}

const HabilidadesCard: FC<HabilidadesCardProps> = ({
  personalSkills,
  professionalSkills
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={0}>
      {professionalSkills.length > 0 ? (
        <Grid item xs={12}>
          <Card elevation={0}>
            <CardHeader
              avatar={<HowToRegOutlined style={{ color: '#757575' }} />}
              title={
                <Typography variant="h5" className={classes.text}>
                  Técnicas
                </Typography>
              }
              className={classes.cardHeader}
            />
            <CardContent
              className={classes.cardContent}
              style={{ marginLeft: 30 }}
            >
              <TagInput
                title="Técnicas"
                tags={professionalSkills}
                onChange={() => {}}
                editable={false}
              />
            </CardContent>
          </Card>
        </Grid>
      ) : (
        <></>
      )}
      {personalSkills.length > 0 ? (
        <Grid item xs={12}>
          <Card elevation={0}>
            <CardHeader
              avatar={<Icon style={{ color: '#757575' }}>attribution</Icon>}
              title={
                <Typography variant="h5" className={classes.text}>
                  Comportamentais
                </Typography>
              }
              className={classes.cardHeader}
            />
            <CardContent style={{ marginLeft: 30 }}>
              <TagInput
                title="Comportamentais"
                tags={personalSkills}
                onChange={() => {}}
                editable={false}
              />
            </CardContent>
          </Card>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default HabilidadesCard;
