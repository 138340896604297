import axios from 'axios';
import api from 'src/api';
import statesAbbreviationMap from 'src/utils/statesAbbreviationMap';

export class UtilService {
  private static instance: UtilService;

  constructor() {
    if (!UtilService.instance) {
      UtilService.instance = this;
    }
    return UtilService.instance;
  }

  findAddressByCep = async (CEP: string) => {
    CEP = CEP.replace(/\D/g, '');
    if (CEP.replace(/\D/g, '').length < 8) return { erro: true };

    const { data: address } = await axios.get(
      `https://viacep.com.br/ws/${CEP}/json/`
    );

    const {
      erro,
      cep,
      logradouro: street,
      bairro: neighborhood,
      localidade: city,
      uf: state
    } = address;
    return { erro, cep, street, city, state: erro ? '' : (statesAbbreviationMap.find(st => st.key === state).value), neighborhood, country: 'Brasil' };
  };

  getJobAnalyticsChart = async id => {
    const response = await api.get(`/jobs/${id}/chart-url`);
    return response.data || { error: 'error' };
  };
}

const utilService = new UtilService();
export default utilService;
