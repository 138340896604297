// nível profissional

export const skillLevelOptions = [
  { label: 'Operacional', value: 'OPERACIONAL', code: 1 },
  { label: 'Especialista', value: 'SPECIALIST', code: 2 },
  { label: 'Estágio', value: 'INTERNSHIP', code: 3 },
  { label: 'Trainee', value: 'TRAINEE', code: 4 },
  { label: 'Júnior', value: 'JUNIOR', code: 5 },
  { label: 'Pleno', value: 'INTERMEDIATE', code: 6 },
  { label: 'Sênior', value: 'SENIOR', code: 7 },
  { label: 'Coordenação', value: 'COORDINATOR', code: 8 },
  { label: 'Gerencial', value: 'MANAGER', code: 9 },
  { label: 'Diretoria', value: 'DIRECTOR', code: 10 },
  { label: 'Mestre', value: 'MASTER' },
  { label: 'Outro', value: 'OTHER' },
  { label: 'Arquiteto', value: 'ARCHITECT' },
  { label: 'Auxiliar', value: 'AUXILIARY' },
  { label: 'Assistente', value: 'ASSISTANT' }
].sort((a, b) => a.code - b.code);