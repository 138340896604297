import React from 'react';
import type { FC } from 'react';

import { Menu, MenuItem, Box, Button, Typography } from '@mui/material';
import { useHistory } from 'react-router';

import { useStyles } from './styles';

interface ChangeProductsProps {
  listItems: any;
  anchor: null | HTMLElement;
  handleClose: () => void;
}

export const ChangeProduct: FC<ChangeProductsProps> = ({
  anchor,
  handleClose,
  listItems
}) => {
  const open = Boolean(anchor);
  const classes = useStyles();
  const history = useHistory();
  const accessRouteIsInsights = history.location.pathname.includes('/insights');

  return (
    <Menu
      id="change-product-menu"
      aria-labelledby="change-product-menu"
      anchorEl={anchor}
      open={open}
      elevation={0}
      className={classes.changeProductRoot}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <Box className={classes.boxTextMenu}>
        <Typography className={classes.text}>
          Você está no {accessRouteIsInsights ? 'FLOW' : 'JOBS'}.
        </Typography>
        <Typography className={classes.subtitle}>Acesse também</Typography>
      </Box>

      {listItems?.map(({ option, icon, onClick }, index) => {
        return (
          <Box key={index}>
            <MenuItem
              onClick={onClick}
              key={index}
              className={classes.hoverMenuItem}
            >
              <Button
                variant="text"
                color="primary"
                fullWidth
                startIcon={icon}
                className={classes.productButton}
              >
                {option}
              </Button>
            </MenuItem>
          </Box>
        );
      })}
    </Menu>
  );
};
